// modules/MShop/App/PopUpStore/F/Style/Pc/index.styl
.is_popup_store
  display block

.checkout_body
  .is_popup_store_txt1
    display none

.creator-header__button--popup-mypage
  display none !important

html.is_popup_store
  .pd-guide--delivery
  .creator-header__button--menu
  .pd-product__price
  .pd-total
  .bottom-tab-bar
  .app-product-item__price
  .payment_type_container.section
  .coupon_point.section
  .pre-shipping-order-item__price-set__basic
  .cart-store-item__price-set__item--shipping
  .pd-purchase-info__body__desc
    display none !important
  .pd-guide.ruler
    border-bottom 0 none

  .creator-header__button--popup-mypage
    display flex !important
    &::before
      display none


  .checkout_body
    .is_popup_store_txt1
      font-style normal
      display inline !important


  .purchase_agree-wrapper
  .shipping_agree-wrapper
    .description
      display none
    .description--is-popup-store
      display block !important

  .shipping_agree-wrapper
    display block !important





html.is_popup_store_admin
  .admin_option
    margin-bottom 32px
    >*
      display none
    display flex
    justify-content center
    align-items center
    button
    a
      width 100%
      max-width 400px
      height 52px
      justify-content center
      align-items center
      background #000
      border 1px solid transparent
      border-radius 4px
      color #fff
      font-size 16px
      margin-right 16px
      &:last-child
        margin-right 0
  .customer-order-popup-store__orders
    display flex
  .popup_store_order_status_CREATED
    .customer-order-popup-store__done
      display flex
  .popup_store_order_status_PAID
    .customer-order-popup-store__cancel
      display flex
  .popup_store_order_status_DELETED
    .customer-order__order-at-wrapper
      background #ff0000 !important
  .customer-order__back-to-list
    display none !important
