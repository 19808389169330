body.creator-settings .don_page:not(.page_in_mp_maker)
  .btn.request_withdraw
    display flex
    align-items center
    justify-content center
    img
      width 16px
      margin-right 8px
      margin-top -2px
.studio-withdraw
  [data-show="false"]
    display none
  display flex
  align-items center
  &__amount
    font-size 15px
    letter-spacing -0.5px
    color #828282
  &__available
    margin-right 20px
    margin-left 12px
    color #3c89f9
    font-weight 600
