$color_studio_blue = #3c89f9
$studio_border_radius = pxToRem(4)

body.setting-ecommerce-info
  $form_padding = pxToRem(48)

  @import '../Share/index.styl';
  @import './font.styl';
  @import './seller_info_form.styl';



  .seller-info__forms form
    padding $form_padding 0
    border-bottom 1px solid #f2f2f2
    &:first-child
      padding-top 0
    &:last-child
      padding-bottom 0
      border-bottom none

  .studio-info-form__btn-save
    width pxToRem(160)
    height pxToRem(48)
    display block
    margin 0 auto
    margin-top pxToRem(12)
    color #fff !important
    font-weight 500
    letter-spacing -0.5px
    text-align center
    border-radius $studio_border_radius
    background-color #3c89f9
    border 1px solid #3c89f9
    box-sizing border-box
    &:disabled
      background-color #F2F2F2
      color #A2A2A2 !important
      border 1px solid #F2F2F2
      cursor not-allowed



  *:focus-visible
    outline none

  $tab_height = pxToRem(40)
  .ecommerce-info
    &__tabs
      display inline-flex
      margin 0
      margin-top pxToRem(40)
      padding 0
      height $tab_height
      list-style none
      border 1px solid $color_studio_blue
      border-radius $studio_border_radius
      overflow hidden

    &__tab-menu
      width pxToRem(300)
      > a
        display flex
        justify-content center
        align-items center
        width 100%
        height 100%
        line-height $tab_height
        color inherit !important
      &[data-is_active="true"]
        background-color $color_studio_blue
        color #fff
      &[data-is_active="false"]
        color $color_studio_blue

    &__form
      padding-top $form_padding
      &--padding0
        padding-top 0
    &__form-section
      margin-bottom pxToRem(40)
      &:nth-last-child(2)
        margin-bottom 0

    &__select-delivery-companies
      width 50%
    &__file
      display none
    &__business_file
      &[is_file="false"]
        .ecommerce-info__btn-file-del
        .ecommerce-info__btn-file-link
          display none
      &[is_file="true"]
        .ecommerce-info__btn-file
          display none
    &__mail-order-business
      margin-top pxToRem(6px)
    &__agree
      margin-top pxToRem(45px)
      padding pxToRem(20px) pxToRem(25px) pxToRem(19px)
      display flex
      align-items center
      border-radius pxToRem(3px)
      background-color #f8f8f8
      > div
        margin-right pxToRem(30px)
        display flex
        align-items center
        font-size 14px
        font-weight 500
        line-height 1.5
        letter-spacing -0.5px
        color #4c4c4c
        input
          margin 0 pxToRem(8px) pxToRem(2px) 0
          vertical-align middle
        span
          margin-left pxToRem(5px)
          font-weight bold
          text-decoration underline
    &__btn-terms
    &__btn-privacy
      cursor pointer

    &__alert
      h3
        margin 0
        padding 0
        color #111
        font-size 20px
        font-weight 800
        margin-bottom 12px
      p
        margin 0
        padding 0
        text-align center !important
        color #111 !important
        font-size 15px !important
        line-height 1.73 !important


  .studio-qna-type-control
  .studio-stores-delivery-companies
    &[qna_type="BOARD"]
      width 100% !important
      .studio-qna-type-control__sns
        display none
    &[qna_type="SNS"]
      width 100% !important
      .studio-qna-type-control__board
        display none
    &__board
      margin-top pxToRem(18px)
      p
        margin 0 0 pxToRem(4px)
        padding 0
        font-size pxToRem(13px)
        line-height 1.67
        letter-spacing -0.2px
        color #828282
      ul
        margin 0
        padding 0
        list-style none
      li
        font-size pxToRem(12px)
        line-height 1.67
        letter-spacing -0.2px
        color #828282
    &__sns
      .studio-info-form__body
        padding 0 !important
    &__sns-control
      margin pxToRem(10px) 0
    &__sns-txt1
      font-size pxToRem(13px)
      font-weight 500
      line-height 1.38
      letter-spacing -0.5px
      color #828282
  .studio-stores-delivery-companies
    padding 20px
    &__board
      margin-top auto
      padding pxToRem(24px)
      background #fafafa
      width 100%
    &__list
      margin-top pxToRem(20px)
    &__item
      align-items center
      justify-content space-between
      margin-top pxToRem(12px)
      display none
      &--show
        display flex
    &__left
      letter-spacing -0.5px
      color #a2a2a2
      display flex
      align-items center
      font-weight 500
    &__icon
      width 20px
      height 20px
    &__icon-bg
      padding 6px
      border-radius 3px
      background-color #ebf3fe
      display flex
      align-items center
      justify-content center
    &__name
      color #4e4e4e
      margin-left 12px

    &__delivery_company_id
      margin-left 8px
    &__margin-left3
      margin-left 3px
    &__right
      width 86px
      display flex
      align-items center
      justify-content space-between
    &__btn
      border-radius 4px
      font-size 13px
      border none
      font-weight bold
      width 39px
      height 24px
      &--modify
        background-color #ebf3fe
        color $color_studio_blue !important
      &--remove
        background-color #fee8e8
        color #f66565 !important