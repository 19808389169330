input[type="checkbox"] {
  display none !important
}

.checkbox-icon {
  display inline-block
  width 18px
  height 18px
  border 1px solid #DDDDDD
  border-radius 4px
  cursor pointer
  margin-right pxToRem(8)
}

input[type="checkbox"]:checked + .checkbox-icon {
  background url('./checkbox.svg') center no-repeat
  background-color $color_studio_blue
  border-color $color_studio_blue
  background-size 20px
}

