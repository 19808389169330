/*
 * ------------------스토어---------------------
 * 이미지, 뱃지, (상품이름 || 제품이름), 가격, (상품이름 ? bp이름 : null), 좋아요 버튼
 *  - 스토어(상품)
 * not-like 이미지, 뱃지, (상품이름 || 제품이름), 가격, (상품이름 ? bp이름 : null)
 *  - 스토어(커뮤니티) (마지막)
 *  < 가로형
 *    - 리뷰(스토어안에서 전부)
*/
.{a-p-i}--v4
  width 50%
  padding 0 3.5px
  margin-bottom 50px
  box-sizing border-box
  .{a-p-i}
    &__p-name
      letter-spacing -0.2px
      margin-bottom 4px
      font-size 15px
      font-weight 600
      letter-spacing -0.3px
      color #111
      line-height normal
      height 20px
    &__category-name
      display block
      line-height normal
      margin-bottom 7px
      letter-spacing -0.3px
      color #828282
      height 17px
    &__like
      display block
    &__price
      font-size 18px
      font-weight 600
      letter-spacing -0.5px
      color #111
      line-height normal
      height 25px
  &.grid-stack-container__item
    width 100%
.{a-p-i}--v4-not-like
  width 224px
  .{a-p-i}
    &__like
        display none