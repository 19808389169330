.app-home-popular-product
  &__list
    position relative
  &__list-wrapper
    width 100%
    display grid
    grid-template-columns: repeat(auto-fill, minmax(18%, 1fr));
    grid-gap pxToRem(25)
  &__items
    display flex
    width auto
  &__item
    width 100%
    height 100%
    border-radius pxToRem(16px)
    overflow hidden
    flex-shrink 0
    img
      width 100%


