// modules/MShop/App/CustomerOrderReceipt/F/Style/Pc/index.styl
.receipt-frame
  >.don_wrapper
    width 608px !important
    border-radius pxToRem(16px) !important
    >.header
      padding 0 !important
    >.body
      >.don_page
        &.receipt-history:not([page-index="0"]) >.don_wrapper >.header >.title
          display flex
          justify-content flex-start
          align-items center
        >.don_wrapper >.header
          width 100%
          box-sizing border-box
          padding ptr(16) ptr(16) ptr(16) ptr(24)
          margin 0
          height ptr(56)
          border-bottom 1px solid $gray-5
          >.title
            {$kr-16-b}
          & .don_hide_frame
            top ptr(18) !important
            right ptr(16) !important
            width ptr(20) !important
            height ptr(20) !important
        &:not([page-index="0"]) >.don_wrapper >.header
          >.title
            display flex
            justify-content center
            align-items center
          >.don_back_page
            background svgToData("%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1837_227331)'%3E%3Cpath d='M14.0647 3.33594C14.0647 3.51927 13.998 3.71094 13.848 3.8526L7.69805 10.0026L13.848 16.1526C14.1314 16.4359 14.1314 16.9026 13.848 17.1859C13.5647 17.4693 13.098 17.4693 12.8147 17.1859L6.14805 10.5193C5.86471 10.2359 5.86471 9.76927 6.14805 9.48594L12.8147 2.81927C13.098 2.53594 13.5647 2.53594 13.848 2.81927C13.9897 2.96094 14.0647 3.1526 14.0647 3.33594Z' fill='%23141414'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1837_227331'%3E%3Crect width='20' height='20' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat
            background-size ptr(20) ptr(20)
            width ptr(20)
            height ptr(20)
            top ptr(18)
            left ptr(16)




.receipt-customer-order
  padding ptr(16)
  &__header
    padding pxToRem(16) pxToRem(24)
    background-color $gray-5
    display flex
    align-items center
    justify-content space-between
    margin-bottom ptr(24)
    border-radius ptr(8)
  &__paid-at
    display flex
    column-gap ptr(12)
    &__title
      color $gray-80
    &__date
      color $black
  &__purchase
    padding pxToRem(4px) pxToRem(8px)
    margin 0
    border 1px solid $gray-20
    background-color white
    color $gray-80
    backdrop-filter blur(20px)
    -webkit-backdrop-filter blur(20px)
    border-radius ptr(4)
.receipt-shipping-order-group__shipping-orders
  border-top 1px solid $ness-gray-5
  margin-top pxToRem(12)
  &:first-child
    border-top-color transparent
    margin-top 0
.receipt-store-order
  &__header
    border-bottom 1px solid $black
    padding-bottom ptr(12)
    margin-bottom ptr(16)
    display flex
    align-items center
    column-gap pxToRem(4)
  &__logo
    order 2
    {$image-store-logo}
    width pxToRem(20)
    height pxToRem(20)
  &__name
    order 1
    color $black
.receipt-shipping-order
  display flex
  flex-direction column
  row-gap ptr(24)
  &__content
    display flex
    align-items center
    justify-content space-between
  &__name
    color $black
  .seller-delivery-type-logo
    display flex
    align-items center
    &:after
      content ""
      {$image-seller-delivery-logo}
      margin-left pxToRem(4px)
    &:before
      display none
  .marpple-delivery-type-logo
  .fast_marpple-delivery-type-logo
    display flex
    align-items center
    &:after
      content ""
      {$image-marpple-delivery-logo}
      margin-left pxToRem(4px)
    &:before
      display none
  .digital-delivery-type-logo
    display flex
    align-items center
    &:after
      content ""
      {$image-digital-delivery-logo}
      margin-left pxToRem(4px)
    &:before
      display none


  &__shipping-price-info
    display flex
    align-items center
    column-gap pxToRem(4)
  &__price
    color $black
    &:after
      {$kr-14-m}

.receipt-up-c
  &__content
    display flex
    align-items center
    column-gap pxToRem(12)
  &__thumbnail
    background-color $gray-5
    display flex
    overflow hidden
    border-radius ptr(4)
    img
      width pxToRem(72px) !important
      height pxToRem(72px) !important
      border-radius pxToRem(4px)
      object-fit contain
  &__canvas-wrapper
    display flex
    canvas
      width pxToRem(72px) !important
      height pxToRem(72px) !important
      border-radius pxToRem(4px)
  &__right
    display flex
    flex-direction column
    row-gap pxToRem(4)
  &__id
    color $gray-80
  &__product-name
    color $black
  &__product-price
    color $black
    &:after
      {$kr-20-b}
.receipt-page-button
  width 100%
  margin-top pxToRem(16)
  padding pxToRem(8) pxToRem(24)
  border 1px solid $gray-20
  border-radius pxToRem(8)
  background-color $white
  height ptr(40)
  color $gray-80

.purchase-receipt-customer-order
  padding pxToRem(16px)
  padding-bottom calc(env(safe-area-inset-bottom) + 112px)
  padding-bottom calc(constant(safe-area-inset-bottom) + 112px)
  &__title
    display none
  &__info
    display flex
    flex-direction column
    row-gap pxToRem(16)
    margin-bottom pxToRem(24)
    border-bottom 1px solid $gray-5
    padding-bottom pxToRem(24)
    &:last-child
      border-bottom 0
      margin-bottom 0
  &__info-title
    color $black
  &__info-left
    color $gray-50
    flex-shrink 0
    min-width ptr(128)
  &__info-right
    color $black
  &__row
    display flex
    align-items center
    justify-content space-between
    color $black
    column-gap ptr(16)
    .mp_currency
      &:after
        {$kr-16-m}
    &--red
      color $red-80
    &--blue
      color #606060 !important
  &__result-price
    color $black
    &.mp_currency
      &:after
        {$kr-20-b}

  &__buttons
    position fixed
    bottom 0
    left 0
    right 0
    background-color $white
    padding pxToRem(16px)
    border-top 1px solid $gray-5
    z-index 10
    display flex
    column-gap pxToRem(16px)
    button
      width 100%
      height pxToRem(56px)
      border-radius pxToRem(8px)
  &__pdf
    background-color $white
    border 1px solid $gray-20
    color $gray-80
  &__print
    background-color $blue-100
    border none
    color $white
  &__cancel
    box-sizing border-box
    display flex
    align-items center
    column-gap pxToRem(6)
    width 100%
    background-color $red-10
    color $red-80
    padding pxToRem(16)
    margin-bottom pxToRem(24)
    border-radius ptr(8)
/*
 영수증 프린트
*/
@media print
  body
  .don_frame
    display none !important
    &.receipt-frame
      display block !important
      >.don_wrapper
        max-width 100% !important
        max-height max-content !important
        width 100% !important
        border-radius unset !important
        box-shadow unset !important
        >.body
          transform scale(0.8) !important
      .header
      .purchase-receipt-customer-order__buttons
        display none !important
      .purchase-receipt-customer-order
        padding 0 !important
        &__cancel
          display none
