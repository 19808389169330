.seller-info__form
  $border_radius = pxToRem(4)
  $border = 1px solid #F2F2F2

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .studio-info-form
    display flex
    flex-wrap wrap
    row-gap pxToRem(8)
    flex-basis 50%
    flex-grow 1


  .studio-info-form__control
    overflow hidden
    &:nth-child(1)
      width 50%
      border-block $border
      border-left $border
      border-radius $border_radius 0 0 $border_radius
    &:nth-child(2)
      width 50%
      border-block $border
      border-right $border
      border-radius 0 $border_radius $border_radius 0
    &:nth-child(3)
      width 100%
      border $border
      border-radius $border_radius
      .studio-info-form__label
        justify-content flex-start
      .studio-info-form__body-wrap
        max-width calc(50% - 240px)

  .seller-info__agree
    margin-top pxToRem(20)
    display flex
    align-items center
    border-radius pxToRem(3px)
    column-gap pxToRem(24)


  .seller-info__agree-item
    color #111
    font-size pxToRem(14)
    font-weight 400
    line-height 150%
    letter-spacing -0.3px
    display flex
    align-items center
    > label
      display flex
      align-items center
    > button
      border none
      background-color transparent
      font-weight 600
      text-decoration underline


.certification-form
  &__mobile
    position relative
    container-type inline-size
    container-name mobile
    &[data-is_certified="true"]
      .certification-form__mobile-certified
        display flex
    &[data-is_certified="false"]
      .certification-form__mobile-certified
        display none
  &__mobile-certified
    position absolute
    right 0
    top 50%
    transform translateY(-50%)
    display flex
    align-items center
    column-gap pxToRem(8)
    padding-left pxToRem(6)
    margin-right pxToRem(12)
    color $color_studio_blue
    background-color white
    &__icon
      display flex
      width pxToRem(18)
      align-items center
    &__text
      font-size pxToRem(14)
      font-weight 400
      line-height 150%
      letter-spacing -0.3px

@container mobile (max-width: 200px) {
  .certification-form__mobile-certified__text  {
    display none;
  }
}
