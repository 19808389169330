@import '../../../../../../www/css/creator/crew/crew.banner.mixins.styl';

.don_frame.plus-style-editor-modal > .don_wrapper
  width 600px
  height 834px
  border-radius 10px
  overflow hidden
.don_frame.plus-style-editor-modal > .don_wrapper > .header
  position absolute
  top 0
  left 0
  display flex
  justify-content center
  align-items center
  flex-direction row
  width 100%
  background-color #fff

.plus-style-editor
  position relative
  padding 56px 40px 0
  &__image-box
    margin-top 30px
    position relative
    box-sizing border-box
    width 100%
    &--hide
      display none
      & *
        display none
  &__image
    width 100%
    vertical-align top
  &__delete-image
    position absolute
    top -15px
    right -15px
    deleteButton()
  &__upload
    width 100%
    &--hide
      display none
  &__upload-button
    reset-button()
    imageUploadBox()
    width 100%
    padding-bottom 100%
  &__contents
    padding 30px 0
    width 100%
  &__input
    inputStyle()
  &__label
    margin-bottom 10px
    display block
  &__file
    blind()
  &__button-box
    margin-top 15px
    display grid
    grid-template-columns repeat(auto-fit, minmax(calc(50% - 5px), auto))
    grid-column-gap 10px
  &__delete
    whiteButton()
  &__submit
    submitButton()
