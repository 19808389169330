
.settings-question-detail
  .question-detail
    padding-top ptr(42)

  .w_br
  .m_br
    display block


.question-detail
  padding ptr(72) ptr(232)
  display flex
  flex-direction column
  row-gap ptr(48)

  &__question
    display flex
    flex-direction column
    row-gap ptr(16)

  &__default-info-wrap
    display flex
    flex-direction column
    row-gap ptr(8)

  &__h2
    margin 0
    padding 0
    color $black

  &__user
    display flex
    flex-direction column
    row-gap ptr(16)

  &__header
    position relative

  &__btn-fold
    display none

  &__info
    display flex
    column-gap ptr(16)
    min-width ptr(134)

  &__head
    color $gray-50
    min-width ptr(134)

  &__content
    color $black

    & > a
      text-decoration underline

  &__head-date
    color $gray-80

  &__body
    max-height pxToRem(400px)
    overflow auto

  &__info-box
    margin-top ptr(16)
    padding pxToRem(24px)
    border-radius pxToRem(16px)
    border none
    background-color $gray-5
    display flex
    column-gap ptr(16)

    &[data-is-answer="true"]
      background-color $blue-10

  &__info-box-left
    min-width ptr(180)
    display flex
    flex-direction column
    row-gap ptr(4)

    &__title
      color $black

      &[data-is-answer="true"]
        color $blue-100

    &__date
      color $gray-80

      &[data-is-answer="true"]
        color $blue-100

  &__info-box-right
    display flex
    flex-direction column
    row-gap ptr(8)

    &__content-wrap
      display flex
      column-gap ptr(8)

    &__content-title
      min-width ptr(72)
      color $gray-80

    &__content-body
      color $black
      overflow auto

      & > a
        text-decoration underline

  &__file
    display flex
    justify-content space-between
    align-items center
    margin pxToRem(25px) 0 0
    padding pxToRem(17px) pxToRem(19px) pxToRem(17px) pxToRem(20px)
    border-radius pxToRem(4px)
    background-color #f2f2f2

  &__file-txt
    display block
    color $black
    text-decoration underline
    limitLine(1)

  &__file-down
    img
      width pxToRem(14px)

  &__buttons
    margin-top pxToRem(32px)

  &__buttons-area
    min-height pxToRem(56px)

    &--add-question
      display grid
      grid-gap pxToRem(10px)
      grid-template-columns repeat(2, 1fr)

      > *
        width 100%

  &__btn-add-question
    border-radius ptr(8px)
    border solid 1px $gray-20 !important
    color $gray-80 !important
    background-color $white !important
    text-align center
    {$button-white-theme}

  &__btn-complete
    border-radius ptr(8px)
    border none
    color $white !important
    background-color $blue-100 !important
    text-align center
    {$button-blue-theme}


.add-question-item-editor
  &__required
    color #1C75FF

  &__error-msg
    display none
    margin pxToRem(7px) 0 0
    padding 0
    font-size pxToRem(13px)
    font-weight 500
    line-height 1.38
    letter-spacing -0.5px
    color #f66565

    &--show
      display block

  &__control
    position relative
    margin-top pxToRem(48px)

    &:firat-child
    &--mt0
      margin-top 0

  &__control-area
    position relative

    &--cols2
      display grid
      grid-gap pxToRem(10px)
      grid-template-columns repeat(2, 1fr)

  &__label
    display block

    &--inline
      display inline-flex
      align-items center

      input
        margin-bottom pxToRem(4px)
        margin-right pxToRem(5px)

    &--lm10
      margin-left pxToRem(10px)

    &--lm-minus3
      margin-left pxToRem(-3px)

  &__label-txt
    margin-bottom pxToRem(8px)
    color $black

  &__label-txt-sub
    font-weight 400
    color #757575

  &__label-txt-sub2
    font-weight 400
    color #3d89f9

  &__body
    position relative
    min-height pxToRem(48px)
    border-bottom 1px solid $gray-10

    &--error
      border-color #f66565

    &--radio
    &--check
      min-height auto
      margin-top pxToRem(12px)
      border 0 none
      border-radius 0

    &--is_public
      min-height pxToRem(20px)

    &--textarea
      padding ptr(12) ptr(16) ptr(12) 0

    .flatpickr-wrapper
      position absolute
      top 0
      left 0
      right 0
      bottom 0

    &--terms
      padding pxToRem(10px)
      height pxToRem(160px)
      overflow auto

    &--file
      width 100%
      position relative

      &[can_cancel="false"]
        .add-question-item-editor__body-file-del
          display none

  &__body-area
    position relative

    &--cols2
      display grid
      grid-gap pxToRem(10px)
      grid-template-columns repeat(2, 1fr)

  &__body-input
  &__body-textarea
    color $black
    border 0 none !important
    background-color transparent !important
    outline none

    &::placeholder
      color #b2b2b2
      font-weight 500

  &__body-input
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    padding pxToRem(6px) pxToRem(24px) pxToRem(5px)

  &__body-textarea
    padding 0
    width 100%
    height pxToRem(72px)
    resize none

  &__body-textarea-length
    margin-top 0
    text-align right
    color $gray-50

  &__txt1
    margin 0
    margin-top pxToRem(8px)
    color $gray-50

  &__body-right
    position absolute
    top 0
    right 0
    bottom 0
    padding 0 pxToRem(11px)
    display flex
    align-items center

  &__control-wrap
    display flex
    align-items center
    justify-content space-between

  &__input-file
    position absolute
    top 0
    left 0
    width 0
    height 0
    opacity 0

  &__body-file-text
    position absolute
    top 50%
    left 0
    right pxToRem(60px)
    transform translateY(-50%)
    limitLine(1)

  &__body-file-del
    position absolute
    top 50%
    right pxToRem(20px)
    transform translateY(-50%)
    cursor pointer

    img
      width pxToRem(20px)

  &__btn-file-area
    width pxToRem(160px)
    height pxToRem(48px)
    margin-left pxToRem(16px)
    display flex
    align-items center
    justify-content center

    label
      position relative
      display block
      width 100%
      height 100%
      overflow hidden

  &__btn-file
    width 100%
    height 100%
    box-sizing border-box
    border-radius pxToRem(4px)
    text-align center
    color $blue-100 !important
    background-color $blue-10 !important
    border none

  &__buttons
    margin-top pxToRem(48px)

  &__btn-submit
    width 100%
    height pxToRem(56px)
    padding 0
    color $gray-50 !important
    background-color $gray-5 !important
    text-align center
    border 0 none
    border-radius pxToRem(8px)
    pointer-events none

    &[data-is-active="true"]
      pointer-events auto
      color $white !important
      background-color $blue-100 !important
      transition background-color 200ms ease-out

      &:hover:not(&:disabled,&.disabled)
        background-color $blue-10 !important
        color $blue !important
