.creator-my-review
  padding pxToRem(48) 0
  .review-recent-list
    padding 0
  &__title
    padding 0
    margin 0 0 pxToRem(36)

.creator-my-cheerup-list
  margin-top ptr(24)
