
.token-gate
  margin 24px 0 12px
  &__flex
    display flex
  &__flex-1
    flex 1
    min-width 0
  &__label
    margin-right 34px
    flex-shrink 0
  &__btn
    width 100%
    margin 24px 0 0
    padding 16px 0 14px
    font-weight 600
    text-align center
    color #ffffff
    border none
    outline none
    display flex
    align-items center
    justify-content center
    background-color #111111
    font-size 16px
    border-radius 8px
    box-sizing border-box
    letter-spacing -0.2px
    svg
      margin-right 8px
  &__btn-colorful
    background-image linear-gradient(278deg, #4b6be0, #b32dfd) !important
    border-style solid
    border-width 1px
    border-image-slice 1
    border-color initial
    background-origin border-box
    svg
      margin-right 8px
    &:disabled
      background-image linear-gradient(278deg, #4b6be0, #b32dfd) !important
      border-style solid
      border-width 1px
      border-image-slice 1
      background-origin border-box
  &__btn-border-colorful
    border 1px solid transparent
    background-image linear-gradient(to bottom, #fff, #fff), linear-gradient(285deg, #4b6be0, #b82dfd)
    background-origin border-box
    background-clip content-box, border-box
    padding 0

.token-gate-list
  margin-bottom 14px
.token-gate-item
  display flex
  align-items center
  justify-content space-between
  margin-bottom 10px
  &:last-child
    margin-bottom 0
  &__chain-icon
    margin-right 6px
    margin-top -3px
    display flex
    align-items center
  &__chain
    display flex
    align-items center
    width 24%
    color #424242
    overflow hidden
    text-overflow ellipsis
    white-space nowrap
    flex-shrink 0
  &__name
    width 66%
    display flex
    align-items center
    flex-shrink 0
    a
      width 100%
      overflow hidden
      text-overflow ellipsis
      white-space nowrap
      color #828282
    a:hover
      text-decoration underline
  &__icon
    display flex
    align-items center
    justify-content center
    padding 2px
    margin-top -4px
    width 16px
    flex-shrink 0
    &[data-status="pass2"]
      visibility hidden
      width 18px
    img
      width 100%

