.article-editor-frame[is_modal="true"]
  > .don_wrapper
    width ptr(448)
    min-height ptr(560)
    max-height ptr(618)
    border-radius: ptr(16) !important;
    > .header
      padding: ptr(22) ptr(16);
      border-bottom: 1px solid var(--Gray-5, #F5F5F5);
      .title
        font-size: ptr(16);
        color: $black;
        font-family: Pretendard;
        font-weight: 700;
        line-height: ptr(24);
        letter-spacing: -0.2px;

.article-editor
  padding ptr(16) 0 0
  height 100%
  overflow auto
  &__body
    margin: 0 ptr(16);
  &__textarea
    width 100%
    margin 0
    padding 0
    min-height ptr(260)
    font-size ptr(13)
    font-weight normal
    font-stretch normal
    font-style normal
    line-height 1.38
    letter-spacing -0.2px
    color #111
    box-sizing border-box
    border 0;
    border-bottom 1px solid $gray-10;
    resize none
    outline none
    &::placeholder
      color #a2a2a2
  &__body-p
    margin: ptr(8) 0 0;
    padding: 0;
    color: $gray-50;
    font-family: Pretendard;
    font-size: ptr(12);
    font-weight: 500;
    line-height: ptr(17);
    letter-spacing: -0.2px;
  &__upload
    margin ptr(22) ptr(16) 0
  &__upload_title
    margin 0
    padding 0
    font-size ptr(14)
    letter-spacing -0.2px
    color $black;
    font-family: Pretendard;
    font-weight: 700;
    line-height: ptr(22);
    letter-spacing: -0.2px;
  &__upload_txt1
    color: $gray-50;
    font-family: Pretendard;
    font-size: ptr(12);
    font-weight: 500;
    line-height: ptr(17);
    letter-spacing: -0.2px;
  &__require
    position: relative;
    color $white
    &:after
      content ''
      display block
      width ptr(4)
      height ptr(4)
      position absolute
      top ptr(2)
      left 0
      border-radius ptr(2)
      background-color $blue-100
  &__upload_list
    margin ptr(10) 0 0
    padding 0
    list-style none
    display flex
    flex-wrap wrap
    align-items center
    li:nth-child(5n)
      margin-right 0
  &__upload_item
    position relative
    width ptr(68)
    height ptr(68)
    overflow hidden
    margin 0 ptr(8) ptr(6) 0
    > img
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      margin auto
      width: 100%;
      height: 100%;
      object-fit cover;
      border-radius: ptr(8);
  &__btn_add
    position relative
    width ptr(68)
    height ptr(68)
    margin 0 ptr(8) ptr(6) 0
    display inline-block
    box-sizing border-box
    cursor pointer
    border-radius: ptr(8);
    border: 1px dashed $gray-20;
    svg
    img
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      margin auto
  &__upload_item_del
    position absolute
    top 0
    right 0
    width ptr(21)
    height ptr(21)
    cursor pointer
    background-color #111
    border-radius: ptr(8);
    img
      width ptr(9)
      height ptr(9)
      position absolute
      top 0
      right 0
      bottom 0
      left 0
      margin auto
  &__file_input
    display none
  &__btn_area
    border-top 1px solid $gray-5
    margin-top ptr(16)
    padding ptr(16)
  &__submit
    width 100%
    height ptr(56)
    font-size ptr(16)
    letter-spacing -0.2px
    color: $gray-50;
    text-align: center;
    font-family: Pretendard;
    font-weight: 700;
    line-height: ptr(24);
    letter-spacing: -0.2px;
    background $gray-5
    border 0 none
    border-radius ptr(8)
    &--on
      color $white
      background-color $blue-100


