new-maker_font_14_r_140 =
  font-style normal
  font-weight 400
  font-size 14px
  line-height 140%
.image_selector_wrap
  position absolute
  top 0
  z-index 3
  width 100%
  height 100%
  .maker-image-selector
    display flex
    flex-direction column
    border 1px solid #E8E8E8
    border-radius 8px
    background white
    user-select none
    &__head
      font-size 15px
      padding 12px 16px
      display flex
      justify-content space-between
      align-items center
      cursor pointer
    .hide
      display none
    &__options
      display grid
      padding 12px
      max-height 452px
      border-top 1px solid #E8E8E8
      overflow auto
      box-sizing border-box
      .paper_option
        padding 12px
        text-align center
        color #858585
        cursor pointer
        &__img[data-column_length="2"]
          max-width 164px
        &__img[data-column_length="3"]
          max-width 64px
        &__img
          width 100%
        &__name
          padding-top 12px
          font-size 14px
        &:hover
          background #F6F6F6
          border-radius 16px
      .selected_option
        color #FF6B00

@import "checkbox.styl"
@import "ConditionAgreeText.styl"
@import "ConditionPopu.styl"
@import "ConditionTooltip.styl"
@import "Marpplizer.styl"

