pb-item-order-detail_border = #ddd
pb-item-order-detail_color_ededed = #ededed
pb-item-order-detail_border_f2f2f2 = #f2f2f2
pb-item-order-detail_font_color_111 = #111;
pb-item-order-detail_color_3c89f9 = #3c89f9;
pb-item-order-detail_font_color_4e4e4e = #4e4e4e;
pb-item-order-detail_font_color_f66565 = #f66565;
pb-item-order-detail_background_fafafa = #fafafa;
pb-item-order-detail_background_f8fbff = #f8fbff;
pb-item-order-detail_font_weight_500 = 500;
pb-item-order-detail_font_weight_600 = 600;
pb-item-order-detail_font_13 = 13px;
pb-item-order-detail_font_14 = 14px;
pb-item-order-detail_font_15 = 15px;

.pb-item-order-detail
  .refund-content
  .cancel-content
    display none
  &[data-can_cancel="true"]
    .cancel-content
      display block
  &[data-can_refund="true"]
    .refund-content
      display block
  button
    background none
    outline none
    border 0
  &__footer
    display flex
    justify-content center
    padding 0 40px 40px 40px
    .close
      border 1px solid pb-item-order-detail_border
      font-size pb-item-order-detail_font_14
      padding 12px 55px
      border-radius 2px
  &__content
    margin-bottom 30px
    padding 0 40px
  .section
    &__title
      font-size pb-item-order-detail_font_15
      font-weight bold
      color pb-item-order-detail_font_color_111
      margin-bottom 10px
      &:before
        content "• "
    &__content
      &.id-number
        padding 18px 20px
        background pb-item-order-detail_background_f8fbff
        font-size pb-item-order-detail_font_14
        font-weight pb-item-order-detail_font_weight_600
        color pb-item-order-detail_font_color_4e4e4e
        a
          color pb-item-order-detail_color_3c89f9
          margin-right 15px
          margin-left 4px
      &.refund-content-explain
      &.cancel-explain
        background pb-item-order-detail_background_f8fbff
        font-size pb-item-order-detail_font_13
        color pb-item-order-detail_font_color_4e4e4e
        padding 18px 20px
        list-style none
        li
          &:before
            content "• "
          margin-bottom 4px
  .refund-content
    form
      select
        padding-left 10px
        -webkit-appearance none
        -moz-appearance none
        background $image-down-arrow no-repeat 97% center
        background-size 18px 18px
        cursor pointer
      input[name="quantity"]
        padding-left 13px
      &[data-is_charge_in_seller="true"]
        select[name="description"]
          option[data-is_charge_in_seller="false"]
            display none
      &[data-is_charge_in_seller="false"]
        select[name="description"]
          option[data-is_charge_in_seller="true"]
            display none
  .row
    color pb-item-order-detail_font_color_4e4e4e
    font-size pb-item-order-detail_font_14
    border 1px solid pb-item-order-detail_border_f2f2f2
    border-radius 4px
    display flex
    margin-bottom 6px
    > *
      flex 1
      display flex
      &:not(.empty)
        border-left 1px solid pb-item-order-detail_border_f2f2f2
      > *
        flex 1
      &:first-child
        border-left 0
    &__label
      background pb-item-order-detail_background_fafafa
      padding 18px 20px
      border-right 1px solid pb-item-order-detail_border_f2f2f2
      max-width 240px
      min-width 100px
    &__content
      padding 18px 20px
      select
      input
        width 100%
        height 100%
  form
    .section__buttons
      margin-top 2px
      display flex
      justify-content center
    .red-explain
      font-size pb-item-order-detail_font_13
      font-weight pb-item-order-detail_font_weight_500
      color pb-item-order-detail_font_color_f66565
    .input
      padding 5px
      width 100%
      input
      select
        border 1px solid pb-item-order-detail_color_ededed
        border-radius 2px
    .submit
      background pb-item-order-detail_color_3c89f9
      border-radius 2px
      color #fff
      font-size pb-item-order-detail_font_14
      font-weight pb-item-order-detail_font_weight_500
      padding 9px 18px
    .submit-cancel-all
      background #fff
      border 1px solid pb-item-order-detail_border
      border-radius 2px
      color pb-item-order-detail_font_color_4e4e4e
      font-size pb-item-order-detail_font_14
      font-weight pb-item-order-detail_font_weight_500
      padding 9px 18px
      margin-left 6px
    .radio_input
      display flex
      align-items center
      > *
        display flex
        align-items center
        > *
          cursor pointer
          width 100px
      input
        margin 0
        width 40px
      label
        margin-right 30px
        display block
        &:last-child
          margin-right 0
