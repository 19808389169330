.main-section
  .table__wrapper
    position relative
    height calc(100vh - 48rem)
    min-height 16.3rem
    overflow scroll
  .main-section__pagination
    display block
  &[data-total_page_count="1"]
    .main-section__pagination
      display none
    .table__wrapper
      position relative
      height calc(100vh - 44rem)
      min-height 16.3rem
      overflow scroll
.main-section__table-v2
  .table
    background #fff
    border 1px solid $gray-s2
    input[type="checkbox"]
      width pxToRem(17px)
      height pxToRem(17px)
      cursor pointer
    input[type="text"]
    select
      text-align center
      height pxToRem(30px)
      width 100%
      border 1px solid $gray-s1
      border-radius 2px
    &__head
      position sticky
      width fit-content
      top 0
      background #fff
      z-index 2
    &__body
      z-index 1
    &__row
      width fit-content
      display flex
      min-height pxToRem(58px)
    &__th
    &__td
      font-size pxToRem(14px)
      padding 0 4px
      display flex
      justify-content center
      align-items center
      flex-shrink 0
      >div
        limitLine(2)
        word-break keep-all
        word-wrap break-word
    &__right
      width 100%
    &__head
      .table__row
        border-bottom 1px solid #000
        height pxToRem(58px)
      .table__th
        font-weight 600
    &__body
      .table__row
        border-bottom 1px solid $gray-s1
    .checkbox
    .checkbox_all
      width 57px
    [data-key="주문구분"]
    [data-key="상품타입"]
      display none
    [data-key="진행상태"]
      width 80px
      text-align center
    [data-key="주문일시"]
    [data-key="주문번호"]
    [data-key="상품번호"]
    [data-key="옵션"]
    [data-key="수량"]
    [data-key="주문자 이름"]
    [data-key="수령인 이름"]
    [data-key="연락처1"]
    [data-key="연락처2"]
    [data-key="우편번호"]
    [data-key="상품금액"]
    [data-key="배송비"]
    [data-key="결제수단"]
    [data-key="환불여부"]
    [data-key="구매확정일"]
    [data-key="합배송번호"]
    [data-key="요청일시"]
    [data-key="완료일시"]
    [data-key="환불 수량"]
    [data-key="환불 금액"]
    [data-key="환불 사유"]
    [data-key="상품 가격"]
      text-align center
      width 100px
    [data-key="택배사"]
    [data-key="주문자 이메일"]
    [data-key="배송정책"]
    [data-key="상품명 별칭"]
    [data-key="스토어"]
      text-align center
      width 150px
    [data-key="상품명"]
      text-align center
      width 300px
      a
        color #000 !important
    [data-key="운송장번호"]
      text-align center
      width 130px
    [data-key="배송지"]
      text-align left
      width 500px
      &.table__td
        justify-content start
    [data-key="배송메모"]
      text-align left
      width 250px
      &.table__td
        justify-content start
    [data-key="상품번호"]
      cursor pointer
    [data-key="상품명"]
    [data-key="상품명 별칭"]
    &__td:has(.to-pb-item-order-detail)
      cursor pointer
