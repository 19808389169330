.app-home-limit-product
  max-width pxToRem(1920)
  width 100%
  padding 0 pxToRem(180)
  box-sizing border-box
  &[data-length="1"]
    display flex
    flex-direction column
    align-items center
    .app-home-limit-product__list
      width 33%
      grid-template-columns repeat(1, minmax(100%, pxToRem(500)))
  &[data-length="2"]
    display flex
    flex-direction column
    align-items center
    .app-home-limit-product__list
      width 66%
      grid-template-columns repeat(2, minmax(50%, pxToRem(500)))
  &__wrapper
    width 100%
    background-color $dim-90
    display flex
    align-items center
    justify-content center
    flex-direction column
    padding pxToRem(80) 0
  &__list
    width 100%
    display grid
    grid-template-columns repeat(3, minmax(30%, pxToRem(500)))
    grid-gap pxToRem(24)

  .app-product-item--home-limit
    width 100%
    margin 0
    .app-product-item
      &__bottom-info
        height unset
      &__store-name
        display none
      &__p-name
        color $white
      &__category-name
        color $white
      &__price
        color $white
      &__review-info
        color $white
