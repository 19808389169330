.don_dialog_msg
  .content
    padding 50px 50px 36px !important
    >.footer
      .buttons
        button
          font-weight bold
    >.body
      .msg
        .custom-body
          display flex
          flex-direction column
          align-items center
          gap 12px
          h1
            padding 0
            margin 0
            font-size 20px
            font-weight 800
            line-height 1.3
            letter-spacing -0.3px
            text-align center
            color $gray-1
          p
            padding 0
            margin 0 !important
            font-size 15px
            line-height 1.73 !important
            letter-spacing -0.2px
            text-align center
            color $gray-1
          span
            font-size 14px
            font-weight 600
            line-height 1.57
            letter-spacing -0.2px
            text-align center
            color #3c89f9



.don_dialog_msg
  .content
    >.body
      .msg
        .youtube-link-alert
          &__ol
            margin pxToRem(10) pxToRem(6) 0 pxToRem(20)
            padding 0 0 14px
          &__ol-li
            color #828282
            font-size pxToRem(15)
            font-weight 400
            line-height 120%
            letter-spacing -0.3px
            text-align left
          &__txt1
            margin 0 !important
            color #111
            font-size pxToRem(18) !important
            font-weight 600
            text-align center
          &__txt2
            color #828282
            font-size pxToRem(15) !important
            font-weight 400
            text-align center
          &__txt3
            color #3C89F9
            font-size pxToRem(16) !important
            font-weight 400
            text-align center
            a
              color #3C89F9
