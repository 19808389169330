body.creator-settings
  .settings-content
    .header
      .studio-open-api
        &__text-box
          margin: ptr(24) 0 0;
          padding: ptr(12);
          list-style: none;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          background: $gray-100;
          border-radius: ptr(4);
        &__text-box-li
          display: flex;
          color: $gray-80;
          font-family: Pretendard;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px; /* 157.143% */
          letter-spacing: -0.2px;
          &:before
            margin-right: ptr(10);
            content: "· ";
        &__header
          display flex
          align-items center
          justify-content flex-start
          margin 0
        &__terms
          button
            width 100px
            height 40px
            padding 12px 0 11px
            background transparent
            border solid 1px #3c89f9
            font-weight 500
            letter-spacing -0.5px
            color #3c89f9
            border-radius: 4px;
          &--agree
            button
              color #a2a2a2
              border solid 1px #ddd
        &__get-key
          button
            width 100px
            height 40px
            padding 12px 0 11px
            border-radius: 4px;
            background: $blue-10;
            border: 0 none;
            font-weight 500
            letter-spacing -0.5px
            color $blue-100
            margin-left 10px

  .studio-info__form
    .studio-open-api
      &__control
        display flex
        align-items stretch
        width 100%
      &__body
        flex 1
      &__row
        width 100%
        &--border-bottom
          border-bottom 1px solid #ededed
      &__label-txt
        background transparent
        box-sizing content-box
        border-right 1px solid #ededed
        width 110px
        font-weight 600
        line-height 1
        letter-spacing -0.5px
        text-align center
        color #4c4c4c

      &__select
        width 200px
        &--margin
          margin-bottom 10px
      &__remove-company
        padding 6px 10px
        border-radius 3px
        background-color #ebf3fe
        font-size 13px
        font-weight 500
        letter-spacing -0.5px
        color #111
        border 0
        display flex
        align-items center
        cursor pointer
        &:after
          content ''
          display block
          width 12px
          height 12px
          margin-left 5px
          margin-top -1px
          background url(//s3.marpple.co/files/u_29089/2023/1/original/bfaa9267f9d0303710190b283834fbcb2f08d4ac1.webp) no-repeat 0 / 12px 12px
      &__copy
        display flex
        align-items center
        cursor pointer
        span
          text-decoration underline
        &:after
          content ''
          display block
          width 14px
          height 14px
          margin-left 5px
          background url(//s3.marpple.co/files/u_29089/2023/1/original/600f11124c56f72263115d6e760b1ce691f0e27e1.webp) no-repeat 0 / 14px 14px

  .studio-open-api-alert-created
    text-align center
    margin-bottom 16px
    &__header
      margin 0 0 12px 0
      font-size 20px
      font-weight 800
      line-height 1.3
      letter-spacing -0.3px
      color #111
    &__content1
      font-size 15px
      line-height 1.73
      letter-spacing -0.2px
      color #111
      margin-bottom 12px
    &__content2
      font-weight 600
      line-height 1.57
      letter-spacing -0.2px
      color #3c89f9
      font-size 14px
