.app-mypage-like
  padding-top ptr(24)
  padding-bottom ptr(120)
  &__mui-page
    >.don_wrapper >.header
      box-sizing border-box
      margin-top pxToRem(36px)
      .tab_buttons
        width 100%
        display flex
        justify-content center
        align-items center
        box-sizing border-box
        position relative
        z-index 2
        max-width ptr(1376)
        margin auto
        margin-top ptr(24)
      .tab_button
        reset-button()
        flexCenter()
        display flex
        align-items center
        column-gap ptr(4)
        padding ptr(12) 0
        flex 1
        color $gray-50
        box-sizing border-box
        {$kr-16-b}
        border-bottom 1px solid $gray-20
        .count
          {$en-16-unica-b}
          margin-bottom ptr(2)
        &[selected="true"]
          border-bottom 1px solid $black
          color $black
          {$kr-16-b}
  &__lists-product
    & > .infi_wrapper > .infi_items > div
      display grid
      grid-template-columns repeat(5, minmax(0, 1fr))
      gap ptr(32) ptr(16)
      width 100%
      overflow hidden
    & > .infi_wrapper > .infi_items
      margin-bottom ptr(32)

  &__lists-seller
    & > .infi_wrapper > .infi_items > div
      display grid
      grid-template-columns repeat(4, minmax(0, 1fr))
      gap ptr(24)
      width 100%
      overflow hidden
    & > .infi_wrapper > .infi_items
      margin-bottom ptr(24)
  &__lists-seller.no-result
    display unset

.no-like-result
  text-align center
  padding ptr(160) 0
  &__heart
    margin-bottom ptr(24)
  &__heart-img
    width ptr(160)
  &__title
    margin-bottom ptr(8)
    color $gray-80
  &__title-desc
    margin-bottom ptr(24)
    color $gray-50
  &__button
    width ptr(196)



#like_nbox .app-mypage-like
  border-top none
#like_nbox .app-mypage-like__mui-page
  >.don_wrapper >.header
    margin-top 0
