$bottom-tab-bar = '.bottom-tab-bar'
$brand-logo = '.brand-logo'

$root-index = 1

{$bottom-tab-bar}
  display none
  position fixed
  top 0
  bottom 0
  right 0

  flexDirection column
  box-sizing border-box
  width pxToRem(80px)
  border-left 1px solid #f8f8f8
  background-color #fff
  z-index 4

  &--hide
    transition none
    transform none
  &__inner
    display block
    width 100%
  &__anchor
    flexCenter(column)
    width 100%
    height pxToRem(79px)
    &::before
      margin-bottom pxToRem(6px)
      width pxToRem(26px)
      height pxToRem(26px)
      background-size pxToRem(26px)
      border-radius 5px
      content ''
    &--shopping
      &::before
        background-image: url(//s3.marpple.co/files/u_1396787/2021/5/original/187e1e3f1b1a0d17d36febce77104c84e3fb538d1.svg)
        ^[{$root-index}]__anchor--active^[{$root-index}..-{$root-index}]
          background-image: url(//s3.marpple.co/files/u_1396787/2021/5/original/420e1b02fc0ab1ddd3a639c3f99a6af4406a6d1c12.svg)
    &--live
      &::before
        background-image: url(//s3.marpple.co/files/u_1396787/2021/5/original/9f765973be1d6135fee6ec8126b3f021d26774367.svg)
        ^[{$root-index}]__anchor--active^[{$root-index}..-{$root-index}]
          background-image: url(//s3.marpple.co/files/u_1396787/2021/5/original/a7ecd9d6d54d1607b6fab70a5f86c18f7c1399e76.svg)
    &--community
      &::before
        background-image: url(//s3.marpple.co/files/u_1396787/2021/5/original/76c004742826bf45bf5888a7862cc6665b47a4c95.svg)
        ^[{$root-index}]__anchor--active^[{$root-index}..-{$root-index}]
          background-image: url(//s3.marpple.co/files/u_1396787/2021/5/original/d42ba6734d06cc9bf5c4f751d32fc6d370f1f4fc4.svg)
    &--search
      &::before
        background-image: url(//s3.marpple.co/files/u_1396787/2021/5/original/4b71475b704f5470a8f96bde5c7b8c3f3f9d3c8f11.svg)
        ^[{$root-index}]__anchor--active^[{$root-index}..-{$root-index}]
          background-image: url(//s3.marpple.co/files/u_1396787/2021/5/original/5d68c1088699c7931d04b4e89d805c0d952b5aa210.svg)
    &--mypage
      &::before
        background-image: url(//s3.marpple.co/files/u_1396787/2021/5/original/f26a27b8456cb059a6092468b4ba481cd2fd86f39.svg)
        ^[{$root-index}]__anchor--active^[{$root-index}..-{$root-index}]
          background-image: url(//s3.marpple.co/files/u_1396787/2021/5/original/327c52e14972934562d9f11f2f0acc390f7306e18.svg)
  &__text
    font pxToRem(13px), pxToRem(20px), -.2px
    color #a2a2a2
    ^[{$root-index}]__anchor--active ^[{$root-index}..-{$root-index}]
      color #111

{$brand-logo}
  display block
  margin 0 auto pxToRem(24px)
  &__image
    vertical-align top
    &--small
      width pxToRem(48px)
      aspect-ratio 48 / 24

/*
** Version1
@import '../Mo/index.styl';

// pc
{$brand-logo}
  display block
  margin-bottom 25px
  &__image
    vertical-align top
    &--small
      @media screen and (max-width pc-break-point)
        width pxToRem(30px)
        height pxToRem(30px)

{$bottom-tab-bar}
  displayFlex space-between center
  flexDirection column
  box-sizing border-box
  top 0
  left auto
  right 0
  width 80px
  border-left 1px solid #f8f8f8
  @media screen and (max-width pc-break-point)
    width pxToRem(80px)
  &--hide
    transition none
    transform none
  &__inner
    display block
    padding 0
    width 100%
  &__anchor
    height 79px
    @media screen and (max-width pc-break-point)
      height pxToRem(79px)
      &:before
        width pxToRem(24px)
        height pxToRem(24px)

html[is_app=true]
  {$bottom-tab-bar}
    display none
*/
