/*
 * 2024 마플샵 리뉴얼
 */
.my-account
  width ptr(448)
  margin $default-header-pc_height auto 0
  &__title
    width 100%
    text-align center
    padding 0
    margin 0 0 ptr(32)
  &__controls
    display flex
    flex-direction column
    width 100%
    row-gap ptr(32)
  &__control
    display flex
    flex-direction column
    row-gap ptr(8)
  &__control_head
    position relative
    width 100%
    .required
      position: relative;
    .required:after
      content ''
      width 4px
      aspect-ratio 1
      border-radius 99px
      top 0
      right -8px
      display block
      position absolute
      background-color $blue

  &__control-radio
    display flex
    column-gap ptr(24)
    padding ptr(8) 0
  &__radio-item
    flex none
    width auto

  &__profile_image
    width 100%
    display flex
    align-items center
    row-gap ptr(28)
    flex-direction column
    input[type="file"]
      display none
    &__notice
      text-align center
      color $gray-50
    &__delete
      position absolute
      width ptr(32)
      display flex
      align-items center
      justify-content center
      aspect-ratio 1
      background $black
      border-radius 999px
      margin 0
      color $white
      top 8px
      right 0
      &[data-active="false"]
        display none
    &__upload
      padding ptr(4) ptr(12)
      background-color $blue
      border-radius pxToRem(8)
      margin 0
      color $white
      position absolute
      bottom 0
      left 50%
      transform translate(-50%, 50%)
      cursor pointer
    &__icon
      width ptr(12)
      aspect-ratio 1
    &__preview
      position relative
      width ptr(150)
      height ptr(150)
      border-radius 999px
      flex-shrink 0
      img
        width 100%
        height 100%
        border-radius 999px
        object-fit cover

  &__control-email
    display flex
    column-gap ptr(8)
    & > input
      flex-grow 1
      width unset
  &__btn_change_email
    flex 0 0 ptr(160)
    cursor pointer

  &__help_txt1
    margin pxToRem(8) 0 0
    color $gray-50

  &__help_txt2
    margin pxToRem(8) 0 0
    padding 0
    font-size pxToRem(13)
    line-height 1.54
    letter-spacing pxToRem(-0.2)
    color #4e4e4e

  &__radio-input
    appearance auto !important;

  &__radio_txt
    color #111

  &__buttons
    margin-top ptr(32)

  &__btn_withdrawal
    color $gray-50;
    text-decoration underline
    cursor pointer












