.up-item__thumbnail-wrapper
  display inherit
  div
  a
    display inherit
  img
    object-fit contain
    background #f6f6f6
.up-item-thumbnail
  &__canvas-wrapper
    position relative
    width 100%
    padding-top 100%
    canvas
      background #f6f6f6
      position absolute
      width 200%
      height 200%
      top 0
      left 0
      transform scale(0.5);
      transform-origin 0 0
      border-radius pxToRem(8px)
.up-item-option-infos
  width 100%
  height 100%
  span
    display inline-block
  &__divide
    margin 0 pxToRem(4px)
  &__flex
    display flex
    align-items center
  &__option
    overflow hidden
    white-space nowrap
    text-overflow ellipsis
.up-item-text-option-infos
  width 100%
  height 100%
  &__option
    font-size pxToRem(13px)
    color $gray-600
  &__value
    font-size pxToRem(13px)
    color $gray-700

.up-item-list
  .store-order
    margin-bottom pxToRem(33px)
    &__name
      font-size pxToRem(16px)
      font-weight bold
      .store-logo
        padding-left pxToRem(25px)
        background url(//s3.marpple.co/files/u_193535/2022/9/original/8ebf49d1f7a16946fce523b9cd2e619fe1ac3f7e5.svg) no-repeat left pxToRem(-1px)
        background-size pxToRem(19px) pxToRem(19px)
    &__header
      box-sizing border-box
      margin-bottom pxToRem(24px)
      display flex
      align-items center
    &__shipping-orders-wrapper
      border 1px solid $gray-200
      border-radius pxToRem(8px)
  .shipping-order
    border-bottom 1px solid $gray-200
    &:last-child
      border-bottom 0
    &__sid
      display none
    &__name
      font-size pxToRem(16px)
      font-weight bold
      color $gray-800
    &__free-shipping-explain
      font-weight bold
    &__header
      box-sizing border-box
      display flex
      justify-content space-between
      align-items center
      width 100%
      padding pxToRem(12px) pxToRem(16px)
      background-color $gray-100
    &__price-info-wrapper
      padding 0 pxToRem(18px)
    &__price-info
      padding pxToRem(15px) 0
      font-size pxToRem(14px)
      border-top 1px solid $gray-200
      box-sizing border-box
      display flex
      justify-content space-between
      width 100%
      &__right
        min-width 110px
        text-align right
        overflow hidden
        white-space nowrap
        text-overflow ellipsis
  .up-item-option-infos
    &__item
      font-size pxToRem(13px)
      color $gray-700
      display flex
      justify-content space-between
      margin-bottom pxToRem(4px)
      &[data-status="NO_STOCK"]
      &[data-status="OPTION_CHANGED"]
      &[data-status="NOT_ENOUGH_STOCK"]
      &[data-status="LIMIT_EXPIRED"]
      &[data-status="DISCONTINUED"]
        color $gray-500
      &:last-child
        margin-bottom 0
    &__status-not-normal
      background $red-a
      font-weight bold
      color #fff
      margin-right pxToRem(8px)
      border-radius pxToRem(2px)
      font-size 12px
      padding pxToRem(4px) pxToRem(6px) pxToRem(3px)
  .up-item
    padding pxToRem(18px) 0
    margin 0 pxToRem(18px)
    box-sizing border-box
    border-bottom 1px solid $gray-200
    &__option-wrapper
      background $gray-200
      border-radius pxToRem(8px)
    &__option-infos
      padding pxToRem(14px)
    &__cart-option
      button
        background none
        border none
      &__delete-btn
        position absolute
        top 0
        right 0
      &__revise-btn
        position absolute
        right 0
        bottom 0
        color $sky-800
    &-text-option-infos
      box-sizing border-box
      padding pxToRem(14px)
      border-top 1px dotted $gray-400
      &__title
        font-size pxToRem(14px)
        color $gray-600
        margin-bottom pxToRem(6px)
      &__value
        font-size pxToRem(14px)
        color $gray-700
        margin-bottom pxToRem(6px)
        &:last-child
          margin-bottom 0
    &:last-child
      border none
    &__product-wrapper
      position relative
      margin-bottom pxToRem(14px)
  .up-item-price
    color $gray-800
    font-size pxToRem(16px)
    font-weight 600
.up-item-product-info
  width 100%
  height 100%
  color $gray-800
  display flex
  flex-direction column
  justify-content center
  &__up_c_id
    color $gray-500
  &__second-name
    color $gray-500
  &__store-name
    color $gray-800
    font-weight bold
    font-size pxToRem(15px)
    padding-top pxToRem(2px)
    margin-bottom pxToRem(6px)
  &__name
    &-value
      word-break keep-all
      color $gray-800
      limitLine(1)
  .store-logo
    padding-left pxToRem(23px)
    background url(//s3.marpple.co/files/u_193535/2022/9/original/8ebf49d1f7a16946fce523b9cd2e619fe1ac3f7e5.svg) no-repeat left pxToRem(-1px)
    background-size pxToRem(18px) pxToRem(18px)
.seller-delivery-type-logo
  display flex
  align-items center
  .tooltip-question
    margin-top pxToRem(-2px)
  &:before
    content ""
    background url(//s3.marpple.co/files/u_1504988/2024/2/original/d39564e55aec7d410bbfc24a99b009f30c14e39f1.svg) no-repeat left 0
    background-size pxToRem(24px) pxToRem(24px)
    display block
    width pxToRem(24px)
    height pxToRem(24px)
    margin-right pxToRem(8px)
.marpple-delivery-type-logo
.fast_marpple-delivery-type-logo
  display flex
  align-items center
  font-weight 600
  &:before
    content ""
    display block
    width pxToRem(24px)
    height pxToRem(24px)
    margin-right pxToRem(8px)
    background url(//s3.marpple.co/files/u_1504988/2024/2/original/078520a757439566076ab09fcdfdc1c56274eace2.svg) no-repeat left 0
    background-size pxToRem(24px) pxToRem(24px)
.digital-delivery-type-logo
  display flex
  align-items center
  font-weight 600
  &:before
    content ""
    display block
    width pxToRem(30px)
    height pxToRem(30px)
    margin-right pxToRem(8px)
    background url(//s3.marpple.co/files/u_2308668/2023/11/original/d35f00d4ad58df237d29d900d341d04846a6baa61.png) no-repeat left 0
    background-size pxToRem(30px) pxToRem(30px)
    margin-top pxToRem(-2px)

