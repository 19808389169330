


@media screen and (min-width: 1025px)
  .new_maker_guide_layer
    >.don_wrapper
      width 880px !important
      height 654px !important
    .don_page
      height 100%
      >.don_wrapper
        height 100%
        >.body
          height 100%
    .don_tab
      height 100%
      .don_wrapper
        height 100%
    .maker_guide_body
      position relative
      height 100%
      .img
        text-align center
        img
          max-width 100%
          vertical-align top
      .btns
        position absolute
        left 40px
        right 40px
        bottom 40px
        display flex
        margin 0 auto
        justify-content space-between
        button
          width 49.3%
          height 40px
          color #000
          font-size 16px
          font-weight 500
          vertical-align top
          background-color #fff
          border 1px solid #d5dbe0
          border-radius 2px
          &.btn_edit
            color #fff
            border-color #3c89f9
            background-color #3c89f9
          &.btn_confirm
          &.btn_down_agree
            width 100%
            color #fff
            border-color #3c89f9
            background-color #3c89f9
  &.dtg
    >.don_wrapper
      width 880px !important
      height 400px !important


@media screen and (min-width: 1025px) and (max-height 720px)
  .new_maker_guide_layer
    >.don_wrapper
      width 722px !important
      height 554px !important

@media screen and (max-width: 1024px)
  .new_maker_guide_layer
    .maker_guide_body
      .img
        text-align center
        img
          max-width 100%
          vertical-align top
      .btns
        position absolute
        left 25px
        right 25px
        bottom 21px
        display flex
        margin 0 auto
        justify-content space-between
        button
          width 49%
          height 38px
          color #000
          font-size 12px
          font-weight 500
          vertical-align top
          background-color #fff
          border 1px solid #d5dbe0
          border-radius 2px
          &.btn_edit
            color #fff
            border-color #3c89f9
            background-color #3c89f9
          &.btn_confirm
          &.btn_down_agree
            width 100%
            color #fff
            border-color #3c89f9
            background-color #3c89f9


@media screen and (max-width: 320px)
  .new_maker_guide_layer
    > .don_wrapper
      width 310px !important
      height 466px !important




