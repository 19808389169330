.today-feed
  position relative

  [data-show="false"]
    display none

  &__thumb_container
    width 100%
    font-size 0
    position relative

  &__category
    // 마플샵 리뉴얼 할 때 타이포그래피 바꾸기
    {$font-en-b-14--100}

  &__thumbnail
    width 100%

  &__content
    width 100%
    box-sizing border-box
    //padding pxToRem(17px) pxToRem(24px) 0 // 20 / 2 * 1.3 (폰트 절반)
    padding pxToRem(16px) 0 0 // 20 / 2 * 1.3 (폰트 절반)
    color black

  &__content_title
    font-size pxToRem(20px)
    font-weight 600
    line-height 1.3
    letter-spacing -0.3px
    position relative

  &__content_title_txt
    font-weight 600
    line-height 1.3
    letter-spacing -0.2px
    color #111
    margin 0
    limitLine(1)

  &__content_title_first
    position absolute
    transform translateY(-100%)
    top 0
    left 0
  &__content_title_second
    position relative

  &__content_description
    font-size pxToRem(14px)
    line-height 1.29
    letter-spacing -0.2px
    color #111
    margin 0
    margin-top pxToRem(8px)
    limitLine(1)

  &__content_products
    display flex
    gap pxToRem(8px)
    margin-top pxToRem(16px)
    padding pxToRem(8px) pxToRem(24px) 0

  &__content_product
    background-color #f6f6f6
    display flex
    flex-grow 1
    flex-shrink 1
    flex-basis 0
    color #4e4e4e
    justify-content center
    align-items center
    font-size pxToRem(16px)
    font-weight 600
    &-empty
      opacity 0
      cursor auto

  &__content_empty
    padding-top 100%
    opacity 1

.today-feed--mobile
  .today-feed__content_title
    font-size pxToRem(23px)

  .today-feed__content_category
    font-size pxToRem(14px)

.today-feed--relates
  .today-feed__content_title
    font-size pxToRem(20px)

  .today-feed__content_category
    font-size pxToRem(15px)

.today-list-item
  display flex

  &__thumb
    width 31.5%

  &__content
    padding-left pxToRem(16px)
    padding-top pxToRem(7px)
    overflow hidden

  &__content_category
    margin 0
    font-size pxToRem(11px)
    font-weight 500
    line-height 1.64
    letter-spacing -0.2px
    color #a2a2a2

  &__content_title
    margin 0
    margin-top 2px
    font-size pxToRem(17px)
    font-weight 600
    line-height 1.31
    letter-spacing -0.2px
    color #111
    overflow hidden
    text-overflow ellipsis
    white-space nowrap

  &__content_description
    margin 0
    margin-top 3px
    font-size pxToRem(14px)
    font-style normal
    line-height 1.29
    letter-spacing -0.2px
    color #111
    overflow hidden
    text-overflow ellipsis
    white-space nowrap


.today-feed_sm
  .today-feed__content
    padding pxToRem(11px) pxToRem(20px) 0 // 17 / 2 * 1.3 (폰트 절반)

  .today-feed__content_title
    font-size pxToRem(17px)

  .today-feed__content_description
    font-size pxToRem(12px)
    margin-top pxToRem(5px)
