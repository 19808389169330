html.creator.hello-creator[is_mobile="false"]
  @media screen and (max-width 1920px)
    font-size: calc(1vw * (14 / 19.2)) !important;

.HelloCreatorPage
  h3
  h4
  p
    padding 0
    margin 0
  ul
  li
    margin 0
    padding 0
  *
    box-sizing border-box
    /* KR-48/Bold */
    font-family: Pretendard;
    font-style: normal;
    letter-spacing: pxToRem(-0.2px);

  /* cta button */
  .request
    position: fixed;
    bottom: ptr(40);
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;

  .section
    position relative
    width 100%
    height pxToRem(1080px)

  .section1
    height 100vh;
    .bg
      position absolute
      top 0
      left 0
      width 100%
      height 100%
      z-index -1
      display flex
      justify-content center
      img
      video
        width 100%;
        object-position center
        object-fit cover
      &:after
        content ''
        position absolute
        top 0
        left 0
        width 100%
        height 100%
        background: linear-gradient(180deg, rgba(28, 117, 255, 0.00) 46.5%, rgba(28, 117, 255, 0.90) 86.5%);
    .texts
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      gap: pxToRem(8px);
      position absolute
      bottom pxToRem(136px)
      margin 0 auto
      width pxToRem(500px)
      left 0
      right 0
      text-align center
      *
        color: var(--White, #FFF);
        font-weight: 700;
      h3
        font-size: pxToRem(48px);
        line-height: pxToRem(60px);
      p
        font-size: pxToRem(20px);
        line-height: pxToRem(28px); /* 140% */

  .section2
    .imgs
      position absolute
      left 0
      right 0
      width pxToRem(1266px)
      margin 0 auto
      img
        position absolute
      img:nth-child(1)
        width: pxToRem(392px);
        height: pxToRem(392px);
        top pxToRem(69px);
        left pxToRem(248px);
      img:nth-child(2)
        width: pxToRem(420px);
        height: pxToRem(420px);
        top pxToRem(543px);
        left pxToRem(0px);
      img:nth-child(3)
        width: pxToRem(420px);
        height: pxToRem(420px);
        top pxToRem(265px);
        right pxToRem(0px);
    .texts
      position absolute
      left 50%
      top 50%
      transform translateX(-50%) translateY(-50%)
      width pxToRem(600px)
      margin auto auto
      text-align center
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      gap: pxToRem(24px);
      *
        color: var(--Blue-100, #1C75FF);
        font-size: pxToRem(48px);
        font-weight: 700;
        line-height: pxToRem(60px); /* 125% */
      p
        font-size: pxToRem(20px);
        font-weight: 500;
        line-height: pxToRem(32px); /* 160% */

  .section3
    background: var(--Blue-10, #EBF3FE);
    .texts
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      gap: pxToRem(24px);
      position absolute
      left 50%
      top pxToRem(160px)
      transform translateX(-50%)
      text-align center
      *
        color: var(--Blue-100, #1C75FF);
        font-size: pxToRem(48px);
        font-weight: 700;
        line-height: pxToRem(60px);
      p
        font-size: pxToRem(20px);
        font-weight: 500;
        line-height: pxToRem(32px);
    img
      position absolute
      left 0
      right 0
      bottom pxToRem(120px)
      width pxToRem(1275px)
      margin 0 auto

  .section3-2
    position relative
    display: flex;
    padding: pxToRem(160px) pxToRem(188px) pxToRem(100px) pxToRem(188px);
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: pxToRem(72px);
    > .texts
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: pxToRem(24px);
      text-align center
      h3
        font-size: pxToRem(48px);
        font-weight: 700;
        line-height: pxToRem(60px);
      p
        font-size: pxToRem(20px);
        font-weight: 500;
        line-height: pxToRem(32px);
    .details
      display: flex;
      align-items: flex-start;
      gap: pxToRem(24px);
      align-self: stretch;
      width pxToRem(1544px)
      margin 0 auto
      .detail
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: pxToRem(32px);
        img
          width: pxToRem(368px);
          align-self: stretch;
        .texts
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: pxToRem(16px);
          align-self: stretch;
          text-align center
          h4
            font-size: pxToRem(20px);
            font-weight: 700;
            line-height: pxToRem(28px); /* 140% */
          p
            color #6B6B6B
            font-size: pxToRem(20px);
            font-weight: 500;
            line-height: pxToRem(32px); /* 160% */
            word-break: keep-all


  .section4
    height auto;
    display: flex;
    padding: pxToRem(160px) pxToRem(446px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: pxToRem(112px);
    background: var(--Black, #141414);

    >.texts
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: pxToRem(24px);
      text-align center
      h3
      p
        color: var(--White, #FFF);
      h3
        font-size: pxToRem(48px);
        font-weight: 700;
        line-height: pxToRem(60px);
      p
        font-size: pxToRem(20px);
        font-weight: 500;
        line-height: pxToRem(32px);

    .cards
      width: pxToRem(1000px)
      margin 0 auto
    .card
      display: inline-flex;
      width: pxToRem(1000px)
      height: pxToRem(400px);
      padding-right: pxToRem(80px);
      align-items: center;
      gap: pxToRem(20px);
      flex-shrink: 0;
      border-radius: pxToRem(24px);
      .texts
        display: flex;
        width: pxToRem(620px);
        padding: pxToRem(80px) pxToRem(0px) pxToRem(80px) pxToRem(80px);
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: pxToRem(16px);
      h4
        font-size: pxToRem(24px);
        font-weight: 700;
        line-height: pxToRem(34px); /* 141.667% */
      p
        font-size: pxToRem(20px);
        font-weight: 500;
        line-height: pxToRem(32px); /* 160% */
      img
        display: flex;
        width: pxToRem(280px);
        height: pxToRem(280px);
        justify-content: center;
        align-items: center;

    .card:nth-child(1)
      background #FFF9E2
      color #704C00
    .card:nth-child(2)
      background #EBFEF3;
      color #009444;
      transform: rotate(4.336deg);
    .card:nth-child(3)
      background #EBF3FE
      color #1C75FF
      transform: rotate(-1.859deg);
    .card:nth-child(4)
      background #FFE4FF
      color #990000
      transform: rotate(3.234deg);

  .section5
    background: #1C75FF;
    display flex
    flex-direction: column;
    position relative
    overflow hidden
    .texts
      position relative
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      margin pxToRem(126px) 0 pxToRem(66px)
      gap: pxToRem(24px);
      text-align center
      color #fff
      z-index 3
      h3
        font-size: pxToRem(48px);
        font-weight: 700;
        line-height: pxToRem(60px); /* 125% */
      p
        font-size: pxToRem(20px);
        font-weight: 500;
        line-height: pxToRem(32px); /* 160% */
    .coins
      position absolute
      top 0
      left 0
      right 0
      margin 0 auto
      z-index 2
      width 100%
      max-width pxToRem(1920px)
    .product
      z-index 1
      position: relative;
      display: flex;
      width: pxToRem(1920px);
      padding: pxToRem(0px) pxToRem(188px);
      margin 0 auto
      gap: pxToRem(48px);
      .img
        position relative
        height: pxToRem(460px);
        flex: 1 0 0;
        > *
          position absolute
        .t
          width pxToRem(748px)
          height 100%
          top 0
          left 0
          text-align center
          background #F6F6F6
          border-radius: pxToRem(32px);
          img
            height 100%
        img.coin1
          top pxToRem(40px)
          left 0
          transform translateX(-50%)
          width pxToRem(290px)
          height pxToRem(290px)
        img.coin2
          bottom pxToRem(-224px)
          right pxToRem(-126px)
          width pxToRem(393px)
          height pxToRem(393px)
      .prices
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        flex: 1 0 0;
        color: var(--White, #FFF);
        .price
        .profit
          display: flex;
          padding: pxToRem(36px) pxToRem(16px) pxToRem(44px) pxToRem(16px);
          justify-content: space-between;
          align-items: flex-start;
          align-self: stretch;
          border-top: pxToRem(1px) solid var(--White, #FFF);
          h4
            font-size: pxToRem(20px);
            font-weight: 700;
            line-height: pxToRem(28px); /* 140% */
          .value
            font-family: "Bebas Neue Pro";
            letter-spacing normal;
            font-size: pxToRem(80px);
            font-weight: 700;
            line-height: 80%; /* pxToRem(64px) */
            position relative
            text-align justify
            &:before
              position absolute
              content 'KRW'
        .price
          .value
            width pxToRem(198px)
            &:before
             left pxToRem(-142px)
        .profit
          .value
            width pxToRem(284px)
            &:before
              left pxToRem(-204px)
        .profit
          border-bottom: pxToRem(1px) solid var(--White, #FFF);
          .value
            font-size: pxToRem(120px);
        p
          display: flex;
          padding: pxToRem(16px) pxToRem(0px);
          align-items: center;
          gap: pxToRem(4px);
          align-self: stretch;
          font-size: pxToRem(14px);
          font-weight: 500;
          line-height: pxToRem(22px); /* 157.143% */


  .section6
    display: flex;
    padding: pxToRem(32px) pxToRem(32px) pxToRem(32px) calc(50% - 772px);
    align-items: center;
    overflow hidden
    gap: pxToRem(165px);
    .left
      display: flex;
      width pxToRem(519px)
      flex-direction: column;
      align-items: flex-start;
      gap: pxToRem(160px);
    .texts
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: pxToRem(24px);
      color #1C75FF
      h3
        font-size: pxToRem(48px);
        font-weight: 700;
        line-height: pxToRem(60px); /* 125% */
      p
        font-size: pxToRem(20px);
        font-weight: 500;
        line-height: pxToRem(32px); /* 160% */
    .tabs
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: pxToRem(24px);
      align-self: stretch;
      color: #D9D9D9;
      font-size: pxToRem(96px);
      font-weight: 700;
      line-height: pxToRem(90px); /* 93.75% */
      .tab-button
        display: flex;
        align-items: flex-start;
        gap: pxToRem(12px);
        transition color 0.2s ease-out
        cursor pointer
        span
          font-family: "Bebas Neue Pro";
          letter-spacing normal;
        svg
          margin-top pxToRem(8px)
      svg:nth-child(1)
        display none
      svg:nth-child(2)
        display block
      .selected
        color #1C75FF
        svg:nth-child(1)
          display block
        svg:nth-child(2)
          display none

    .right
      width pxToRem(1016px)
      height pxToRem(1016px)
      flex-shrink: 0;
      border-radius: pxToRem(32px);
      background: var(--Blue-10, #EBF3FE);
      overflow hidden
      .swiper-slide
        img
          width 100%

  .section7
    background #7A44A0
    .scroll::-webkit-scrollbar {
      display none;
    }
    .scroll
      padding-left calc(50% - 990px);
      width 100%
      overflow scroll
      overflow-y hidden
      -ms-overflow-style: none;
      scrollbar-width: none;
      .scroll-wrapper
        display: flex;
        width: pxToRem(9840px);
        height: pxToRem(1080px);
        justify-content: center;
        align-items: flex-start;
        > div
          width: pxToRem(1980px);
          margin-right -0.5px
        .img
          .title
            width 100%;
          p
            width pxToRem(600px)
            margin pxToRem(160px) auto 0
            text-align center
            color: var(--Pink-20, #FFE4FF);
            font-size: pxToRem(48px);
            font-weight: 700;
            line-height: pxToRem(60px); /* 125% */
          img
            width pxToRem(1980px)
        .texts
          position relative
          height 100%
          display flex
          align-items: center;
          flex-shrink: 0;
          width: pxToRem(1980px);
          .texts-wrapper
            position absolute
            top 50%
            transform translateY(-50%);
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            gap: pxToRem(24px);
            width 100%
            text-align center
            color: var(--Pink-20, #FFE4FF);
            h3
              font-size: pxToRem(48px);
              font-weight: 700;
              line-height: pxToRem(60px); /* 125% */
            p
              font-size: pxToRem(20px);
              font-weight: 500;
              line-height: pxToRem(32px); /* 160% */

  .section8
    display: flex;
    width: 100%
    padding-top: pxToRem(160px);
    flex-direction: column;
    gap: pxToRem(74px);
    background: var(--Blue-10, #EBF3FE);
    overflow hidden
    .info
      display: flex;
      flex-direction: column;
      align-items: center;
      width pxToRem(1920px)
      margin 0 auto
      .texts
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: pxToRem(24px);
        color #1C75FF
        text-align center
        margin-bottom pxToRem(80px)
        h3
          font-size: pxToRem(48px);
          font-weight: 700;
          line-height: pxToRem(60px); /* 125% */
        p
          font-size: pxToRem(20px);
          font-weight: 500;
          line-height: pxToRem(32px); /* 160% */
      .counts
        display: flex;
        padding: pxToRem(0px) pxToRem(188px);
        justify-content: center;
        align-items: flex-start;
        gap: pxToRem(80px);
        align-self: stretch;
        *
          color: var(--Blue-100, #1C75FF);
        h4
          font-size: pxToRem(20px);
          font-weight: 700;
          line-height: pxToRem(28px);
        .value
          font-size: pxToRem(120px);
          font-weight: 700;
          line-height: 100%; /* pxToRem(120px) */
          font-family: "Bebas Neue Pro";
          letter-spacing normal;
      > p
        display: flex;
        margin 0
        padding: pxToRem(0px) pxToRem(188px);
        justify-content: flex-end;
        align-items: center;
        align-self: stretch;
        color: var(--Blue-100, #1C75FF);
        text-align: right;
        font-size: pxToRem(14px);
        font-weight: 700;
        line-height: pxToRem(22px); /* 157.143% */
    .creators
      display: flex;
      //width: pxToRem(1920px);
      flex-direction: column;
      align-items: center;
      gap: pxToRem(16px);
      ul
        margin 0
        padding 0
        list-style none
        display: flex;
        align-items: center;
        gap: pxToRem(28px);
        img
          width pxToRem(200px)

  .section9
    display: flex;
    padding: pxToRem(40px) pxToRem(120px) pxToRem(40px) calc(50% - 772px);
    align-items: center;
    gap: pxToRem(26px);
    overflow hidden
    .texts
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: pxToRem(24px);
      color #1C75FF
      h3
        font-size: pxToRem(48px);
        font-weight: 700;
        line-height: pxToRem(60px); /* 125% */
      p
        font-size: pxToRem(20px);
        font-weight: 500;
        line-height: pxToRem(32px); /* 160% */
    .img
      width: (2120/2)px
      height: (2000/2)px;
      flex-shrink: 0;
      img
        width 100%

  .section10
    width: 100%;
    height pxToRem(180px)
    overflow: hidden;
    display flex
    .sign
      display: flex;
      padding: pxToRem(40px) 0 0 pxToRem(80px);
      justify-content: center;
      align-items: flex-start;
      gap: pxToRem(80px);
      background: var(--Blue-10, #EBF3FE);
      img
        height pxToRem(100px)

  .section11
    overflow hidden
    background: var(--Black, #141414);
    position relative
    h3
      position absolute
      top pxToRem(140px)
      left pxToRem(188px)
      color: var(--White, #FFF);
      font-size: pxToRem(48px);
      font-weight: 700;
      line-height: pxToRem(60px); /* 125% */
    .top
      position relative
      .text
        position absolute
        top pxToRem(140px)
        right calc(50% - 580px)
        width: pxToRem(528px);
        flex-direction: column;
        align-items: flex-start;
        gap: pxToRem(24px);
        flex-shrink: 0;
        color: var(--White, #FFF);
        opacity 0
        transition opacity 0.2s ease-out
        display: flex;
        &.selected
          opacity 1
        h4
          font-size: pxToRem(20px);
          font-weight: 700;
          line-height: pxToRem(28px); /* 140% */
        span
          margin-right pxToRem(2px)
        p
          font-size: pxToRem(20px);
          font-weight: 500;
          line-height: pxToRem(32px); /* 140% */
    .swiper
      position absolute
      bottom pxToRem(80px)
      left 0
      right 0
      width pxToRem(1160px)
      height pxToRem(680px)
      margin 0 auto
      overflow hidden
      opacity 0
      border-radius: pxToRem(16px);
      &.swiper-container-horizontal
        opacity: 1;
      &.swiper-container-horizontal>.swiper-pagination-progressbar {
        position: absolute;
        left: 0;
        right: 0;
        width: auto;
        top auto;
        bottom: 0;
        height: pxToRem(2px);
        background: var(--Bright-20, rgba(255, 255, 255, 0.20));
      }
      .swiper-pagination-progressbar-fill {
        background: #fff;
      }
      .swiper-slide
        position relative
        width pxToRem(1160px)

      .img
        position absolute
        left 0
        bottom 0
        width 100%
        height pxToRem(680px)
        border-radius: pxToRem(16px);
        img
          width 100%
          border-radius: pxToRem(16px);
    .swiper-buttons
      position absolute
      bottom pxToRem(80px)
      left 0
      right 0
      width pxToRem(1160px)
      height (1080-(140+80))px
      margin 0 auto
      button.prev
        opacity 0.3
    .pager-wrapper
      position absolute
      left pxToRem(-192px)
      bottom pxToRem(0px)
      display: flex;
      align-items: center;
      gap: pxToRem(12px);
      color: var(--White, #FFF);
      *
        font-family: "Bebas Neue Pro";
        font-size: pxToRem(16px);
        font-style: normal;
        font-weight: 700;
        line-height: pxToRem(24px); /* 150% */
        letter-spacing normal
      .swiper-pagination
        position relative
        width: pxToRem(48px);
        height: pxToRem(1px);
      .swiper-pagination-progressbar {
        background: var(--Bright-20, rgba(255, 255, 255, 0.20));
      }
      .swiper-pagination-progressbar-fill {
        background: #fff;
      }

    .buttons
      position absolute
      bottom 0
      right pxToRem(-192px)
      width pxToRem(88px)
      display: flex;
      align-items: flex-start;
      gap: pxToRem(8px);
      button
        display: flex;
        width: pxToRem(40px);
        height: pxToRem(40px);
        padding: pxToRem(8px);
        justify-content: center;
        align-items: center;
        gap: pxToRem(10px);
        border-radius: pxToRem(999px);
        background: var(--Bright-20, rgba(255, 255, 255, 0.20));



  .section12
    display: flex;
    padding: pxToRem(80px);
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    background: var(--Blue-100, #1C75FF);
    height pxToRem(716px)
    text-align center
    img
      width: pxToRem(623.547px);
      height: pxToRem(409px);
    h3
      color: var(--White, #FFF);
      font-size: pxToRem(48px);
      font-weight: 700;
      line-height: pxToRem(60px); /* 125% */
      margin-bottom pxToRem(32px)
      margin-top pxToRem(-60px)

