
.main-section
  .tooltip-icon2
    margin-bottom 0 !important
    ul
      margin 0
  &__table
    table
      min-width pxToRem(2500px)
    flex 1
    margin-top pxToRem(15px)
  &__buttons
    display flex
    justify-content space-between
    &__status
      display flex
      min-height 33px
      select, input, button
        height 100%
    &__status
      &__label
        width pxToRem(110px)
      &__body
        display flex
    @media screen and (min-width: 530px)
      .empty
        width 0
      .change-delivering-all
        display flex
    input[name="invoice_number"]
      margin-right pxToRem(6px)
    select
      -webkit-appearance none
      -moz-appearance none
      border 1px solid $gray-s1
      border-radius 2px
      margin-right pxToRem(8px)
      background $image-down-arrow no-repeat 95% center
      background-size pxToRem(18px) pxToRem(18px)
      background-color #fff
      font-size pxToRem(14px)
      text-indent pxToRem(10px)
    input[type="text"]
      text-indent pxToRem(10px)
    &__status__label
      width pxToRem(130px)
      font-size pxToRem(13px)
      font-weight 600
      display flex
      align-items center
.search-select
  input[type="text"]
    border 1px solid $gray-s1
  select
    &[name="cate_item_id"]
      .option
        display none
        &.is_visible
          display block
.n_pb_item_order--masking
  filter blur(5px)
.masking-notice
  display flex
  align-items center
  column-gap pxToRem(4)
  color $gray-s4
  margin-top pxToRem(24)
