// modules/MShop/Landing/OfflinePopupStore/F/Style/Pc/index.styl

html.creator.offline-popup-store[is_mobile="false"]
  .creator-layout__body
    padding 0
    margin 0
    width 100%
    max-width 100vw
    box-sizing border-box
  @media screen and (max-width 1920px)
    font-size: calc(1vw * (14 / 19.2)) !important;

.OfflinePopupStorePage
  &.editing
    display none
  h3
  h4
  p
    padding 0
    margin 0
  ul
  li
    margin 0
    padding 0

  /* cta button */
  .cta-button
    position: fixed;
    bottom: ptr(40);
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;

  .section
    overflow hidden
  .section1
    position relative
    width: 100%;
    height 100vh;
    h1
      margin pxToRem(16px) auto 0
      width pxToRem(178px)
      z-index 3
    .overlay
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      width 100%
      height 100%
      z-index -1
      background: rgba(0, 0, 0, 0.30);
    .bg
      position absolute
      top 0
      left 0
      width 100%
      height 100%
      z-index -1
      display flex
      justify-content center
      img
      video
        width 100%;
        object-position center
        object-fit cover
    .texts
      color #fff
      text-align center
      position absolute
      left 0
      right 0
      bottom pxToRem(136px)
      h3
        color: $white
        text-align: center;
        {$kr-20-b}

        span:nth-child(1)
          {$kr-20-m}
          display block
        span:nth-child(2)
          {$kr-48-b}

  .section2
    position relative
    width: 100%;
    height pxToRem(1080px)
    text-align center
    display: flex;
    box-sizing border-box
    padding: pxToRem(120px) pxToRem(188px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: pxToRem(80px);
    align-self: stretch;
    .texts
      color: $blue-100
      text-align: center;

      *
        {$kr-48-b}
      h3
        padding 0 0 pxToRem(24px);
        margin 0;
      p
        padding 0
        margin 0
        {$kr-20-m}

    .imgs
      display: flex;
      justify-content: center;
      align-items: center;
      gap: pxToRem(32px);
      img
        display: flex;
        width: pxToRem(916px);
        height: pxToRem(520px);
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;

  .section3
    display: flex;
    padding: pxToRem(120px) pxToRem(188px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    background: $brown-100
    text-align center
    h3
      color: $brown-10
      text-align: center;
      margin-bottom pxToRem(116px)
      {$kr-48-b}

    .text-banners
      display: flex;
      width: pxToRem(1544px);
      align-items: flex-start;
      gap: pxToRem(60px);
      margin-bottom pxToRem(60px)
      .banner
        display: flex;
        flex-direction: column;
        align-self: stretch;
        width pxToRem(341px)
        *
          align-self: stretch;
        img
          width: pxToRem(32px);
          height: pxToRem(32px);
        h4
          margin pxToRem(16px) 0 pxToRem(32px)
          color: $brown-10
          text-align: center;
          {$kr-28-b}
        p
          color: $brown-10
          text-align: center;
          {$kr-20-m}

    .footer
      a
        display: flex;
        min-width: pxToRem(160px);
        padding: pxToRem(16px) pxToRem(24px);
        align-items: center;
        gap: pxToRem(8px);
        border-radius: pxToRem(12px);
        background: $brown-10
        color: $brown-100
        text-align: center;
        {$kr-16-b}

  .section4
    height pxToRem(1080px)
    box-sizing border-box
    padding: pxToRem(96px) pxToRem(188px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: pxToRem(60px);
    @media screen and (min-width 1920px)
      padding: pxToRem(96px) calc(50% - 772px);
    .texts
      *
        color: $brown-100
        text-align: center;
        {$kr-48-b}
    .slide-imgs
      padding: 0 0 58px;
      position relative
      opacity 0
      &.swiper-initialized
        opacity: 1;
      .swiper-wrapper
        display: flex;
        align-items: center;
        align-self: stretch;
      img
        width: pxToRem(992px);
        border-radius: pxToRem(24px);
        //&:first-child
        //  margin-left pxToRem(188px)
        //&:last-child
        //  margin-right pxToRem(188px)
    .swiper-horizontal>.swiper-pagination {
      position: absolute;
      left: 0;
      right: 0;
      width: auto;
      top auto;
      bottom: 0;
      height: 2px;
      background: $dim-10
    }
    .swiper-pagination-progressbar-fill {
      background: #704C00;
    }


  .section5
    width 100%
    height pxToRem(1080px)
    box-sizing border-box
    display: flex;
    padding: pxToRem(96px) pxToRem(188px);
    flex-direction: column;
    background: $blue-100
    @media screen and (min-width 1920px)
      padding: pxToRem(96px) calc(50% - 772px);
    .texts
      display: flex;
      align-items: flex-start;
      gap: pxToRem(160px);
      align-self: stretch;
      .left
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: pxToRem(24px);
        flex: 1 0 0;
        *
          color: $blue-10
          {$kr-48-b}
        p
          {$kr-20-m}
      .right
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: pxToRem(16px);
        div
          display: flex;
          align-items: flex-start;
          gap: pxToRem(16px);
          align-self: stretch;
          *
            color: $blue-10
            {$kr-20-m}
          h4
            {$kr-16-b}
            width pxToRem(192px)
          ul
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: pxToRem(6px);
            margin 0
            padding 0
            list-style none
          li
            padding 0

    .slide-imgs
      position relative
      margin-top pxToRem(101px)
      padding-bottom pxToRem(83px)
      opacity 0
      &.swiper-initialized
        opacity: 1;

      .swiper-pagination {
        position: absolute;
        left: 0;
        right: 0;
        width: auto;
        top auto;
        bottom: 0;
        height: 2px;
        background: $bright-20
      }
      .swiper-pagination-progressbar-fill {
        background: #fff;
      }
      .items
        display: flex;
        //align-items: flex-start;
        //gap: pxToRem(24px);
        //align-self: stretch;
      .item
        //display: flex;
        width: pxToRem(360px);
        //flex-direction: column;
        //align-items: flex-start;
        //gap: pxToRem(8px);
        img
          width: 100%;
          height 100%;
          border-radius: pxToRem(16px);
      .caption
        color: $white
        {$kr-12-m}

  .section6
    width 100%
    height pxToRem(1080px)
    box-sizing border-box
    position relative
    padding: pxToRem(96px) pxToRem(188px);
    background: $blue-10
    overflow hidden
    @media screen and (min-width 1920px)
      padding: pxToRem(96px) calc(50% - 772px);
    .content
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: pxToRem(80px);
      align-self: stretch;
      position relative
      z-index 2
      .texts
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: pxToRem(16px);
        align-self: stretch;
        *
          color: $blue-100
          text-align: center;
          {$kr-48-b}
        p
          {$kr-20-m}

      .left
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: pxToRem(32px);
        flex: 1 0 0;
        text-align left
        .item
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: pxToRem(8px);
          align-self: stretch;
          *
            color: $blue-100
            {$kr-24-b}
          .number
            {$kr-20-b}
          p
            {$kr-16-m}
          .small
            display: flex;
            align-items: center;
            gap: 4px;
            font-size 14px
            line-height 22px

    .bg
      position absolute
      right pxToRem(110px)
      bottom pxToRem(-410px)
      width pxToRem(1152px)
      height pxToRem(1152px)
      @media screen and (min-width 1920px)
        right: calc(50% - 850px);
      img
        width 100%
        height 100%


  .section7
    width 100%
    height pxToRem(1080px)
    box-sizing border-box
    display: flex;
    padding: pxToRem(78px) pxToRem(120px) pxToRem(78px) pxToRem(120px);
    gap: pxToRem(80px);
    justify-content center
    align-self: stretch;
    background: $purple-100
    .content
      display: flex;
      position relative
      left -44px
      width pxToRem(1612px);
      align-items: center;
      gap: pxToRem(80px);
      align-self: stretch;
    img
      width: pxToRem(904px);
      height: pxToRem(924px);
      flex-shrink: 0;
    .texts
      width pxToRem(560px);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: pxToRem(16px);
      flex: 1 0 0;
      *
        color: $blue-10
        {$kr-48-b}
        align-self: stretch;
      p
        {$kr-20-m}

  .section8
    width 100%
    height pxToRem(1080px)
    box-sizing border-box
    display: flex;
    padding: pxToRem(96px) pxToRem(188px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: pxToRem(60px);
    align-self: stretch;
    background: url("//s3.marpple.co/files/u_14345/2024/4/original/45bca379559e218eb28a62ff04be813c11a0118a1.png") no-repeat;
    background-size cover;
    .texts
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: pxToRem(16px);
      *
        color: $white
        text-align: center;
        {$kr-48-b}
      p
        {$kr-20-m}

    .imgs
      display: flex;
      align-items: flex-start;
      gap: pxToRem(16px);
      img
        width: pxToRem(240px);
        height: pxToRem(381.887px);
        border-radius: pxToRem(16px);

  .section9
    width 100%
    height pxToRem(1080px)
    box-sizing border-box
    display: flex;
    padding: pxToRem(160px) pxToRem(188px);
    align-items: flex-start;
    justify-content center
    gap: pxToRem(160px);
    align-self: stretch;
    background #fff
    img
      width: pxToRem(540px);
      height: pxToRem(762px);
      display: flex;
      flex-direction: column;
    .texts
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      width: pxToRem(844px);
      height: pxToRem(762px);
      h3
        color: $blue-100
        {$kr-48-b}
      ul
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: pxToRem(16px);
        align-self: stretch;
        li
          display: flex;
          padding-bottom: pxToRem(16px);
          align-items: flex-start;
          gap: pxToRem(16px);
          align-self: stretch;
          border-bottom: pxToRem(1px) solid $blue-100
          *
            color: $blue-100
            {$kr-16-b}
          h4
            width: pxToRem(160px);
          .body
            font-weight 500
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: pxToRem(8px);

      a
        width 100%
        padding: pxToRem(16px) pxToRem(24px);
        box-sizing border-box
        display: flex;
        border-radius: pxToRem(12px);
        background: $blue-100
        color: $white
        text-align: center;
        {$kr-16-b}
        justify-content: space-between;
        align-items center
        svg
          margin-top pxToRem(2px)


  .section10
    width 100%
    height pxToRem(1080px)
    box-sizing border-box
    display: flex;
    .left
      width 50%
      background: #EBF3FE;
      display: flex;
      padding: pxToRem(120px) pxToRem(188px);
      box-sizing border-box
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: pxToRem(48px);
      align-self: stretch;
      @media screen and (min-width 1920px)
        padding: pxToRem(120px) calc(25% - 292px);
    .right
      width 50%;
      display: flex;
      box-sizing border-box
      padding: pxToRem(120px) pxToRem(0px);
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: pxToRem(48px);
      align-self: stretch;
      background: $blue-100
    .left
      color: #1C75FF;
      word-wrap: break-word
      {$kr-16-b}
      .heading
        text-align: center;
        color: #1C75FF;
        word-wrap: break-word
        {$kr-24-b}
      .rows
        align-self: stretch;
        height: pxToRem(422px);
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: pxToRem(16px);
        display: flex
      .row
        align-self: stretch;
        padding-bottom: pxToRem(16px);
        border-bottom: pxToRem(1px) #1C75FF solid;
        justify-content: flex-start;
        align-items: flex-start;
        gap: pxToRem(16px);
        display: inline-flex
      .title
        width: pxToRem(100px);
        color: #1C75FF;
        {$kr-16-b}
      .body
        flex: 1 1 0; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: pxToRem(8px); display: inline-flex
        list-style none
        li {
          position relative
          padding-left 2px
        }
        li::before {
          position absolute
          display block
          content '·';
          font-size 24px
          top -1px
          left -13px
        }
      .text1
        align-self: stretch;
      a
        margin-top pxToRem(16px)
        padding-left: pxToRem(12px); padding-right: pxToRem(12px); padding-top: pxToRem(8px); padding-bottom: pxToRem(8px); background: #1C75FF; border-radius: pxToRem(8px); border-bottom: pxToRem(1px) #1C75FF solid; justify-content: flex-start; align-items: flex-start; gap: pxToRem(10px); display: inline-flex
        span
          color: #EBF3FE;
          {$kr-14-m}

    .right
      img
        width pxToRem(500px)
        height pxToRem(500px)
      h3
        color: $blue-10
        text-align: center;
        {$kr-28-b}

      a
        border 0 none
        display: flex;
        min-width: pxToRem(160px);
        padding: pxToRem(16px) pxToRem(24px);
        align-items: center;
        gap: pxToRem(8px);
        border-radius: pxToRem(12px);
        background: #EBF3FE;
        color: #1C75FF;
        text-align: center;
        {$kr-16-b}

  .section11
    min-height pxToRem(1080px)
    box-sizing border-box
    padding: pxToRem(80px) pxToRem(188px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: pxToRem(60px);

    .texts
      *
        color: $black;
        text-align: center;
        {$kr-48-b}

    .offline-popupstores
      padding: 0 0 pxToRem(64);
      position relative
      opacity 0
      display: flex;
      justify-content center
      gap pxToRem(32)
      overflow hidden
      max-width pxToRem(1540)
      margin 0 auto

      &.swiper-initialized
        opacity: 1;
      .swiper-wrapper
        display: flex;
        align-items: center;
        align-self: stretch;

      .swiper-pagination-progressbar {
        position: absolute;
        left: 0;
        right: 0;
        width: auto;
        top auto;
        bottom: 0;
        height: 2px;
        background: $dim-10;
      }
      .swiper-pagination-progressbar-fill {
        background: $blue-100;
      }

    .more_button_box
      width 100%
      text-align center

      .more_button
        margin 0 auto
        height pxToRem(56)
        width pxToRem(234)

        & > svg
          height pxToRem(20)
          width pxToRem(20)

  .section12
    width 100%
    height pxToRem(1080px)
    box-sizing border-box
    display: flex;
    padding: pxToRem(120px) pxToRem(188px);
    justify-content center
    align-items: flex-start;
    gap: pxToRem(60px);
    align-self: stretch;
    background $brown-10;

    .left
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      width pxToRem(640px)
      height pxToRem(844px)
      .header
        height: pxToRem(240px);
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: pxToRem(24px);
        display: inline-flex
        h3
          color: #704C00;
          {$kr-48-b}
        p
          align-self: stretch;
          color: #704C00;
          {$kr-20-m}
      .footer
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: pxToRem(24px);
        color: $brown-100
        *
          {$kr-20-m}
        .text1
          align-self: stretch;
        .text2
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: pxToRem(8px);
          align-self: stretch;
          font-size: pxToRem(16px);
          h4
            font-weight: 700;
            line-height: pxToRem(24px); /* 150% */
            display: flex;
            align-items: center;
            gap: pxToRem(4px);
            align-self: stretch;
            svg
              position relative
              top pxToRem(-1px)
          p
            font-weight 500
    .right
      display: flex;
      flex-direction: column;
      width: pxToRem(844px);
      height: pxToRem(844px);
      align-self: stretch;
      a
      img
        display block
        width 100%
        height 100%
      img
        border-radius: pxToRem(24px);


  .section13
    display: flex;
    padding: pxToRem(80px);
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    background: $blue-100
    img
      width: pxToRem(623.548px);
      height: pxToRem(409px);
    .texts
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: pxToRem(32px);
      margin-top pxToRem(-60px)
    h3
      color: #FFF;
      text-align: center;
      {$kr-48-b}

.OfflinePopupStoreEditor
  position relative
  z-index 4
  background url(//s3.marpple.co/files/u_14345/2024/4/original/6f28c9c5d9cd55b8b1c9bdc624201397657fe2c01.png) no-repeat;
  background-size cover;
  background-position center;
  width: 100%;
  height 100vh;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #fff;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0 1000px #123123123123 inset;
  }
  input
    appearance auto

  .bg
    background: $dim-60
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px); /* Add this line first, it fixes blur for Safari*/
    height 100%
  *
    box-sizing border-box
    {$kr-16-m}

  .close
    display: flex;
    height: pxToRem(72px);
    padding: pxToRem(16px) pxToRem(0px);
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    button
      border 0 none
      display: flex;
      width: pxToRem(40px);
      height: pxToRem(40px);
      padding: pxToRem(10px);
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: pxToRem(999px);
      background: $bright-5
      svg
        flex-shrink: 0;
  .body
    flex-shrink: 0;
  .form
    margin 0 auto
    display: flex;
    width: pxToRem(800px);
    flex-direction: column;
    align-items: flex-start;
    gap: pxToRem(24px);
    > .header
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: pxToRem(8px);
      align-self: stretch;
      .text1
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: pxToRem(12px);
        span
          color: $blue-100
          {$kr-16-m}
        h2
          margin 0
          padding 0
          color: $white
          {$kr-48-b}
      .text2
        color: #FFF;
        {$kr-20-m}

    > .inputs
      width: pxToRem(800px); flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: pxToRem(12px); display: flex;
      *
        color: #A2A2A2;
      .marppleshop-url-display.hide
        display none !important
      .input-wrap
        padding-top: pxToRem(12px);
        padding-bottom: pxToRem(12px);
        border-bottom: pxToRem(1px) #A2A2A2 solid;
        justify-content: flex-start;
        align-items: flex-start;
        display: flex;
        width 100%
        input[type="text"]
          width 100%
          word-wrap: break-word
          background transparent
          border 0 none
          color #fff
        textarea
          width 100%
          height pxToRem(80px)
          background transparent
          border 0 none
          color #fff
        textarea::placeholder {
          color: #A2A2A2;
        }
        input
        textarea
          outline none
        button
          border-radius pxToRem(999px)
          border 0 none
          background: $gray-5
          display: flex;
          width: pxToRem(24px);
          height: pxToRem(24px);
          padding: pxToRem(6.4px);
          justify-content: center;
          align-items: center;
          gap: pxToRem(8px);
          flex-shrink: 0;
      > .input-wrap:nth-child(2)
        gap: pxToRem(24px);
        input[type="text"]
          width pxToRem(230px)
          margin-right pxToRem(-18px)
      .input-wrap2
        display: flex;
        align-items: flex-start;
        gap: pxToRem(16px);
        align-self: stretch;
        .input-wrap
          display: flex;
          padding: pxToRem(12px) pxToRem(0px);
          align-items: flex-start;
          flex: 1 0 0;
      .info
        height pxToRem(24px)
        color: $gray-50
        {$kr-12-m}
    .footer
      .agree
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: pxToRem(8px);
        label
          color: $white
          {$kr-16-m}
        p
          margin 0
          padding 0
          color: $white
          {$kr-12-m}

      button
        margin-top pxToRem(24px)
        border 0 none
        display: flex;
        min-width: pxToRem(160px);
        padding: pxToRem(16px) pxToRem(24px);
        align-items: center;
        gap: pxToRem(8px);
        border-radius: pxToRem(12px);
        background: $blue-10
        color: $blue-100
        text-align: center;
        {$kr-16-b}


html.offline-popup-store .don_small_loader_wrap, .don_loader_wrap
  background: rgba(255, 255, 255, 0.5);
