.mshop-default-footer
  width 100%
  border-top 1px solid $gray-200
  display flex
  flex-direction column
  padding-bottom 28px
  [data-show="false"]
    display none !important

  &__upper
    display flex
    align-items center
    height 47px
    justify-content space-between
    pc-layout-inner-style()
  &__main-links
    column-gap 20px
    color $gray-700
    font-size 13px
    line-height 14px
    font-weight 500
    letter-spacing -0.2px
    display flex
  &__vertical-separator
    display inline-flex
    width 1px
    height 10px
    background-color $gray-300
    &--lg
      margin 0 20px
    &--md
      margin 0 12px
  &__main-link
    cursor pointer
    &.bold
      font-weight bold
  &__langs-pc
    cursor pointer
    {$font-body-b4-r}
    display flex
    align-items center
    justify-content center
    gap 4px
  &__langs-pc-icon
    width 12px
    height 12px
    display flex
  &__hr
    width 100%
    height 1px
    background-color $gray-200
    border 0
    margin 0
  &__bottom
    pc-layout-inner-style()
    flex-grow 1
    padding-top 30px
    display flex
    justify-content space-between
  &__company
    color $black
    font-size 13px
    font-weight 500
    line-height 20px
    letter-spacing -0.2px
    cursor pointer
    margin 0
    display inline-block
    &[data-always-open="true"]
      cursor default
  &__company[data-open="true"] &__company-icon
    transform rotate(-180deg)
  &__company-detail
    margin-top 10px
    max-width 1300px
    align-items center
    row-gap 4px
    {$font-body-b4-r}
    display none
    color $gray-500
    flex-wrap wrap
    &[data-open="true"]
      display flex
    &[data-always-open="true"]
      display flex
  &__company-icon-wrapper
    width 16px
    height 16px
    display inline-flex
    border-radius 50%
    background-color $white
    border 1px solid $gray-200
    margin-left 6px
    align-items center
    justify-content center
  &__company-icon
    display inline-flex
    width 12px
    transition transform 0.3s linear
  &__description-text
    color: $gray-500
    font-size 13px
    font-weight 400
    line-height 18px
    letter-spacing -0.2px
    margin 0
    margin-top 12px

  &__sns-list
    display flex
    column-gap 8px
  &__sns
    cursor pointer
    display flex
    align-items center
    justify-content center
    width 32px
    height 32px
    background-color $black
    border-radius 50%
  &__sns-icon
    width 16px
    height 16px
  &__langs-mobile
    display none

  .mshop-comp-dropdown
    .mshop-comp-dropdown__menu
      padding 4px 0
    .mshop-comp-dropdown__button
      {$font-body-b4-r}
    .mshop-comp-dropdown__option
      {$font-body-b5-r}
      padding pxToRem(10) pxToRem(57) pxToRem(8) pxToRem(20)
