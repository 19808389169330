.app-home-cate-list
  margin-bottom pxToRem(120px)
  &__item
    margin-bottom pxToRem(80px)
  &__feed-product
  &__product
    display flex
    align-items flex-start
    flex-shrink 0
  &__feed-product
    margin-bottom 40px
    &:last-child
      margin-bottom 32px
  &__feed
    margin-right 22px
  &__feed-item
    width pxToRem(338px)
  &__feed-img
    width 100%
    height pxToRem(203px)
    background-size cover
    background-position center
    margin-bottom 12px
    background-color #f6f6f6
  &__feed-title
    font-size 20px
    font-weight bold
    line-height 1.3
    letter-spacing -0.2px
    color #111
    margin-bottom 8px
  &__feed-description
    line-height 1.29
    letter-spacing -0.2px
    color #111
