.maker_sticker_frame.maker_custom_asset_lib_frame
  > .don_wrapper
    padding-top: 72px;
    width: 950px;
    height: 740px;
    max-width: 950px;
    max-height: 740px;
    > .header
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 72px;
      box-sizing: border-box;
      padding: 24px;
      border-bottom: 1px solid $ness-gray-10;
      .title
        color: $ness-black;
        font-family: "Pragmatica Extended";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
      .don_hide_frame
        position: absolute !important;
        top: 17px !important;
        right: 17px !important;
        background-image: url("//s3.marpple.co/files/u_218933/2024/10/original/ba121cc4be96331487611d185440e03f717d3ae61.svg") !important;
        background-repeat: no-repeat !important;
        background-size: 24px !important;
        background-position: center !important;
  .maker_custom_asset_lib_page
    > .don_wrapper
      > .header
        .don_hide_frame
          display: none;
  .maker_custom_asset_lib_page2
  .maker_custom_asset_lib_page3
    > .don_wrapper
      padding-top 68px
      > .header
        padding 24px 0 12px
        display flex
        position absolute
        top 72px
        left 24px
        right 24px
        align-items center
        background-color $ness-white
        .don_back_page
          position static
          background url("//s3.marpple.co/files/u_218933/2024/10/original/77907bf056764d35cbc45de4b2033497cb67f8ec1.svg") no-repeat
          background-size 24px 24px
          width 24px
          height 24px
        .name
          position static
          text-align left
          margin-left 10px
          color: $ness-black;
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;
        .status
          position static
          margin-left 6px
          color: $ness-blue
          font-family: Pragmatica Extended, Pretendard;
          font-size: 14px;
          font-weight: 700;
          line-height: 120%;
          &[is_badge="free"]
            display inline-flex
            padding: 4px 5px;
            color: $ness-black;
            text-align: center;
            font-family: Pragmatica Extended, Pretendard;
            font-size: 10px;
            font-weight: 700;
            line-height: 120%;
            background: $ness-blue;
            backdrop-filter: blur(3.7333333492279053px);
  .maker_custom_asset_lib_page3
    > .don_wrapper
      > .header
        .group_image_name
          display: inline-block;
          position: relative;
          margin-left: 8px;
          padding-left: 8px;
          color: $ness-black;
          font-family: Pretendard;
          font-size: 14px;
          font-weight: 700;
          line-height: 120%;
          &:before
            content: '';
            display: inline-flex;
            position: absolute;
            top: 50%;
            left: 0;
            height: 10px;
            width: 1px;
            transform: translateY(-50%);
            background-color $ness-gray-20


  .maker_custom_asset_image_list
    padding-top 4px
  .maker_custom_asset_list
    padding-top: 24px;
  .custom_asset_folder_item
    position relative
    display inline-block
    vertical-align top
    margin 0 0 24px 24px
    width 207.5px
    cursor pointer
    &:hover
    &:active
      .image
        border-color $ness-blue

    .image
      position relative
      padding 0
      padding-top 100%
      box-sizing border-box
      border-radius: 8px;
      border: 1px solid $ness-gray-20;
      img
        position absolute
        top 0
        left 0
        right 0
        bottom 0
        max-width 90%
        max-height 90%
        margin auto
        border-radius: 8px;
    .name
      margin-top 8px
      text-align center
      height 19px
      overflow hidden
      text-overflow ellipsis
      white-space nowrap
      color: $ness-black;
      font-family: Pretendard;
      font-size: 12px;
      font-weight: 500;
      line-height: 140%;
    .status
      margin-top 2px
      color: $ness-blue
      text-align: center;
      font-family: Pragmatica Extended, Pretendard;
      font-size: 12px;
      font-weight: 700;
      line-height: 120%;
  .custom_asset_image_item
    position relative
    display inline-block
    margin 0 0 24px 24px
    width 207.5px
    height 207.5px
    cursor pointer
    box-sizing border-box
    border-radius: 8px;
    border: 1px solid $ness-gray-20;
    &[is_group="true"]
      margin-bottom 40px
      .thumbs
        padding 100% 0 0
        display block
        text-align center
        &[group_length="0"]
          display none
        .thumb
          display inline-block
          width 36%
          height 36%
          margin 0
          background-repeat no-repeat
          background-size contain
          background-position center center
          &:nth-child(1)
            position absolute
            top 10%
            left 10%
          &:nth-child(2)
            position absolute
            top 10%
            right 10%
          &:nth-child(3)
            position absolute
            bottom 10%
            left 10%
          &:nth-child(4)
            position absolute
            bottom 10%
            right 10%
      .image
        display none
      .name
        margin-top 8px
        display block
        color: $ness-black;
        text-align: center;
        font-family: Pretendard;
        font-size: 12px;
        font-weight: 500;
        line-height: 140%;
    &.selected
    &:active
    &:hover
      border-color: $ness-blue
      background-color $ness-white
    .thumbs
      display none
    .image
      border-radius 8px
      padding 100% 0 0
      background url(//s3.marpple.co/files/u_218933/2024/10/original/c0f2b3a8218b1e0ccba0c6daa30cb8bb114e6d201.png) no-repeat
      background-size contain
      img
        position absolute
        top 0
        left 0
        right 0
        bottom 0
        max-width 90%
        max-height 90%
        margin auto
        vertical-align top
    .name
      display none


.maker_sticker_frame.maker_custom_user_asset_lib_frame
  > .don_wrapper
    padding-top: 72px;
    width: 950px;
    height: 740px;
    max-width: 950px;
    max-height: 740px;
    > .header
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 72px;
      box-sizing: border-box;
      padding: 24px;
      border-bottom: 1px solid $ness-gray-10;
      .title
        color: $ness-black;
        font-family: "Pragmatica Extended";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
      .don_hide_frame
        position: absolute !important;
        top: 17px !important;
        right: 17px !important;
        background-image: url("//s3.marpple.co/files/u_218933/2024/10/original/ba121cc4be96331487611d185440e03f717d3ae61.svg") !important;
        background-repeat: no-repeat !important;
        background-size: 24px !important;
        background-position: center !important;
  .maker_custom_user_asset_lib_page
    > .don_wrapper
      > .header
        .don_hide_frame
          display: none;
  .maker_custom_user_asset_lib_page2
  .maker_custom_user_asset_lib_page3
    > .don_wrapper
      padding-top 22px
      > .header
        display none
        padding 24px 0 12px
        position absolute
        top 72px
        left 24px
        right 24px
        align-items center
        background-color $ness-white
        .don_back_page
          position static
          background url("//s3.marpple.co/files/u_218933/2024/10/original/77907bf056764d35cbc45de4b2033497cb67f8ec1.svg") no-repeat
          background-size 24px 24px
          width 24px
          height 24px
        .name
          position static
          text-align left
          margin-left 10px
          color: $ness-black;
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;
        .status
          position static
          margin-left 6px
          color: $ness-blue
          font-family: Pragmatica Extended, Pretendard;
          font-size: 14px;
          font-weight: 700;
          line-height: 120%;
          &[is_badge="free"]
            display inline-flex
            padding: 4px 5px;
            color: $ness-black;
            text-align: center;
            font-size: 10px;
            font-weight: 700;
            line-height: 120%;
            background: $ness-blue;
            backdrop-filter: blur(3.7333333492279053px);
  .maker_custom_user_asset_lib_page3
    > .don_wrapper
      > .header
        .group_image_name
          display: inline-block;
          position: relative;
          margin-left: 8px;
          padding-left: 8px;
          color: $ness-black;
          font-family: Pretendard;
          font-size: 14px;
          font-weight: 700;
          line-height: 120%;
          &:before
            content: '';
            display: inline-flex;
            position: absolute;
            top: 50%;
            left: 0;
            height: 10px;
            width: 1px;
            transform: translateY(-50%);
            background-color $ness-gray-20


  .maker_custom_user_asset_image_list
    padding-top 4px
  .custom_asset_folder_item
    position relative
    display inline-block
    vertical-align top
    margin 0 0 24px 24px
    width 207.5px
    cursor pointer
    &:hover
    &:active
      .image
        border-color $ness-blue

    .image
      position relative
      padding 0
      padding-top 100%
      box-sizing border-box
      border-radius: 8px;
      border: 1px solid $ness-gray-20;
      img
        position absolute
        top 6%
        left 0
        right 0
        bottom 0
        max-width 90%
        max-height 90%
        margin auto
        border-radius: 8px;
    .name
      margin-top 8px
      text-align center
      height 19px
      overflow hidden
      text-overflow ellipsis
      white-space nowrap
      color: $ness-black;
      font-family: Pretendard;
      font-size: 12px;
      font-weight: 500;
      line-height: 140%;
    .status
      margin-top 2px
      color: $ness-blue
      text-align: center;
      font-family: Pragmatica Extended, Pretendard;
      font-size: 12px;
      font-weight: 700;
      line-height: 120%;
  .custom_asset_image_item
    position relative
    display inline-block
    margin 0 0 24px 24px
    width 207.5px
    height 207.5px
    cursor pointer
    box-sizing border-box
    border-radius: 8px;
    border: 1px solid $ness-gray-20;
    &[is_group="true"]
      margin-bottom 40px
      .thumbs
        padding 100% 0 0
        display block
        text-align center
        &[group_length="0"]
          display none
        .thumb
          display inline-block
          width 36%
          height 36%
          margin 0
          background-repeat no-repeat
          background-size contain
          background-position center center
          &:nth-child(1)
            position absolute
            top 10%
            left 10%
          &:nth-child(2)
            position absolute
            top 10%
            right 10%
          &:nth-child(3)
            position absolute
            bottom 10%
            left 10%
          &:nth-child(4)
            position absolute
            bottom 10%
            right 10%
      .image
        display none
      .name
        margin-top 8px
        display block
        color: $ness-black;
        text-align: center;
        font-family: Pretendard;
        font-size: 12px;
        font-weight: 500;
        line-height: 140%;
    &.selected
    &:active
    &:hover
      border-color: $ness-blue
      background-color $ness-white
    .thumbs
      display none
    .image
      border-radius 8px
      padding 100% 0 0
      background url(//s3.marpple.co/files/u_218933/2024/10/original/c0f2b3a8218b1e0ccba0c6daa30cb8bb114e6d201.png) no-repeat
      background-size contain
      img
        position absolute
        top 0
        left 0
        right 0
        bottom 0
        max-width 90%
        max-height 90%
        margin auto
        vertical-align top
    .name
      display none
