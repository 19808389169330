.cart-style-up-item__option
  display none
.up-item
  &__cart-option
    display none
.checkout_body[data-has_only_pb="true"]
  .purchase_agree-wrapper
  .shipping_agree-wrapper
  .coupon_point.section
  .shipping_type.section
    display none !important
.checkout_body[data-has_only_mp="true"]
  .disclosure_of_information_to_third_parties-wrapper
    display none
.checkout_body
  .pbg_tpl_product
    display none
  &[data-has_pbg="true"][data-has_tpl="true"][data-has_mps="false"]
  &[data-has_tpl="true"][data-has_mps="false"]
    .mps_tpl_product
      display none
    .pbg_tpl_product
      display block
  /* NESS */
  &[data-is_ness="true"][data-has_pbg="true"][data-has_tpl="true"][data-has_mps="false"]
  &[data-is_ness="true"][data-has_tpl="true"][data-has_mps="false"]
    .mps_tpl_product
      display block
    .pbg_tpl_product
      display none
.pre-shipping-order-item
  .mps-tooltip-icon,
  .tooltip-icon2
    margin-left 0 !important
    display none
[data-all_is_digital_product="true"]
  #shipping_info
  .shipping_type
    display none !important
  .check_all
    margin-bottom 20px !important
