DIGITAL_PRODUCT_CREATOR_HEADER_HEIGHT = 55px
DIGITAL_PRODUCT_CREATOR_LEFT_RATIO = 40%
DIGITAL_PRODUCT_CREATOR_RIGHT_RATIO = 100% - DIGITAL_PRODUCT_CREATOR_LEFT_RATIO

@keyframes file-upload {
  from {
    transform: translateY(50%);
    opacity: 0;
  }
  30% {
    transform: translateY(-10%)
    opacity: 0.3;
  }
  to {
    transform: translateY(0)
    opacity: 1;
  }
}
@keyframes file-upload-image {
  from {
    transform: translateY(50%);
    opacity: 0;
  }
  30% {
    transform: translateY(-4%)
    opacity: 0.3;
  }
  to {
    transform: translateY(0)
    opacity: 1;
  }
}

@keyframes rotate_image {
  100% {
    transform: rotate(360deg)
  }
}


.digital-product-creator-frame
  .w_br
  .m_br
    display block
  &__btn-close-frame
    position absolute
    top 9px
    right 9px
    width 36px
    height 36px
    border 0 none
    padding 0
    margin 0
    background-color transparent
    background-repeat no-repeat
    background-position center
    background-size 18px
    background-image url("//s3.marpple.co/files/u_14345/2021/5/original/e6fc7c6c93a1091aa268db9f6fa0730c8da9793d1.svg")
  &__btn-back-page.hide
    visibility hidden
  &.don_frame[is_modal="true"]
    >.don_wrapper
      width auto
      height auto
      top pxToRem(20px)
      left pxToRem(20px)
      right pxToRem(20px)
      bottom pxToRem(20px)
      min-width pxToRem(1200px)
      >.body
        overflow hidden
        position relative
      >.header
        height 55px
        width 100%
        position absolute
        top 0
        left 0
        border-bottom 1px solid $gray-s1
        background-color white
    .don_tab
      >.don_wrapper
        position static

.digital-product-category-selection
  margin-top DIGITAL_PRODUCT_CREATOR_HEADER_HEIGHT
  position absolute
  top 0
  left 0
  right 0
  bottom 0
  padding pxToRem(60px)
  display flex
  flex-direction column
  align-items center
  gap pxToRem(60px)
  &__title
    display flex
    flex-direction column
    align-items center
    &__main
      font-size pxToRem(24px)
      font-weight 600
      margin-bottom pxToRem(12px)
      letter-spacing -1px
    &__sub
      font-size pxToRem(14px)
      color $gray-s3
      letter-spacing pxToRem(-0.5px)
  &__categories
    display grid
    grid-column-gap pxToRem(20px)
    grid-row-gap ptr(20)
    grid-template-columns repeat(5, 1fr)
    >.category
      display flex
      flex-direction column
      align-items center
      cursor pointer
      max-width pxToRem(344px)
      >img
        width 100%
        height 100%
        margin-bottom pxToRem(24px)
      >.category-name
        font-size pxToRem(20px)
        font-weight 500
        letter-spacing -0.5px
        line-height 1.2


.digital-product-creator
  margin-top DIGITAL_PRODUCT_CREATOR_HEADER_HEIGHT
  display flex
  position absolute
  top 0
  left 0
  right 0
  bottom 0
  &__left
    flex-basis DIGITAL_PRODUCT_CREATOR_LEFT_RATIO
    background-color #f8f9fa
    padding pxToRem(50px) 0
    display flex
    flex-direction column
    align-items center
    box-sizing border-box
    overflow auto
  &__head
    text-align center
  &__title
    margin 0
    padding 0
    color #000
    font-size pxToRem(22px)
    font-weight bold
    letter-spacing -1px
    text-align center
  &__desc
    color $gray-s3
    font-size pxToRem(14px)
    letter-spacing -1px
    text-align center
  &__thumbnails-area
    margin-top pxToRem(76px)
    padding 0
    display flex
    align-items center
    flex-direction column

  &__right
    flex-basis DIGITAL_PRODUCT_CREATOR_RIGHT_RATIO
    padding pxToRem(40px)
    box-sizing border-box
    overflow auto
  &__form-buttons
    text-align right
  &__btn-preview
    min-width pxToRem(130px)
    height pxToRem(40px)
    border-radius pxToRem(2px)
    border 1px solid $gray-s2
    background-color #fff
    box-sizing border-box
    font-size pxToRem(14px)
    font-weight 500
    letter-spacing -0.5px
    text-align center
    color $gray-2
    &--disabled
      color $gray-s4 !important
  &__btn-submit
    min-width pxToRem(130px)
    margin-left pxToRem(6px)
    height pxToRem(40px)
    border-radius pxToRem(2px)
    background-color #3c89f9
    box-sizing border-box
    border 1px solid #3c89f9
    font-size pxToRem(14px)
    font-weight 600
    letter-spacing -0.5px
    text-align center
    color #fff !important
    &--disabled
      background-color #828282
      border-color #828282
  &__btn-cancel
    min-width pxToRem(130px)
    height pxToRem(40px)
    border-radius pxToRem(2px)
    border 1px solid #ddd
    background-color #fff
    box-sizing border-box
    font-size pxToRem(14px)
    font-weight 600
    letter-spacing -0.5px
    text-align center
    color #4c4c4c !important
  &__btn-more
    display inline-block
    width 24px
    background transparent
    padding 0
    margin-left pxToRem(6px)
    border none
    vertical-align middle
    img
      width 100%
  &__more-buttons
    top pxToRem(37px) !important
    right pxToRem(70px) !important
  &__error-msg
    margin pxToRem(7px) 0 0
    padding 0
    font-size pxToRem(13px)
    font-weight 500
    line-height 1.38
    letter-spacing -0.5px
    color #f66565
  &__form-area
    margin-top pxToRem(30px)
    margin-bottom pxToRem(40px)


.digital-product-thumbnail-editor
  position relative
  max-width pxToRem(500px)
  &__list
    margin 0
    padding 0
    list-style none
    display grid
    grid-gap pxToRem(10px)
    grid-template-columns repeat(6, 1fr)
  &__item
    position relative
    border 1px dashed #ccc
    padding-top calc(100% - 2px)
    background url("//s3.marpple.co/files/u_218933/2022/8/original/2b31b03ac278bce848da14702fbc4b25fe7769061.png") no-repeat center
    background-size pxToRem(15px) pxToRem(15px)
    cursor pointer
    &--on
      border-style solid
      background none
      &:hover
        border solid 1px #3c89f9
        .digital-product-thumbnail-editor__item-thumb-del
          display block
      img
        position absolute
        top pxToRem(2px)
        left pxToRem(2px)
        right pxToRem(2px)
        bottom pxToRem(2px)
        max-width calc(100% - 4px)
        max-height calc(100% - 4px)
        margin auto
        vertical-align top
    &--selected
      border solid 1px #3c89f9
      &:hover
        border-width 1px
  &__item-thumb-del
    display none
    position absolute
    top pxToRem(-10px)
    right pxToRem(-10px)
    width pxToRem(20px)
    height pxToRem(20px)
    img
      width 100%
      height 100%
  &__view-area
    position relative
    margin-top pxToRem(10px)
    width pxToRem(500px)
    border 1px dashed transparent
    max-width 100%
    padding-top 100%
    &[data-length="0"]
      border-color #ccc
    &[data-length="1"]
      .digital-product-thumbnail-editor__arrow
        display none
    &[is-show-upload="true"]
      .digital-product-thumbnail-editor__upload
        display block
      .digital-product-thumbnail-editor__photos
        display none
    &[is-show-upload="false"]
      .digital-product-thumbnail-editor__upload
        display none
      .digital-product-thumbnail-editor__photos
        display block
  &__upload
    position absolute
    top 50%
    left 0
    right 0
    transform translateY(-50%)
    margin auto
    width pxToRem(280px)
    text-align center
  &--hide
    display none
  &--show
    display block
  &__upload-icon
    img
      width pxToRem(36px)
      vertical-align top
  &__update-text1
    margin-top pxToRem(26px)
    font-size pxToRem(15px)
    font-weight 800
    line-height 1.27
    letter-spacing -0.2px
    color #aeaeae
  &__update-text2
    margin-top pxToRem(10px)
    font-size pxToRem(13px)
    font-weight 500
    line-height 1.46
    letter-spacing -0.2px
    color #aeaeae
  &__upload-btn-area
    margin-top pxToRem(36px)
    label
      position relative
      display inline-block
  &__input-file
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    opacity 0
    display none
    width 100%
    height 100%
  &__upload-btn
    padding pxToRem(10px) pxToRem(25px) pxToRem(9px) pxToRem(25px)
    border 0 none
    background-color #3c89f9
    color #fff !important
    font-size 14px
    font-weight 600
    letter-spacing -0.5px
    border-radius pxToRem(2px)
    span
      display block
      padding pxToRem(2px) 0 pxToRem(2px) pxToRem(27px)
      background url("//s3.marpple.co/files/u_218933/2022/8/original/5730e4209e5552643156f0fa44916a5eec8010671.png") no-repeat left center
      background-size pxToRem(20px) pxToRem(20px)
  &__photos
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    &--show
      display block
    &--hide
      display none
  &__photo
    display none
    position relative
    width 100%
    height 100%
    &--show
      display block
    img
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      margin auto
      max-width 100%
      max-height 100%
    &:hover
    &:active
      .digital-product-thumbnail-editor__photo-delete
        display block
  &__photo-delete
    display none
    position absolute
    top pxToRem(12px)
    right pxToRem(12px)
    background-color rgba(17, 17, 17, 0.45)
    width pxToRem(36px)
    height pxToRem(36px)
    border-radius 50%
    cursor pointer
    & img
      width pxToRem(18px)
      height pxToRem(18px)
  &__photo-set-first
    position absolute
    left 0
    right 0
    bottom pxToRem(-40px)
    text-align center
    font-size pxToRem(14px)
    line-height 1.57
    letter-spacing -0.5px
    color #000
    input
      vertical-align middle
      margin-bottom pxToRem(5px)
  &__text
    margin pxToRem(104px) 0 0
    padding pxToRem(7px) pxToRem(20px) pxToRem(5px)
    border-radius pxToRem(4px)
    background-color rgba(76, 76, 76, 0.05)
    font-size pxToRem(13px)
    line-height 1.46
    letter-spacing -0.2px !important
    text-align center
    color #4c4c4c
  &__arrow-prev
    position absolute
    top 50%
    left pxToRem(-40px)
    transform translateY(-50%)
    cursor pointer
  &__arrow-next
    position absolute
    top 50%
    right pxToRem(-40px)
    transform translateY(-50%)
    cursor pointer


.digital-product-creator-form
  >hr
    margin pxToRem(40px) 0
    border 0
    border-top 1px solid $gray-5
  &__control
    margin-top pxToRem(24px)
  &__input-wrapper
    position relative
    border-radius 3px
    border solid 1px $gray-s1
    &--error
      border-color #f66565
  &__text-input
    color $gray-1
    font-weight 500
    padding: pxToRem(11px) pxToRem(40px) pxToRem(11px) pxToRem(11px)
    height pxToRem(40px)
    font-size: pxToRem(14px)
    border 0 none !important
    background-color #fff
    outline none
    flex 1
    width 100%
    &::placeholder
      color $gray-4
  &__select-input
    color $gray-4
    font-weight 500
    padding: pxToRem(11px) pxToRem(40px) pxToRem(11px) pxToRem(11px)
    height pxToRem(40px)
    font-size: pxToRem(14px)
    border 0 none !important
    background-color #fff
    outline none
    flex 1
    width 100%
    -webkit-appearance none
    -moz-appearance none 
    background $select-down-arrow no-repeat calc(100% - 12px) center
    background-size ptr(20) ptr(20)
    &.active
      color $gray-1
    & > option[value=""]
      display none
    & > option
      color $gray-1
  &__required
    color $blue-100
  &__input-title
    margin-bottom pxToRem(12px)
    font-size pxToRem(14px)
    font-weight 600
    letter-spacing -0.5px
    color #000
    &__sub
      font-size pxToRem(13px)
      font-weight 500
      line-height 1.38
      letter-spacing -0.5px
      color $gray-s4
      margin-left pxToRem(6px)
  &__radio-label
    font-size pxToRem(14px)
    letter-spacing -0.5px
    margin-right pxToRem(27px)
    >input[type='radio']
      margin-top -1px
      margin-left 0
      vertical-align middle
      appearance none
      border 1px solid $gray-s1
      border-radius 50%
      width pxToRem(18px)
      height pxToRem(18px)
    >input[type="radio"]:checked
      border pxToRem(6px) solid #3c89f9
  &__product_names
    display flex
    align-items center
    justify-content space-between
  &__letter-number
    width pxToRem(100px)
    height pxToRem(40px)
    font-size pxToRem(14px)
    background-color $gray-s1
    color $gray-3
    display flex
    justify-content center
    align-items center
    flex-shrink 0
  &__flag-icon
    width pxToRem(18px)
    height pxToRem(18px)
    object-fit contain
  &__number-input-wrap
    position relative
    display flex
  &__won
    position absolute
    top 0
    right 0
    bottom 0
    padding 0 pxToRem(11px)
    display flex
    align-items center
  &__desc
    font-size pxToRem(13px)
    font-weight 500
    color $gray-3
  &__select-cate-items
    width 100%
    -webkit-appearance none
    -moz-appearance none
    font-size pxToRem(14px)
    padding: pxToRem(11px) pxToRem(40px) pxToRem(11px) pxToRem(11px)
    border 0 none !important
    background $image-down-arrow no-repeat calc(100% - 10px) center
    background-size 18px 18px
    color $gray-4
    &--selected
      color $gray-1
  &__upload-box
  &__preview-upload-box
    display flex
    cursor pointer
    padding pxToRem(20px)
    height pxToRem(80px)
    border solid 1px $gray-s1
    border-radius 3px
    gap pxToRem(12px)
    &__icon-background
      width pxToRem(40px)
      height pxToRem(40px)
      background-color #3c89f9
      border-radius 3px
      display flex
      justify-content center
      align-items center
    &__icon
      width pxToRem(24px)
      height pxToRem(24px)
    &__guides
      height pxToRem(40px)
      padding pxToRem(2px) 0
      display flex
      justify-content center
      flex-direction column
      gap pxToRem(5px)
    &__guide-main
      font-size pxToRem(14px)
      font-weight 500
      color #3c89f9
    &__guide-sub
      font-size pxToRem(13px)
      font-weight 500
      color $gray-3
  &__preview-upload-box.disabled
    cursor default
  &__upload-results-dashboard
    display flex
    align-items center
    border-top-left-radius 3px
    border-top-right-radius 3px
    height pxToRem(50px)
    background-color #f5f9fe
    gap pxToRem(14px)
    border-top solid 1px $gray-s1
    border-left solid 1px $gray-s1
    border-right solid 1px $gray-s1
    border-bottom solid 1px rgba(0,0,0,0)
    cursor pointer
    &.close
      border-radius 3px
      border-bottom solid 1px $gray-s1
    &__toggle-button
      width pxToRem(18px)
      height pxToRem(18px)
      transform rotate(-180deg)
      transition transform 0.4s
      &.close
        transform rotate(0)
    &__upload-message
      opacity 0
      transition opacity 225ms cubic-bezier(0.25, 0.46, 0.45, 0.94)
      &.text-fade
        opacity 1
        transition opacity 300ms cubic-bezier(0.55, 0.085, 0.68, 0.53)
    &__files-total-count
      opacity 0
      transition opacity 225ms cubic-bezier(0.25, 0.46, 0.45, 0.94)
      &.text-fade
        opacity 1
        transition opacity 300ms cubic-bezier(0.55, 0.085, 0.68, 0.53)
    &__fail
      color #f66565
      &.none
        display none
  &__upload-results
    display flex
    flex-direction column
    padding pxToRem(20px)
    border solid 1px $gray-s1
    border-top none
    border-bottom-left-radius 3px
    border-bottom-right-radius 3px
    gap pxToRem(12px)
    max-height 500px
    transform-origin top
    background-color white
    overflow hidden
    opacity 1
    transition max-height 0.4s, padding-top 0.6s, padding-bottom 0.6s, margin-top 0.6s, margin-bottom 0.6s, opacity 0.6s
    &.close
      max-height 0
      padding-top 0
      padding-bottom 0
      margin-top 0
      margin-bottom 0
      opacity 0
  &__decorate-image-background
    width pxToRem(32px)
    height pxToRem(32px)
    background-color #ebf3fe
    display flex
    justify-content center
    align-items center
    animation file-upload 1s
    border-radius 50%
    flex-shrink 0
    &.fail
      background-color #fde7ea
  &__decorate-image-icon
    width pxToRem(20px)
    height pxToRem(20px)
    animation file-upload-image 1s
  &__upload-info
    color $gray-2
    letter-spacing -0.5px
  &__spinner-icon
    animation rotate_image 0.7s linear infinite
    transform-origin 50% 50%
    width pxToRem(20px)
    height pxToRem(20px)
    opacity 1
    transition opacity 0.2s
    &.hide
      opacity 0
    &.none
      display none
  &__complete-circle
    position relative
    border 1.5px solid #3c89f9
    border-radius 50%
    width pxToRem(20px)
    height pxToRem(20px)
    transition opacity 0.2s
    opacity 1
    &.hide
      opacity 0
    &.none
      display none
  &__complete-checkmark
    position absolute
    transform rotate(45deg) translate(-50%, -50%)
    left 28%
    top 46%
    height pxToRem(8px)
    width pxToRem(5px)
    border-bottom 1.5px solid #3c89f9
    border-right 1.5px solid #3c89f9
  &__complete-checkmark-screen
    position absolute
    transform-origin right
    transform translate(-50%, -50%) scaleX(1)
    left 50%
    top 50%
    width pxToRem(10px)
    height pxToRem(10px)
    background-color white
    transition transform 0.9s
    &.hide
      transform translate(-50%, -50%) scaleX(0)
  &__upload-result
    display flex
    align-items center
    &__fail-wrapper
      display flex
      align-items center
    &__file-name
      font-size pxToRem(14px)
      font-weight 500
      line-height 1.29
      letter-spacing -0.5px
      color $gray-2
      animation file-upload 1s
      word-break break-all
    &__file-size
      font-size pxToRem(14px)
      font-weight 500
      line-height 1.29
      letter-spacing -0.5px
      color $gray-s4
      animation file-upload 1s
      flex-shrink 0
    &__fail-message
      font-size pxToRem(13px)
      font-weight 500
      letter-spacing -0.5px
      color #f66565
    &__icon
      transition-property transform opacity
      transition-duration 0.5s
      transition-timing-function ease-out
      transform translateY(-50%)
      height pxToRem(18px)
      opacity 0
      overflow hidden
      flex-shrink 0
    &__icon.active
      height pxToRem(18px)
      width pxToRem(18px)
      opacity 1
      transform translateY(0)
      cursor pointer
    &__progress
      appearance none
      margin-right pxToRem(70px)
      transition 1s ease-out
      opacity 0
      height fit-content
      &::-webkit-progress-bar
        transition 0.2s ease-out
        opacity 0
        display block
        border-radius 10px
        background-color #f2f2f2
        margin-right 10px
        width 0
        height pxToRem(8px)
      &::-webkit-progress-value
        display block
        border-radius 10px
        background-color #3c89f9
        transition 0.4s ease-out
    &__progress-view
      width 44px
      text-align right
      font-weight 500
      font-size pxToRem(14px)
      color #3c89f9
      transition 1s ease-out
    &__progress.active
      opacity 1
      transition 1s ease-out
      &::-webkit-progress-bar
        opacity 1
        width pxToRem(200px)
    &__progress-view.active
      opacity 1
    &__progress.hide
    &__progress-view.hide
      opacity 0
    &__progress.none
    &__progress-view.none
      display none
  &__setting-token-gate
    .setting-token-gate-wrapper--empty
      display none
  &__story
    border-top 1px solid #f2f2f2
    .lang-selector
      display none
    > div
      padding 0 !important
  &__information-list
    display flex
    flex-direction column
    gap pxToRem(24px)
  &__information-contents
    background-color $gray-6
    padding pxToRem(20px)
    display flex
    flex-direction column
    gap pxToRem(6px)
    border-radius 3px
  &__information-content
    color $gray-3
    font-size pxToRem(13px)
  &__check-box-label
    font-size pxToRem(14px)
    letter-spacing -0.5px
    margin-right pxToRem(8px)
    font-weight 500
    >input[type='checkbox']
      margin-top 1px
      margin-left 0
      vertical-align middle
      cursor pointer
      border 1px solid #d9d9d9
      border-radius 2px
      width pxToRem(18px)
      height pxToRem(18px)
  &__required-message
    color #3c89f9
  &__input-file
  &__preview_input-file
    position absolute
    top 0
    left 0
    width 0
    height 0
    opacity 0
    display none
  &__preview-progress-wrapper
    display flex
    align-items center
    width 100%
    margin-left pxToRem(-10px)
  &__qna-type-mp
    &--hide
      display none
  &__qna-type-sns
    margin-top pxToRem(6px)
    &--hide
      display none
  &__qna-info
    margin pxToRem(13px) 0 0
    padding 0
    font-size pxToRem(12px)
    line-height 1.5
    letter-spacing -0.5px
    color #757575
  &__inquiry-precautions
    margin-top pxToRem(16px)
    padding pxToRem(20px)
    color #828282
    border-radius pxToRem(3px)
    background-color #f8f8f8
    &__txt1
      margin 0 0 pxToRem(6px)
      padding 0
      font-weight 600
      line-height 1.29
      letter-spacing -0.2px
      color #111
    ul
      margin 0
      padding 0
      list-style none
      font-size pxToRem(13px)
      line-height 1.69
  &__icon-important
    margin-left pxToRem(2px)
    display inline-block
    vertical-align top
    width pxToRem(15px)
    height pxToRem(15px)
    background url(//s3.marpple.co/files/u_218933/2022/8/original/06b2fc36647431e3e4b333a11156a1eaa285ab351.png) no-repeat
    background-size pxToRem(15px) pxToRem(15px)
    &--ml0
      margin-left 0
      margin-right pxToRem(2px)
  &__input-wrap
    display flex
    width 100%
    column-gap ptr(24)
    & > div
      flex 1

.dpc-styl
  &__mt-10px
    margin-top pxToRem(10px)
  &__mt-12px
    margin-top pxToRem(12px)
  &__mt-18px
    margin-top pxToRem(12px)
  &__mt-24px
    margin-top pxToRem(24px)
  &__mt-40px
    margin-top pxToRem(40px)
  &__ml-4px
    margin-left pxToRem(4px)
  &__ml-8px
    margin-left pxToRem(8px)
  &__ml-10px
    margin-left pxToRem(10px)
  &__ml-12px
    margin-left pxToRem(12px)
  &__ml-16px
    margin-left pxToRem(16px)
  &__ml-20px
    margin-left pxToRem(20px)
  &__mr-auto
    margin-right auto
  &__mr-28px
    margin-right pxToRem(28px)
  &__flex
    display flex
  &__items-center
    align-items center


