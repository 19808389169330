$mshop-comp-dropdown-transition = ease 0.15s
.mshop-comp-dropdown
  position relative

  &[data-open="true"] &__menu
    display flex
  &[data-open="true"] &__button-icon
    transform rotate(90deg)

  &__button
    userSelectNone()
    cursor pointer
    display flex
    column-gap 0.38rem
    background-color transparent
    border 0
    align-items center
    margin-left auto

    {$font-body-b1-r}
    color: $gray-800
    & > input
      display none
  &__button-icon
    width 0.75rem
    height 0.75rem
    display inline-flex
    transform rotate(-90deg)
    transition transform $mshop-comp-dropdown-transition

  &__menu
    display none
    margin-top 0.75rem
    background-color $white
    z-index 1
    position absolute
    left 0
    flex-direction column

    padding 0.5rem 0
    border-radius 0.5rem
    border: 1px solid $gray-200
    background $white
    box-shadow 0 4px 8px 0 rgba(17, 17, 17, 0.05)
    &--right
      right 0
      left auto
  &__option
    display flex
    padding pxToRem(12) pxToRem(32) pxToRem(14) pxToRem(20)
    background-color $white
    border 0
    align-items center
    white-space nowrap
    {$font-body-b1-r}
    color $gray-700
    &:hover
      background-color $gray-100
    &--sub
      background-color $gray-100
      color $gray-600
    &--sub:hover
      color $gray-800
    &[data-selected="true"]
      {$font-body-b1-sb}
      color $gray-800 !important

  &__option-icon
    width 0.75rem
    height 0.75rem
    display inline-flex
    transition transform $mshop-comp-dropdown-transition

  &__option > &__option-icon
    margin-left auto
  &__option-sub-container
    display none
    &[data-open="true"]
      display block

  &__option--group[data-open="true"] &__option-icon
    transform rotate(180deg)

  &--nbox[data-highlight="true"]
    &[data-open="true"], &[data-selected="true"]
      .mshop-comp-dropdown__button
        border-color $nbox-color
        color $nbox-color
      .mshop-comp-dropdown__button *
        stroke $nbox-color !important

  /* NESS용 dropdown 디자인 */
  &--NESS
    // dropdown button
    .mshop-comp-dropdown__button
      column-gap pxToRem(8px)
    .mshop-comp-dropdown__button-text
      color $ness-black
      {$font-kr-b-14--100}
    .mshop-comp-dropdown__button-icon
      transform rotate(0)
      width 0.5rem
      height 0.375rem
    &[data-open="true"] .mshop-comp-dropdown__button-icon
      transform rotate(180deg)

    // dropdown menu box
    .mshop-comp-dropdown__menu
      border-radius pxToRem(4px)
      border 1px solid $ness-black
      padding 0
      overflow hidden
    .mshop-comp-dropdown__option
      {$font-kr-b-14--100}
      color $ness-gray-80
      padding pxToRem(18px) pxToRem(16px)
      min-width pxToRem(160px)
      &:not(:last-child)
        border-bottom 1px solid $ness-gray-5
      &:hover
        color $ness-gray-90
        background-color $ness-gray-5
      &[data-selected="true"]
        color $ness-white
        background-color $ness-black
