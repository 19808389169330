.creator-settings
  .product-guide-popup-frame
    > .don_wrapper
      max-width 736px
      max-height 610px
      width 80%
      height 80%
      overflow hidden
    .don_hide_frame
      background-image url(//s3.marpple.co/files/u_2283830/2024/10/original/e65cc766cb28a8f2365cce65952083b7414141081.webp) !important
      background-size 24px !important
      top 24px !important
      right 24px !important

  .product-guide-popup
    &__body
      width ptr(736)
      padding 0 ptr(48)
    &__title
      margin 48px 0 32px
      text-align center
    &__ul
      padding 0
      margin 0
      list-style none
      display flex
      justify-content space-between
      gap ptr(32)
    &__li
      flex 1 1 0
      display flex
      flex-direction column
      gap ptr(16)
    &__img
      img
        width 100%
    &__text
      display flex
      flex-direction column
      gap ptr(8)
      h3, p
        margin 0
        padding 0
    &__guide
      display flex
      flex-direction column
      gap ptr(4)
