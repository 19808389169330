.shipping-info-editor-frame.don_frame[is_modal="true"]
  >.don_wrapper
    width 608px
    >.header
      width 100%
      box-sizing border-box
      padding pxToRem(16px)
      margin 0
      height pxToRem(56px)
      border-bottom 1px solid $gray-5
      >.title
        {$kr-16-b}

.shipping-info-editor
  padding pxToRem(32px)
  padding-bottom calc(env(safe-area-inset-bottom) + 136px)
  padding-bottom calc(constant(safe-area-inset-bottom) + 136px)
  &__error-msg
    display none
    margin pxToRem(7px) 0 0
    padding 0
    font-size pxToRem(13px)
    font-weight 500
    line-height 1.38
    letter-spacing -0.5px
    color #f66565
    &--show
      display block
  &__checks
    display flex
    column-gap pxToRem(16px)
    margin-bottom pxToRem(24px)
    .mshop-core__checkbox__title
      color $black
  &__control
    position relative
    margin-top pxToRem(24px)
    &:firat-child
    &--mt0
      margin-top 0
    &--mt8
      margin-top pxToRem(8px)
  &__control-area
    position relative
    &--cols2
      display grid
      grid-gap pxToRem(10px)
      grid-template-columns repeat(2, 1fr)
  &__label
    display block
    &--inline
      display inline-flex
      align-items center
      input
        margin-bottom pxToRem(4px)
        margin-right pxToRem(5px)
    &--lm10
      margin-left pxToRem(10px)
    &--lm-minus3
      margin-left pxToRem(-3px)

  &__label-txt
    margin-bottom pxToRem(8px)
  &__body
    position relative
    min-height pxToRem(48px)
    box-sizing border-box
    &--error
      border-color #f66565
    &--postcode
      width 70%
  &__body-area
    position relative
    &--cols2
      display grid
      grid-gap pxToRem(10px)
      grid-template-columns repeat(2, 1fr)
  &__body-input
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    padding pxToRem(12px) 0
    color $black
    border none
    border-bottom 1px solid $gray-10
    background-color #fff
    outline none
    border-radius 0
    -webkit-border-radius 0
    &::placeholder
      -webkit-text-fill-color $gray-50
      color $gray-50
    &--error
      border-color #f66565
  &__body-select
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    padding pxToRem(12px) pxToRem(16px) pxToRem(12px) 0
    box-sizing border-box
    -webkit-appearance none
    -moz-appearance none
    border 1px solid $gray-10
    border-radius pxToRem(8px)
    background $icon-dropdown-arrow no-repeat 97% center
    background-size pxToRem(16px)
    width 100%
    height 100%
    text-indent pxToRem(12px)
    color $black
  &__btn-postcode
    width 100%
    height 100%
    border-radius pxToRem(8px)
    border none
    background-color $blue-10
    text-align center
    color $blue-100
    {$button-blue-theme}
  &__icon-important
    margin-right pxToRem(5px)
    padding-top pxToRem(4px)
    img
      width pxToRem(15px)
  &__body-right
    position absolute
    top 0
    right 0
    bottom 0
    padding 0 pxToRem(11px)
    display flex
    align-items center
  &__control-wrap
    display flex
    align-items center
    justify-content space-between
  &__control-wrap-grid2
    display grid
    grid-gap pxToRem(8px)
    grid-template-columns auto pxToRem(200px)
  &__buttons
    position fixed
    bottom 0
    left 0
    right 0
    background-color $white
    padding pxToRem(16px)
    border-top 1px solid $gray-5
    z-index 10
    display flex
    &:after
      display block
      content ''
      padding-bottom env(safe-area-inset-bottom)
      padding-bottom constant(safe-area-inset-bottom)
    & > button
      width 100%
      height pxToRem(56px)
      border-radius pxToRem(8px)
  &__btn-submit
    color $gray-50
    border 0 none
    background-color $gray-5
    &.active
      color $white
      background-color $blue-100
      transition background-color 200ms ease-out
      &:hover:not(&:disabled,&.disabled)
        background-color $blue-10 !important
        color $blue !important
  &__btn-cancel
    color $gray-80
    border 1px solid $gray-20
    background-color $white
    margin-right ptr(16)
    transition border 200ms ease-out
    &:hover:not(&:disabled,&.disabled)
      border 1px solid $blue !important
      color $blue !important
