.don_frame[frame_name='/modules/Creator/Login/ResetPassword/S/Mui/frame.js']
  .reset-password-wrapper
    display flex
    .reset-password
      display flex
      flex-direction column
      align-items center
      margin 0 auto
      width pxToRem(520px)
      &__title
        width 100%
        padding 0 0 pxToRem(20px) 0
        margin 0
        line-height 1.5
        font-size pxToRem(20px)
        font-weight bold
      &__sub-title
        width 100%
        padding pxToRem(24px) 0
        margin 0
        font-size pxToRem(17px)
        font-weight bold
        line-height 1
        letter-spacing -0.3px
        text-align left
        color $gray-1
      &__info
        width 100%
        display flex
        flex-direction column
        padding-bottom pxToRem(40px)
        padding-top pxToRem(4px)
      &__privacy
        box-sizing border-box
        width 100%
        display flex
        flex-direction column
        gap pxToRem(10px)
        padding pxToRem(20px) pxToRem(24px)
        background-color #f8f8f8
        border-radius pxToRem(8px)
        &__data
          display flex
          gap pxToRem(10px)
        &__key
          width pxToRem(128px)
          color #828282
        &__value
          font-size pxToRem(14px)
          &.email
            font-weight 600
            color #0e2ee2
      &__email
        width 100%
        display flex
        flex-direction column
        gap pxToRem(24px)
        padding-top pxToRem(10px)
        margin-bottom pxToRem(50px)
        &__title
          font-size pxToRem(16px)
          font-weight 600
          line-height 1.38
          letter-spacing -0.2px
          color $gray-1
          padding 0
          margin 0
        &__value
        &__value-check
          box-sizing border-box
          outline none
          width 100%
          margin pxToRem(8px) 0 0
          line-height 1.38
          padding pxToRem(16px) pxToRem(25px)
          border-radius pxToRem(4px)
          border solid pxToRem(1px) #dddddd
          font-size pxToRem(16px)
          letter-spacing pxToRem(-0.2px)
          &::placeholder
            color $gray-4
          &:focus
            border solid pxToRem(1px) #111
          &.no-equal
            border solid pxToRem(1px) #f66565
        &__no-equal
          display none
          font-size pxToRem(13px)
          margin pxToRem(8px) 0 0 0
          font-weight 500
          line-height 1.31
          letter-spacing -0.5px
          text-align left
          color #f66565
      &__buttons
        display flex
        width 100%
        gap pxToRem(10px)
      &__button
        width 100%
        height pxToRem(56px)
        background-color #a2a2a2
        border-radius pxToRem(8px)
        border none
        padding 0
        margin 0
        color white
        font-size pxToRem(16px)
        font-weight bold
        &:hover
          cursor default
        &.enable
          background-color #111
          &:hover
            cursor pointer
