@import "../share.styl";

.tracking-item-order.don_frame[is_modal="true"]
  >.don_wrapper
    width 608px
    >.header
      width 100%
      box-sizing border-box
      padding pxToRem(16px) pxToRem(16px) pxToRem(16px) pxToRem(24px)
      margin 0
      >.title
        {$kr-16-b}

  .shipping-info-modal
    &__summary
      padding pxToRem(32px) pxToRem(0px)
