@import "../share.styl"
.up-item-option-infos
  &__quantity
    span
      width 50px
.up-item
  &__product-wrapper
    display grid
    grid-column-gap pxToRem(18px)
    grid-template-columns minmax(pxToRem(65px), pxToRem(110px))  minmax(pxToRem(200px), auto)
  &__warning-message
    padding-top pxToRem(18px)
    display flex
    align-items flex-start
    img
      width pxToRem(15px)
      padding-bottom pxToRem(2px)
    span
      margin-left pxToRem(4px)
      line-height pxToRem(18px)
      color #f66565
  &__thumbnail-wrapper
    img
      width pxToRem(110px)
      height pxToRem(110px)
  &__cart-option
    &__delete-btn
      img
        width 15px
        height 15px
    &__revise-btn
      font-size 13px
.up-item-product-info
  &__up_c_id
    {$font-body-en-b4-r}
    color $gray-500
    margin-bottom pxToRem(6px)
  &__name
    {$font-body-b1-r}
    margin-bottom pxToRem(6px)
  &__second-name
    font-size pxToRem(14px)
    margin-bottom pxToRem(12px)
  &__price
    font-weight 500
    font-size pxToRem(16px)


.cart-style-up-item
  .canvas-basic-anchor
    transform scale(.5)
  .canvas-basic-item
    position relative
    display flex
    canvas
      width pxToRem(256px)
      height pxToRem(256px)
      transform-origin 50% 50%
  .cch-cart-style-up-item-main
    display flex
    &__thumbnail
      margin-right pxToRem(16px)
      flex-grow 0
      flex-shrink 0
      width pxToRem(128px)
      height pxToRem(128px)
      display flex
      justify-content center
      align-items center
      background #f6f6f6
      border-radius ptr(16)
      overflow hidden
      img
        width pxToRem(128px)
        height pxToRem(128px)
        object-fit contain
    &__info
      flex-grow 1
      display flex
      flex-direction column
      justify-content center
      row-gap ptr(4)
      &__name
        *
          limitLine(2)
          color $black
          padding-right ptr(25)
      &__second-name
        *
          limitLine(1)
          color $gray-50
      &__price
        color $black
        limitLine(1)
        &:after
        &:before
          {$kr-20-b}
  .cch-style-option-list
    background $gray-5
    border-radius 8px
    &__item-wrapper
      padding pxToRem(8px) pxToRem(16px)
  .cch-style-option-list__item
    display flex
    justify-content space-between
    margin-bottom pxToRem(8px)
    &:last-child
      margin-bottom 0
    &__info-1
      display flex
      align-items center
      span
        color $black
      &__not-normal-warning
        display none
        background $error
        font-weight bold
        color #fff !important
        margin-right pxToRem(8px)
        border-radius pxToRem(2px)
        font-size 12px
        padding pxToRem(4px) pxToRem(6px) pxToRem(3px)
      &__option-name
        max-width pxToRem(340px)
        padding-right 1px
        limitLine(1)
      &__quantity
        display inline-flex
        justify-content flex-end
        flex-shrink 0
    &__info-2
      display flex
      align-items center
      justify-content flex-end
      flex-shrink 0
      margin-left pxToRem(8)
      color $black
      &__price
        color $black
        &:after
        &:before
          {$kr-14-m}
  //&__price
  .cch-style-option-list
    &[data-text_options_length="0"]
      .cch-style-option-list__text-item
        display none
    &__item
      &[data-status="NO_STOCK"]
      &[data-status="OPTION_CHANGED"]
      &[data-status="NOT_ENOUGH_STOCK"]
      &[data-status="LIMIT_EXPIRED"]
        .cch-style-option-list__item__info-1__not-normal-warning
          display inline-block
          flex-shrink 0
  .cch-style-option-list__text-item
    padding 0 pxToRem(16px)
    padding-bottom ptr(8)
    &__title
      limitLine(1)
      color $gray-50
    &__value
      limitLine(1)
      color $black
      &:last-child
        margin-bottom 0

  &__option
    &__delete-btn
      position absolute
      background none
      border none
      top ptr(8)
      right ptr(8)
      background #fff
      img
        width pxToRem(16px)
        height pxToRem(16px)
    &__modify-btn
      background $white
      position absolute
      bottom ptr(8)
      right ptr(8)
      color $gray-80
      padding 0 pxToRem(12px)
      border 1px solid $gray-50
      border-radius 8px
      height ptr(36)
  &__content-1
    position relative
    margin-bottom pxToRem(12px)
  &__content-2
    margin-bottom pxToRem(4px)
  &__content-warning
    display none
    margin-top ptr(12)
    align-items flex-start
    img
      padding-bottom pxToRem(2px)
    span
      margin-left pxToRem(2px)
      line-height pxToRem(16px)
      color $error

  &[data-has_warning='true']
    .cart-style-up-item__content-warning
      display flex

.up-item-list-price-info
  &__1
    padding-bottom pxToRem(16px)
    border-bottom 1px solid $black
  &__2
    padding-top ptr(8)
    padding-bottom ptr(16)
    border-bottom 1px dashed $gray-50
    display flex
    flex-direction column
    &__price
      color $black
      &:after
      &:before
        {$kr-14-m}
    &__item
      padding ptr(12) 0
      display flex
      justify-content space-between
      &__info-2
        &__price
          background-color inherit
          color $black
          &:after
          &:before
            {$kr-14-m}
    &__item-shipping-wrap
      background-color $gray-5
      border-radius 8px
    &__item-shipping
      display flex
      justify-content space-between
      color $black
      padding ptr(12)
      &__label
        display flex
        align-items center
      img
        width pxToRem(7px)
        height pxToRem(7px)
        margin-right pxToRem(7px)
    &__pickup
      &[data-is_pickup="false"]
        display none
  &__3
    padding-top pxToRem(16px)
    display flex
    justify-content space-between
    &__label
      color $black
    &__content
      color $black
    &__price
      color $black
      &:after
      &:before
        {$kr-20-b}
    &.delivery-fee-info
      padding-top ptr(8)
      justify-content flex-end
      & > .up-item-list-price-info__3__label
        color $gray-50
