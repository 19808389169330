$pp = plus-product
.{$pp}
  width 100%
  max-width 1200px
  margin 0 auto
.{$pp}-hide
  display none !important
.{$pp}-header
  display flex
  justify-content space-between
  align-items center
  margin-bottom 10px
.{$pp}-btn
  position relative
  display flex
  justify-content flex-end
  padding 10px 0
  background rgba(255,255,255,0.9)
  &__wrapper
    z-index 6
  &__preview
    position relative
    top auto
    right auto
  &__save
    position relative
    top auto
    right auto


.{$pp}-editor
  position relative
  margin 8px 0
  &__margin
    &--bottom
      margin-bottom 140px
  &__title
    display flex
    align-items center
    margin 0 -5px
  &__row
    width (100 / 3)%
    padding 0 5px
    margin 0
    box-sizing border-box
  &__input
    height 40px
  &__modify
    display flex
    margin-bottom 20px
    justify-content space-between
  &__modify-wrapper
    display flex
  &__modify-item
    margin-left 20px
    display flex
    align-items center
    &--margin0
      margin-left 0
  &__remove-btn
    border 1px solid #d9d9d9
    background #fff
    padding 3px 8px
  &__sort-btn
    reset-button()
    border 1px solid #d9d9d9
    padding 3px 8px
    margin-left 4px
    display flex
    justify-content center
    align-items center
    &:after
      content ''
      display block
      width 10px
      height 10px
      background-image url(//s3.marpple.co/files/u_29089/2021/4/original/bcce647f518324867afae2da33d8ee5f36a57e281.png)
      background-size 10px
      margin-left 4px
    &--up:after
      transform rotate(90deg)
    &--down:after
      transform rotate(-90deg)
      margin-bottom 3px
    &.active
      background #000000
      color #fff
  &__is_public
    font-size 15px
  &__type
    font-size 15px
  &__cgi-item
    .category-group-item__img
      cursor grab

.{$pp}-add
  margin 40px 0
  display flex
  justify-content center
  &__btn
    reset-button()
    display flex
    align-items center
    font-size 16px
  &__icon
    margin-right 8px

.{$pp}-cgi
  &__add-item
    display flex
    justify-content center
    align-items center
    margin auto 0 !important
    width calc((100% - 40px) / 6)
  &__add-btn
    reset-button()
    .crew-banner__add-icon
      margin auto
      background-color #d9d9d9
      width 50px
      height 50px

    p
      font-size 17px
  &__modify-item
    input[type="file"]
      opacity 0
      position absolute
      top 0
      left 0
      width 1px
      height 1px
  &__label
    text-align center
    position absolute
    bottom 0
    left 0
    right 0
    border 1px solid #d9d9d9
    background #fff
    padding 3px 8px
    margin auto
    width 84px
    display block
    cursor pointer
  &__remove-btn
    position absolute
    top 0
    right 1px
  &__filter
    text-align center
    margin 6px 0 20px
    button
      reset-button()
      width 130px
      border 1px solid #d9d9d9
      padding 3px 8px
  &__filter-category
    margin-bottom 4px

.plus-category-group--editing
  .category-group-item
    &__filter_name
      display flex
      align-items center
      justify-content center
    &__filter-remove
      position relative
      top auto
      right auto
      width 18px
      height 18px
      background-size 12px
      margin-left 4px
    &__list
      flex-wrap wrap
    &__button
      display none
  .category-group__box
    &--medium
      .{$pp}-cgi
        &__add-item
          margin-right 8px
          &:nth-child(6n)
            margin-right 0
    &--large
      .{$pp}-cgi
        &__add-item
          margin-right 8px
          &:nth-child(4n)
            margin-right 0
