.app-home-community-list
  position relative
  &__container
    display grid
    grid-template-columns repeat(auto-fill, minmax(23%, 1fr))
    grid-gap pxToRem(24)
  .community-review-item
    width 100%
    margin-bottom 0
    box-sizing border-box
    &__photo_img
      position relative
      width 100%
      padding-top 100%
      overflow hidden
      img
        position absolute
        top 0
        left 0
        right 0
        bottom 0
        margin auto
        width 100%
        max-height none
    &__body
      height 46px
      overflow hidden
