common() {
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing -0.2px;
  color: #111111;
}

$flex(jc=flex-start, ai=center, di=row) {
  display: flex;
  flex-direction: di;
  justify-content: jc;
  align-items: ai;
}

d-flex(jc="", ai="") {
  display: flex;
  flex-direction: column;
  justify-content: jc if !!jc;
  align-items: ai if !!ai;
}

d-flex-row($jc="", $ai="") {
  display: flex;
  flex-direction: row;
  justify-content: $jc;
  align-items: $ai;
}

ellipsis() {
  display block
  width 100%
  white-space nowrap
  text-overflow ellipsis
  overflow hidden
}

big-title() {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.33;
}

mid-title() {
  font-size: 22px;
  font-weight: normal;
  line-height: 1.27;
  //color: #4e4e4e;
  color: #111111;
  margin: 0;
}

sm-title() {
  font-size: 14px;
  font-weight: 600;
}

control-button() {
  border none
  outline none
  background #f8f8f8
}

text() {
  font-size: 14px;
  font-weight: normal;
}

ol-item() {
  font-size: 14px;
  font-weight: normal;
}

ul-item() {
  font-size: 14px;
  font-weight: normal;
}

caution(bg-color = #f8f8f8) {
  margin: 20px 0;
  padding: 16px 20px;
  border-radius: 6px;
  background-color: bg-color;

  &__title {
    font-size: 14px;
    font-weight: 600;
    color: #4e4e4e;
  }

  &__text {
    margin: 8px 0 0;
    font-weight: normal;
    color: #4e4e4e;
  }
}

square(num) {
  width num !important
  height num !important
}

rect(w, h) {
  width w
  height h
}

single-image() {
  display: block;
  margin: 0 auto
  width: 377px;
  max-width 100%
  object-fit: cover;
}

unordered-list()
  padding-left 0
  li
    position relative
    margin 0
    padding 0
    margin-left ptr(12)
    list-style-type disc
    &:last-child
      margin-bottom 0

fold(s=0)
  .pd-radio:nth-child(n+{s})
    position relative
    z-index 2
    animation slideLeft
    animation-duration 0.5s
    animation-fill-mode forwards
    .pd-radio__face
      border none
      margin 2px
    .pd-radio__text
      opacity 0
      transition opacity 0.5s
    .pd-radio__face--color
      position relative
      top 0
      left 0
      display block
      border none
      margin 2px
      &:before
        position absolute
        top 0
        right 0
        left 0
        bottom 0
        content "+"
        display flex
        justify-content center
        align-items center
        font-size 20px
        font-weight 600
        text-align center
        color #fff
        opacity 0
        animation fadeIn
        animation-duration 0.5s
        animation-fill-mode forwards
  .pd-radio:nth-child(n+{s+1})
    position absolute
    top auto
    left auto !important
    bottom 0
    right 2px
    z-index 1
    opacity 0
    animation fadeIn
    animation-duration 0.5s
    animation-fill-mode forwards
  .pd-radio:nth-child(n+{s+2})
    right -2px
    z-index 0
  .pd-radio:nth-child(n+{s+3})
    display none

fold_mobile(s=0)
  .pd-radio:nth-child(n+{s})
    position relative
    z-index 2
    animation slideLeft
    animation-duration 0.5s
    animation-fill-mode forwards
    .pd-radio__face
      border none
      margin 2px
    .pd-radio__text
      opacity 0
      transition opacity 0.5s
    .pd-radio__face--color
      position relative
      display block
      border none
      square(28px)
      margin 2px
      &:before
        position absolute
        top 0
        right 0
        left 0
        bottom 0
        content "+"
        display flex
        justify-content center
        align-items center
        font-size 20px
        font-weight 600
        text-align center
        color #fff
        animation fadeIn
        animation-duration 0.5s
        animation-fill-mode forwards
  .pd-radio:nth-child(n+{s+1})
    position absolute
    top auto
    left auto !important
    bottom 0
    right 0
    z-index 1
    opacity 0
    animation fadeIn
    animation-duration 0.5s
    animation-fill-mode forwards
  .pd-radio:nth-child(n+{s+2})
    right -6px
    z-index 0
  .pd-radio:nth-child(n+{s+3})
    display none
