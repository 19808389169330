$apfs=app-product-filters;
$apf=app-product-filter;
.{$apfs}
  position relative
.{$apf}
  margin-bottom 18px
  &__wrapper
    overflow auto
    padding 0 50px 127px
  &__name
    font-size 18px
    line-height 1.33
    letter-spacing -0.2px
    color #111111
    margin-bottom 10px
  &__list
    display flex
    flex-wrap wrap
    margin 0 -3px
  &__item-btn
    padding 8px 16px 5px
    border-radius 17px
    border solid 1px #dddddd
    background #fff
    font-size 15px
    line-height 1.4
    letter-spacing -0.2px
    color #4e4e4e
    margin 0 3px 6px
    &--selected
      color #1C75FF
      border solid 1px #1C75FF
  &__ok
    padding 24px 50px 50px
    position fixed
    bottom 0
    left 0
    right 0
    background #fff
  &__ok-btn
    border-radius 4px
    background-color #f8f8f8
    font-size 18px
    font-weight 600
    line-height 1.33
    letter-spacing -0.2px
    color #111111
    padding 15px 0
    width 100%
    border 0
    &--selected
      background-color #111111
      color #fff !important
