.adult-access-denied
  text-align center
  &__img
    width 124px
    height 125px
    margin-bottom 18px
    font-size 0
  &__title
    font-size 20px
    font-weight 800
    line-height 1.3
    letter-spacing -0.3px
    color #111
    margin-bottom 12px
  &__description
    font-size 15px
    line-height 1.73
    letter-spacing -0.2px
.adult-item
  left 50%
  border 2px solid #dfdfdf
  color #dfdfdf
  display flex
  justify-content center
  align-items center
  border-radius 100%
  width 95px
  height 95px
  &__age
    font-weight 100
    font-size 40px
    position relative
    margin 6px 0 0
  &--19
    .adult-item__plus
      display none
  &--18
    .adult-item__age
      margin 6px 0 0 10px
    .adult-item__plus
      font-size 20px

.adult-item-wrap
  position absolute
  top 0
  left 0
  bottom 0
  right 0
  background #f3f3f3
  border-radius 100%
  display flex
  justify-content center
  align-items center
  .adult-item
    width 75px
    height 75px
    &__age
      font-weight 100
      font-size 30px
      position relative
      margin 4px 0 0
    &--19
      .adult-item__plus
        display none
    &--18
      .adult-item__age
        margin 4px 0 0 8px
      .adult-item__plus
        font-weight 300
        font-size 16px

.adult-lang
  margin-top ptr(12)
  display flex
  align-items center
  justify-content center
  gap ptr(32)
  &__text
    color $blue
