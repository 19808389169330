.mall-communities
  padding pxToRem(48px) 0 pxToRem(96px)
  .community-recent-list
    min-height pxToRem(400px)
  &__sub_title
    margin pxToRem(60px) 0 pxToRem(18px)
    &:first-child
      margin-top 0
  &__title
    p
      margin 0 0 4px 0
      font-size pxToRem(18px)
      font-weight 600
      line-height 1.33
      letter-spacing -0.2px
      color #a2a2a2
    h2
      margin 0 0 pxToRem(24px)
      font-size pxToRem(26px)
      font-weight 600
      line-height 1.31
      letter-spacing -0.3px
  &__king_of_reviews
    display flex
    justify-content space-between
    align-items stretch
    margin-bottom pxToRem(70px)

.user-profile-image
  background-color: $white;

.community-review-king
  flex 1 1 0
  overflow hidden
  margin-left pxToRem(16px)
  &:first-child
    margin-left 0

.community-review-king-trs
  &__thumb
    position relative
    max-width 100%
    margin-bottom pxToRem(14px)
  &__thumb-img
    position relative
    width 100%
    padding-top 100%
    background-size cover
    background-repeat no-repeat
    background-position center
    border-radius pxToRem(30px)
  &__sns
    position absolute
    bottom pxToRem(-3px)
    right pxToRem(-3px)
    width pxToRem(24px)
    height pxToRem(24px)
    border 3px solid #fff
    border-radius 50%
    overflow hidden
    img
      width 100%
  &__rank
    margin-bottom pxToRem(12px) !important
  &__name
    margin-bottom pxToRem(12px)
    font-size pxToRem(18px)
    line-height 1.33
    color #4e4e4e
    white-space nowrap
    text-overflow ellipsis
    overflow hidden
  &__info
    display flex
    justify-content flex-start
    align-items center
    font-size pxToRem(15px)
    font-weight 600
    line-height 1.4
  &__star
    margin-right pxToRem(3px)
    img
      display inline-block
      margin-bottom -1px
      height pxToRem(14px)
      width pxToRem(14px)
  &__avg
    margin-right pxToRem(10px)
  &__review-txt
    font-weight normal


.community-item
  margin-bottom pxToRem(40px)
  &__photo
    position relative
    width 100%
    cursor pointer
  &__photo_img
    background-color rgba(0, 0, 0, 0.04)
    &:after
      content ' '
      display block
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      background-color #000
      opacity 0.1
    img
      width 100%
      height 100%
      max-width 100%
      max-height 800px
      vertical-align top
      object-fit cover
  &__more
    display none
    &[is_more="true"]
      display block
      position absolute
      top pxToRem(8px)
      right pxToRem(8px)
      width pxToRem(35px)
      height pxToRem(35px)
      background url("//s3.marpple.co/files/u_218933/2021/5/original/7182c9727bf6f6e5986a74dadebdfc08732530821.png") no-repeat
      background-size pxToRem(35px) pxToRem(34px)
  &__user
    position absolute
    left pxToRem(18px)
    bottom pxToRem(18px)
    color #fff
  &__body
    margin pxToRem(14px) pxToRem(18px) pxToRem(12px)
    font-size pxToRem(16px)
    line-height 1.63
    letter-spacing -0.2px
    color #000
    cursor pointer
  &[article_type="text"]
  &[article_type="community_text"]
    .community-item__body
      height auto
      min-height pxToRem(106px)
      border 1px solid #e5e5e5
      margin-top 0
      margin-left 0
      margin-right 0
      padding pxToRem(15px) pxToRem(20px)
    .community-item__body-text
      height pxToRem(106px)
      limitLine(4)
    .community-item__body-user
      margin-top pxToRem(10px)
      font-weight 500
  &__controls
    margin 0 pxToRem(18px)
    cursor pointer

.community-review-item
  aspect-ratio 676 / 286
  padding pxToRem(24px)
  border 1px solid $gray-10
  border-radius pxToRem(24px)
  display flex
  flex-direction column
  gap pxToRem(16px)
  &__photo
    flex-shrink 0
    position relative
    width ptr(142)
    cursor pointer
    aspect-ratio 1
    overflow hidden
    background-color #F6F6F6
    display flex
    align-items center
    border-radius pxToRem(12px)
  &__photo_img
    background-size cover
    background-position center
    background-repeat no-repeat
    width 100%
    height 100%
    z-index 1
    object-fit cover
    object-position center
    &[data-is_swiper="false"]
      width 100%
      height 100%
    img
      object-fit cover
      object-position center
      width 100%
      height 100%
      z-index 1
  &__more
    display none
    &[is_more="true"]
      display none
      position absolute
      top pxToRem(8px)
      right pxToRem(8px)
      width pxToRem(35px)
      height pxToRem(35px)
      background url("//s3.marpple.co/files/u_218933/2021/5/original/7182c9727bf6f6e5986a74dadebdfc08732530821.png") no-repeat
      background-size pxToRem(35px) pxToRem(34px)
      z-index 1
  &__user-info
    display flex
    align-items center
  &__user-info-middle
    display flex
    flex-direction column
    row-gap ptr(2)
    margin-left ptr(12)
  &__score
    background-color inherit
  &__user_name
    color $black
    margin-left ptr(2)
    & > span
      limitLine(1)
      margin-right ptr(16)
    & > a
      display flex
      align-items center
      column-gap ptr(4)
      margin-right ptr(16)
      & > span
        limitLine(1)
      & > img
        flex-shrink 0
  &__user-profile-wrap
    width pxToRem(42px)
    height pxToRem(42px)
    flex-shrink 0
  &__body
    margin 0
    color $black
    cursor pointer
    min-height pxToRem(66px)
    limitLine(3)
    word-break break-word
  &__product
    margin 0
  &__controls
    margin 0
  &__remove
    display flex
    justify-content center
    align-items center
  &__remove-btn
    display flex
    align-items center
    border none
    color $gray-80
    cursor pointer
    padding 0
    height pxToRem(22px)
    background-color $white
  &__middle
    display flex
    justify-content space-between
    column-gap ptr(16)
    max-height ptr(142)

.community-pd-review-item
  margin-bottom 40px
  &__photo
    position relative
    width 100%
    cursor pointer
  &__photo_img
    background-size cover
    background-position center
    background-repeat no-repeat
    img
      width 100%
      max-height pxToRem(800px)
  &__more
    display none
    &[is_more="true"]
      display block
      position absolute
      top pxToRem(8px)
      right pxToRem(8px)
      width pxToRem(35px)
      height pxToRem(35px)
      background url("//s3.marpple.co/files/u_218933/2021/5/original/7182c9727bf6f6e5986a74dadebdfc08732530821.png") no-repeat
      background-size pxToRem(35px) pxToRem(34px)
  &__info
    margin-top pxToRem(6px)
    padding 0 pxToRem(20px)
    display flex
    justify-content space-between
    align-items center
  &__user_name
    color $black
    & > span
      limitLine(1)
      margin-right ptr(16)
    & > a
      display flex
      align-items center
      column-gap ptr(4)
      margin-right ptr(16)
      & > span
        limitLine(1)
      & > img
        flex-shrink 0
  &__created_at
    font-size pxToRem(15px)
    line-height 1.4
    letter-spacing -0.2px
    text-align right
    color #a2a2a2


.article-item-control
  display flex
  justify-content space-between
  align-items center
  &__btn_like
    box-sizing border-box
    display flex
    justify-content flex-end
    align-items center
    border none
    color $gray-50
    cursor pointer
    padding-left ptr(24)
    background-image url('//s3.marpple.co/files/u_2308668/2024/4/original/a00fb932fb5434114fe868c8f1d0082a491ea2e21.png')
    background-size pxToRem(20px)
    background-position-x 0
    background-repeat no-repeat
    height pxToRem(20px)
    &[is_like="true"]
      background-image url('//s3.marpple.co/files/u_2308668/2024/4/original/7757e7d591949f6dfb32e0c5287ae01758a00d4c1.png')
  &__btn_comment
    display none
  &__date
    flex 1
    text-align right
    color $gray-50
    height 100%
  &__btn_report
    display none

.article-cheer-up-item-control
  display inline-flex
  justify-content space-between
  align-items center
  gap ptr(12)
  &__btn_like
    flex 1
    box-sizing border-box
    display flex
    justify-content flex-start
    align-items center
    border none
    color $gray-50
    cursor pointer
    padding-left ptr(28)
    background-image url('//s3.marpple.co/files/u_2308668/2024/11/original/dd91688cff5a0a4e228e5c6a5c12e64444170ae71.svg')
    background-size pxToRem(24px)
    background-position center left
    background-repeat no-repeat
    height pxToRem(24px)
    &[is_like="true"]
      background-image url('//s3.marpple.co/files/u_2308668/2024/11/original/c858d7f3e0deac1b6233ad2b33d1807f2447ea4a1.svg')
  &__btn_comment
    box-sizing border-box
    display flex
    justify-content flex-end
    align-items center
    border none
    color $gray-50
    cursor pointer
    padding-left ptr(28)
    background-image url('//s3.marpple.co/files/u_2308668/2024/11/original/3008cec05f662657936e52e95e11b2e0b863383d1.svg')
    background-size pxToRem(24px)
    background-position center left
    background-repeat no-repeat
    height pxToRem(24px)
  &__date
    flex 1
    text-align right
    color $gray-50
    height 100%
  &__btn_report
    display none

.article-product
  .community-review-item__date
    display none
  margin 0
  margin-top ptr(16)
  > a
    display flex
    flex-direction column
    justify-content center
    position relative
    padding-left pxToRem(76px)
    margin 0
    min-height pxToRem(60px)
  &__thumb
    display flex
    align-items center
    justify-content center
    position absolute
    top 0
    left 0
    width pxToRem(60px)
    height pxToRem(60px)
    background-color #f8f8f8
    overflow hidden
    border-radius pxToRem(8px)
    img
      max-width 100%
      max-height 100%
  &__name
  &__store_name
  &__price
    color $gray-80
    limitLine(1)
    margin-top ptr(2)
  &__store_name
    display none
  &__price
    color $gray-80
    &[lang="kr"]
      &:after
        {$kr-14-m}
        content "원"
    &[lang="en"]
      &:before
        {$kr-14-m}
        content "$"
    &[lang="jp"]
      &:before
        {$kr-14-m}
        content "¥"

.article-review-score
  white-space nowrap
  .star
    display inline-block
    margin-right pxToRem(2px)
    width pxToRem(16px)
    height pxToRem(16px)
    background url("//s3.marpple.co/files/u_2308668/2024/4/original/28137197cd5a35fee38531263c8ff9a856cfc9731.png") no-repeat center center
    background-size pxToRem(16px) auto
    &[is="true"]
      background-image url("//s3.marpple.co/files/u_2308668/2024/4/original/62c1c5e9e64dcdbf684e8dec0b6b8444ee3a81f01.png")

.cheer-up-item
  padding pxToRem(24px)
  border 1px solid $gray-10
  border-radius pxToRem(24px)
  display flex
  flex-direction column
  gap pxToRem(16px)
  &__body
    display flex
    align-items flex-start
    justify-content space-between
    gap ptr(20)
  &__body-text
    flex 1
    font-size ptr(14)
    font-weight 500
    line-height ptr(22)
    letter-spacing -0.2px
    margin 0
    color $black
    cursor pointer
    min-height pxToRem(66px)
    limitLine(3)
    word-break break-word
  &__photo
    flex-shrink 0
    position relative
    width ptr(96)
    cursor pointer
    aspect-ratio 1
    overflow hidden
    background-color #F6F6F6
    display flex
    align-items center
    border-radius pxToRem(12px)
  &__photo_img
    background-size cover
    background-position center
    background-repeat no-repeat
    width 100%
    height 100%
    z-index 1
    object-fit cover
    object-position center
    &[data-is_swiper="false"]
      width 100%
      height 100%
    img
      object-fit cover
      object-position center
      width 100%
      height 100%
      z-index 1
  &__more
    display none
    &[is_more="true"]
      display none
      position absolute
      top pxToRem(8px)
      right pxToRem(8px)
      width pxToRem(35px)
      height pxToRem(35px)
      background url("//s3.marpple.co/files/u_218933/2021/5/original/7182c9727bf6f6e5986a74dadebdfc08732530821.png") no-repeat
      background-size pxToRem(35px) pxToRem(34px)
      z-index 1
  &__user-info
    display flex
    align-items center
  &__user-info-middle
    display flex
    flex-direction column
    row-gap ptr(2)
    margin-left ptr(12)
  &__score
    background-color inherit
  &__user_name
    color $black
    & > span
      limitLine(1)
      margin-right ptr(16)
    & > a
      display flex
      align-items center
      column-gap ptr(4)
      margin-right ptr(16)
      & > span
        limitLine(1)
      & > img
        flex-shrink 0
  &__user-profile-wrap
    width pxToRem(42px)
    height pxToRem(42px)
    flex-shrink 0
  &__shop-icon
    width ptr(16)
    height ptr(16)
    color $blue-100
  &__controls
    margin 0
  &__remove
    display flex
    justify-content center
    align-items center
  &__remove-btn
    display flex
    align-items center
    border none
    color $gray-80
    cursor pointer
    padding 0
    height pxToRem(22px)
    background-color $white
  &__middle
    display flex
    justify-content space-between
    column-gap ptr(16)
    max-height ptr(142)

button.mps2_action_button,
a.mps2_action_button
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 200ms ease-out, box-shadow 200ms ease-out;
  width: 100%;
  flex: 1 1 0;
  border 0 none
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.2px;

  &[data-fit="true"]
    width: fit-content;
    flex: unset;

  &.size_regular
    height: ptr(40);
    padding: ptr(9) ptr(16);
    gap: ptr(4);
    border-radius: ptr(8);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);

  &.size_medium
    height: ptr(40);
    padding: ptr(9) ptr(16);
    gap: ptr(4);
    border-radius: ptr(8);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);

  &.size_large
    height: ptr(40);
    padding: ptr(16) ptr(24);
    gap: ptr(8);
    border-radius: ptr(12);

    html[is_mobile="true"] &
      height: ptr(52);
      gap: ptr(4);
      border-radius: ptr(8);
      backdrop-filter: blur(20px);
      -webkit-backdrop-filter: blur(20px);

  &.type_white
    background-color: $blue-10;
    color: $blue;

    &:hover
      background-color: $blue;
      color: $white;

  &.type_blue
    background-color: $blue;
    color: $white;

    &:hover
      background-color: $blue-10;
      color: $blue;

  &.type_line
    box-shadow: 0 0 0 1px $gray-20 inset;
    background-color: $white;
    color: $gray-80;

    &:hover
      box-shadow: 0 0 0 1px $blue inset;
      color: $blue;

  &.type_disabled
    cursor: default;
    background-color: $gray-5;
    color: $gray-50;

  .mps2_action_button__wrap
    display flex
    align-items center
    justify-content center
    gap ptr(4)
    height: ptr(40);
    box-sizing border-box
  .mps2_action_button__icon
    width ptr(16)
    height ptr(16)
