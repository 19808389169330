.seller-studio-table
  box-sizing border-box
  padding 25px
  width 100%
  height calc(100vh - 300px)
  background #f8f8f8
  border-radius 8px
  &__table
    width 100%
    height 100%
    border solid 1px #ddd
    background #fff
    overflow-y auto
    position relative
    overflow-x auto
  &__head
  &__body
  &__totals
    min-width 1450px
    text-align right
  &__head
    border-bottom 1px solid #000
    position sticky
    top 0
    width 100%
    z-index 2
    background-color #fff
  &__body
    z-index 1
  &__totals
    z-index 2
    position sticky
    bottom 0
    background-color #f5f9fe
  &__tr
    display flex
    border-bottom 1px solid #ededed
  &__th
  &__td
  &__tt
    box-sizing border-box
    padding 20px
    overflow hidden
  &__td--text-overflow
    text-overflow ellipsis
    white-space nowrap
    position relative
  &__th
    font-weight bold
  &__tool-tip-wrap
    display flex
    justify-content flex-end
  &__date
    text-decoration underline


@media screen and (max-width 1024px)
  .seller-studio-table
    padding 4px
    &__table
      overflow-x auto
    &__head
    &__body
    &__totals
      width 1000px
