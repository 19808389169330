html[is_mobile="false"].ad_res
  .don_dialog_msg .content
    width 475px
    >.body .modal-content
      text-align left
      &__title
        h4
          margin 0 0 24px
          font-size 20px
          font-weight 600
          line-height 1.3
        p
          margin 0 0 10px
          font-size 15px
          font-weight 600
          line-height 1.4
          color #111
      &__items
        margin 10px 0 0
        padding 0
        li
          list-style-type none
          font-size 14px
          line-height 1.43
        li + li
          margin-top 4px
      &__tip
        margin 12px 0 0
        font-size 13px
        line-height 1.54
        color #a2a2a2
      &__ok
        margin-top 44px
      &__ok-btn
        display block
        width 100%
        padding 15px 0 13px
        font-size 18px
        font-weight 600
        line-height 1.33
        text-align center
        color #ffffff
        border-radius 4px
        border none
        background-color #111111
