svgToData(svg)
  url("data:image/svg+xml," + svg)

$blur-10 =
  background $bright-20
  backdrop-filter blur(5px)
  -webkit-backdrop-filter blur(5px)

$blur-30 =
  background $bright-20
  backdrop-filter blur(15px)
  -webkit-backdrop-filter blur(15px)

$blur-40 =
  background $bright-20
  backdrop-filter blur(20px)
  -webkit-backdrop-filter blur(20px)

$blur-60 =
  background $bright-20
  backdrop-filter blur(30px)
  -webkit-backdrop-filter blur(30px)

$image-store-logo =
  background url(//s3.marpple.co/files/u_2308668/2024/3/original/663bbe879648233b6f88e88e33fd983b827fb7f81.png)
  background-position left
  background-repeat no-repeat
  background-size pxToRem(20px) pxToRem(20px)


$image-marpple-delivery-logo =
  width pxToRem(20px)
  height pxToRem(20px)
  background svgToData("%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_726_224293)'%3E%3Cpath d='M2.51665 5.27573L9.59166 1.77573C9.87499 1.63407 10.2083 1.62573 10.5 1.75907L13 2.90073L4.86665 6.92573V10.9257C4.86665 11.0257 4.92499 11.1174 5.01665 11.1591L6.64165 11.9591C6.81665 12.0424 7.02499 11.9174 7.02499 11.7257V7.87573L15.125 3.8674L17.4667 4.9424C17.8417 5.1174 18.0833 5.4924 18.0833 5.90073V13.7757C18.0833 14.1757 17.8583 14.5424 17.4917 14.7257L10.4333 18.2174C10.1417 18.3674 9.79165 18.3674 9.49165 18.2174L2.51665 14.7757C2.15832 14.6007 1.92499 14.2257 1.92499 13.8257V6.22573C1.92499 5.82573 2.14999 5.45907 2.51665 5.27573Z' fill='%23141414'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_726_224293'%3E%3Crect width='20' height='20' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A")
  background-repeat no-repeat
  background-size pxToRem(20px) pxToRem(20px)


$image-seller-delivery-logo =
  width pxToRem(20px)
  height pxToRem(20px)
  background svgToData("%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.29169 8.5724C7.86698 8.5724 8.33335 8.10603 8.33335 7.53073C8.33335 6.95543 7.86698 6.48906 7.29169 6.48906C6.71639 6.48906 6.25002 6.95543 6.25002 7.53073C6.25002 8.10603 6.71639 8.5724 7.29169 8.5724Z' fill='%23141414'/%3E%3Cpath d='M12.7084 8.5724C13.2837 8.5724 13.75 8.10603 13.75 7.53073C13.75 6.95543 13.2837 6.48906 12.7084 6.48906C12.1331 6.48906 11.6667 6.95543 11.6667 7.53073C11.6667 8.10603 12.1331 8.5724 12.7084 8.5724Z' fill='%23141414'/%3E%3Cpath d='M10 1.66406C5.40002 1.66406 1.66669 5.3974 1.66669 9.9974C1.66669 14.5974 5.40002 18.3307 10 18.3307C14.6 18.3307 18.3334 14.5974 18.3334 9.9974C18.3334 5.3974 14.6 1.66406 10 1.66406ZM10 16.8724C6.20835 16.8724 3.12502 13.7891 3.12502 9.9974C3.12502 6.20573 6.20835 3.1224 10 3.1224C13.7917 3.1224 16.875 6.20573 16.875 9.9974C16.875 13.7891 13.7917 16.8724 10 16.8724Z' fill='%23141414'/%3E%3Cpath d='M12.9167 10.8474C12.875 10.9057 11.8084 12.2224 10 12.2224C8.19169 12.2224 7.13335 10.9224 7.08335 10.8474C6.83335 10.5307 6.37502 10.4724 6.05835 10.7224C5.74169 10.9724 5.68335 11.4307 5.92502 11.7474C5.98335 11.8224 7.45835 13.6807 9.99169 13.6807C12.525 13.6807 14 11.8224 14.0584 11.7474C14.3 11.4307 14.25 10.9724 13.9334 10.7307C13.6167 10.4807 13.1584 10.5391 12.9084 10.8557L12.9167 10.8474Z' fill='%23141414'/%3E%3C/svg%3E%0A")
  background-repeat no-repeat
  background-size pxToRem(20px) pxToRem(20px)

$image-digital-delivery-logo =
  width pxToRem(20px)
  height pxToRem(20px)
  background svgToData("%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_3246_320253)'%3E%3Cpath d='M17.25 2H6.75C5.65 2 4.75 2.9 4.75 4V20C4.75 21.1 5.65 22 6.75 22H17.25C18.35 22 19.25 21.1 19.25 20V4C19.25 2.9 18.35 2 17.25 2ZM12 20.25C11.31 20.25 10.75 19.69 10.75 19C10.75 18.31 11.31 17.75 12 17.75C12.69 17.75 13.25 18.31 13.25 19C13.25 19.69 12.69 20.25 12 20.25ZM16.38 5.5H7.62C7.14 5.5 6.74 5.11 6.74 4.62C6.74 4.13 7.13 3.74 7.62 3.74H16.37C16.85 3.74 17.25 4.13 17.25 4.62C17.25 5.11 16.86 5.5 16.37 5.5H16.38Z' fill='black'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_3246_320253'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A")
  background-repeat no-repeat
  background-size pxToRem(20px) pxToRem(20px)

$icon-dropdown-arrow = svgToData("%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.57002 11.6646C8.2534 12.1399 7.55507 12.1399 7.23844 11.6646L2.95914 5.24093C2.60496 4.70927 2.9861 3.9974 3.62493 3.9974L12.1835 3.9974C12.8224 3.9974 13.2035 4.70927 12.8493 5.24093L8.57002 11.6646Z' fill='%23141414'/%3E%3C/svg%3E%0A")

$marppleshop-layout-padding = 32px;
$pc-max-width = 1960px;
$pc-layout-body-max-width = 1440px;
$pc-layout-body-min-width = 960px;
$default-header-pc_height = ptr(72);
$default-sub-header-pc_height = ptr(64);

$default-header-mo_height = ptr(60);
$default-sub-header-mo_height = ptr(64);

$button-white-theme =
  transition background-color 200ms ease-out
  background-color $blue-10
  color $blue
  &:hover:not(&:disabled,&.disabled)
    background-color $blue !important
    color $white !important

$button-blue-theme =
  transition background-color 200ms ease-out
  background-color $blue
  color $white
  &:hover:not(&:disabled,&.disabled)
    background-color $blue-10 !important
    color $blue !important

$button-line-theme =
  transition border 200ms ease-out
  border 1px solid $gray-20
  background-color $white
  color $gray-80
  &:hover:not(&:disabled,&.disabled)
    border 1px solid $blue !important
    color $blue !important


