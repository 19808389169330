// modules/MShop/Youtube/Bridge/F/Style/Pc/index.styl
html.youtube-x-marpple[is_mobile="false"]
  @media screen and (max-width 1920px)
    font-size: calc(1vw * (14 / 19.2)) !important;

.youtube-error-wrap
  text-align center
  h3
    font-size pxToRem(20)
    line-height pxToRem(26)
    font-weight 800
    margin 0
    margin-bottom pxToRem(12)
    letter-spacing pxToRem(-0.3)
  div.message
    letter-spacing pxToRem(-0.2)
    font-size pxToRem(15)
    line-height pxToRem(26)
    font-weight 400
    display flex
    justify-content center
    align-items center
    flex-wrap wrap
    gap pxToRem(4)


  a.link
    color rgba(60, 137, 249, 1)
    letter-spacing pxToRem(-0.3)
    font-size pxToRem(16)
    line-height pxToRem(24)
    font-weight 600
    margin-top pxToRem(20)
    display flex
    justify-content center
    align-items center
    flex-wrap wrap

.youtube-bridge
  &__title
    font-size pxToRem(48)
    color: #141414
    text-align: center
    font-weight: 700
    line-height: pxToRem(60)
    letter-spacing: -0.2px
    strong
      color #1F5EFF
  &__title2
    color: var(--Black, #141414);
    text-align: center;
    font-size: pxToRem(40);
    font-weight: 700;
    line-height: pxToRem(56);
    letter-spacing: -0.2px;
    strong
      color #1F5EFF
  &__sub-txt1
    color: var(--Black, #141414)
    font-size: pxToRem(20)
    font-weight: 400
    line-height: pxToRem(28)
    letter-spacing: -0.2px
    a
      color: var(--Blue, #1C75FF)
      text-decoration-line: underline
  &__content
    max-width pxToRem(1920)
    margin 0 auto
    width 100%

.youtube-bridge-help01
  margin 0
  padding 0
  list-style none
  display flex
  align-items center
  justify-content center
  gap pxToRem(180)
  &__li
    text-align center
    width pxToRem(310)
  &__img
    margin-bottom pxToRem(24)
    img
      width pxToRem(291)
  &__txt1
    color: #141414
    text-align: center;
    font-size: pxToRem(20);
    font-weight: 500;
    line-height: pxToRem(28)
    letter-spacing: -0.2px;

.youtube-bridge-why
  display flex
  align-items center
  justify-content center
  gap pxToRem(40)
  &__ol
    width pxToRem(700)
    margin 0
    padding 0
    list-style none
    display flex
    flex-direction column
    gap pxToRem(60)
  &__li
    display flex
    align-items flex-start
    justify-content flex-start
    gap pxToRem(24)
  &__icon
    display: flex
    padding-bottom: pxToRem(4)
    justify-content: center
    align-items: center
    width pxToRem(40)
    height pxToRem(40)
    color: var(--Blue, #1C75FF);
    font-family 'Bebas Neue Pro';
    font-size: pxToRem(24)
    font-weight: 700;
    line-height: pxToRem(24)
    letter-spacing: -0.2px
    text-transform: uppercase;
    border-radius: pxToRem(8)
    background: #EBF3FE
  &__txt-area
    display flex
    flex-direction column
    gap pxToRem(8)
    text-align left
  &__txt1
    margin 0
    padding 0
    color: var(--Black, #141414);
    font-size: pxToRem(28)
    font-weight: 700
    line-height: pxToRem(39)
    letter-spacing: -0.2px
  &__txt2
    margin 0
    padding 0
    font-size: pxToRem(16)
    line-height: pxToRem(24)
    color: var(--Gray-80, #6B6B6B);
    text-align: left;
    font-weight: 500;
    letter-spacing: -0.2px;
  &__thumb
    img
      width pxToRem(700)


.youtube-bridge-how
  width 100%
  &__ol
    margin 0
    padding 0
    list-style none
    display flex
    align-items flex-start
    justify-content center
    gap pxToRem(22)
  &__li
    width pxToRem(500)
  &__img
    margin-bottom pxToRem(24)
    img
      width 100%
  &__step
    margin-bottom pxToRem(16)
    color: #141414
    text-align: left
    font-family 'Bebas Neue Pro'
    font-size: pxToRem(24)
    font-weight: 700
    line-height: pxToRem(24)
    letter-spacing: -0.2px
    text-transform: uppercase
  &__txt1
    margin-bottom pxToRem(24)
    color: #141414
    font-size: pxToRem(16)
    font-weight: 500
    text-align: left
    line-height: pxToRem(24)
    letter-spacing: -0.2px
  &__txt2
    text-align: left
  &__a
    color: #1C75FF !important
    font-size: pxToRem(16)
    font-weight: 700
    line-height: pxToRem(24)
    letter-spacing: -0.2px
    padding-bottom 4px
    border-bottom 1px solid #1C75FF

.youtube-bridge-guide1
  display flex
  align-items center
  justify-content center
  &__ul
    display flex
    gap pxToRem(48) pxToRem(20)
    width pxToRem(1544)
    flex-wrap wrap
    justify-content space-between
    margin 0
    padding 0
    list-style none
  &__li
    width pxToRem(748)
    text-align left
  &__img
    margin-bottom pxToRem(24)
    img
      width 100%
  &__txt1
    margin 0 0 pxToRem(8)
    padding 0
    color: var(--Black, #141414)
    font-size: pxToRem(24)
    font-weight: 700
    line-height: pxToRem(34)
    letter-spacing: -0.2px
  &__txt2
    margin 0
    padding 0
    color: var(--Gray-80, #6B6B6B)
    font-size: pxToRem(16)
    font-weight: 500
    line-height: pxToRem(26)
    letter-spacing: -0.2px
  &__prev
  &__next
    display none

.youtube-bridge-main-09
  background: var(--Light-Blue, #EBF3FE)

.youtube-bridge-select-login-apply
  margin-top pxToRem(100)
  width 100%
  &__h3
    margin 0 0 pxToRem(12)
    padding 0
    color: var(--Black, #141414)
    text-align: center
    font-size: pxToRem(24)
    font-weight: 700
    line-height: pxToRem(34)
    letter-spacing: -0.2px
  &__p
    margin 0
    padding 0
    color: var(--Black, #141414);
    text-align: center;
    font-size: pxToRem(20);
    font-weight: 400;
    line-height: pxToRem(28);
    letter-spacing: -0.2px;
  &__btns
    margin-top pxToRem(60)
    display grid
    align-items center
    justify-content space-between
    gap pxToRem(24)
    grid-template-columns 1fr 1fr



.main-09-card
  margin pxToRem(80) auto 0
  padding 0
  list-style none
  width pxToRem(1544)
  display flex
  align-items flex-start
  justify-content center
  gap pxToRem(32)
  &__li
    width pxToRem(494)
    min-height pxToRem(554)
    padding pxToRem(80) pxToRem(40)
    display flex
    flex-direction column
    align-items center
    justify-content center
    gap pxToRem(80)
    box-sizing border-box
    border-radius: pxToRem(24)
    border: 1px solid var(--Gray-10, #E7E7E7)
    background: var(--White, #FFF)
  &__under
    text-decoration underline
  &__txt1
    margin 0
    padding 0
    color: var(--Black, #141414)
    font-size: pxToRem(28)
    font-weight: 700
    line-height: pxToRem(39)
    letter-spacing: -0.2px
  &__ul
    margin 0
    padding 0
    list-style none
    display flex
    flex-direction column
    gap pxToRem(16)
    align-items center
    justify-content center
  &__ul-li
    color: var(--Black, #141414)
    font-size: pxToRem(24)
    font-weight: 500
    line-height: pxToRem(34)
    letter-spacing: -0.2px
    strong
      color: var(--Blue, #1C75FF)
      font-weight: 700
      line-height: pxToRem(34)
      background-color #EBF3FE

.youtube-channel-item2
  width 100%
  display: flex;
  height: pxToRem(100);
  padding: pxToRem(20);
  align-items: center;
  box-sizing border-box;
  gap: pxToRem(16);
  border-radius: pxToRem(20);
  background: var(--Light-Blue, #EBF3FE);
  &__logo
    width: pxToRem(60);
    height: pxToRem(60);
    border-radius 9999px;
  &__info
    flex 1
    display flex
    flex-direction column
    gap pxToRem(2)
    text-align left
  &__title
    color: var(--Black, #111);
    font-size: pxToRem(16);
    font-weight: 700;
    line-height: pxToRem(24);
    letter-spacing: -0.2px;
    limitLine(1)
  &__url
    color: var(--Gray-80, #6B6B6B);
    font-family: "Neue Haas Unica Pro";
    font-size: pxToRem(14);
    font-weight: 400;
    line-height: pxToRem(20);
    letter-spacing: -0.2px;
    limitLine(1)

.youtube-bridge-faq
  margin pxToRem(80) auto 0
  width pxToRem(1544)
  text-align left
  &__ul
    margin 0
    padding 0
    list-style none
    display flex
    flex-direction column
    gap pxToRem(16)
  &__li
    position relative;
    background: var(--Light-Blue, #EBF3FE);
    border-radius: pxToRem(24);
    &[is_open="true"]
      color #fff
      background: var(--Blue, #1C75FF);
    &[is_open="false"]
      color: var(--Blue, #1C75FF);
  &__wrap
    padding pxToRem(37) pxToRem(60);
    cursor pointer
  &__plus
  &__minus
    position absolute
    top pxToRem(37)
    right pxToRem(60)
  &__plus
    color #1C75FF
  &__minus
    color #fff
  &__question
    font-size: pxToRem(24);
    font-weight: 700;
    line-height: pxToRem(34);
    letter-spacing: -0.2px;
    cursor pointer
  &__answer
    display none
    padding-top pxToRem(37)
    color: var(--White, #FFF);
    font-size: pxToRem(16);
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.2px;
    &--show
      display block
  &__p
    margin 0 0 pxToRem(16)
    padding 0
    &--mb0
      margin-bottom 0
  &__answer-ul
    margin 0 0 pxToRem(16)
    padding 0
    list-style none
  &__answer-li
    position relative
    padding-left pxToRem(22)
    &:before
      content ''
      position absolute
      top 50%
      left pxToRem(10)
      transform translateY(-50%)
      display block
      width pxToRem(4px)
      height pxToRem(4px)
      background-color #fff
      border-radius 99px
  &__answer-ol
    margin 0 0 pxToRem(16) pxToRem(24)
    padding 0
    list-style auto
  &__a
    margin-right pxToRem(2)
    display inline-flex
    align-items center
    gap pxToRem(2)
    color: var(--White, #FFF) !important;
    border-bottom 1px solid #fff;

.youtube-bridge-page
  scroll-snap-type y mandatory
  width 100%
  position relative
  letter-spacing pxToRem(-0.3)
  text-align center
  font-family Pretendard, -apple-system, BlinkMacSystemFont, systems-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  font-style normal

  /* cta button */
  .cta-button
    justify-content space-between;
    position: fixed;
    bottom: ptr(40);
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;

  pxToVw(value)
    unit(value/1920*100, 'vw')
  pxToVMin(value)
    unit(value/1920*100, 'vmin')
  pxToVh(value)
    unit(value/1080*100, 'vh')
  font_18_m_150 =
    font-size pxToRem(18)
    line-height 150%
    font-weight 600
  font_16_m_150 =
    font-size pxToRem(16)
    font-weight 600
    line-height 150%
  .h3-53
    font-weight 700
    font-size pxToRem(40)
    line-height pxToRem(53)
  .h3-56
    font-weight 700
    font-size pxToRem(40)
    line-height pxToRem(56)
  .h3-60
    font-weight 700
    font-size pxToRem(48)
    line-height pxToRem(60)
  input
    font-family "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    font-style normal
  button
    display flex
    justify-content center
    align-items center
    border none
    background-color transparent
    font-family "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    font-style normal
    &:disabled
      background: var(--Gray-5, #F5F5F5);
      color: var(--Gray-50, #A2A2A2);
    &.primary
      background-color #1C75FF
      color #FFFFFF
      &:disabled
        background: var(--Gray-5, #F5F5F5);
        color: var(--Gray-50, #A2A2A2);
    &.m-button
      display flex
      height pxToRem(60)
      padding 0 pxToRem(24)
      justify-content center
      align-items center
      gap pxToRem(4)
      border-radius pxToRem(8)
    &.default
      height pxToRem(68)
      width 100%
      display flex
      justify-content center
      align-items center
      border-radius pxToRem(8)
      {font_18_m_150}
    &.gray
      background-color #4E4E4E
      color #FFFFFF
      &:hover
        background-color #2f2f2f
    &.full-width
      width 100%

  .underline
    text-decoration underline
  .chip
    color #FFFFFF
    background-color #1F5EFF
    text-align center
    /* Body_EN / B2  14px Medium */
    font-family Campton
    font-size pxToRem(18)
    font-style normal
    font-weight 550
    line-height 150%
    height pxToRem(38)
    display inline-flex
    align-items center
    justify-content center
    padding pxToRem(6) pxToRem(12) pxToRem(4) pxToRem(12)
    border-radius pxToRem(12) pxToRem(12) pxToRem(12) 0
    box-shadow 0 0 pxToRem(25) 0 rgba(0, 0, 0, 0.40)
    backdrop-filter blur(pxToRem(6))
    &.revers
      color #1F5EFF
      background-color #FFFFFF

  .youtube-bridge-page__header2
    position fixed
    top 0
    left 0
    right 0
    display flex
    justify-content center
    align-items center
    padding pxToRem(16) 0
    gap pxToRem(16)
    width 100%
    height pxToRem(60)
    box-sizing border-box
    z-index 10
    background: rgba(20, 20, 20, 0.01);
    backdrop-filter: blur(6px);
    color #fff
    transition color 0.5s
    will-change transform
    &--black
      color: var(--Black, #141414);
    img
      flex-shrink 0
  .youtube-bridge-page__full-page
    width 100%
    height 100vh
    & > .swiper-wrapper
      display block
      & > .swiper-slide
        display flex
        flex-direction column
        position relative
        //height 100vh
        & .youtube-bridge-page__section
          width 100%
  .youtube-bridge-page__section
    display flex
    align-items center
    justify-content center
    flex-direction column
    min-height pxToRem(1080)
    //min-height pxToRem(900)
    padding-top pxToRem(100)
    padding-bottom pxToRem(100)
    position relative
    &--01
      padding-top 0
      min-height 100vh
      background-color #1C75FF
    &--color-white
      color #fff
    &#step1
      width 100%
      background #1C75FF
      .step1_bg
        position absolute
        top 0
        left 0
        right 0
        bottom 0
        z-index 0
        background: linear-gradient(90deg, rgba(28, 117, 255, 0.00) 0%, #1C75FF 50%, rgba(28, 117, 255, 0.00) 100%);
      .h3-60
      .desc
        color #fff
    &#step2
      .desc .underline
        color #1C75FF
    &.main-transition
      transition height 0.5s
      will-change transform
    &:nth-child(2)
      --header-height pxToRem(66)
      height calc(100vh - var(--header-height))
    &.gradation-color
      background: linear-gradient(0deg, #1C75FF 69.3%, rgba(29, 117, 255, 0.00) 100%);
    &.content-height
      height fit-content
    .title
      font-size pxToRem(32)
      line-height pxToRem(48)
      font-weight 600
      & > div:last-child
        font-size pxToRem(40)
        line-height pxToRem(53)
        font-weight bold

    .content
      max-width pxToRem(1920)
      margin 0 auto
      width 100%
    .section-content-wrapper
      display flex
      flex-direction column
      justify-content center
      align-items center
      width 100%
      gap pxToRem(50)
      height 100%
    /* main-01 */
    .phone-mockup-content
      padding-top pxToRem(50)
      display flex
      justify-content center
      align-items center
      flex-direction column
      width 100%
      .main-video-container
        width 100%
        display flex
        justify-content center
        position relative
        padding-bottom pxToRem(130)
        .marquee-wrapper .marquee-background
          min-height pxToRem(280)
          padding 0
        .mockup-container
          display flex
          justify-content center
          align-items center
          width pxToRem(290)
          aspect-ratio 290 / 600
          position relative
        .mockup-frame
          z-index 2
          position absolute
          width 100%
          height 100%
          left 0
          top 0
        .main-video
          box-sizing border-box
          width 100%
          height 100%
          position relative
          z-index 1
          padding 1vmin
          display flex
          justify-content center
          align-items center
          video
            position relative
            z-index 1
            height 100%
            border-radius pxToRem(16)
            box-sizing border-box
          .back-drop-black
            position absolute
            z-index 0
            top 0
            left pxToRem(2)
            background-color #111
            width calc(100% - (5 / 14) * 1rem)
            height 100%
            border-radius 4vmin
            box-shadow pxToRem(-20) pxToRem(20) pxToRem(40) 0 rgba(0, 0, 0, 0.40)
            box-sizing border-box
    .description
      position absolute
      left 0
      right 0
      bottom 0
      height: pxToRem(550)
      padding-bottom pxToRem(136)
      z-index 3
      display flex
      justify-content flex-end
      align-items center
      flex-direction column
      color: var(--White, #FFF);
      text-align: center;
      font-family: Pretendard;
      letter-spacing: -0.2px;
      gap pxToRem(20)
      font-size pxToRem(20)
      font-weight 500
      line-height pxToRem(32)
      width 100%
      background: linear-gradient(0deg, #1C75FF 69.3%, rgba(29, 117, 255, 0.00) 100%);
      .description-header
        font-size pxToRem(48)
        font-weight 700
        line-height pxToRem(60)
    .marquee-wrapper
      display flex
      overflow-x hidden
      width 100%
      position absolute
      z-index 0
      top 42%
      transform translateY(-50%)
    .marquee-background
      --gap pxToRem(80)
      flex-shrink 0
      display flex
      gap var(--gap)
      justify-content space-around
      align-items center
      will-change transform
      animation marquee-left 50s linear infinite
      min-width 100%
      height pxToRem(280)
      max-height pxToRem(280)
      padding pxToRem(60) 0
      &[aria-hidden="true"]
        margin-left var(--gap)
      img
        height 100%

    /* main-02 */
    .video-container
      box-sizing border-box
      height pxToVh(855)
      margin auto
      will-change transform
      transition all 0.5s
      border-radius pxToRem(60)
      overflow hidden
      aspect-ratio 8 / 9
      &.full-width
        max-width pxToRem(1520)
        max-height pxToRem(855)
        aspect-ratio 16 / 9
      .marpple-video
        box-sizing border-box
        object-fit cover
        width 100%
        height 100%
        margin 0 auto

    /* main-03 */
    .m-shop-container
      width 100%
      overflow hidden
      padding-bottom pxToRem(118)
      .swiper-pagination-bullet
        background-color #828282
        &.swiper-pagination-bullet-active
          background-color #F2F2F2
    .m-shop-item-list
      display flex
      align-items center
      .m-shop-item
        display flex
        flex-direction column
        gap pxToRem(20)
        width fit-content
        .swiper-slide-shadow-left,
        .swiper-slide-shadow-right
          background-color #111111CC
          background-image -webkit--gradient(to right,rgba(17,17,17,.5),rgba(17,17,17,0))
          background-image -webkit-linear-gradient(to right,rgba(17,17,17,.5),rgba(17,17,17,0))
          background-image linear-gradient(to right,rgba(17,17,17,.5),rgba(17,17,17,0))
        &.swiper-slide-next
        &.swiper-slide-prev
          cursor pointer
        video
          width  min(pxToVw(800), pxToRem(800))
          max-width pxToRem(800)
          height auto
          border-radius pxToRem(20)
        .m-shop-profile
          display flex
          gap pxToRem(12)
          align-items center
          color #FFFFFF
          img
            width pxToRem(80)
            height pxToRem(80)
            border-radius pxToRem(12)
          .item
            display flex
            flex-direction column
            text-align left
            font-size pxToRem(16)
            line-height pxToRem(24)
            font-weight 400
            .item-title
              font-size pxToRem(20)
              line-height pxToRem(30)
              font-weight 700

  /* main-04 */
  .main-04
    min-height pxToRem(1576)
    .goods-container
      margin-top pxToRem(60)
      display flex
      box-sizing border-box
      width 100%
      height 100%
      overflow hidden
      position relative
      justify-content center
    &.content-height
      .title
        margin-top pxToRem(120)
      .youtube-bridge-page__section-more-btn
        svg
          transform rotateX(180deg)
      .goods-container
        height auto
        margin-bottom pxToRem(120)
    .youtube-bridge-page__section-more-btn
      position absolute
      z-index 2
      bottom pxToRem(0)
      font-size pxToRem(16)
      line-height pxToRem(24)
      color: #141414
      font-weight: 500;
      letter-spacing: -0.2px;
      display: flex;
      height: pxToRem(56)
      padding pxToRem(16) pxToRem(24)
      justify-content: center
      align-items: center
      gap: pxToRem(4)
      transition all 0.3s ease-in-out
      border-radius: pxToRem(12)
      background: rgba(20, 20, 20, 0.05)
      backdrop-filter: blur(20px)
      svg
        transition all 0.3s ease-in-out
        width pxToRem(20)
        height pxToRem(20)
  .youtube-bridge-page__section-wrap
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width 100%
  .goods-mask-gradient
    pointer-events none
    position absolute
    z-index 1
    top 0
    left 0
    width 100%
    height calc(100% + 100rem / 14)
    box-sizing border-box
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 90%, #FFF 99.98%);
  .goods-list
    display flex
    gap pxToRem(25)
    flex-wrap wrap
    align-items flex-start
    align-content flex-start
    justify-content center
    max-width pxToRem(1560)
    position relative
    margin 0 auto
    width min(85vw, pxToRem(1560))
    .goods
      border-radius pxToRem(16)
      position relative
      overflow hidden
      background-color #F6F6F6
      width min(15vw, pxToRem(292))
      max-width pxToRem(292)
      aspect-ratio 1 / 1
      &.hidden
        display none
      .goods-thumbnail
        width 100%
        aspect-ratio 1 / 1
      .goods-profile
        border-radius pxToRem(60)
        width min(pxToVw(48), pxToRem(48))
        max-width pxToRem(48)
        border 1px solid #F2F2F2
        position absolute
        left pxToRem(16)
        bottom pxToRem(16)
        aspect-ratio 1 / 1

  .sample-mockup-list
    display flex
    align-items center
    justify-content space-evenly
    height 100%
    .sample-mockup
      display flex
      flex-direction column
      gap pxToRem(28)
      font-size pxToRem(18)
      line-height pxToRem(27)
      font-weight 400
      width fit-content
      height 100%
      align-items center
      will-change transform
      transition transform 0.2s ease-in-out
      &:hover
        transform scale(1.05)
      img
        width 26.7145vmin
        max-width pxToRem(272)
        max-height 100%
        aspect-ratio 272/ 560
        object-fit contain
      &.center
        img
          width 31.76vmin
          max-width pxToRem(327)
          aspect-ratio 327 / 560

  .main-06
    height 100vh
    padding-top 0
    padding-bottom 0
    background #1C75FF
    overflow hidden
    .with-swiper
      position absolute
      left 50%
      transform translateX(-50%)
      visibility hidden
      &.view
        visibility visible

      .logo-area
      .detail
        transition all 0.6s ease-in-out
        opacity 0
        color #DDDDDD
        a
          color #DDDDDD
        &.view
          opacity 1


    .main-06-content-list
      display flex
      align-items center
      align-self flex-start
      //width calc(100% * 3)
      height 100%

      .main-06-content
        width 100%
        height 100%
        position relative
        display flex
        justify-content center
        align-items center
    .main-06-content-prev-btn
    .main-06-content-next-btn
      position absolute
      top 50%
      transform translateY(-50%)
      &.swiper-button-disabled
        opacity 0.2
        transition stroke 0.3s ease-in-out
    .main-06-content-prev-btn
      left pxToRem(-200)
    .main-06-content-next-btn
      right pxToRem(-200)

    video
      height 100%
      width 100%
      object-fit cover
    .back-drop-video
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      border-radius: pxToRem(20);
      opacity: 0.2;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #1A1A1A 100%)

  .card
    z-index 1
    position relative
    display flex
    flex-direction column
    gap pxToRem(60)
    align-items center
    width pxToRem(1544)
    .card-desc
      font-size pxToRem(20)
      line-height pxToRem(32)
      font-weight 500
      display flex
      flex-direction column
      gap pxToRem(16)
      text-align center
      letter-spacing: -0.2px
      color: #fff
      .card-desc-highlight
        font-size: pxToRem(48)
        font-weight: 700
        line-height: pxToRem(60)
        letter-spacing: -0.2px
    .card-detail
      width pxToRem(1002)
      color #ddd
      font-size pxToRem(24)
      line-height pxToRem(36)
      font-weight 700
      position relative
      display flex
      align-items center
      justify-content center
      text-align left
      gap pxToRem(80)
      &__subtitle
        margin-bottom pxToRem(16)
        display flex
        align-items center
        justify-content flex-start
        gap pxToRem(10)
        color: var(--White, #FFF);
        font-size: pxToRem(20)
        font-weight: 700;
        line-height: pxToRem(28)
        letter-spacing: -0.2px
      &__info-box
        padding pxToRem(24) pxToRem(32)
        display flex
        flex-direction column
        gap pxToRem(18)
        width pxToRem(450)
        box-sizing border-box
        border-radius: pxToRem(16)
        background: #FFF
        backdrop-filter: blur(20px)
      &__info-txts
        display flex
        align-items center
        justify-content space-between
        gap pxToRem(20)
      &__info-txt1
        color: #1C75FF
        font-size: pxToRem(24)
        font-weight: 700
        line-height: pxToRem(34)
        letter-spacing: -0.2px
      &__info-txt2
        color: #1C75FF
        font-family 'Bebas Neue Pro'
        font-size: pxToRem(40)
        font-weight: 700
        line-height: pxToRem(34)
        span
          display inline-block
          margin-left pxToRem(2)
          font-family: Pretendard;
          font-size: pxToRem(24)
      img.logo
        width pxToRem(472)
      .detail
        .text
          margin-bottom pxToRem(40)
          flex 1
          text-align left
          color: #FFF
          font-size: pxToRem(36)
          font-weight: 700;
          line-height: pxToRem(50)
          letter-spacing: -0.2px
          a
            color #fff
        button
          background-color transparent
          padding: pxToRem(8p)
          &:first-child
            margin-left pxToRem(-8)
          &:last-child
            margin-right pxToRem(-8)
  .card-02
    z-index 1
    position relative
    display flex
    flex-direction column
    gap pxToRem(60)
    align-items flex-start
    justify-content center
    width pxToRem(1544)
    &__help-txt1
      display flex
      align-items center
      justify-content flex-start
      gap pxToRem(8)
      color: var(--Blue, #1C75FF);
      font-size: pxToRem(14)
      font-weight: 700;
      line-height: pxToRem(22)
      letter-spacing: -0.2px
    .card-desc
      font-size pxToRem(20)
      line-height pxToRem(32)
      font-weight 500
      display flex
      flex-direction column
      gap pxToRem(16)
      text-align left
      letter-spacing: -0.2px
      color: #141414
      .card-desc-highlight
        font-size: pxToRem(48)
        font-weight: 700
        line-height: pxToRem(60)
        letter-spacing: -0.2px
    .flip-list
      display flex
      width pxToRem(872)
      align-items flex-start
      align-content flex-start
      gap pxToRem(24)
      flex-wrap wrap
    .flip-card
      background-color transparent
      width pxToRem(200)
      height pxToRem(236)
      aspect-ratio 1
      perspective: 1000px
      &:hover
        .flip-card-inner
          transform rotateY(180deg)
          transition-duration 0.6s
    .flip-card-inner
      position: relative
      width 100%
      height 100%
      text-align center
      transition transform 1s
      transform-style preserve-3d
    .flip-card-front
    .flip-card-back
      width 100%
      height 100%
      box-sizing border-box
      display flex
      padding pxToRem(54) 0 pxToRem(50) 0
      flex-direction column
      justify-content center
      align-items center
      gap pxToRem(8)
      flex-shrink 0
      border-radius pxToRem(24)
      position absolute
      -webkit-backface-visibility hidden
      backface-visibility hidden
    .flip-card-front
      color: #1C75FF
      font-size: pxToRem(20)
      font-weight: 700
      line-height: pxToRem(28)
      letter-spacing: -0.2px
      border-radius: 24px;
      background: var(--Light-Blue, #EBF3FE);
      img
        width pxToRem(96)
        height pxToRem(96)
    .flip-card-back
      padding pxToRem(46) 0 pxToRem(42) 0
      border-radius: pxToRem(24)
      background: var(--Blue, #1C75FF);
      color #FFFFFF
      font-size pxToRem(16)
      line-height 150%
      font-weight 400
      transform rotateY(180deg)
      .header
        font-size pxToRem(20)
        line-height 150%
        font-weight 600


  .main-07
    overflow hidden
    .background-logo
      position absolute
      right 0
      width pxToRem(600)
      aspect-ratio 1 / 1
  /* main-08 */
  .main-08
    min-width pxToRem(600)
  .main-08-wrap
    //height 100vh
    width 100%
    display flex
    justify-content center
    align-items center
    #step3.none-login
      margin pxToRem(250) 0
    & .youtube-bridge-page__section:nth-child(2)
      height 100vh
    .main-08
      will-change transform
      transition display ease-in-out 0.2s
      &.hidden
        display none
  .youtube-bridge-page__section.integration-processing
    min-height 100vh
    height auto
    gap 0
    .desc
      display flex
      justify-content center
      align-items center
    .hand-image
      position absolute
      right pxToRem(-140)
      width pxToRem(140)
      aspect-ratio 1 / 1
    & .button-area-vertical
      display flex
      flex-direction column
      width 100%
      gap pxToRem(28)
      margin-top pxToRem(60)
      & > a
        width 100%
    & .button-area
      display flex
      margin-top pxToRem(40)
      width 100%
      max-width pxToRem(600)
      gap pxToRem(16)
      button
        display flex
        align-items center
        justify-content center
        font-size pxToRem(16)
        line-height  150%
        font-weight 600
        flex 1
        min-height pxToRem(56)
        box-sizing border-box
        width 100%
        border-radius: pxToRem(12)
        gap pxToRem(4)
        &:disabled
          color: var(--Gray-50, #A2A2A2);
        &:not(.primary):not(:disabled)
          color #1C75FF
          border-radius: pxToRem(12);
          background: var(--Light-Blue, #EBF3FE);
  .main-08
    .desc
      position relative
      display flex
      align-items center
      flex-direction column
      gap pxToRem(20)
      font-size pxToRem(20)
      font-weight 400
      line-height pxToRem(30)
      color: var(--Black, #141414);
      .h3-60
        color: var(--Black, #141414);
  .youtube-bridge-page__section.integration-complete
  .youtube-bridge-page__section.integration
    gap pxToRem(100)
    display flex
    justify-content center
    align-items center
    .desc
      .hand-image
        position absolute
        right pxToRem(-170)
        top pxToRem(30)
        width pxToRem(140)
        aspect-ratio 1
  .youtube-bridge-page__integration-btn
    position relative
    padding pxToRem(16) pxToRem(24)
    font-size pxToRem(16)
    color: var(--Blue, #1C75FF)
    font-weight: 700;
    line-height: pxToRem(24)
    letter-spacing: -0.2px
    border-radius pxToRem(12)
    transition all 0.2s ease-in-out
    background: var(--Light-Blue, #EBF3FE)


  /* footer */
  .youtube-bridge-page__footer
    margin pxToRem(32) pxToRem(32) 0
    display flex
    color #fff
    justify-content center
    align-items center
    flex-direction column
    .more
      display flex
      justify-content center
      align-items center
      position relative
      & > img
        position relative
        z-index: 0;
        width 100%
        aspect-ratio 1920 / 400
        border-radius: 40px;
      .footer-desc
        position absolute
        z-index 1
        display flex
        flex-direction column
        justify-content center
        align-items center
        gap pxToRem(32)
        font-size pxToRem(24)
        color: var(--White, #FFF)
        text-align: center
        font-weight: 700
        line-height: pxToRem(34)
        letter-spacing: -0.2px
      .youtube-bridge-page__footer-more-btn
        padding pxToRem(16) pxToRem(24)
        background-color #111
        color: var(--Bright-70, rgba(255, 255, 255, 0.70));
        font-weight: 500;
        line-height: pxToRem(24)
        letter-spacing: -0.2px
        font-size pxToRem(16)
        border-radius pxToRem(12)
        background: var(--Bright-20, rgba(255, 255, 255, 0.20))
        backdrop-filter: blur(20px)
    .corporation
      display flex
      justify-content space-between
      align-items center
      max-width pxToRem(1313)
      margin 0 auto
      width 100%
      padding pxToRem(77) 0 pxToRem(76)
      .info
        color #141414
        font-size pxToRem(14)
        line-height pxToRem(26)
        font-weight 600
        text-align left
        display flex
        flex-direction column
        gap pxToRem(16)
        .lite
          font-weight 400
        & > div > a:last-child
          margin-left pxToRem(12)
        & a
          color #141414
          text-underline none
      .contact
        text-align right
        display flex
        flex-direction column
        gap pxToRem(16)
        .group
          display flex
          flex-direction column
          gap pxToRem(4)
        .tell
          font-size pxToRem(48)
          line-height 100%
          font-weight 700
          color #141414
          text-decoration none
        .time
          font-size pxToRem(14)
          line-height 150%
          font-weight 400
          color #141414
        .email
          font-size pxToRem(16)
          line-height pxToRem(26)
          font-weight 400
          color #141414
          & a
            color #141414

  .form-field-list
    width 100%
    box-sizing border-box
    display flex
    flex-direction column
    gap pxToRem(20)
    font-size pxToRem(16)
    line-height 150%
    font-weight normal
    .form-field
      display flex
      flex-direction column
      align-items flex-start
      width 100%
      &.error
        & input.form-input:focus-visible
          outline pxToRem(3) solid #D92A0F
        & p.helper-text
          color #D92A0F
      label
        font-weight 600
        margin-bottom pxToRem(6)
      &__label
        display flex
        align-items center
        gap pxToRem(4)
      &__required-icon
        display flex
        align-items flex-start
        padding-bottom pxToRem(8)
        color #1C75FF
      input.form-input
        width 100%
        display flex
        color: var(--Black, #141414);
        font-family: Pretendard;
        font-style: normal;
        font-weight: 500;
        line-height: pxToRem(24);
        letter-spacing: -0.2px;
        font-size pxToRem(16)
        padding pxToRem(12) pxToRem(16) pxToRem(12) 0
        box-sizing border-box
        background-color transparent
        border 0 none
        border-bottom: 1px solid var(--Gray-10, #E7E7E7);
        &:disabled
          background: var(--Gray-5, #F5F5F5);
        &:read-only
          background: var(--Gray-5, #F5F5F5);
        &::placeholder
          color #A2A2A2
          overflow hidden
          text-overflow ellipsis
          white-space nowrap
          font-size pxToRem(16)
          line-height 150%
          font-weight 400
        &:focus-visible
          outline pxToRem(3) solid #1F5EFF
      p.helper-text
        font-size pxToRem(12)
        line-height 150%
        font-weight 400
        margin pxToRem(8) 0 0
      .input-with-button
        display flex
        gap pxToRem(8)
        width 100%
        & > input
          flex 1
        & > button
          display flex
          height pxToRem(48)
          min-width pxToRem(140)
          padding 0 pxToRem(24)
          justify-content center
          align-items center
          gap pxToRem(4)
          border-radius pxToRem(8)
          font-size: pxToRem(14);
          font-weight: 700;
          line-height: pxToRem(22);
          letter-spacing: -0.2px;
          &:not(:disabled):not(.primary)
            color #1C75FF
            background: var(--Light-Blue, #EBF3FE);
            backdrop-filter: blur(20px);
          &:disabled
            color: var(--Gray-50, #A2A2A2);
            background: var(--Gray-5, #F5F5F5);
            backdrop-filter: blur(20px);
          &:disabled.complete
            color: var(--Gray-50, #A2A2A2);
            background: var(--Gray-5, #F5F5F5);
            backdrop-filter: blur(20px);
          &:disabled.primary
            color: var(--Gray-50, #A2A2A2);
            background: var(--Gray-5, #F5F5F5);
      .form-field-item-warp
        width 100%
        display flex
        flex-direction column
        gap pxToRem(8)
      .verify-email
        display none
        transition display ease-in-out 0.2s
        &.show
          display flex
        .wait-verify-code
          display flex
          align-items center
          position relative
          .countdown
            position absolute
            right pxToRem(20)
            width fit-content
            color #1F5EFF
            text-align right
            font-size pxToRem(15)
            font-style normal
            font-weight 400
            line-height pxToRem(22)


    .checkbox-wrap
      position relative
      display flex
      align-items center
      justify-content space-between
      width 100%
      label
        flex 1
      &--all
        padding-bottom pxToRem(12)
        border-bottom: 1px solid var(--Gray-20, #D9D9D9);
      &.large
        font-size pxToRem(16)
        font-weight 600
        line-height 150%
        & > label > span.checkbox
          width pxToRem(26)
          height pxToRem(26)
          & > svg
            width pxToRem(14)
            height pxToRem(14)
      & .term
        margin-left pxToRem(10)
        & a
          font-weight 600
          color #A2A2A2
          text-decoration-line underline
    input[type="checkbox"]
      visibility hidden
      width 0
      height 0
      margin 0
      &:checked + label > span.checkbox
        background-color #1F5EFF
        border none
        & > svg
          display block

      & + label
        display inline-flex
        align-items center
        cursor pointer
        gap pxToRem(8)
        & > span.checkbox
          display flex
          align-items center
          justify-content center
          border-radius pxToRem(4)
          width pxToRem(16)
          height pxToRem(16)
          box-sizing border-box
          border: 1px solid var(--Gray-20, #D9D9D9);
          & > svg
            display none
            margin-bottom pxToRem(2)
    .agree-box
      display flex
      flex-direction column
      width 100%
      align-items flex-start
      gap pxToRem(14)
      & > div
        box-sizing border-box
        width 100%
        display flex
        flex-direction column
        align-items flex-start
        gap pxToRem(8)
        font-size pxToRem(14)
        font-weight 400
        line-height 150%
        & label
          display inline-flex
          align-items center
          gap pxToRem(8)
          position relative
          cursor pointer
          color: var(--Black, #141414);
          & input[type="checkbox"]
            visibility hidden
            position absolute
            z-index 0
            &:checked + .check_box
              background-color #1F5EFF
          & .check_box
            border-radius pxToRem(4)
            width pxToRem(16)
            height pxToRem(16)
            background-color #CCCCCC
            background-image url(//s3.marpple.co/files/u_2947328/2023/11/original/cf8c350b7b7ee7a8d8b9df25baac9dff3cdf82c31.png)
            background-size contain
          & a
            margin-left pxToRem(2)
            font-weight 600
            color #A2A2A2
            text-decoration-line underline
  .youtube-bridge-page__integration-signup
    width 100%
    max-width pxToRem(600)
    margin-top pxToRem(100)
  .youtube-bridge-page__integration-channel
    margin-top pxToRem(60)
    width 100%
    max-width pxToRem(600)
    box-sizing border-box
    .youtube-channel
      margin-bottom pxToRem(20)
    .youtube-bridge-page__select-channel-list
      display flex
      flex-direction column
      gap pxToRem(8)
      width 100%
      .form-field-list
        & .form-field
          & .youtube-channel-item
            margin 0
      .youtube-channel-item
        box-sizing border-box
        width 100%
        display flex
        align-items center
        gap pxToRem(12)
        padding pxToRem(12) 0
        color #A2A2A2
        font-size pxToRem(16)
        font-weight 500
        line-height pxToRem(24)
        text-align left
        margin 0 0 pxToRem(20)
        border-bottom: 1px solid var(--Gray-20, #D9D9D9);
        background: var(--White, #FFF);
        &[checked="true"]
          border-bottom: 1px solid var(--Blue, #1C75FF);
        &.disabled
          .youtube-channel-info
            img
              opacity 0.6
            .channel-name
              color #A2A2A2
        &:not(.disabled)
        input[type="radio"]:not(:disabled)
          cursor pointer
        input[type="radio"]
          margin 0
          width pxToRem(16)
          height pxToRem(16)
          appearance none
          border pxToRem(1) solid #DDD
          border-radius 50%
          background-color #FFF
          flex 0 0 auto
          &:checked
            border pxToRem(4) solid #1C75FF
            & + div
              color #111111
              & .channel-name
                font-weight 600
          &:disabled
            background-color #F2F2F2
        .youtube-channel-info
          flex 1 1 auto
          display flex
          gap pxToRem(8)
          align-items center
          img
            width pxToRem(32)
            aspect-ratio 1 / 1
            flex 0 0 auto
            border-radius pxToRem(100)
          .channel-name
            flex 1 1 auto
            font-weight 500
            color #4E4E4E
            display flex
            flex-direction column
            align-items flex-start
            text-overflow ellipsis
            white-space nowrap
            overflow hidden
            & > span
              width 100%
              text-overflow ellipsis
              white-space nowrap
              overflow hidden
              & .sub-text
                font-size pxToRem(14)
                font-weight 400
                line-height 150%
  .youtube-bridge-page__section.integration-complete
    justify-content center
    gap pxToRem(40)
    width 100%
    .party-img
      position absolute
      right pxToRem(-148)
      bottom pxToRem(10)
      width pxToRem(140)
      height pxToRem(140)
      filter drop-shadow(0x 0 pxToRem(20) rgba(0, 0, 0, 0.80))
      &.hi-there
        animation hi-there 2s ease forwards
      img
        width 100%
    .youtube-bridge-page__integration-complete-view
      max-width pxToRem(600)
      width 100%
      position relative
      .youtube-bridge-page__select-channel-list
        gap pxToRem(8)
        align-items center
        .relation-icon
          flex-shrink 0
          width pxToRem(60)
          height pxToRem(60)
          aspect-ratio 1
          svg
            width 100%
            height 100%
        .youtube-channel-item
          padding pxToRem(15) pxToRem(20)
          color: var(--Black, #141414)
          font-size: pxToRem(16)
          font-weight: 500
          line-height: pxToRem(24)
          letter-spacing: -0.2px
          background: var(--White, #FFF)
          border-bottom: 1px solid var(--Blue, #1C75FF)
          cursor auto
          .youtube-channel-info
            img
              width pxToRem(60)
              border-radius pxToRem(40)
            .channel-name
              text-align left
              font-weight 700
              .sub-text
                font-size pxToRem(13)
                font-weight 400
                line-height pxToRem(20)
                color #4E4E4E
      .button-area
        position relative
        width 100%
        margin-top pxToRem(36)
        .chip
          position absolute
          right pxToRem(12)
          bottom pxToRem(50)
        button
          font-size pxToRem(18)
          font-weight 600
          line-height 150%
          &.primary
            height pxToRem(56)
            width 100%
            border-radius pxToRem(8)
  .move-image
    &.bottom-origin
      transform-origin 20% 100%
      &.hi-there
        animation hi-there-bottom 2s ease forwards
    &.hi-there
      animation hi-there 2s ease forwards

.youtube-bridge-marquee
  display flex
  overflow-x hidden
  width 100%
  position absolute
  z-index -1
  &__background
    --gap pxToRem(40)
    flex-shrink 0
    display flex
    gap var(--gap)
    justify-content space-around
    align-items center
    will-change transform
    animation marquee-left 50s linear infinite
    min-width 100%
    height 45vmin
    max-height pxToRem(400)
    padding-bottom pxToRem(80)
    &[aria-hidden="true"]
      margin-left var(--gap)
    & img
      height 100%

@keyframes marquee-left {
  from { transform: translateX(0); }
  to { transform: translateX(calc(-100% - var(--gap))); }
}
@keyframes hi-there {
  30% { transform: scale(1.1); }
  40%, 60% { transform: rotate(-20deg) scale(1.1); }
  50% { transform: rotate(20deg) scale(1.1); }
  70% { transform: rotate(0deg) scale(1.1); }
  100% { transform: scale(1); }
}

@keyframes hi-there-bottom {
  30% { transform: scale(1.1); }
  40%, 60% { transform: rotate(-10deg) scale(1.1); }
  50% { transform: rotate(10deg) scale(1.1); }
  70% { transform: rotate(0deg) scale(1.1); }
  100% { transform: scale(1); }
}

@media screen and (max-height 900px)
  .youtube-bridge-page
    .main-06
      min-height pxToRem(900)
//@media (min-width 1024px) and (max-width 1580px)
//  html[is_mobile=false]:has(.youtube-bridge-page)
//    font-size .87vw !important
@media screen and (max-width 1024px)
  html[is_mobile=false]:has(.youtube-bridge-page)
    //font-size .87vw !important
    .youtube-bridge-page
      min-width auto !important
      .youtube-bridge-page__section:not(.content-height)
        height 100vh
        min-height 768px
  .youtube-bridge-page
    min-width 1024px


