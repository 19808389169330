.pb-order
  position relative
  .main-section
    height auto
  &__paid-at
    position absolute
    top 10px
    right 0
  &__input
    width 240px
    border 0 !important
  &__input-wrapper
    padding 12px
    display flex
    align-items center
    img
      width 16px
      margin-right 12px
    border 1px solid $gray-s1
.pb-order-count
  border-radius 8px
  border solid 1px $gray-5
  display flex
  margin-bottom 20px
  [data-order_process_step_id]
    cursor pointer
  &__item
    border-right 1px solid $gray-5
    padding pxToRem(25px) pxToRem(35px)
    display flex
    align-items center
    &[data-order_process_step_id="ALL"]
      background-color $gray-5
      padding pxToRem(25px) pxToRem(25px)
      .pb-order-count__img
        display none
    &[data-order_process_step_id="ORDERED"]
      .pb-order-count__img
        background-image url(//s3.marpple.co/files/u_29089/2023/7/original/ea2efb902dfcb3b7626d7b0a0052a63391027d9c1.webp)
    &[data-order_process_step_id="PB_PRODUCT_PREPARING"]
      .pb-order-count__img
        background-image url(//s3.marpple.co/files/u_29089/2023/7/original/bb5bd8becd1b7a3d5b40a0310432c0a8fd1770a71.webp)
    &[data-order_process_step_id="DELIVERING"]
      .pb-order-count__img
        background-image url(//s3.marpple.co/files/u_29089/2023/7/original/2468c8febda69b0fa547a057a3e274bb14fe2f1e1.webp)
    &[data-order_process_step_id="DELIVERED"]
      .pb-order-count__img
        background-image url(//s3.marpple.co/files/u_29089/2023/7/original/db1db090d571f065b3caac34b54b2e65a83872111.webp)
    &[data-order_process_step_id="CONFIRMED"]
      .pb-order-count__img
        background-image url(//s3.marpple.co/files/u_29089/2023/7/original/1dcc50c0dbb1997d1689e283dfb1cabb56e5f9c01.webp)
    &[data-selected="true"]
      .pb-order-count__name
      .pb-order-count__count
      .pb-order-count__count-kr
        color #3c89f9
      .pb-order-count__img
        background-color #3c89f9
      &[data-order_process_step_id="ORDERED"]
        .pb-order-count__img
          background-image url(//s3.marpple.co/files/u_29089/2023/7/original/b7beded3440347715ad5ec7bd8e15feb4e1669611.webp)
      &[data-order_process_step_id="PB_PRODUCT_PREPARING"]
        .pb-order-count__img
          background-image url(//s3.marpple.co/files/u_29089/2023/7/original/33ca912aea86d18364f82fdb1091d60c54bc08f01.webp)
      &[data-order_process_step_id="DELIVERING"]
        .pb-order-count__img
          background-image url(//s3.marpple.co/files/u_29089/2023/7/original/ceb1bcbc5fb76d5f3fa7874bb6fa8d998842b2f71.webp)
      &[data-order_process_step_id="DELIVERED"]
        .pb-order-count__img
          background-image url(//s3.marpple.co/files/u_29089/2023/7/original/3823009432c48c5f89144d74548d2a00bdca67131.webp)
      &[data-order_process_step_id="CONFIRMED"]
        .pb-order-count__img
          background-image url(//s3.marpple.co/files/u_29089/2023/7/original/85e3ab8431f307347de24c9da1a750e0df620f751.webp)
  &__info
    flex-shrink 0
  &__name
    font-weight 500
    letter-spacing -0.5px
    color $gray-3
  &__count
    font-size pxToRem(20px)
    font-weight bold
    letter-spacing -0.5px
    color $gray-2
  &__count-kr
    font-weight 500
    letter-spacing -0.5px
    color $gray-2
    font-size pxToRem(14px)
    margin-left 2px
  &__img
    flex-shrink 0
    background-color $gray-5
    border-radius 100%
    width pxToRem(40px)
    height pxToRem(40px)
    display flex
    justify-content center
    align-items center
    margin-right pxToRem(12px)
    background-repeat no-repeat
    background-size 20px 20px
    background-position center

  &__cancels
    padding 22px 25px
  &__item-cancel
    display flex
    align-items center
    justify-content space-between
    margin-bottom 4px
    &[data-order_process_step_id="CANCELED"]
      .pb-order-count__img-cancel
        background-image url(//s3.marpple.co/files/u_29089/2023/7/original/f6232e67b71158e39e2c03102f173fdfbd32c6471.webp)
    &[data-order_process_step_id="REFUNDED"]
      .pb-order-count__img-cancel
        background-image url(//s3.marpple.co/files/u_29089/2023/7/original/99f76ee057c0eb4e3bb9996e393969b326d622141.webp)
    &[data-selected="true"]
      .pb-order-count__name
      .pb-order-count__count-cancel
      .pb-order-count__count-kr
        color #3c89f9
      .pb-order-count__img-cancel
        background-color #3c89f9
      &[data-order_process_step_id="CANCELED"]
        .pb-order-count__img-cancel
          background-image url(//s3.marpple.co/files/u_29089/2023/7/original/6e756e1b5dedbe722d9b800d84030549dd837f602.webp)
      &[data-order_process_step_id="REFUNDED"]
        .pb-order-count__img-cancel
          background-image url(//s3.marpple.co/files/u_29089/2023/7/original/eb69d79a0b4bb113a4c321c4a1bf75bc06d419702.webp)
  &__count-cancel
    flex-shrink 0
    font-size pxToRem(16px)
    font-weight bold
    letter-spacing -0.5px
    color $gray-2
    margin-left pxToRem(12px)
  &__img-cancel
    flex-shrink 0
    background-color $gray-5
    border-radius 100%
    width pxToRem(18px)
    height pxToRem(18px)
    display flex
    justify-content center
    align-items center
    margin-right pxToRem(8px)
    background-repeat no-repeat
    background-size 10px 10px
    background-position center
  &__name-cancel
    flex-shrink 0
    font-weight 500
    letter-spacing -0.5px
    color $gray-3
