.app-product-sub
  margin-top pxToRem(48)
  margin-bottom pxToRem(64)
  overflow hidden
  min-height pxToRem(700)
  &__page-title
    margin 0 0 pxToRem(36)
    padding 0
    {$font-heading-h3-b}
  &__header
    margin 0 0 pxToRem(24)
  &__title
    line-height 1.36
    letter-spacing -0.2px
    color #111111
    font-size pxToRem(28)
    font-weight 600
    margin-bottom pxToRem(40)
    display flex
  &__word
    display flex
    margin-right pxToRem(16)
  &__selected-wrapper
    position relative
    margin-right pxToRem(-4)
  &__selected-filter
    reset-button()
    position relative
    border-bottom pxToRem(2) solid #111
    display flex
    line-height 1.36
    letter-spacing -0.2px
    color #111111
    font-size pxToRem(28)
    font-weight 600
    justify-content space-between
    &:after
      content ''
      margin-left pxToRem(18)
      display block
      width pxToRem(20)
      height pxToRem(20)
      background-image url(//s3.marpple.co/files/u_29089/2021/5/original/99e339848ab8a6a98e4abd8b859a600a4963c5c81.png)
      background-repeat no-repeat
      background-size pxToRem(20) pxToRem(20)
      margin-top pxToRem(5)
    &--selected:after
      transform rotate(180deg)
  &__sort-selector
    position absolute
    top 100%
    left 0
    right 0
    z-index 1
    display none
    min-width pxToRem(173)
    &--show
      display block
    select
      position absolute
      top 0
      visibility hidden
  &__sort-list
    margin-top pxToRem(4)
    color #111111
    background-color #ffffff
    border-radius pxToRem(4)
    border solid pxToRem(1) #dddddd
  &__sort-item
    text-align center
    border pxToRem(1) solid #f8f8f8
    button
      reset-button()
      padding pxToRem(10) 0
      font-size pxToRem(15)
      line-height 1.4
      letter-spacing -0.2px
      color #424242
      width 100%
    &--selected
      button
        color #111
        font-weight 600
    &:last-child
      border none
  &__style
    display flex
    align-items center
  &__style-btn
    margin 0
    background #fff
    padding pxToRem(7) pxToRem(16) pxToRem(6)
    border-radius pxToRem(17)
    border solid pxToRem(1) #dddddd
    line-height 1.4
    letter-spacing -0.2px
    color #4e4e4e
    font-size pxToRem(15)
    margin-right pxToRem(15)
    flex-shrink 0
  &__selected-style
    display flex
    align-items baseline
    justify-content space-between
    flex 1
    &[data-len="0"]
      margin-top 0
      .app-product-sub__style-init-btn
        display none
  &__style-init-btn
    reset-button()
    line-height 1.4
    letter-spacing -0.2px
    color #4e4e4e
    flex-shrink 0
  &__style-list
    display flex
  &__style-item
    reset-button()
    margin 0 pxToRem(12) 0 0
    font-size pxToRem(15)
    line-height 1.4
    letter-spacing -0.2px
    color #1C75FF
    display flex
    align-items center
    &:after
      content ''
      margin 0 0 pxToRem(2) pxToRem(4)
      width pxToRem(9)
      height pxToRem(9)
      background-image url(//s3.marpple.co/files/u_29089/2021/5/original/c4f08c57632db2de339db1296e4410432c54e5b41.png)
      background-size pxToRem(9)

  &__list
    margin 0 -.5
    &--false
      display grid
      grid-template-columns repeat(5, minmax(0, 1fr))
      gap 0 pxToRem(25)
    .app-product-item--nbox
      width 100%
    &--app-store
      margin 0 pxToRem(-3.5)

.app-product-zero
  margin-top pxToRem(86)
  display flex
  flex-direction column
  align-items center
  text-align center
  &__sad-icon
    width pxToRem(80)
    height pxToRem(80)
    margin-bottom pxToRem(16)
    font-size 0
    img
      width 100%
  &__title
    font-size pxToRem(24)
    font-weight 600
    line-height 1.33
    letter-spacing -0.2px
    color #4e4e4e
    margin-bottom pxToRem(18)
  &__description
    font-size pxToRem(18)
    font-style normal
    line-height 1.33
    letter-spacing -0.2px
    color #a2a2a2
    margin-bottom pxToRem(40)
  &__back
    display block
    padding pxToRem(12) pxToRem(32) pxToRem(10)
    border-radius pxToRem(4)
    border solid pxToRem(1) #dddddd
    font-size pxToRem(16)
    line-height 1.38
    letter-spacing -0.2px
    color #4e4e4e

