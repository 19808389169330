

.question-empty
  padding ptr(160) 0
  margin-bottom pxToRem(120px)
  text-align center
  display flex
  flex-direction column
  align-items center
  &__icon
    margin-bottom pxToRem(24px)
    img
      width pxToRem(160px)
  &__txt1
    margin 0 0 pxToRem(8px)
    padding 0
    text-align center
    color $gray-80
  &__txt2
    margin 0 0 pxToRem(24px)
    padding 0
    color $gray-50
    width ptr(296)
  &__btn
    display none

.question-list
  display flex
  justify-content center
  padding ptr(24) ptr(232)
  &__wrap
    width 100%
  &__ul
    margin 0 0 pxToRem(32px)
    padding 0
    list-style none
  &__li
    padding pxToRem(48px) 0
    border-bottom 1px solid $gray-20
    cursor pointer
  &__txt1
    text-align center
    display flex
    align-items center
    justify-content center
    color $gray-50
    img
      width pxToRem(20px)
      margin-right pxToRem(8px)
      vertical-align top

.question-list-item
  .mo
    display none
  display flex
  align-items center
  justify-content space-between
  &__info
    width 100%
  &__status-area
    display flex
    align-items center
    justify-content space-between
    margin-bottom ptr(16)
  &__status
    border-radius ptr(4)
    padding 0 ptr(8)
    height ptr(24)
    border none
    &--question
      color $blue-100
      background-color $blue-10
    &--answer
      color $gray-80
      background-color $gray-5
  &__created-at
    color $gray-80
  &__title
    margin-bottom pxToRem(16px)
    a
      color $black
  &__shop-wrap
    display flex
    column-gap ptr(16)
  &__shop-detail
    display flex
    flex-direction column
    row-gap ptr(4)
    a
      color $black
      text-decoration underline
  &__shop-detail-header
    min-width ptr(90)
    color $gray-50





