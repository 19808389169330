$drawer = '.drawer'
$avatar = '.avatar'

$keyframe-drawer-in = drawer-in
$keyframe-drawer-out = drawer-out

$root-index = 1

drawer-size = 600px

{$drawer}
  position fixed
  top 0
  right 0
  displayFlex flex-end
  width drawer-size
  height 100%
  visibility hidden
  z-index 10
  &__inner
    position absolute
    top 0
    right 0
    width drawer-size
    height 100%
    background-color #fff
    opacity 0
    transform translateX(70%)
    &--slide-in
      animation-duration .25s
      animation-timing-function ease-out
      animation-name $keyframe-drawer-in
      animation-fill-mode forwards
    &--slide-out
      animation-duration .25s
      animation-timing-function ease-out
      animation-name $keyframe-drawer-out
      animation-fill-mode forwards
    &--keep-open
      transform translateX(0)
      opacity 1
      visibility visible
  &__header
    displayFlex space-between center
    padding 0 65px 0 80px
    height 72px
  &__header-column
    display flex
    align-items center
  &__logo-anchor
    display block
    font-size 24px
    font-weight 600
    line-height 1.56
    letter-spacing -0.2px
    color #111
    html[is_plus="true"] &
      display none
  &__logo-image
    width 48px
    vertical-align middle
  &__title-image
    height 28px
    vertical-align middle
  &__button
    reset-button()
    flexCenter()
    position relative
    width 52px
    height 40px
    &::before
      width 24px
      height 24px
      background-size 24px
      background-repeat no-repeat
      content ''
    &--cart
      &::before
        background-image: url(//s3.marpple.co/files/u_1396787/2021/5/original/db2a15fcf8f4913f2079caa31f0864ec068f7b951.svg)
        //background-image url(//image1.marpple.co/files/u_29089/2022/12/original/c5926f6f74b0b91071893be361144dd26ab258d51.png?w=48)
        //height pxToRem(24px)
    &--close
      &::before
        background-image: url(//s3.marpple.co/files/u_1396787/2021/5/original/f26d844a658278066c7595d406a3fb1d50412c101.svg)
  &__blind-text
    blind()
  &__cart-count-box
    flexCenter()
    display none
    position absolute
    //top pxToRem(3px)
    //right pxToRem(7px)
    top pxToRem(2px)
    right pxToRem(3px)
    padding 4px 0 1px
    width 19px
    border-radius 100%
    background-color #1C75FF
    color #fff
    &--show
      display flex
    &--wide
      right 2px
      width 25px
      border-radius 8px
  &__cart-count
    font 12px 12px -.2px
    color: #fff;
  &__body
    box-sizing border-box
    margin-top 16px
    padding 0 80px 60px
    height calc(100% - 117px)
    overflow-y auto
  &__text
    font 20px, 26px, -.3px
    &--bold
      font-weight 600
{$drawer}-login
  display flex
  align-items center
  width 100%
  &__box
    position relative
    width calc(100% - 86px)
  &__button
    display inline-block
  &__link
    display block
    font 14px 18px -.2px
    &:last-child
      position absolute
      top 50%
      right 0
      transform translateY(-50%)
    &--round
      flexCenter()
      height 30px
      padding 0 13px
      border 1px solid #ddd
      border-radius 15px
  &__link-text
    margin-bottom -2px
    font 14px 18px -.2px
  &__text-box
    margin-bottom -2px
    margin-right pxToRem(87px)
    limitLine(1)
  &__text
    font 20px, 26px, -.3px
    &--bold
      font-weight 600

{$drawer}-category
  margin-top 18px
  &__group-anchor
    display inline-flex
    margin-top 30px
    font 23px, 28px, -.3px, 600
  &__list
    reset-list()
    display flex
    flex-wrap wrap
    margin 0 -10px
    ^[{$root-index}]__group-anchor ~ ^[{$root-index}..-{$root-index}]
      margin-top 4px
  &__item
    width calc(100% / 3)
  &__anchor
    display block
    margin-top 10px
    padding 0 10px 4px
    font 16px, 20px, -.2px
    word-break break-word
    color #4e4e4e
{$drawer}-banner
  margin-top 56px
  &__button
    reset-button()
    displayFlex flex-start center
    width 100%
    height 47px
    border-radius 25px 25px 0 0
    font 18px, 24px, -.2px, 600
    color #111
    &::before
      width 48px
      height 48px
      margin-right 14px
      border-radius 100%
      background #111 url(//s3.marpple.co/files/u_1396787/2021/5/original/5031166ddd9e3dab63c5b9f0212985f765864a021.svg) no-repeat center
      background-size 24px
      content ''
    &--apply
      &::before
        background #EBF3FE url(//s3.marpple.co/files/u_1504988/2024/2/original/5356520e3edcf53952861acb6afcaa9bb0ff32e61.png) no-repeat center
        background-size contain
      html[is_plus="true"] &
        display none

{$avatar}
  position relative
  width 64px
  height 64px
  border-radius 24px
  background #f8f8f8 url("//s3.marpple.co/files/u_1396787/2021/4/original/c0c2545a3890e2512a4f499947ce007963f188fc2.png") no-repeat center
  background-size 29px
  &__image
    width 100%
    height 100%
    border-radius 24px
  &--login
    margin-right 22px
    displayFlex flex-end flex-end
  &__edit
    reset-button()
    flexCenter()
    display none
    position absolute
    bottom 0
    right 0
    width 24px
    height 24px
    border solid 2px #ffffff
    border-radius 24px
    background #f8f8f8 url('//s3.marpple.co/files/u_1396787/2021/4/original/ac9c39ba9bf5c3347f251d65fcecda023bddfb881.png') no-repeat center
    background-size 16px
    ^[{$root-index}]__text
      blind()

{$creator}-mask
  position fixed
  top 0
  left 0
  width 100%
  height 100%
  background-color #000
  opacity 0
  transition opacity .3s ease-in-out
  visibility hidden
  z-index 9
  &--is-open
    opacity 0.5
    visibility visible

@keyframes {$keyframe-drawer-in} {
  from {
    transform translateX(70%)
    opacity .3
  }
  to {
    transform translateX(0)
    opacity 1
    visibility visible
  }
}
@keyframes {$keyframe-drawer-out} {
  from {
    transform translateX(0)
    opacity 1
    visibility visible
  }
  to {
    transform translateX(70%)
    opacity 0
    visibility hidden
  }
}
