$asp=app-store-product
.{$asp}
  &__kv
    position relative
    height 0
    margin 0 0 24px
    img
      width 100%
  &__cate_lists
    margin 0 0 20px
    .slide-selector__item
      margin 0 8px 8px 0
      padding 9px 20px 7px
      border-radius 19px
      border solid 1px #dddddd
      background-color #fff
      font-size 16px
      line-height 1.38
      letter-spacing -0.2px
      color #4e4e4e
      height auto
      &[data-selected="true"]
        border solid 1px #1C75FF
        color #1C75FF
  &__list
    min-height 500px
    margin 0 0 30px
  &__list-head
    margin-bottom 12px
    display flex
    align-items center
    font-size 20px
    line-height 1.3
    letter-spacing -0.3px
    color #111111
  &__title
    margin-right 4px
    font-weight 600
.app-store-suspended
  display flex
  flex-direction column
  justify-content center
  align-items center
  text-align center
  min-height 700px
  img
    width 143px
  p
    width 100%
    font-weight 600
    margin 16px 0 0
    font-size 26px
    line-height 42px


.app-studio-suspended
  display flex
  flex-direction column
  align-items center
  justify-content center
  height 100%
  padding-bottom 200px
  &__image
    width 160px
  &__title
    text-align center
    /* KR-20/Bold */
    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 140% */
    letter-spacing: -0.2px !important
  &__description
    display flex
    flex-direction column

    color: var(--Gray-80, #6B6B6B);
    text-align: center;
    /* KR-12/Medium */
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px; /* 141.667% */
    letter-spacing: -0.2px !important;
  &__description-bottom
    color: var(--Gray-80, #6B6B6B);
    text-align: center;
    /* KR-12/Medium */
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px; /* 141.667% */
    letter-spacing: -0.2px !important;
