html.creator.crew body.crew-youtube .body .crew .crew-body
  display block
.plus-youtube
  &__header
    display flex
    align-items center
  &__over-1000
    margin-right 20px
    label
      display flex
      align-items center
  &__item
    a
      text-decoration underline
  &__logo
    width 40px
    img
      max-width 40px
      max-height 26px
  &__youtube-url
    display flex
    align-items center
    img
      width 44px
  &__title
    margin-left 8px
  &__bg
    width 26px
    height 26px
    background-color #fff
  &__name
    width 120px
    text-overflow ellipsis
    overflow hidden
    white-space nowrap
