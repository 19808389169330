body
  background-color #fff
  font-size 1rem
body.mshop > #body > .don_frame > .don_wrapper
  display flex
  flex-direction column

body.mshop .don_frame >.don_wrapper >.body
  order 2
body.mshop .don_frame[frame_index="0"] >.don_wrapper >.header, body .don_frame[frame_index="0"] >.don_wrapper >.header
  display block
  position static
  order 1

button#don_up
  position fixed
  right pxToRem(90px)
  bottom 22px
  width pxToRem(40px)
  height pxToRem(40px)
  box-shadow 0 4px 10px 0 rgba(0, 0, 0, 0.1)
  border 1px solid #f8f8f8
  border-radius 100%
  background-color #fff
  transform none
  opacity 0
  transition opacity .3s
  z-index 8
  &.show
    opacity 1

.creator-layout
  display flex
  flex-direction column
  justify-content space-between
  box-sizing border-box
  // default header 높이만큼 빼줌
  min-height "calc(100vh - %s)" % pxToRem(80px)
  background-color #fff
  &__inner
    box-sizing border-box
  &__column
    &--pd-left
      padding 0 0 0 24px
    &--pd-side
      padding 0 24px
  &__body
    position relative
    box-sizing border-box
    z-index 2
    width ptr(1440)
    margin: 0 auto;
  &__blind
    blind()
  &__up
    display none

@media screen and (max-width: 1023px)
  .creator-layout
    width 1023px
