.pb-guide-popup
  display flex
  height 700px
  &__frame.don_frame[is_modal="true"]
    >.don_wrapper
      width 896px
      height 700px
      >.header
        .don_hide_frame
          background-image url(//s3.marpple.co/files/u_2283830/2024/10/original/ac998dae9c58a3705a07832d3592450a6cf60c341.webp) !important
          background-size 24px !important
          top 22px !important
          right 22px !important
  &__left
    width 306px
    height 100%
    padding 60px 42px 42px
    display flex
    flex-direction column
    justify-content space-between
  &__left-title
    margin-bottom 60px
    display flex
    flex-direction column
    gap ptr(4)
  &__menus
    list-style none
    margin 0
    padding 0
  &__menu-item
    margin-bottom 33px
    padding-left 10px
    display flex
    align-items center
    button
      font-size 16px
      font-weight 500
      letter-spacing -0.5px
      color #a2a2a2 !important
      border 0
      background none
      padding 0
      margin-left 12px
    &:before
      content ""
      background-color $blue-100
      border-radius 1px
      width 2px
      height 16px
      visibility hidden
      position relative
    &--selected
      button
        color #3c89f9 !important
        font-weight 700 !important
      &:before
        visibility visible
  &__next-button,
  &__start-button
    display inline-flex
    padding 8px 24px
    align-items center
    justify-content center
    border-radius 999px
    background-color $blue-10
    color $blue-100 !important
    border none
  &__start-button
    background-color $blue-100
    color $white !important
    display none
  &__right
    position relative
    width 590px
    height 100%
  &__visuals
    height 100%
  &__visual
    height 100%
    display flex
    flex-direction column
    justify-content flex-end
    background #fff
    .pb-guide-popup__img
      height 100%
      background-repeat no-repeat
      background-size 590px 100%
    &[data-idx="0"]
      .pb-guide-popup__img
        background-image url(//s3.marpple.co/files/u_2283830/2024/10/original/f3cf17da292955befeb0d6d15f6eaa7f076e7cfa1.webp)
    &[data-idx="1"]
      .pb-guide-popup__img
        background-image url(//s3.marpple.co/files/u_2283830/2024/10/original/183cc630a7c99806125131ec8226dd5301909a4e2.webp)
    &[data-idx="2"]
      .pb-guide-popup__img
        background-image url(//s3.marpple.co/files/u_2283830/2024/10/original/58994fd570ea094180ffe2ac4b8d1863cf5501746.webp)
    &[data-idx="3"]
      .pb-guide-popup__img
        background-image url(//s3.marpple.co/files/u_2283830/2024/10/original/101e8df23b3e96f7e5ac24a6ba4255f1362a0af13.webp)
    &[data-idx="4"]
      .pb-guide-popup__img
        background-image url(//s3.marpple.co/files/u_2283830/2024/10/original/ad1f038832fe6d82ef78072b1d1e28a986fb89504.webp)
    &[data-idx="5"]
      .pb-guide-popup__img
        background-image url(//s3.marpple.co/files/u_2283830/2024/10/original/6aa0ba7681831689be4e52a6d44359aa14703f205.webp)
  &__info
    position absolute
    bottom 0
    left 0
    width 100%
    padding ptr(32) ptr(32) ptr(48)
    -webkit-backdrop-filter blur(30px)
    backdrop-filter blur(30px)
    background-color $dim-60
    display flex
    flex-direction column
    gap ptr(12)
    color $white !important

