/* 레거시 frame에 Rune페이지를 띄운 곳, 크리에이터 리뷰는 subHeader가 존재하고, Brandcrumbs는 없다.  */
.don_frame[frame_name="/modules/MShop/App/Store/Main/S/Mui/frame.js"] {
  .PhotoReviewPage {
    margin-top: $default-header-pc_height + $default-sub-header-pc_height;
  }

  .Breadcrumbs {
    display: none;
  }
}

.store-community
  min-height 700px
  &__seller
    margin 0 0 12px
  &__sub_title
    margin 0 0 16px
    font-size 20px
    font-weight 600
    line-height 1.3
    letter-spacing -0.3px
    color #111
  &__write
    position relative
    height 0

.store-articles-empty
  margin 80px 0 72px
  text-align center
  &__text
    font-size 18px
    line-height 1.33
    letter-spacing -0.2px
    text-align center
    color #a2a2a2
  &__write
    margin-top 40px

.store-articles-write
  &__btn
    position absolute
    top 7px
    right 0
    width 129px
    background none
    font-size 16px
    line-height 1.25
    letter-spacing -0.2px
    color #4e4e4e
    padding 9px 0 7px
    border-radius 20px
    border 1px solid #ddd
    span
      display inline-block
      padding-right 17px
      background url("//s3.marpple.co/files/u_218933/2021/5/original/f0e72f7b53b19500f3c83cf8776aa5fa86763c681.svg") no-repeat 100% 30%
      background-size 13px
    &--empty
      position static
      top auto
      right auto

.store-recent-articles
  position relative
  .community-item
    width 49.4%
    margin-bottom 0
    margin-right 0.8%
    &__photo_img
      position relative
      width 100%
      padding-top 100%
      overflow hidden
      background-size cover
      background-repeat no-repeat
      background-position center
      img
        position absolute
        top 0
        left 0
        right 0
        bottom 0
        width 100%
        height 100%
        max-width 100%
        max-height 100%
        vertical-align top
        object-fit cover
    &__body
      height pxToRem(46px)
      overflow hidden
      limitLine(2)
  .button
    reset-button()
    position absolute
    top 40%
    transform translateY(-50%)
    width pxToRem(24px)
    height pxToRem(56px)
    background-size 100%
    &--aspect-ratio2
      top 50%
    &.swiper-button-next
      left auto
      background-image: url(//s3.marpple.co/files/u_1396787/2021/5/original/406d203825fd5bc4bda171cddda029b823b579ae9.svg)
    &.swiper-button-prev
      background-image: url(//s3.marpple.co/files/u_1396787/2021/5/original/c26fedc55eb060c81598fc071be277d8031265cf8.svg)
    &.swiper-button-disabled
      display none !important

.seller-profile
  display flex
  align-items center
  &__img
    width 50px
    height 50px
    margin-right 12px
    img
      width 100%
      height 100%
      border-radius 16px
    &--empty img
      border 1px solid #ddd
  &__name
    margin-right 4px
    font-size 18px
    font-weight 600
    line-height 1.33
    letter-spacing -0.2px
    color #111
    &--detail
      font-size 16px
      line-height 1.25
  &__mps
    margin-bottom 2px
    img
      width 16px
      vertical-align top

.review-recent-list
  min-height 500px
  padding pxToRem(48px) 0 pxToRem(96px)

.empty-community-articles
.empty-reviews
  padding ptr(160) 0
  margin-bottom ptr(120)
  flexCenter(column)
  &__icon
    margin-bottom ptr(24)
    img
      width ptr(160)
      height ptr(160)
  &__title
    margin-bottom ptr(8)
    color $gray-80
  &__desc
    margin-bottom ptr(24)
    color $gray-50
    text-align center
  &__to-main
    min-width pxToRem(196px)

.store-community2
  box-sizing border-box
  padding: 0 0 ptr(90);
  &__seller-area
    position relative
    width 100%
  &__creator-profile
    margin-top ptr(48)
    margin-bottom ptr(96)
    min-height ptr(180)
  &__seller-profile
    margin-right pxToRem(64px)
    max-width pxToRem(420px)
    width 100%
    align-self flex-start
  &__seller-btns
    margin-top pxToRem(30px)
  &__seller-btn-write
    width 100%
    padding 13px 20px 11px
    font-size 16px
    line-height 1.25
    color #4e4e4e
    background #fff
    border-radius 22px
    border solid 1px #dddddd
    span
      display inline-block
      padding-left 5px
      padding-right 22px
      background url("//s3.marpple.co/files/u_218933/2021/5/original/f0e72f7b53b19500f3c83cf8776aa5fa86763c681.svg") no-repeat 100% 40%
      background-size 15px
  &__seller-article
    max-width pxToRem(796px)
    width 100%
  &__articles
    min-height pxToRem(400px)
    margin-top pxToRem(32px)
    padding-top pxToRem(32px)
  &__articles-header
    position relative
    margin-bottom pxToRem(24px)
  &__articles-buttons
    position absolute
    bottom 0
    right 0
  &__btn-write
    background none
    font-size 16px
    line-height 1.25
    letter-spacing -0.2px
    color #4e4e4e
    padding 9px 20px 7px 30px
    border-radius 20px
    border 1px solid #ddd
    span
      display inline-block
      padding-right 22px
      background url("//s3.marpple.co/files/u_218933/2021/5/original/f0e72f7b53b19500f3c83cf8776aa5fa86763c681.svg") no-repeat 100% 40%
      background-size 15px
