@import './gallery.styl'

.store-about
  display flex
  justify-content center
  margin-bottom 50px
  &__left
    top 20px
    position sticky
    margin-right 64px
    max-width 420px
    width 100%
    align-self flex-start
    margin-bottom 20px
  &__right
    max-width 796px
    width 100%
    min-height 600px

.about
  &__profile
    display flex
    justify-content flex-start
    align-items flex-start
    min-height pxToRem(140px)
  &__thumb
    position relative
    width pxToRem(126px)
    margin-right 18px
  &__thumb-img
    position relative
    width 100%
    padding-top 100%
    background-size cover
    background-repeat no-repeat
    background-position center
    border-radius pxToRem(48px)
  &__sns
    position absolute
    bottom -3px
    right -3px
    width pxToRem(32px)
    height pxToRem(32px)
    padding pxToRem(3px)
    background-color #fff
    border-radius 50%
    overflow hidden
    img
      width 100%
      border-radius 50%
      vertical-align top
  &__info
    position relative
    width 100%
  &__name
    margin 0 0 pxToRem(3px)
    font-size pxToRem(20px)
    font-weight 600
    line-height 1.3
    letter-spacing -0.3px
  &__link
    margin 0 0 pxToRem(16px)
    font-size pxToRem(16px)
    line-height 1.38
    letter-spacing -0.2px
    color #4e4e4e
  &__meta
    display flex
    align-items center
    > button
      display flex
      align-items center
      margin-right 8px
      padding 4px 14px
      border-radius 15px
      background-color #f8f8f8
      border none
      outline none
      &.active
        svg g g
          fill #ec1131
          stroke #ec1131
    .icon
      font-size 0
      line-height 1
      margin-right 5px
    .count, .text
      line-height 1.43
      letter-spacing -0.2px
      color #a2a2a2

  &__edit
    position absolute
    top 0
    right 0
  &__edit-btn
    width 100%
    padding 5px 10px 4px
    font-size 12px
    line-height 1.25
    color #4e4e4e
    background #fff
    border-radius 12px
    border solid 1px #dddddd
  &__description
    margin-top 20px
    font-size 18px
    line-height 1.56
    letter-spacing -0.2px
    color #4e4e4e
    max-height pxToRem(280px)
    limitLine(9)
  &__represent
    margin-top pxToRem(32px)
    img
      width 100%
  &__video
    width 100%
    height 240px
  &__button-area
    margin-top 40px
  &__btn-write
    width 100%
    padding 13px 20px 11px
    font-size 16px
    line-height 1.25
    color #4e4e4e
    background #fff
    border-radius 22px
    border solid 1px #dddddd
