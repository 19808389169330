.app-home-styles
  &__button-box
    displayFlex center center
    margin-top 41px
    width 100%
  &__button
    displayFlex center center
    position relative
    max-width pxToRem(407px)
    width 100%
    height pxToRem(52px)
    border-radius pxToRem(26px)
    background-color #111
    font pxToRem(18px) pxToRem(24px) -0.2px
    color #fff
  &__bubble
    position absolute
    top pxToRem(-13px)
    right 0
    width pxToRem(175px)
    height pxToRem(25px)
    img
      width 100%
      vertical-align top

.app-home-styles-tab
  display block
  &__header
    overflow hidden
  &__header-contents
    displayFlex center flex-start
  &__header-image-box
    position relative
    box-sizing border-box
    margin-bottom pxToRem(9px)
    width pxToRem(92px)
    height pxToRem(92px)
    border 3px solid transparent
    border-radius 100%
    overflow hidden
    &--is-active
      border 3px solid #000
      & ~ .app-home-styles-tab__header-text
        font-weight 600
  &__header-image
    position absolute
    top 0
    left 0
    width 100%
    vertical-align top
    border 3px solid #fff
    border-radius 100%
    box-sizing border-box
  &__header-button
    reset-button()
    margin-left pxToRem(6px)
    width pxToRem(92px)
    display block
    text-align center
  &__header-text
    font pxToRem(16px) pxToRem(20px) -0.2px
    word-break: break-word;
    limitLine(1)
  &__body
    padding-top 24px
  &__body-inner
    position relative
    min-height pxToRem(396px)
  &__contents
    position absolute
    top 0
    left 0
    width 100%
    visibility hidden
    opacity 0.4
    display grid
    grid-gap 7px
    grid-template-columns repeat(6, 1fr)
    grid-template-rows repeat(2, auto)
    transition opacity linear .3s
    &--is-active
      visibility visible
      opacity 1
  &__anchor
    display block
    &:first-child
      grid-column span 2
      grid-row span 2
  &__image
    width 100%
    vertical-align top
