.youtube-bridge-page.youtube-shopping
  & *
  & *::after
  & *::before
    box-sizing border-box
  pxToVw(value)
    unit(value/1920*100, 'vw')
  .layout-split
    display flex
    align-items center
    flex-direction row
    box-sizing border-box
    gap 0
    .left
      display flex
      justify-content flex-end
      align-items center
      box-sizing border-box
      width 50%
      .left-content
        margin-right pxToRem(80)
        position relative
    .right
      display flex
      justify-content flex-start
      align-items center
      box-sizing border-box
      width 50%

  .youtube-bridge-page__section
    .section-desc
      box-sizing border-box
      font-size pxToRem(16)
      line-height 1.5
      font-weight 400
      display flex
      flex-direction column
      gap pxToRem(20)
      text-align left
      color #fff
      .highlight
        font-size pxToRem(40)
        line-height 1.4
        font-weight 700
        color #fff

  .main-03
    &.layout-split
      .left
        margin-bottom pxToRem(-108)
      .right
        margin-bottom pxToRem(-8)
    .marpple-mo-mock-wrap
      position relative
      display flex
      justify-content center
      .youtube-logo-wrap
        position absolute
        z-index 2
        top pxToRem(26)
        left pxToVw(-80)
        width pxToVw(160)
        max-width pxToRem(160)
        max-height pxToRem(160)
        border-radius pxToRem(4)
        aspect-ratio 1 / 1
        overflow hidden
        svg
          width 100%
          height 100%
      .marpple-mo-mock
        position relative
        z-index 1
        width pxToVw(272)
        max-width pxToRem(272)
        box-sizing border-box
        aspect-ratio 272 / 560
        display flex
        justify-content center
        align-items center
        padding pxToRem(12)
        .marpple-mo-mock-frame
          position absolute
          z-index 2
          width 100%
          height 100%
          left 0
          top 0
        .marpple-mo-mock-frame-background
          flex 1 0 pxToRem(268)
          width 100%
          height 100%
          left 0
          top 0
          position absolute
          z-index 0
          box-shadow: 0 pxToRem(20) pxToRem(40) 0 rgba(0, 0, 0, 0.20)
          box-sizing border-box
        video
          flex 1 0 pxToRem(268)
          aspect-ratio 246 / 534
          object-fit contain
          width 100%
          height 100%
          box-sizing border-box
          border-radius pxToRem(24)
          position relative
          z-index 1

      .marquee-background-wrap
        display flex
        align-items center
        overflow-x hidden
        position absolute
        z-index 0
        bottom pxToRem(38)
        width pxToVw(1126)
        max-width pxToRem(1126)
        max-height pxToRem(200)
        aspect-ratio 1126 / 200
        gap pxToRem(14)
        box-shadow pxToRem(-3) pxToRem(4) pxToRem(10) 0 rgba(0, 0, 0, 0.10)
        .gradient-marquee
          width 100%
          height 100%
          background: linear-gradient(90deg, #111 0.03%, rgba(217, 217, 217, 0) 21.37%, rgba(217, 217, 217, 0) 78.63%, #111 99.97%);
          position absolute
          z-index 1
        .marquee-background
        .marquee-background[aria-hidden="true"]
          --gap pxToRem(14)
          max-height pxToRem(100)
          padding-bottom 0
          margin 0
          img
            width pxToVw(100)
            max-width pxToRem(100)
            max-height pxToRem(100)
            aspect-ratio 1 / 1
            object-fit contain

  .main-05.layout-split
    img
      aspect-ratio 1
    .center
      width pxToVw(420)
      max-width pxToRem(420)
    .left-top
      width pxToVw(280)
      max-width pxToRem(280)
      position absolute
      top pxToVw(-140)
      left pxToVw(-140)
      z-index 1
    .left-bottom
      width pxToVw(164)
      max-width pxToRem(164)
      position absolute
      left pxToVw(-204)
      bottom pxToVw(-47)
      z-index 1

  .main-07
    gap pxToRem(120)
    .parallel-marquee-wrapper
      display flex
      flex-direction column
      gap pxToRem(20)
      width 100%
      .marquee-left-wrap
      .marquee-right-wrap
        display flex
        align-items center
        overflow-x hidden
        //width 100%
        gap pxToRem(20)
        .marquee-background
        .marquee-background[aria-hidden="true"]
          --gap pxToRem(20)
          max-height pxToRem(240)
          padding-bottom 0
          margin 0
          min-width auto
          height auto
          justify-content inherit
          &.revers
            animation marquee-right 50s linear infinite
          img
            width pxToVw(240)
            max-width pxToRem(240)
            max-height pxToRem(240)
            aspect-ratio 1 / 1
            object-fit contain
            border-radius pxToRem(34)

  .youtube-bridge-page__footer.youtube-shopping
    .more
      height 100vh
      justify-content inherit
      .gradation-over
        width 100%
        height 100%
        background rgba(17, 17, 17, 0.70)
        position absolute
        z-index 1
      img
        width 20%
        height 100%
      .footer-desc
        gap pxToRem(60)
        font-size pxToRem(28)
        line-height 1.2857
        font-weight 400
        & > div
          display flex
          flex-direction column
          gap pxToRem(8)
        & > div > div:last-child
          font-size pxToRem(36)
          line-height 1.333
          font-weight 600
        .youtube-bridge-page__footer-more-btn
          font-size pxToRem(20)
          line-height 1.71419
          font-weight 400
          padding pxToRem(20) pxToRem(60)
          border-radius pxToRem(80)
          box-shadow 0 0 pxToRem(40) 0 rgba(0, 0, 0, 0.40);
@keyframes marquee-right {
  from { transform: translateX(-100%); }
  to { transform: translateX(0); }
}
