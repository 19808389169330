@import '../../../S/Style/guide.styl';

$review-img-width = ptr(86);
$color-chip-width = ptr(48);
$color-chip-gap = ptr(8);
$gap-24 = ptr(24);


html[can_selling="false"].product-detail
  .delivery-notification
  button[data-goto="cart"]
  button[data-goto="checkout"]
  div[data-item_name="period_limit"]
  div[data-item_name="quantity_limit"]
    display none !important

body.product-detail
  .store-body
    #product_detail[is_selling_product="false"]
      html[is_seller="false"] &
        .delivery-notification
        button[data-goto="checkout"]
        button[data-goto="cart"]
          display none

#t_shirts_sizes
  margin-top 10px

#pd-review
  .pd-review-more
    text-align center
    padding 9px 10px 8px
    cursor pointer
    color #a2a2a2
    width 440px
    margin 0 auto
    border-radius 22px
    border solid 1px #dddddd
    background-color #ffffff

#product_detail
  margin-top ptr(72)
  padding-top ptr(32)

.pd
  width 100%
  margin 0 auto
  padding 0 0 80px
  position relative
  common()
  d-flex-row()
  .swiper
    &-slide
      font-size 0
    &-container[data-len="1"]
      .swiper-pagination,
      .swiper-button-next,
      .swiper-button-prev
        display none !important
    &-button-next.custom
      right ptr(17)
    &-button-prev.custom
      left ptr(17)
    &-button-next.custom,
    &-button-prev.custom
      background-image none
      width ptr(60)
      height ptr(60)
    &-pagination
      width auto
      left auto
      bottom auto
      top 20px
      right 20px
      font-size 16px
      font-weight 200
      line-height 1.13
      color #ffffff
      padding 6px 12px 2px
      border-radius 13px
      background-color rgba(17, 17, 17, 0.3)

  //&__pb_options
  //  @import '../../../../Product/PbOption/F/Style/index.styl'
  &__max-purchase-per-user
    display flex
    align-items center
    gap ptr(6)
    margin-top ptr(24)
    padding ptr(16)
    border-radius ptr(8)
    {$font-body-b3-r}
    background-color $red-200
    color $red-700
  &__hyundai_n
    position relative
  &__dtg-info
    display flex
    flex-direction column
    gap ptr(16)
    & > .info-wrap
      display flex
      gap ptr(10)
    & .title
      min-width ptr(80)
      {$kr-14-m}
      color $gray-50
    & .contents
      display flex
      flex-direction column
    & .content
      $flex(flex-start, center)
      gap ptr(8)
      {$kr-14-m}
      color $black
      & > .file-type-msg-divider
        width 1px
        height ptr(12)
        background-color #dddddd
        display inline-block
      & > .flex-shrink-zero
        flex-shrink 0
      &__description
        {$kr-14-m}
        color $gray-80
        margin-top ptr(8)
        word-break keep-all
    & .dtg-disabled
      $flex(flex-start, flex-start)
      gap ptr(4)
      color $gray-80
      & > .pd-submit__disabled-text
        {$kr-14-m}
      & > svg
        flex-shrink 0
        width ptr(20)
// 상품상세 컨텐츠 레이아웃 스타일
.pd-contents
  max-width ptr(912)
  &__preview
    margin-bottom ptr(32)
  &__popup
    margin-top 30px
    display flex
    img
      width 100%
  &__tabs
    position sticky
    top ptr(72)
    margin 0 0 ptr(24)
    z-index 2
    background $white
  &__group
    margin-bottom ptr(72)
  //&__tabs-contents
  //  > div
  //    display none
  //  &[current-tab="story"] [tab_name="story"]
  //  &[current-tab="review"] [tab_name="review"]
  //  &[current-tab="bp"] [tab_name="bp"]
  //  &[current-tab="purchaseInfo"] [tab_name="purchaseInfo"]
  //  &[current-tab="faq"] [tab_name="faq"]
  //  &[current-tab="pb_faq"] [tab_name="pb_faq"]
  //  &[current-tab="pb_product_info"] [tab_name="pb_product_info"]
  //  &[current-tab="pb_purchaseInfo"] [tab_name="pb_purchaseInfo"]
  //    display block

.pd-preview
  position relative
  display flex
  gap ptr(16)
  &__thumbs
    display flex
    flex-direction column
    justify-content flex-start
    align-items center
    flex-shrink 0
    gap ptr(8)
    .mp-product-img-bg-wrap
      width ptr(100)
      height ptr(100)
      aspect-ratio 1/1
      border-radius pxToRem(8px)
      overflow hidden
  &__thumbs-btn
    padding 0
    outline none
    border-radius pxToRem(8px)
    border none
    background transparent
    &.active
      outline 1px solid $blue
  &__swiper
    position relative
    width ptr(800)
    aspect-ratio 1 / 1
    .mp-product-img-bg-wrap
      border-radius ptr(16)
      overflow hidden
    .mp-product-img
      object-fit contain
      width 100% !important
      height 100% !important
  &__badges
    $flex(center, center)
    gap ptr(2)
    position absolute
    top ptr(16)
    left ptr(16)
    z-index 10
    .badge
      $flex(center, center)
      padding ptr(4) ptr(8) ptr(5)
      border-radius ptr(4)
      {$en-14-unica-r}
      &__limit
        color $white
        background $blue
      &__sold_out
        color $white
        background $gray-50

.pd-tabs
  d-flex-row(space-between, stretch)
  border-bottom 1px solid $gray-3
  gap ptr(16)
  &__item
    flex 1
    padding ptr(11) 0 ptr(10)
    margin-bottom -1px
    {$font-heading-h5-sb}
    color $gray-s4
    text-align center
    cursor pointer
    box-sizing border-box
    &.active
      border-bottom 2px solid $gray-1
    &.active, &:hover
      color $gray-1

.pd-info-group
  position relative
  max-height ptr(2400)
  padding-bottom ptr(70)
  overflow hidden
  &.open
    max-height none
    .pd-info-group__hide
      height auto
  &__hide
    position absolute
    bottom 0
    left 0
    right 0
    $flex(stretch, flex-end)
    height ptr(180)
    background linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%)
    z-index 1
    opacity 1
    transition opacity 0.5s
    &.hide
      opacity 0
    &.none
      display none
  &__btn
    {$kr-16-b}
    width 100%
    padding ptr(16) ptr(24)
    color $gray-80
    border-radius ptr(8)
    border: 1px solid $gray-20
    background $white
    box-sizing border-box

.pd-links
  d-flex-row(center, center)
  margin ptr(16) 0 ptr(24)
  &__item
    d-flex-row(center, center)
    width ptr(240)
    height ptr(48)
    {$kr-14-b}
    text-align center
    white-space nowrap
    border-radius ptr(8)
    border 1px solid $gray-20
    cursor pointer
    + .pd-links__item
      margin-left ptr(8)

.pd-story
  .product-story-image
    display flex
  &__section
    display flex
    flex-direction column
    margin 0 0 ptr(32)
  &__title
    {$kr-16-b}
    margin 0 0 ptr(8)
  &__desc
    {$kr-14-m}
    word-break break-all
    margin 0 0 ptr(8)
  &__section.images
    img
      width 100%
  &__section.sns
    > blockquote
      max-width calc(100%) !important
    iframe:not(.instagram-media)
      width 100%
      aspect-ratio 16 / 9
      min-height 360px
    iframe.instagram-media
      max-width calc(100%) !important
    iframe.instagram-media:not(.instagram-media-rendered)
      border none !important
    .fb_iframe_widget
      display block
      span, video, iframe
        width 100% !important
        max-width 100% !important

.pd-bp
  max-width 100%
  {$kr-14-m}
  &__section
    &-header
      margin ptr(32) 0 ptr(8)
      .tit
        {$kr-16-b}
    img
      max-width 100%
    ul
      unordered-list()
    .table-wrapper
      overflow-x auto
    table
      width 100%
      margin ptr(32) 0 ptr(32)
      table-layout fixed
      border-collapse collapse
      th, td
        font-weight normal
        height ptr(46)
        vertical-align middle
        text-align center
        border-bottom 1px solid $gray-5
      th
        background $gray-5
      td
        color #4e4e4e
  &__section.thumbs
    margin-bottom ptr(32)
    .swiper-wrapper
      $flex(flex-start, flex-start)
      gap ptr(8)
    .swiper-slide
      flex-shrink 0
      width fit-content
      height ptr(400)
      img
        height 100% !important
        width auto !important
  &__section.size_compare
    margin-bottom ptr(32)
    .swiper-wrapper
      $flex(flex-start, flex-start)
      gap ptr(8)
    .swiper-slide
      flex-shrink 0
      width fit-content
      img
        height ptr(400) !important
        width auto !important
      .txt
        {$kr-12-m}
      .txt + .txt
        {$kr-10-r}
  &__swiper
    transition opacity 0.5s
    .swiper-scrollbar
      position static
      margin-top ptr(12)
      width 100%
      height ptr(2)
      border-radius 0
      .swiper-scrollbar-drag
        border-radius 0
  &__info
    p, ul
      margin 0 0 ptr(8)
    > p
      {$kr-14-m}
      color $black
    > p strong
      {$kr-16-b}
      display block
      margin ptr(32) 0 ptr(8)
  &__size-info
    .size_info_url
      margin 0 0 20px
    img
      single-image()

.pd-purchase-info
  &__title
    {$kr-20-b}
    margin 0 0 ptr(8)
  &__header
    $flex(space-between, center)
    padding ptr(16) 0
    cursor pointer
    h4
      {$kr-16-b}
      margin 0
    .arrow
      width ptr(16)
      height ptr(16)
      transform rotate(0deg)
      font-size 0
  &__section.active .pd-purchase-info__header .arrow
    transform rotate(180deg)
  &__body
    height 0
    overflow hidden
    transition height 0.3s
    &--inner
      position relative
      bottom 0
      padding-bottom ptr(16)
  &__divider
    height 1px
    background $gray-10
  &__body
    &__desc
      margin-bottom ptr(16)
      {$kr-14-m}
      p
        margin 0
        word-break keep-all
      p span
        {$kr-14-b}
        word-break keep-all
      h4
        margin ptr(20) 0 ptr(12)
      ul
        padding-left ptr(20)
        margin 0
  &__table
    th
      min-width ptr(112)
      color $gray-50
    th, td
      {$kr-14-m}
      height ptr(22)
      text-align left
      vertical-align top
      padding ptr(4) 0
  &__anchor
    $flex(flex-start, flex-start, column)
    gap ptr(8)
    margin-top ptr(16)
    a
      d-flex-row(flex-start, center)
      gap ptr(4)
      {$kr-14-m}
      color $gray-80
      cursor pointer
      svg:first-child
        width ptr(22)
      svg:last-child
        width ptr(12)
    a.product_report_btn
      svg:first-child
        width ptr(16)
  &__li
    {$kr-14-m}
    display flex
    color $black
    margin 0
    &:before
      content ''
      display block
      width 2px
      height 2px
      background-color #4e4e4e
      border 100%
      margin 8px 8px 0 0
      flex-shrink 0

.pb-options
  @import '../../../../Product/PbOption/F/Style/index.styl'
  &__label
    // todo
    {$font-en-b-14--120}
    color $gray-1
    margin-bottom ptr(8)
  &__options
    $flex(flex-start, stretch, column)
    gap ptr(10)

.pd-pb_info
  $flex(flex-start, stretch, column)
  gap ptr(32)
  &__title
    margin 0 0 ptr(8)
    {$kr-16-b}
    color $black
  &__body
    {$kr-14-m}
    color $black

.kc-cert
  $flex(flex-start, stretch, column)
  gap ptr(8)
  &__body
    $flex(flex-start, center)
    gap ptr(8)
  &__mark
    width ptr(48)
  &__info-box
    $flex(flex-start, stretch, column)
    gap ptr(4)
  &__info-f
    $flex(flex-start, center)
    gap ptr(8)
  &__info-no
    {$en-14-unica-b}
  &__info-link
    {$kr-12-m}
    color $gray-80
    a
      $flex(flex-start, center)
      gap ptr(4)
    svg
      width ptr(10)
  &__info-s
    {$kr-12-m}
    color $gray-50

.pd-faq
  d-flex(stretch, flex-start)
  gap ptr(40)
  &__divide
    width 100%
    height 1px
    background $gray-5
  &__section
    width 100%
  &__title
    {$font-heading-h4-b}
    margin 0 0 ptr(12)
  &__list
    $flex(flex-start, flex-start, column)
    padding 0
    margin 0
  &__qna
    list-style-type none
    word-break keep-all
    width 100%
    &-q
      display flex
      justify-content flex-start
      align-items center
      {$font-body-b1-r}
      color $gray-1
      height ptr(54)
      position relative
      cursor pointer
      box-sizing border-box
    .arrow
      position absolute
      top 50%
      right 0
      width ptr(16)
      height ptr(16)
      transform translateY(-50%) rotate(0deg)
      font-size 0
    &-a
      {$font-body-b3-r}
      color $gray-2
      height 0
      overflow hidden
      transition height 0.3s
      &--inner
        position relative
        bottom 0
        padding-bottom ptr(16)
      img
        max-width 100%
    &.active
      .pd-faq__qna-q
        {$font-body-b1-b}
        color $black
        .arrow
          transform translateY(-50%) rotate(180deg)

.pd-counter
  $flex(space-between, center)
  width ptr(114)
  height ptr(44)
  padding ptr(14) ptr(12) ptr(15) ptr(12)
  border-radius ptr(8)
  background $gray-5
  &__btn
    $flex(center, center)
    width ptr(30)
    height ptr(30)
    padding 0
    control-button()
    background transparent
    &:disabled
      cursor unset
      color $gray-20
      pointer-events none
    svg
      width ptr(12)
  &__current
    $flex(center, center)
    width ptr(42)
    {$en-16-unica-m}
    text-align center
    border none
    outline none
    background transparent
    color $black
    &:disabled
      cursor unset
      color $gray-20

.pd-guide
  d-flex-row(flex-start, flex-start)
  padding-top ptr(12)
  margin-bottom ptr(4)
  &__icon
    margin-right ptr(4)
    width ptr(20)
    color $gray-3
  &__left-title
    $flex(flex-start, center)
    gap ptr(4)
    {$kr-14-m}
    min-width ptr(80)
    color $gray-50
    word-break keep-all
  &__anchor
    display none
  &.ruler
    position absolute
    top 0
    right 0
    d-flex-row(flex-start, center)
    margin-bottom 0
    .pd-guide__text
      color $gray-3
      cursor pointer
  &__start-end
    color $gray-1
    font-weight normal
  &__desc
    display flex
    align-items center
    flex-wrap wrap
    {$kr-14-m}
    &--wait
      margin-top ptr(8)
      {$kr-12-m}
      color $gray-80
    &.make_date
      padding-left ptr(16)
  &__desc2
    {$kr-12-m}
    color $gray-2
    text-align left
  &__start-end-strong
    {$en-14-unica-b}
    color $blue
  &__delivery-company
    color $black
  &__table
    border-collapse collapse
    tr + tr
      th, td
        padding-top ptr(16)
    th, td
      vertical-align top
      {$kr-14-m}
      > div
        word-break keep-all
    th + td
      padding-left ptr(16)
    th
      $flex(flex-start, center)
      gap ptr(4)
      text-align left
      width ptr(80)
      color $gray-50
      svg
        width ptr(16)
    td
      p
        $flex(flex-start, center)
        margin 0
      p + p
        margin-top ptr(8)

.pd-total
  gap ptr(16)
  width 100%
  border-top 1px solid $black
  padding-top ptr(8)
  d-flex-row(space-between, center)
  &.hide
    display none
  &__count
    {$kr-14-b}
  &__price
    {$en-24-unica-b}
    color $black
    &::after
      {$kr-24-b}

.pd-submit
  &__btns
    display flex
    gap ptr(16)
    &--hide
      display none
  &__disabled
    display flex
    gap ptr(16)
    height ptr(20)
    margin-top ptr(16)
    & > .pd-submit__disabled-text
      font-size ptr(13)
      color $gray-80
    & > img
      display block
      width ptr(15)
      height ptr(15)
      margin-top 1px
  &__submit_btn_wrapper
    position relative
    width 100%
  &__btn
    display flex
    justify-content center
    align-items center
    height ptr(56)
    width 100%
    {$kr-16-b}
    line-height normal
    outline none
    border none
    border-radius ptr(8)
    box-sizing border-box
    &:disabled
      color $white !important
      cursor default !important
      background $gray-20 !important
      border solid 1px $gray-20 !important
    &--cart
      border solid 1px $gray-20
      background #fff
      color $black
    &--membership-signup
      color $white
      background-color $black
      cursor pointer
    svg
      margin-right 8px

  .pd-noti
    $flex(center, center)
    color $white
    &__label
      $flex(center, center)
      gap ptr(4)
      {$kr-16-b}
      white-space nowrap
      svg
        width ptr(20)
.pd-noti-container
  position absolute
  top 105%
  left 50%
  transform translateX(-50%)
  &__triangle
    position absolute
    bottom 100%
    left 50%
    width ptr(13)
    font-size 0
    transform translateX(-50%)
  .pd-noti
    position relative
    $flex(center, center)
    padding ptr(16)
    height ptr(36)
    color $red-80
    border-radius ptr(999)
    background #FFE4FF
    &__label
      $flex(center, center)
      gap ptr(4)
      {$kr-14-m}
      white-space nowrap
      svg
        width ptr(16)

.pd-form
  width ptr(448)
  padding-left ptr(16)
  margin-left ptr(16)
  &__sticky
    $flex(flex-start, stretch, column)
    gap ptr(32)
  &__options
    position relative
    d-flex(flex-start, stretch)
    margin 0
    gap ptr(16)
  & .preview
    margin-top ptr(16)
    &__title
      {$kr-14-m}
      margin-bottom ptr(8)
    &__player
      display none
    &__custom-player
      $flex(space-between, center)
      gap ptr(12)
      height ptr(64)
      padding ptr(16)
      border-radius ptr(8)
      border solid 1px $gray-10
      box-sizing border-box
    &__play-buttons
      background-color $light-blue
      border-radius 50%
      width ptr(32)
      height ptr(32)
      display flex
      justify-content center
      align-items center
      padding 0
      cursor pointer
    &__play-button
      border none
      outline none
      margin 0
      padding 0
      background transparent
      width ptr(32)
      color $blue
      font-size 0
      svg
        width ptr(20)
      .play-icon
        display none
      .pause-icon
        display block
      &.pause
        .play-icon
          display block
        .pause-icon
          display none
    &__progress-container
      height ptr(20)
      flex 1
      display flex
      align-items center
      width 100%
    &__progress-background
      border-radius ptr(4)
      background-color $gray-5
      height ptr(4)
      width 100%
      position relative
    &__progress
      position absolute
      top 0
      left 0
      background-color $ss-sky
      height ptr(4)
      border-radius ptr(4)
    &__time-container
      -ms-user-select none
      -moz-user-select -moz-none
      -khtml-user-select none
      -webkit-user-select none
      user-select none
    &__time
      {$en-14-unica-m}
      color $gray-80
  &__pb-multi-options
    display none
  .tooltip-icon
    font-size 0
    line-height 0
    color $gray-50
    &[aria-expanded="true"]
      color $blue
    & > svg
      width ptr(16)

.pd-product
  $flex(flex-start, stretch, column)
  gap ptr(16)
  &__seller
    $flex(space-between, flex-start)
    gap ptr(32)
    padding ptr(8) 0
    margin 0
  &__action
    margin-top ptr(3)
    $flex(flex-end, center)
    flex-shrink 0
    gap ptr(20)
    button
      padding 0
      margin 0
      outline none
      border none
      background transparent
      width ptr(24)
      height ptr(24)
    button.like
      color $gray-20
    button.like.active
      color $blue
  &__title_area
    $flex(flex-start, stretch, column)
    gap ptr(8)
  &__badges
    $flex(flex-start, center)
    gap ptr(4)
  &__product_type
    $flex(center, center)
    {$kr-14-m}
    width fit-content
    height ptr(24)
    padding 0 ptr(8)
    color $purple
    background $purple-10
    border-radius ptr(4)
  &__goods_type
    $flex(center, center)
    {$kr-14-m}
    width fit-content
    height ptr(24)
    padding 0 ptr(8)
    border-radius ptr(4)
    &.mps
      color $blue
      background $light-blue
    &.pbg
      color $green
      background $green-10
    &.tpl
      color $green
      background $green-10
    &.dtg
      color $brown
      background $brown-10
  &__name
    {$kr-28-m}
    margin 0
  &__bp-name
    {$kr-16-m}
    color: $gray-80
    margin 0
  &__price
    d-flex-row(flex-start, center)
    {$en-24-unica-b}
    margin 0
    &:after
      {$kr-24-b}
  &__like
    $flex(center, center, column)
    {$kr-12-m}
    color: $gray-20
    &--active
      color: $blue
  &__discount-rate
    {$font-en-b-24--100}
    color $blue-1s
    transform translateY(3px)
  &__price-number
    {$font-en-b-24--100}
    color $gray-1
    &::after
      {$font-kr-b-24--140}
  &__price-number--deprecated
    {$font-en-b-20--100}
    color $gray-3
    & > span
      text-decoration line-through
    &::after
      display inline-block
      {$font-kr-b-20--140}
      text-decoration none !important
      margin-left -2px
  &__review
    $flex(flex-start, center)
    gap ptr(8)
    &__star
      .article-review-score .star
        width ptr(20)
        height ptr(20)
        background-size ptr(20)
    &__count
      {$kr-14-m}
      color $gray-50
      height ptr(22)
      cursor pointer
      border-bottom 1px solid $gray-50
  &__ebook-writer
    color $black

.pd-seller
  $flex(flex-start, flex-start)
  gap ptr(4)
  &__name
    gap ptr(8)
    {$kr-20-b}
    color $black
    margin 0
    svg
      margin-bottom ptr(-3)
      width ptr(20)


.pd-field
  position relative
  padding 0
  border none
  &__label
    {$kr-14-b}
    margin-bottom ptr(16)
    &.count_label
      margin-bottom unset
  &.counter
    display flex
    justify-content space-between
    align-items center
    .pd-field__label
      margin-bottom ptr(0)
  &__max_count
    margin-top ptr(24)
    $flex(flex-start, flex-start)
    gap ptr(8)
    color $blue
  &__max_count_icon
    svg
      width ptr(20)
  &__max_count_text
    $flex(flex-start, stretch, column)
    {$kr-14-m}
  &.colors
    .expand-btn
      display none
      .pd-radio__face
        width ptr(36)
        height ptr(36)
        display block
        margin 0 auto
        padding 0
        background transparent
        border-radius 50%
        border ptr(2) solid $white
        box-sizing border-box
        &:hover
          border ptr(2) solid $blue
        .pd-radio__face--color
          width ptr(32)
          height ptr(32)
          display block
          border-radius 50%
          border ptr(2) solid #fff
          box-sizing border-box
          background conic-gradient(from 180deg at 50% 50%, #0C6BFF 0deg, #009618 91.79999828338623deg, #FCD042 185.3999948501587deg, #FF4646 271.79999828338623deg, #C900C1 360deg)
    &.fold
      .pd-radio:nth-child(n+16)
        display none
      .expand-btn
        display block !important
    .dynamic-html
      position relative
  &__count-price
    $flex(center, center, column)
    &.hide
      display none
  &__pd-counter-wrap
    width 100%
    padding-bottom ptr(8)
    $flex(space-between, center)


.pd-select
  padding 14px 20px
  border-radius 4px
  border solid 1px #a2a2a2
  width 100%
  font-size 15px
  line-height 1.2
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background #fff
  background-image url("//s3.marpple.co/files/u_1504988/2023/11/original/b547f5b1bd76b8b1b4494fb315b603e23bf6ff1e1.png")
  background-repeat no-repeat
  background-position calc(100% - 20px) 50%

.pd-radio-group
  &.color
    display grid
    grid-column 8
    grid-template-columns repeat(8, minmax(0, 1fr))
    gap ptr(12) ptr(4)
    //grid-template-columns: 'repeat(auto-fill, minmax(%s, auto))' % ($color-chip-width);
    //gap $color-chip-gap
  &.value
    d-flex-row(flex-start, flex-start)
    flex-wrap wrap
    gap ptr(8)

.pd-radio
  cursor pointer
  input[type="radio"]
    position fixed
    width 0
    height 0
    margin 0
    padding 0
    opacity 0
    z-index -1
  &:hover:not(.disabled):not(.color) .pd-radio__face
  [type="radio"]:checked + .pd-radio__face
    text-shadow 0 0 0 #000
    box-shadow inset 0 0 0 2px $black
  &__face:not(.color)
    control-button()
    display block
    padding 0
  &.color
    $flex(space-between, center, column)
    &:hover .pd-radio__face,
    [type="radio"]:checked + .pd-radio__face
      border-color inherit !important
    &[style="border-color: #ffffff"]
    &[style="background: #fefefe"]
      border-color #eee !important
    .pd-radio__face
      width ptr(36)
      height ptr(36)
      display block
      margin 0 auto
      padding 0
      background transparent
      border-radius 50%
      border ptr(2) solid $white
      box-sizing border-box
    .pd-radio__face--color
      width ptr(32)
      height ptr(32)
      display block
      border-radius 50%
      border ptr(2) solid #fff
      box-sizing border-box
      &[style="background: #ffffff"]
      &[style="background: #fefefe"]
        background linear-gradient(180deg, rgba(248,248,248,1) 0%, rgba(240,240,240,1) 100%) !important
  &.value
    .pd-radio__face
      d-flex(center, center)
      padding ptr(12)
      height ptr(44)
      min-width ptr(55)
      {$en-16-unica-m}
      color $black
      background $gray-5
      box-sizing border-box
      border-radius ptr(6)
    input[disabled] + .pd-radio__face
      color $gray-20 !important
      box-shadow none !important
    input:not([disabled]):checked + .pd-radio__face
      color $white !important
      background $black
  &__text
    ellipsis()
    display block
    margin 4px 0 0
    {$kr-12-m}
    text-align center
    color $gray-50

.pd-package
  margin-bottom ptr(20)

.spo_custom_options,
.pb-options
  &__label
    display none
  &__options
    $flex(flex-start, stretch, column)
    gap ptr(16)
  select.spo_custom_option,
  select.spo_option
    position relative
    appearance none
    display block
    width 100%
    padding ptr(12)
    padding-right ptr(40)
    margin 0
    {$kr-16-m}
    color $black
    border-radius ptr(8)
    border 1px solid $gray-20
    outline none
    cursor pointer
    background-image url(//s3.marpple.co/files/u_1504988/2024/6/original/239213dad3ed50f191a011e791e05ab8eb55f73e1.png)
    background-repeat no-repeat
    background-position calc(100% - 16px) center
    background-size ptr(16)
    background-color #fff
    text-overflow: ellipsis
    white-space nowrap
    option
      color $black
    option:disabled
      color $gray-50
    &[data-is_default_selected="true"]
      color $gray-50
    &:disabled
      color $gray-50
      background-image url(//s3.marpple.co/files/u_1504988/2024/6/original/dc0940e58653c545b45473b3e6f550f730879e6c1.png)
      background-color $gray-5 !important
  .spo_text_option_wrap
    display flex
    align-items center
    column-gap ptr(16)
    padding-right ptr(16)
    border-bottom 1px solid $gray-20
    &.disabled
      background-color $gray-5
    &:last-of-type
      margin-bottom ptr(8)
    &.active
      border-bottom 1px solid $blue-100
  input.spo_text_option
    appearance none
    display block
    width 100%
    height auto
    padding ptr(12) 0
    margin 0
    {$kr-16-m}
    border-radius 0
    border none
    outline none
    cursor pointer
    box-sizing border-box
    &::placeholder
      color $gray-50
    &:disabled
      color $gray-50
      background $gray-5

.pd-best-seller
  margin-bottom ptr(120)
  &__heading
    h3
      display flex
      justify-content flex-start
      align-items center
      margin 0 0 ptr(8)
      color $gray-1
      {$font-en-b-24--100}
      svg
        width ptr(20)
        margin-left ptr(8)
    p
      margin 0
      {$font-kr-b-20--100}
      color $gray-1
  &__list
    display grid
    gap ptr(24)
    grid-column 5
    grid-template-columns repeat(5, minmax(0, 1fr))
    margin-top ptr(24)

.pd-review
  &__title
    {$kr-20-b}
    margin-bottom ptr(8)
  &__empty
    padding ptr(80) 0
    text-align center
    h4
      {$kr-16-b}
      margin 0 0 ptr(8)
    p
      {$kr-14-m}
      color $gray-80
      margin 0
  &__more
    margin ptr(40) auto 0
    width ptr(300)
    height ptr(60)
    display flex
    justify-content center
    align-items center
    cursor pointer
    {$font-body-b1-r}
    color $black
    border-radius ptr(8)
    border 1px solid $gray-300
    background #fff
  &__images
    position relative
    d-flex-row(flex-start, center)
    gap ptr(8)
    margin-bottom ptr(24)
  &__images-more
    position absolute
    top 0
    right 0
    width ptr(122)
    height ptr(122)
    {$font-en-b-14--120}
    color $white
    padding 0
    margin 0
    border none
    outline none
    border-radius ptr(4)
    background rgba(0,0,0,0.5)
  &__list
    .grid-stack-container__column
      > div + div
        margin-top ptr(0) !important
        padding-top ptr(0) !important
        border-top none !important
    .grid-stack-container__item_wrapper
      margin-bottom 0 !important
    .grid-stack-container__item_wrapper + .grid-stack-container__item_wrapper
      margin-top ptr(0) !important
      padding-top ptr(0) !important
      border-top none !important

.pd-review-image-item
  width ptr(122)
  height ptr(122)
  cursor pointer
  img
    width 100%
    height 100%
    object-fit cover
    border-radius ptr(4)

.pd-review-item
  cursor pointer
  width 100%
  position relative
  padding ptr(24) 0
  border-bottom 1px solid $gray-10
  &__wrapper
    $flex(space-between, stretch, column)
  &__info
    $flex(space-between, flex-start)
    padding-bottom ptr(8)
  &__user
    $flex(flex-start, center)
    gap ptr(12)
  &__profile
    width ptr(42)
    height ptr(42)
    img
      width 100%
      height 100%
      object-fit cover
      border-radius 50%
  &__name
    margin 0
    color $black
    margin-left ptr(2)
    & > span
      limitLine(1)
      margin-right ptr(16)
    & > a
      display flex
      align-items center
      column-gap ptr(4)
      margin-right ptr(16)
      & > span
        limitLine(1)
      & > img
        flex-shrink 0
  &__star
    .article-review-score
      $flex(flex-start, center)
    .star
      width ptr(16)
      height ptr(16)
      margin 0 ptr(2) 0 0
  &__content
    $flex(space-between, flex-start)
    padding-left ptr(54)
  &__body
    $flex(space-between, stretch, column)
    {$kr-14-m}
    color $black
    word-break keep-all
    word-wrap break-word
    min-width 0
  &__option
    margin 0 0 ptr(4)
    {$kr-12-b}
    color $gray-50
  &__sns
    $flex(flex-start, center)
    align-self flex-start
    gap ptr(4)
    margin-top ptr(8)
    padding ptr(4) ptr(8)
    {$kr-12-m}
    color $blue
    border-radius ptr(4)
    background $blue-10
    svg
      width ptr(14)
  &__images
    flex-shrink 0
    width ptr(108)
    height ptr(108)
    margin-left ptr(16)
  &__img
    position relative
    width 100%
    height 100%
    border-radius ptr(8)
    overflow hidden
    img
      width 100%
      height 100%
      object-fit cover
    .dim
      $flex(center, center)
      position absolute
      inset 0
      background $dim-30
      border-radius ptr(8)
      {$en-16-unica-m}
      color $white
  &__meta
    padding-left ptr(54)
    margin-top ptr(8)
    {$en-14-unica-m}
    color $gray-2

.pd-default-story
  padding ptr(24) ptr(16)
  margin 0 auto ptr(36)
  border-radius ptr(16)
  background-color $gray-5
  &__header
    $flex(center, center)
    gap ptr(4)
    {$kr-16-b}
    color $black
    margin 0 0 ptr(8)
    svg
      width ptr(20)
  &__body
    {$kr-14-m}
    text-align center
    color $gray-80

.tooltip-container.mps2-tooltip
  padding ptr(24)
  border-radius ptr(16)
  background rgba(235, 243, 254, 0.60)
  backdrop-filter blur(20px)
  -webkit-backdrop-filter blur(20px)
  border none
  box-shadow none
  &.membership-tooltip
    padding ptr(16)
  h4
    {$kr-14-b}
    margin 0
    color $blue
  p
    {$kr-14-m}
    margin 0
    color $blue

.private-product-detail
  margin-top $default-header-pc_height
  flex 1
  $flex(center, center, column)
  gap ptr(24)
  text-align center
  padding ptr(160) 0
  &__image
    width ptr(160)
    height ptr(160)
    img
      width 100%
      height 100%
      object-fit cover
  &__title
    {$kr-24-b}
    color $black
    margin-bottom ptr(8)
  &__description
    {$kr-16-m}
    color $gray-50
  &__btn
    display block
    padding ptr(16) ptr(24)
    color $white
    {$kr-16-b}
    border-radius ptr(12)
    background $blue

.pd-membership-tag
  display flex
  justify-content center
  align-items center
  background-color $blue-10
  color $blue-100
  margin-bottom ptr(8)
  padding ptr(8)
  border-radius ptr(8)
  flex-wrap wrap
  word-break break-word
