/*
 * -----------------메인-----------------------
 * 이미지, 뱃지, (상품이름 || 제품이름), 가격, 스토어이름
 *  - 홈(인기상품), 검색(추천) 상품
 *  < 가로형
 *    - 리뷰(메인안에서 전부), 검색(상품결과)
*/
.{a-p-i}--popular
  .{a-p-i}
    &__like
      display none


