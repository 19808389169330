pb-item-order-detail_font_24 = 24px;
.don_frame[frame_name="/modules/MShop/Studio/PbItemOrderDetail/S/Mui/frame.js"]
  >.don_wrapper
    max-width 1200px
    width 80%
    height 80%
    >.body
      padding-top 100px
    >.header
      display flex
      justify-content space-between
      align-items center
      padding 40px 40px 20px
      background #fff
      position absolute
      top 0
      box-sizing border-box
      width 100%
      .don_hide_frame
        background url(//s3.marpple.co/files/u_193535/2022/12/original/70f4abf27fa32ba939660cb091ce58c21b8f43961.png) center center
        background-size 24px 24px
        width 24px
        height 24px
      .title
        font-size pb-item-order-detail_font_24
        font-weight bold
  @import '../share.styl'
