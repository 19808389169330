.marpple-shop-product-tag
  font-weight bold
  border-radius 2px
  font-size 12px
  margin-right 8px
  padding 6px 6px 4px
  background-color #f5f5f5
  cursor pointe
  &.border_style
    font-size 12px
    margin-right 5px
    padding 2px 6px 1px
    background transparent
  &[data-tag_type="is_free_shipping_product"]
    color #828282
    &.border_style
      border 1px solid #828282
  &[data-tag_type="shipping"]
    color $gray-2
    &.border_style
      border 1px solid $gray-3
      border-radius 2px
  //&[data-tag_type="ALWAYS"]
  //  color #1C75FF
  //  &.border_style
  //    border 1px solid #1C75FF
  &[data-tag_type="HAND_MADE"]
    color $blue-1
    &.border_style
      border 1px solid $blue-1
  &[data-tag_type="PRINT_ON_DEMAND"]
    color $blue-1
    &.border_style
      border 1px solid $blue-1
  &[data-tag_type="CUSTOM"]
    color $blue-1
    &.border_style
      border 1px solid $blue-1
  &[data-tag_type="LIMITED"]
    display none
  &__tooltip
    padding 8px 10px
    border-radius 6px
