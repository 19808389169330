.app-home-quick-menu
  overflow hidden
  margin-bottom pxToRem(80px)
  &__scroll-container
    overflow hidden
  &__container
    display flex
    justify-content center
    align-items flex-start
    gap pxToRem(24px)
  &__image-box
    width pxToRem(126px)
    height pxToREm(126px)
    border-radius 100%
    overflow hidden
  &__image
    vertical-align top
    width 100%
  &__text
    display block
    margin-top 16px
    text-align center
    font 16px 20px -0.2px
    word-break keep-all

