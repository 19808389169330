.arrow-left-header
  display flex
  align-items center
  &__back-btn
    background-color transparent
    border 0
    padding 0
    font-size 0
    background-image url(//s3.marpple.co/files/u_29089/2021/5/original/1d15ceab34ba19f513174b8865b18c01376b0ebe1.png)
    background-repeat no-repeat
    background-size 100%
    width pxToRem(24px)
    height pxToRem(24px)
  &__title
    font-size pxToRem(20px)
    font-weight 600
    letter-spacing -0.2px
    color #111111
    margin 0
    height 22px
.mshop-main-seller-header
  &__logo-text
    display block
    max-width 400px
  &__logo-img
    margin-top pxToRem(-3px)
    display block
    height 28px
    max-width 400px
