// MarppleShop 2.0 상품 스타일
//@import '../Share/item-ness-badge-color.styl'

$ness_product_bottom_info_height = pxToRem(110)
$marpppleshop_product_border_radius = ptr(16)
$ness_product_like_size = pxToRem(24)

.app-product-item--MARPPLE_SHOP
  width 100%
  margin 0
  container-type inline-size
  container-name app-product-item
  overflow hidden

  &[data-is_sold_out="true"]
    .app-product-item
      &__store-name
      &__p-name
      &__price:after
        color $ness-gray-80
      &__price-number
      &__price-number--deprecated
      &__discount-rate
        display none
      &__price:after
        content 'Sold Out'
        {$font-en-b-16--100}
        display inline-block

  .app-product-item
    &__rank
      display none
    &__store-name
      {$font-en-b-14--120}
      color $ness-gray-80
      margin-bottom pxToRem(12)
      margin-right $ness_product_like_size + pxToRem(4)
      line-height $ness_product_like_size
      height $ness_product_like_size
    &__p-name
      {$font-kr-b-20--100}
      line-height normal // line-height 와 overflow의 꼴라보로 하단 잘리는 이슈 수정을 위해 line-height을 조정
      margin-bottom pxToRem(4) // 늘어난 height의 크기만큼 하단 마진에서 빼줌 8 -> 4
      color $ness-black
    &__discount-rate
      {$font-en-b-16--100}
      color $ness-blue
    &__price
      height pxToRem(23)
      display flex
      gap pxToRem(8)
      align-items end
    &__price-number
      {$font-en-b-16--100}
      display inline-block
      &--deprecated
        {$font-en-b-14--120}
        color $ness-gray-60
        > span
          text-decoration-line line-through
    &__price::after
      {$font-kr-b-16--120}
    &__top-info
      border-radius pxToRem(16)
      overflow hidden
    &__bottom-info
      margin pxToRem(12) 0 0 0
      height $ness_product_bottom_info_height
    &__img
      border-radius 0
      .mp-product-img-bg-wrap
        border-radius 0
    &__badges
      position absolute
      top 0
      left 0
      display flex
      z-index 2
    &__badge
      position static
      {$font-en-b-12--120}
      padding pxToRem(6) pxToRem(8) pxToRem(4) pxToRem(8)
      border-radius 0 !important
    &__badge-suffix
      display inline-block
      margin-left pxToRem(4)
    &__like
      display unset !important
      top 0
      right 0
      width $ness_product_like_size
      height $ness_product_like_size
      color $ness-gray-20
      &[data-already_like="true"]
        color $ness-black
    &__review-info
      margin pxToRem(8) 0 0 0
    &__review-score
      display none
    &__review-count
      {$font-en-b-12--120}
      color $ness-gray-80
      display flex
      gap pxToRem(4)
    &__review-title
      order 2
    &__review-number
      order 1
    &__bottom-badge
      display none
    &__coming-soon
      position absolute
      top 50%
      left 50%
      transform translate(-50%, -50%)
      width 100%
      height 100%
      background-color rgba(0, 0, 0, 0.3)
      display flex
      flex-direction column
      gap pxToRem(8)
      justify-content center
      align-items center
      color $ness-white
      {$font-en-b-20--100}
      z-index 1

// 태블릿에서 PC 모드로 보는 경우 대응
@container app-product-item (max-width: 20rem) {
  .app-product-item__badge-suffix,
  .app-product-item__price-number--deprecated {
    display none !important
  }
}
