.creator-settings
  .pb-product-tip-frame
    > .don_wrapper
      max-width 996px
      max-height 663px
      width 80%
      height fit-content
      overflow hidden

  .pb-product-tip
    padding-bottom 30px
    &__title
      margin 38px 0 36px
      padding 0
      font-size 20px
      font-weight 600
      font-stretch normal
      font-style normal
      line-height 1.2
      letter-spacing -0.5px
      text-align center
      color #000
    &__ul
      margin 0 56px
      padding 0 0 60px 0
      list-style none
      display flex
      justify-content space-between
      gap pxToRem(8px)
    &__li
      width 292px
    &__img
      img
        width 100%
    &__h3
      padding 0
      margin 0
      font-size 18px
      font-weight 600
      line-height 1.33
      letter-spacing -0.2px
      color #000
    &__p
      margin 10px 0 0
      padding 0
      font-size 14px
      line-height 1.71
      letter-spacing -0.2px
      color #4e4e4e
    &__p2
      margin 10px 0 0
      padding 0
      font-size 12px
      line-height 1.67
      letter-spacing -0.2px
      color #a2a2a2
    .swiper-button-prev
      position absolute
      top 160px
      left 20px
      width 9px
      height 20px
      cursor pointer
      background url("//s3.marpple.co/files/u_14355/2018/11/original/f_12519_1542351340021_ZScuDmbspMd3P1fdv.png") no-repeat
      background-size 9px auto
    .swiper-button-prev.swiper-button-disabled
      background-image url(//s3.marpple.co/files/u_14355/2018/11/original/f_12521_1542351340021_Z8Mor2wHQA7X6CUu2PFG.png)
    .swiper-button-next
      position absolute
      top 160px
      right 20px
      width 9px
      height 20px
      cursor pointer
      background url("//s3.marpple.co/files/u_14355/2018/11/original/f_12520_1542351340021_sS0z1yZyq9mh0rs8GM5Vg.png") no-repeat
      background-size 9px auto
    .swiper-button-next.swiper-button-disabled
      background-image url(//s3.marpple.co/files/u_14355/2018/11/original/f_12522_1542351340021_Q7KUGbhze0O3w7BKCKG.png)
    .swiper-pagination
      bottom 0
    &__text
      padding 22px 10px