.ad_res_finish
  padding-top $default-sub-header-pc_height
  max-width 1280px
  margin 0 auto 70px
  .content
    margin-top 120px
    text-align center
  .image
    margin 0 auto 23px
    img
      width 351px
  .text
    h3
      margin-bottom 40px
      font-size 28px
      font-weight 600
      line-height 1.36
      letter-spacing -0.2px
  .noti
    list-style none
    justify-self flex-start
    text-align center
    padding 0
    margin 0 0 60px
    li.txt2
      color #a2a2a2
      a
        color #a2a2a2
    li
      font-size 18px
      letter-spacing -0.2px
      color #111
      line-height 1.5
      letter-spacing -0.2px
      text-align center

    li + li
      margin-top 8px
  .link
    margin-bottom 160px
    text-align center
    a
      display inline-block
      width 370px
      padding 16px 0 14px
      font-size 18px
      font-weight 600
      line-height 1.33
      letter-spacing -0.2px
      text-align center
      color #ffffff
      border-radius 4px
      background-color #111111
  p.txt1
    margin 0 0 0 10px
    padding 24px 0 0
    border-top 1px solid #f8f8f8
    font-size 14px
    letter-spacing -0.2px
    text-align center
    line-height 1.5
    letter-spacing -0.2px
    color #a2a2a2


