
body.unsupported-browser-notice
  #don_frame_screen
  #header
  #header2
    display none !important

  main.content
    display block
    max-width 600px
    margin 0 auto
    padding 24px

    .logo
      html[collabo_type="line"] &
        width 200px

      html[collabo_type="line"][is_mobile_size="true"] &
        width 78px

      width 108px
      margin-bottom 40px

    h2,
    h3,
    p
      margin 0
    p
      color #a2a2a2

    section[data-index]
      .title
        font-size 16px
        margin-bottom 8px

      .btn-group
        display flex
        justify-content space-between
        > .btn
          display flex
          justify-content center
          align-items center
          width calc(50% - 8px)

    section[data-index="1"]
      .browser-logo
        display block
        text-align center
        margin 0 auto 16px
        width 48px
      .title
        display block
        text-align center
        margin 0 auto 32px
        font-size 20px
      .description
        margin-bottom 32px

    section[data-index="2"]
      margin-bottom 40px
      .btn-browser
        padding 12px 0
        border 1px solid #dddddd
        border-radius 2px
        img
          width 24px
          margin-right 8px
        span
          font-size 14px
          line-height 1

    section[data-index="3"]
      .browser-logo-group
        width 160px
        margin-top 16px
        margin-bottom 40px

      .btn-group
        margin-top 4px

      .btn-store
        padding 8px 0
        border 1px solid transparent
        border-radius 2px
        background-color #000000;
        img
          width 136px
