$header-menu = '.header-menu'

// @Variable
default-footer-text-color = #828282

.creator-header
  position relative
  height pxToRem(72px)
  z-index 3
  @media screen and (max-width 1023px)
    box-sizing border-box
    width 1023px
  &__inner
    position relative
    box-sizing border-box
    width 100%
    pc-layout-inner-style()
  &__image
    vertical-align top
    width pxToRem(125px)
    height pxToRem(24px)
    &--plus
      width auto
      height 32px
      min-width 40px
  &__row
    position relative
    margin-right pxToRem(-16px)
    height pxToRem(72px)
    background-color #fff
    z-index 2
    &--flex
      display flex
      flex-direction row
      justify-content space-between
      align-items center
    &--menu
      display flex
      align-items center
      transition transform .3s
      z-index 1
      ^[{$root-index}]--unpinned ^[{$root-index}..-{$root-index}]
        transform translateY(-100%)
      ^[{$root-index}]--pinned ^[{$root-index}..-{$root-index}]
        transform translateY(0)
    &--dark
      background-color #000
      color #fff
  &__column
    font-size 0
    &--flex
      display flex
      align-items center
  &__logo-text
    font-size pxToRem(20px)
    font-weight 600
    letter-spacing -0.2px
    height pxToRem(20px)
  &__button
    reset-button()
    flexCenter()
    position: relative
    width pxToRem(52px)
    height pxToRem(40px)
    &::before
      stripe-image-before pxToRem(24px) pxToRem(24px) '//s3.marpple.co/files/u_1396787/2021/4/original/3d43c39061337049df2b744e1e0ac327748d88631.png' pxToRem(88px)
      //stripe-image-before pxToRem(24px) pxToRem(26px) '//s3.marpple.co/files/u_1396787/2021/4/original/3d43c39061337049df2b744e1e0ac327748d88631.png' pxToRem(88px)
    &--cart
      &::before
        background-image: url(//s3.marpple.co/files/u_1396787/2021/5/original/db2a15fcf8f4913f2079caa31f0864ec068f7b951.svg)
        //background-image url(//image1.marpple.co/files/u_29089/2022/12/original/c5926f6f74b0b91071893be361144dd26ab258d51.png?w=48)
        background-size pxToRem(24px)

    &--menu
      &::before
        background-image url(//s3.marpple.co/files/u_1396787/2021/5/original/0e7e7757fe4cd86af4b195d057d165de5e03baea7.svg)
        background-size pxToRem(24px)
      .blind-text
        blind()
  &__text-box
    flexCenter()
    display none
    position absolute
    //top pxToRem(3px)
    //right pxToRem(7px)
    top pxToRem(2px)
    right pxToRem(3px)
    padding 4px 0 1px
    width 19px
    border-radius 100%
    background-color #1C75FF
    color #fff
    @media screen and (max-width: 1280px)
      top pxToRem(2px)
      right pxToRem(5px)
    @media screen and (max-width: 1100px)
      top pxToRem(1px)
      right pxToRem(1px)
    &--show
      display flex
    &--wide
      ^[{$root-index}]__button--cart ^[{$root-index}..-{$root-index}]
        right pxToRem(2px)
        width 25px
        border-radius 8px
  &__text
    &--cart
      font 12px 12px -.2px


{$header-menu}
  reset-list()
  display flex
  align-items center
  margin-left pxToRem(40px)
  gap pxToRem(24px)
  &__anchor
    display block
    font-size pxToRem(20px)
    font-weight 700
    line-height pxToRem(26px)
    color #a2a2a2 !important
    //../__list:not(:first-child) ^[{$root-index}..-1]
    //  margin-left pxToRem(30px)
    &--active
      color #1C75FF !important
.mshop-main-seller-frame
  {$header-menu}
    font-size pxToRem(20px)
    &__anchor
      ../__list:not(:first-child) ^[{$root-index + 1}..-1]
        margin-left pxToRem(26px)
      &--active
        color #111111 !important

@keyframes snow-animation {
  from {
    transform translateY(-11px)
    opacity 1
  }
  to {
    transform translateY(70px)
    opacity 0
  }
}
