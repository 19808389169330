.login-title-wrapper
  margin auto
  width pxToRem(520px)
  padding-top pxToRem(80px)
  padding-bottom pxToRem(8px)
  display flex
  align-items center
  justify-content flex-start
  gap 6px
  box-sizing border-box
  >img
    display none
  >.login-title
    padding 0
    margin 0
    font-size pxToRem(24px)
    font-weight bold
    line-height 1
    letter-spacing -0.2px
    text-align left
    color $gray-1