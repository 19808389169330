html.creator.brand-commerce[is_mobile="false"]
  @media screen and (max-width 1920px)
    font-size: calc(1vw * (14 / 19.2)) !important

.BrandCommercePage
  &.editing
    display none
  h3
  h4
  p
    padding 0
    margin 0
  ul
  li
    margin 0
    padding 0
  *
    box-sizing border-box
    /* KR-48/Bold */
    font-family: Pretendard;
    font-style: normal;
    letter-spacing: pxToRem(-0.2px);
    word-wrap: break-word

  /* cta button */
  .request
    position: fixed;
    bottom: ptr(40);
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;

  .section
    position relative
    width 100%
    height pxToRem(1080px)
    overflow hidden


  .section1
    height 100vh;
    padding pxToRem(60) 0 pxToRem(136) 0;
    display flex
    justify-content flex-end
    align-items center
    flex-direction column
    position relative

    @media screen and (max-width 1024px)
      width 100vw

    &:after
      content ''
      position absolute
      z-index 0
      top 0
      left 0
      width 100%
      height 100%
      background: linear-gradient(180deg, rgba(28, 117, 255, 0.00) 46.5%, rgba(28, 117, 255, 0.90) 86.5%);

    .bg
      width 100%
      height: 100%;
      top: 0;
      position absolute
      left 0
      display flex
      justify-content center
      z-index 0

      img
      video
        margin auto;
        width 100%;
        height 100%;
        object-position center
        object-fit cover

      video
        -webkit-filter: contrast(101%);
        filter: contrast(101%);

    .texts
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: pxToRem(8px)
      width 100%;
      text-align center
      z-index 1
      *
        color: var(--White, #FFF);
        font-weight: 700;
      h3
        font-size: pxToRem(48px)
        line-height: pxToRem(60px)
      p
        font-size: pxToRem(20px)
        line-height: pxToRem(28px) /* 140% */

  .section2
    display: flex;
    height: pxToRem(1080px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: pxToRem(60px);
    background: var(--Blue-10, #EBF3FE);
    .texts
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: pxToRem(24px);
      align-self: stretch;
      color #1C75FF
      text-align center
      h3
        font-size: pxToRem(48px);
        font-weight: 700;
        line-height: pxToRem(60px); /* 125% */
    .cases
      position relative
      display: flex;
      padding: pxToRem(0px) pxToRem(32px) pxToRem(80px);
      align-items: flex-start;
      align-self: stretch;
      opacity: 0;
      &.swiper-container-horizontal
        opacity: 1;
      &.swiper-container-horizontal>.swiper-pagination-progressbar {
        position: absolute;
        left: pxToRem(32px);
        right: pxToRem(32px);
        width: auto;
        top auto;
        bottom: 0;
        height: pxToRem(2px);
        background: var(--White, #FFF);
      }
      .swiper-pagination-progressbar-fill {
        background: var(--Blue-100, #1C75FF);
      }
      .case
        width pxToRem(420px)
        margin-right pxToRem(32px)
        border-radius: pxToRem(24px);
        background: var(--White, #FFF);
        img
          border-radius: pxToRem(24px) pxToRem(24px) 0 0;
          width: pxToRem(420px);
          height: pxToRem(420px);
        .info
          display: flex;
          padding: pxToRem(24px);
          flex-direction: column;
          align-items: flex-start;
          gap: pxToRem(32px);
          align-self: stretch;
          .head
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: pxToRem(8px);
            align-self: stretch;
            color #1C75FF
            h4
              font-size: pxToRem(28px);
              font-weight: 700;
              line-height: pxToRem(36px);
            p
              font-size: pxToRem(14px);
              font-weight: 500;
              line-height: pxToRem(22px);
              display: flex;
              align-items: flex-start;
              gap: pxToRem(8px);
          ul
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: pxToRem(16px);
            align-self: stretch;
            margin 0
            padding 0
            list-style none
            li
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              align-self: stretch;
              color #1C75FF
              h5
                display flex
                font-size: pxToRem(20px);
                font-weight: 500;
                line-height: pxToRem(32px); /* 160% */
                margin 0
                padding 0
              .num
                display flex
                font-family: "Bebas Neue Pro";
                font-size: pxToRem(32px);
                font-weight: 700;
                line-height: pxToRem(32px); /* 100% */
                text-transform: uppercase;
                span.unit
                  margin-left pxToRem(4px)
                  /* KR-20/Landing Medium 32 */
                  font-family: Pretendard;
                  font-size: pxToRem(20px);
                  font-weight: 500;
                  line-height: pxToRem(32px); /* 160% */
      .swiper-button-wrapper
        z-index 1;
        position absolute;
        bottom: 0;
        right pxToRem(80px);
        display flex;
        gap pxToRem(8px);
        .swiper-prev-icon, .swiper-next-icon
          display: flex;
          cursor pointer;
          width: pxToRem(60px);
          height: pxToRem(60px);
          padding: pxToRem(10px);
          justify-content: center;
          align-items: center;
          gap: pxToRem(10px);
          position relative
          border-radius: 999px;
          background: var(--Dim-30, rgba(20, 20, 20, 0.30));

          /* Blur 10 */
          backdrop-filter: blur(5px);

  .section3
    display: flex;
    height: pxToRem(1080px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: pxToRem(80px);
    .texts
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: pxToRem(24px);
      align-self: stretch;
      color #141414
      text-align center
      h3
        font-size: pxToRem(48px);
        font-weight: 700;
        line-height: pxToRem(60px); /* 125% */
      em
        display block
        margin-top pxToRem(8px)
        font-size: pxToRem(14px);
        font-weight: 500;
        line-height: pxToRem(22px); /* 125% */
        color #A2A2A2
      p
        font-size: pxToRem(20px);
        font-weight: 500;
        line-height: pxToRem(32px); /* 160% */
    .imgs
      display: flex;
      justify-content: center;
      align-items: center;
      gap: pxToRem(32px);
      img
        width: pxToRem(916px);
        height: pxToRem(520px);

  .section4
    display: flex;
    height: pxToRem(1080px);
    padding: pxToRem(0px) pxToRem(188px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: pxToRem(160px);
    flex-shrink: 0;

    .bg
      position absolute
      top 0
      left 0
      width 100%
      height 100%
      z-index -1
      display flex
      justify-content center
      img
      video
        width 100%;
        object-position center
        object-fit cover
      &:after
        content ''
        position absolute
        top 0
        left 0
        width 100%
        height 100%
        background: rgba(20, 20, 20, 0.70);
        /* Blur 60 */
        backdrop-filter: blur(30px);
        -webkit-backdrop-filter: blur(30px)
    .texts
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: pxToRem(24px);
      align-self: stretch;
      color #fff
      text-align center
      h3
        font-size: pxToRem(48px);
        font-weight: 700;
        line-height: pxToRem(60px); /* 125% */
      p
        font-size: pxToRem(20px);
        font-weight: 500;
        line-height: pxToRem(32px); /* 160% */

    .details
      display: flex;
      align-items: flex-start;
      gap: pxToRem(48px);
      align-self: stretch;
      .detail
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: pxToRem(16px);
        flex: 1 0 0;
        text-align center
        color #fff
        img
          height: pxToRem(200px);
          flex-shrink: 0;
        h4
          font-size: pxToRem(24px);
          font-weight: 700;
          line-height: pxToRem(34px); /* 125% */
        p
          font-size: pxToRem(16px);
          font-weight: 500;
          line-height: pxToRem(26px); /* 160% */

  .section5
    display: flex;
    width 100%
    height auto
    aspect-ratio: 16 / 9;
    align-items: flex-start;
    .left
      display: flex;
      width 50%
      padding: pxToRem(110px) pxToRem(188px);
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      flex: 1 0 0;
      align-self: stretch;
      background: var(--Blue-10, #EBF3FE);
      .texts
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: pxToRem(8px);
        display: flex;
        color: #1C75FF;
        p
          font-size: pxToRem(24px);
          font-weight: 700;
          line-height: pxToRem(34px);
        h3
          font-size: pxToRem(48px);
          font-weight: 700;
          line-height: pxToRem(60px);
      .details
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: pxToRem(24px);
        align-self: stretch;
        width pxToRem(584px)
        .detail
          display: flex;
          padding-bottom: pxToRem(16px);
          flex-direction: column;
          align-items: flex-start;
          gap: pxToRem(16px);
          align-self: stretch;
          color: var(--Blue-100, #1C75FF);
          border-bottom: pxToRem(1px) solid var(--Blue-100, #1C75FF);
          h4
            /* KR-20/Bold */
            font-size: pxToRem(20px);
            font-weight: 700;
            line-height: pxToRem(28px); /* 140% */
          p
            font-size: pxToRem(20px);
            font-weight: 500;
            line-height: pxToRem(32px); /* 160% */
    .right
      width 50%
      height 100%
      video
        height 100%



  .section6
    display: flex;
    position relative
    max-width pxToRem(1920px);
    height: pxToRem(1080px);
    padding: pxToRem(120px) pxToRem(32px);
    margin 0 auto
    align-items: center;
    gap: pxToRem(80px);
    overflow: hidden;
    .left
      display: flex;
      height: pxToRem(760px);
      padding-left: pxToRem(156px);
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      flex: 1 0 0;
      .texts
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: pxToRem(32px);
        align-self: stretch;
        color: var(--Blue-100, #1C75FF);
        .head
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: pxToRem(8px);
          p
            font-size: pxToRem(24px);
            font-weight: 700;
            line-height: pxToRem(34px); /* 141.667% */
          h3
            font-size: pxToRem(48px);
            font-weight: 700;
            line-height: pxToRem(60px); /* 125% */
        > p
          font-size: pxToRem(20px);
          font-weight: 500;
          line-height: pxToRem(32px); /* 160% */
      .texts2
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: pxToRem(32px);
        align-self: stretch;
        h4
          color: var(--Blue-100, #1C75FF);
          font-size: pxToRem(20px);
          font-weight: 700;
          line-height: pxToRem(28px); /* 140% */
          display: flex;
          align-items: flex-start;
          gap: pxToRem(8px);
        ol
          margin 0
          padding 0
          list-style none
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: pxToRem(24px);
          align-self: stretch;
          li
            color: var(--Blue-100, #1C75FF);
            display: flex;
            width: pxToRem(420px);
            padding-bottom: pxToRem(16px);
            align-items: flex-start;
            gap: pxToRem(8px);
            font-size: pxToRem(20px);
            font-style: normal;
            font-weight: 500;
            line-height: pxToRem(28px); /* 140% */
            border-bottom: pxToRem(1px) solid var(--Blue-100, #1C75FF);
            b
              font-weight: 700;
    .right
      height: pxToRem(840px);
      text-align left
      img
        border-radius pxToRem(32px)
        width 100%
        height 100%
    img.bg
      position absolute
      left 0
      right 0
      margin 0 auto
      transform translateX(pxToRem(92px))
      bottom pxToRem(60px)
      width: pxToRem(513px);
      height: pxToRem(513px);



  .section7
    display: flex;
    height: pxToRem(1080px);
    padding: pxToRem(0px) pxToRem(188px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--Blue-100, #1C75FF);

    h3
      text-align center
      color: var(--Blue-10, #EBF3FE);
      font-size: pxToRem(80px);
      font-style: normal;
      font-weight: 700;
      line-height: 116%;
      letter-spacing: pxToRem(-0.2px);
      margin-bottom pxToRem(43px);
      z-index 1
      position relative

      a
        color: var(--Blue-10, #EBF3FE);
        text-decoration underline
        text-underline-offset pxToRem(13px)
        text-decoration-thickness pxToRem(6px)

      .bg
        --bg_left: pxToRem(30px)
        position absolute
        top pxToRem(-101px)
        left calc(100% - var(--bg_left))
        z-index 0
        img
          width pxToRem(604px)


    h4
      color: var(--Blue-10, #EBF3FE);
      text-align: center;
      margin-bottom pxToRem(12px);

      /* KR-40/Bold */
      font-family: Pretendard;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 56px; /* 140% */
      letter-spacing: -0.2px;

    h5
      color: var(--Blue-10, #EBF3FE);
      text-align: center;
      margin-bottom pxToRem(100px);

      /* KR-16/Regular */
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: -0.2px;


    .details
      display flex;
      justify-content center;
      align-items center;
      gap pxToRem(32px)

      .detail
        padding-top pxToRem(24px)
        display flex;
        width: pxToRem(256px);
        height: pxToRem(252px);
        justify-content flex-start;
        align-items flex-start;
        flex-direction column;
        border-top: pxToRem(1px) solid var(--White, #FFF);
        border-bottom: pxToRem(1px) solid var(--White, #FFF);

        .step
          color: var(--Blue-10, #EBF3FE);
          text-align: center;

          /* EN-32/Bebas-Bold */
          font-family: "Bebas Neue Pro";
          font-size: pxToRem(32px);
          font-style: normal;
          font-weight: 700;
          line-height: pxToRem(32px); /* 100% */
          text-transform: uppercase;
          margin-bottom pxToRem(8px);

        .title
          color: var(--Blue-10, #EBF3FE);

          /* KR-28/Landing Bold 36 */
          font-family: Pretendard;
          font-size: pxToRem(28px);
          font-style: normal;
          font-weight: 700;
          line-height: pxToRem(36px); /* 128.571% */
          letter-spacing: pxToRem(-0.2px);
          margin-bottom pxToRem(16px);

        .content
          color: var(--Blue-10, #EBF3FE);

          /* KR-20/Medium */
          font-family: Pretendard;
          font-size: pxToRem(20px);
          font-style: normal;
          font-weight: 500;
          line-height: pxToRem(28px); /* 140% */
          letter-spacing: pxToRem(-0.2px);


  .section8
    display: flex;
    height: pxToRem(1080px);
    padding-top: pxToRem(100px);
    padding-bottom: pxToRem(90px);
    flex-direction: column;
    align-items: center;
    gap: pxToRem(62px);
    align-self: stretch;
    background: var(--Purple-100, #7A44A0);
    .texts
      display: flex;
      padding: pxToRem(0px) pxToRem(188px);
      flex-direction: column;
      align-items: center;
      gap: pxToRem(8px);
      align-self: stretch;
      color: var(--Pink-10, #FDF0FE);
      text-align center
      .head
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: pxToRem(8px);
        p.txt1
          font-size: pxToRem(48px);
          font-weight: 700;
          line-height: pxToRem(60px); /* 141.667% */
        p.txt2
          display: flex;
          padding: pxToRem(0px) pxToRem(0px) pxToRem(32px) pxToRem(40px);
          align-items: center;
          gap: pxToRem(16px);
          span
            font-family: "Bebas Neue Pro";
            font-size: pxToRem(240px);
            font-weight: 700;
            line-height: 90%; /* pxToRem(216px) */
            letter-spacing: pxToRem(-2.8px);

          svg
            width: pxToRem(80px);
            height: pxToRem(80px);

        h3
          font-size: pxToRem(48px);
          font-weight: 700;
          line-height: pxToRem(60px); /* 125% */
      > p
        font-size: pxToRem(20px);
        font-weight: 500;
        line-height: pxToRem(32px); /* 160% */

    .creators
      position relative;
      width 100%
      height pxToRem(440px)
      .marquee-wrapper
        display flex
        overflow-x hidden
        width 100%
        position absolute
        z-index 0
        top 0

        .marquee-background
          --gap pxToRem(32)
          flex-shrink 0
          display flex
          gap var(--gap)
          justify-content space-around
          align-items center
          will-change transform
          animation marquee-left 50s linear infinite
          min-width 100%
          height pxToRem(200)
          max-height pxToRem(200)
          &[aria-hidden="true"]
            margin-left var(--gap)
          img
            width pxToRem(200px)
            height pxToRem(200px)
            border-radius pxToRem(16px)

        &.top
          top: 0;
        &.bottom
          top: pxToRem(232px);
          .marquee-background
            animation marquee-right 50s linear infinite

  .section9
    display: flex;
    height: pxToRem(1080px);
    padding-top: pxToRem(100px);
    padding-bottom: pxToRem(90px);
    flex-direction: column;
    align-items: center;
    gap: pxToRem(98px);
    align-self: stretch;
    background: var(--Brown-100, #704C00);
    .texts-wrapper
      display flex
      justify-content center
      align-items center
      gap pxToRem(100px)
      padding: pxToRem(0px) pxToRem(188px);

      .texts
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: pxToRem(8px);
        align-self: stretch;
        color: var(--Brown-10, #FFF9E2);
        text-align center

        .head
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: pxToRem(8px);
          p.txt1
            font-size: pxToRem(48px);
            font-weight: 700;
            line-height: pxToRem(60px); /* 141.667% */
          p.txt2
            display: flex;
            padding: pxToRem(0px) pxToRem(0px) pxToRem(32px) pxToRem(40px);
            align-items: center;
            gap: pxToRem(8px);
            font-size: pxToRem(160px);
            font-weight: 700;

            span
              font-family: "Bebas Neue Pro";
              font-size: pxToRem(200px);
              line-height: 90%; /* pxToRem(160px) */
              letter-spacing: pxToRem(-2.8px);

            svg
              width: pxToRem(80px);
              height: pxToRem(80px);

          h3
            font-size: pxToRem(48px);
            font-weight: 700;
            line-height: pxToRem(60px); /* 125% */
        > p
          font-size: pxToRem(20px);
          font-weight: 500;
          line-height: pxToRem(32px); /* 160% */


    .brands
      position relative;
      width 100%
      height pxToRem(440px)
      .marquee-wrapper
        display flex
        overflow-x hidden
        width 100%
        position absolute
        z-index 0
        top 0

        .marquee-background
          --gap pxToRem(32)
          flex-shrink 0
          display flex
          gap var(--gap)
          justify-content space-around
          align-items center
          will-change transform
          animation marquee-left 50s linear infinite
          min-width 100%
          height pxToRem(200)
          max-height pxToRem(200)
          &[aria-hidden="true"]
            margin-left var(--gap)
          img
            width pxToRem(200px)
            height pxToRem(200px)

        &.top
          top: 0;
        &.bottom
          top: pxToRem(232px);
          .marquee-background
            animation marquee-right 50s linear infinite


  .section10
    display: flex;
    height: pxToRem(1080px);
    padding: pxToRem(160px) pxToRem(0px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: pxToRem(80px);
    background: var(--Green-100, #009444);
    > .texts
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: pxToRem(32px);
      align-self: stretch;
      color #FFF9E2;
      h3
        font-size: pxToRem(48px);
        font-weight: 700;
        line-height: pxToRem(60px); /* 125% */

      > p
        font-size: pxToRem(20px);
        font-weight: 500;
        line-height: pxToRem(32px); /* 160% */
        text-align center

    .details
      display: flex;
      width pxToRem(1544px)
      margin 0 auto
      justify-content: center;
      align-items: center;
      gap: pxToRem(40px);
      align-self: stretch;
      .detail
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: pxToRem(32px);
        flex: 1 0 0;
        width pxToRem(488px)
        img
          width pxToRem(488px)
        .texts
          width pxToRem(488px)
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: pxToRem(16px);
          align-self: stretch;
          color: var(--Brown-10, #FFF9E2);
          text-align center
          h4
            font-size: pxToRem(24px);
            font-weight: 700;
            line-height: pxToRem(39px); /* 140% */
          p
            font-size: pxToRem(20px);
            font-weight: 500;
            line-height: pxToRem(32px); /* 160% */


  .section11
    display: flex;
    margin 0 auto
    max-width: pxToRem(1920px);
    height auto
    padding: pxToRem(120px) pxToRem(188px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: pxToRem(120px);
    h3
      color: var(--Black, #141414);
      text-align: center;
      font-size: pxToRem(48px);
      font-weight: 700;
      line-height: pxToRem(60px); /* 125% */

    .faqs
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: pxToRem(16px);
      .faq
        display: flex;
        width: pxToRem(1560px);
        flex-direction: column;
        align-items: flex-start;
        background-color: #EBF3FE;
        border-radius: pxToRem(24px);
        color #1C75FF
        transition background-color 0.3s ease-out
        .heading
          display: flex;
          padding: pxToRem(36px) pxToRem(60px);
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          font-size: pxToRem(24px);
          font-weight: 700;
          line-height: pxToRem(34px); /* 141.667% */
          cursor pointer
          .status
            height pxToRem(24px)
          .opened
            display none
          .closed
            display block
        .body
          display flex
          box-sizing content-box
          padding: pxToRem(0px) pxToRem(60px) 0 pxToRem(60px);
          flex-direction: column;
          justify-content: start;
          align-items: flex-start;
          gap: pxToRem(16px);
          align-self: stretch;
          font-size: pxToRem(16px);
          font-weight: 500;
          line-height: pxToRem(24px); /* 150% */
          height 0px
          overflow hidden
          transition height 0.3s ease-out, padding-bottom 0.3s ease-out
          ul
            padding-left pxToRem(20px)
          ol
            margin 0
            padding 0
            padding-left pxToRem(20px)
          ol.abc
            list-style lower-alpha;
        &.is_open
          background-color: #1C75FF;
          color #fff
          .heading
            .opened
              display block
            .closed
              display none
          .body
            padding-bottom pxToRem(60px)

  .section12
    display: flex;
    padding: pxToRem(120px)
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    background: var(--Blue-100, #1C75FF);
    height pxToRem(860px)
    text-align center
    img
      width: pxToRem(623.547px)
      height: pxToRem(409px)


    .submit_wrapper
      display flex;
      justify-content center;
      align-items center;
      flex-direction column;
      margin-top  pxToRem(-60px)

      h3
        color: var(--White, #FFF);
        font-size: pxToRem(48px)
        font-weight: 700;
        line-height: pxToRem(60px) /* 125% */
        z-index 1;
        margin-bottom pxToRem(40px)

      > p
        color: var(--White, #FFF);
        text-align: center;

        /* KR-24/Bold */
        font-family: Pretendard;
        font-size: pxToRem(24px);
        font-style: normal;
        font-weight: 700;
        line-height: pxToRem(34px); /* 141.667% */
        letter-spacing: pxToRem(-0.2px);
        margin-bottom pxToRem(12px)

      > button
        border-radius pxToRem(12px)
        background: var(--Blue-10, #EBF3FE);
        display: inline-flex;
        min-width: pxToRem(160px)
        padding: pxToRem(14px) pxToRem(24px) pxToRem(16px) pxToRem(24px);
        align-items: center;
        text-align center
        gap: pxToRem(8px)
        color: var(--Blue-100, #1C75FF);
        font-family: "Neue Haas Unica Pro";
        font-size: pxToRem(24px);
        font-style: normal;
        font-weight: 700;
        line-height: pxToRem(34px); /* 141.667% */
        letter-spacing: -0.2px;


.BrandCommerceRequestEditor
  position relative
  z-index 4
  background url(//s3.marpple.co/files/u_14345/2024/6/original/8874d1a7c86678b2ab391ff9369e35aad270f6201.png) no-repeat;
  background-size cover;
  background-position center;
  width: 100%;
  height 100vh;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #fff;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0 1000px #123123123123 inset;
  }
  input
    appearance auto

  .bg
    background: var(--Dim-60, rgba(20, 20, 20, 0.60));
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px); /* Add this line first, it fixes blur for Safari*/
    height 100%
  *
    box-sizing border-box
    /* KR-16/Medium */
    font-family: Pretendard;
    font-size: pxToRem(16px);
    font-style: normal;
    font-weight: 500;
    line-height: pxToRem(24px); /* 150% */
    letter-spacing: pxToRem(-0.2px);
  .close
    display: flex;
    height: pxToRem(72px);
    padding: pxToRem(16px) pxToRem(0px);
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    button
      border 0 none
      display: flex;
      width: pxToRem(40px);
      height: pxToRem(40px);
      padding: pxToRem(10px);
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: pxToRem(999px);
      background: var(--Bright-5, rgba(255, 255, 255, 0.05));
      svg
        flex-shrink: 0;
  .body
    flex-shrink: 0;
  .form
    margin pxToRem(32px) auto 0
    display: flex;
    width: pxToRem(800px);
    flex-direction: column;
    align-items: flex-start;
    gap: pxToRem(24px);
    > .header
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: pxToRem(8px);
      align-self: stretch;
      .text1
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: pxToRem(12px);
        span
          color: var(--Blue-100, #1C75FF);
          /* KR-16/Medium */
          font-size: pxToRem(16px);
          font-weight: 500;
          line-height: pxToRem(24px); /* 150% */
        h2
          margin 0
          padding 0
          color: var(--White, #FFF);
          font-size: pxToRem(48px);
          font-weight: 700;
          line-height: pxToRem(60px); /* 125% */
      .text2
        color: #FFF;
        font-size: pxToRem(20px);
        font-weight: 500;
        line-height: pxToRem(32px); /* 160% */

    > .inputs
      width: pxToRem(800px); flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: pxToRem(12px); display: flex;
      *
        color: #A2A2A2;
      .marppleshop-url-display.hide
        display none !important
      .input-wrap
        padding-top: pxToRem(12px);
        padding-bottom: pxToRem(12px);
        border-bottom: pxToRem(1px) #A2A2A2 solid;
        justify-content: flex-start;
        align-items: flex-start;
        display: flex;
        width 100%
        position relative
        input[type="text"],
        input[type="email"]
          width 100%
          word-wrap: break-word
          background transparent
          border 0 none
          color #fff
        label
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          pointer-events: none;
          transition: 0.2s ease all;
          display none

          .asterisk
            content: ' *';
            color: var(--Red-80, #D92A0F);

            /* KR-14/Medium */
            font-family: Pretendard;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 157.143% */
            letter-spacing: -0.2px;

        input:placeholder-shown + label
          display block

        textarea
          width 100%
          height pxToRem(80px)
          background transparent
          border 0 none
          color #fff
        textarea::placeholder {
          color: #A2A2A2;
        }
        input
        textarea
          outline none
        button
          border-radius pxToRem(999px)
          border 0 none
          background: var(--Gray-5, #F5F5F5);
          display: flex;
          width: pxToRem(24px);
          height: pxToRem(24px);
          padding: pxToRem(6.4px);
          justify-content: center;
          align-items: center;
          gap: pxToRem(8px);
          flex-shrink: 0;
      > .input-wrap:nth-child(2)
        gap: pxToRem(24px);
        input[type="text"],
        input[type="email"]
          width pxToRem(230px)
          margin-right pxToRem(-18px)
      .input-wrap2
        display: flex;
        align-items: flex-start;
        gap: pxToRem(16px);
        align-self: stretch;
        .input-wrap
          display: flex;
          padding: pxToRem(12px) pxToRem(0px);
          align-items: flex-start;
          flex: 1 0 0;
      .info
        height pxToRem(24px)
        color: var(--Gray-50, #A2A2A2);
        /* KR-12/Medium */
        font-size: pxToRem(12px);
        font-weight: 500;
        line-height: pxToRem(17px); /* 141.667% */
    .footer
      .agree
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: pxToRem(8px);

        .agree_checkbox
          display flex
          align-items center

        .agree_checkbox input[type="checkbox"]
          display: none;

        .agree_checkbox svg
          width: pxToRem(16);
          height: pxToRem(16);
          margin-right: pxToRem(4);
          cursor pointer;

        .agree_checkbox .checked
          display: none;


        .agree_checkbox input[type="checkbox"]:checked ~ .unchecked
          display: none;


        .agree_checkbox input[type="checkbox"]:checked ~ .checked
          display: inline;

        label
          color: var(--White, #FFF);
          font-size: pxToRem(16px);
          font-weight: 500;
          line-height: pxToRem(24px); /* 150% */
        p
          margin 0
          padding 0
          color: var(--White, #FFF);
          /* KR-12/Medium */
          font-size: pxToRem(12px);
          font-weight: 500;
          line-height: pxToRem(17px); /* 141.667% */

          strong
            font-size inherit
            line-height inherit
            font-weight 700
      button
        margin-top pxToRem(24px)
        border 0 none
        display: flex;
        padding: pxToRem(16px) pxToRem(24px);
        align-items: center;
        gap: pxToRem(8px);
        border-radius: pxToRem(12px);
        background: var(--Gray-5, #F5F5F5);
        color: var(--Gray-50, #A2A2A2);
        text-align: center;
        /* KR-16/Bold */
        font-size: pxToRem(16px);
        font-weight: 700;
        line-height: pxToRem(24px); /* 150% */
        transition color 0.2s ease-in-out, background-color 0.2s ease-in-out
        pointer-events none
        &.active
          color: var(--White, #FFF);
          background: var(--Blue-100, #1C75FF);
          pointer-events auto



html.brand-commerce .don_small_loader_wrap, .don_loader_wrap
  background: rgba(255, 255, 255, 0.5);
