
html body.mall_help
  letter-spacing ptr(-0.4px)
html body.mall_help
html body.crew-mall-solution
  .crew-mall-header
    border-bottom 1px solid #dcdcdc
  .crew-mall-header-body
    max-width ptr(1120px)
  .store-help
    &[type="creator_mall_customer_faq_kr"]
    &[type="creator_mall_customer_faq_jp"]
    &[type="creator_mall_customer_faq"]
      .help_tab li:nth-child(1)
        border-bottom-color #000
        a
          color color_black
    &[type="creator_mall_faq_kr"]
    &[type="creator_mall_faq_jp"]
    &[type="creator_mall_faq"]
      .help_tab li:nth-child(2)
        border-bottom-color #000
        a
          color color_black

html.mall_help
  .store-help
    .store-help-header
      height auto
      padding 0
      .help_tab
        margin-bottom ptr(32px)
        margin-top ptr(24px)
        ul
          margin 0
          padding 0
          list-style none
          display flex
          li
            width 50%
            border-bottom 1px solid #d5dbe0
            a
              display inline-block
              padding ptr(12px) 0
              width 100%
              text-align center
              color $black


body.creator_store.help
  .store-help-header
  .store-body > h2
    display none

.store-help
  width 100%
  background-color #ffffff
  display flex
  flex-direction column
  align-items center
  html.creator-settings &
    padding-top pxToRem(32px)
    padding-bottom pxToRem(40px)
    min-height pxToRem(400px)
  &-header
    width 100%
    height pxToRem(110px)
    padding pxToRem(40px) pxToRem(20px) 0
    box-sizing border-box
    display flex
    flex-direction column
    justify-content space-between
    html.creator-settings &
      display none
    *
      margin 0
    h2
      font-size pxToRem(20px)
    h3
      font-size pxToRem(14px)
      display flex
      align-items center
      &[data-help-group-selected="false"]
        display none
      img
        margin-right pxToRem(10px)
        max-width pxToRem(14px)
        max-height pxToRem(14px)
  &-body
    width 100%
    background-color #f5f5f5
    &-left
      margin 0
      padding pxToRem(10px) 0 0
    &-groups
      list-style none
      margin 0
      padding 0
      li
        height pxToRem(66px)
        width 100%
        box-sizing border-box
        background-color #ffffff
        a
          width 100%
          height 100%
          box-sizing border-box
          display flex
          align-items center
          span
            flex-grow 1
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          img
            max-width pxToRem(40px)
            max-height pxToRem(40px)
            transform rotate(-90deg)
            margin-left pxToRem(10px)
    &-groups[data-help-group-selected="true"]
      display none
    &-pages
      margin 0
      padding 0
      list-style none
      .help_item
        padding 0 pxToRem(20px)
        background-color #ffffff
        .question
          height pxToRem(66px)
          width 100%
          box-sizing border-box
          margin 0
          display flex
          align-items center
          span
            flex-grow 1
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          img
            max-width pxToRem(40px)
            max-height pxToRem(40px)
            margin-left pxToRem(10px)
            transform rotate(180deg)
        .answer
          padding 0
          margin-bottom pxToRem(16px)
          overflow-wrap break-word
          background-color $white
          display flex
          flex-direction column
          row-gap pxToRem(4px)
          p
            margin 0
            padding 0
            font-size pxToRem(14px)
            color $black
            span
              display inline-block
              margin-bottom pxToRem(4px)
          table
            margin pxToRem(20px) 0
            width 100%
            table-layout fixed
            border-collapse collapse
            text-align center
            th
            td
              padding pxToRem(11px) 0
              color #333
              font-size pxToRem(12px)
              border pxToRem(1px) solid #e9ecef
              font-weight 200
            th
              font-weight 600
              text-align center
              color color_black
              background-color $white
            td
              color color_black
          img
            margin-top pxToRem(10px)
            max-width 100%
          ul, ol
            padding 0 0 0 pxToRem(16px)
            margin pxToRem(6px) 0
      .help_item[data-selected="false"]
        .answer
          display none
        img
          transform rotate(0)
    &-pages[data-help-group-selected="false"]
      display none
  .cs_menu_mobile
    margin-top pxToRem(40px)
    width 100%
    > div
      height pxToRem(66px)
      width 100%
      margin-bottom pxToRem(10px)
      box-sizing border-box
      background-color $white
      font-size pxToRem(14px)
      font-weight 600
      line-height 1.29
      color #000
      a
        display flex
        align-items center
        height 100%
        color #000
        background #fafafa no-repeat pxToRem(20px) 50%
        background-size pxToRem(18px) auto
    .solution
      a
        padding-left pxToRem(48px)
        padding-right pxToRem(20px)
        background-image url("//s3.marpple.co/files/u_218933/2020/7/original/31568008a737ff3455a5839f426bd2fa3dd66feeb4183c0.png")


html.shopify-help
  .store-help
    &-header
      padding-top pxToRem(20px)
    &-body
      margin-top 0
      margin-bottom pxToRem(60px)

.help_body[data-is_store_help="true"]
  margin-top pxToRem(60px)

.store-help
  margin-bottom pxToRem(80px)
  &-header
    padding pxToRem(60px) 0 0 0
    justify-content center
    &::after
      display none
    h2
      font-size pxToRem(26px)
    h3
      display none
  &-body
    display flex
    justify-content space-between
    background-color #ffffff
    &-left
      .cs_menu
      display none
      margin-top pxToRem(40px)
      margin-right pxToRem(40px)
      ul
        margin 0
        padding 0
        list-style none
      li
        display flex
        align-items center
        background $white
        padding 0 ptr(16)
        &.email
          display none
        &.chat
          display none
        &.solution
          display none
        a
          color #000
    &-groups
      display inline-block !important
      min-width pxToRem(210px)
      flex-shrink 0
      margin 0
      padding 0 pxToRem(20px) 0 0
      list-style none
      li
        height pxToRem(48px)
        img
          display none
        a
          padding 0
          span
            color $gray-50
      li[data-selected="true"]
        span
          color $black
    &-pages
      width 0
      flex-grow 1
      padding 0
      .help_item
        box-sizing border-box
        border-bottom pxToRem(1px) solid $gray-10
        padding 0 pxToRem(30px)
        .question
          height pxToRem(72px)
          color $black
          cursor pointer
        .answer
          padding pxToRem(24px)
  .cs_menu_mobile
    display none
