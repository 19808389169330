html.login_page
  #footer
    display none

  .creator_login_frame
    .don_wrapper
      margin 0 auto
      > .header
        overflow visible !important
        height auto !important
        background-color transparent
        .don_hide_frame
          display none
    .don_page.creator_login_page
      min-height auto !important
      .don_wrapper .header
        height auto !important

html:not(.maker_page)
  .creator_login_frame
    > .don_wrapper
      .don_page
        > .don_wrapper > .header
          margin-top 72px
.creator_login_frame
  z-index 100 !important
  > .don_wrapper > .header
    height 48px
    .title
      display none
  .don_page
    .custom_title
      .custom_title_btn
        display none
    > .don_wrapper > .header
      margin 136px auto 40px
      width 520px
      height 64px
      text-align center
      z-index 1
    h1
      margin 0 0 48px
      padding 0
      img
        height 32px
    .tab_buttons
      height 100%
      display flex
      button.tab_button
        flex 1
        position relative
        z-index 1
        margin 0
        padding 0
        height 50px
        box-sizing border-box
        border-bottom solid 1px #dddddd
        background-color #fff
        outline none
        font-size 16px
        font-weight normal
        text-align center
        color #111111
        &:first-child
          margin-right -1px
        &:last-child
        html[lang="en"] &
          font-family CircularStd
        &:active
          color #111111
        &[selected="true"]
          color #111111
          border-color #111
          font-weight 600
    .don_tab
      background-color #fff
  .don_page.creator_login_page .don_tab
  .don_page.creator_login_page .don_tab
    > .don_wrapper > .header
      display none
    > .don_wrapper > .wrap
      position absolute
      top 0
      left 0
      right 0
      width 520px
      margin 0 auto
      padding-bottom 60px
    p.txt1
      margin 0
      padding 4% 0 5%
      font-size 24px
      font-weight 600
      color #212529
    .login_input
      .control
        margin 0 0 24px
        label
          display block
          font-size 16px
          font-weight 600
          line-height 1.38
          letter-spacing -0.2px
          color #111111
          margin-bottom 10px
        input
          width 100%
          padding 16px 24px 14px
          margin 0
          color #111
          font-size 16px
          box-sizing border-box
          border solid 1px #dddddd
          background-color #fff
          border-radius 4px
          &::-webkit-input-placeholder
          &:-ms-input-placeholder
          &::placeholder
            color #a2a2a2
    .gender
      margin 20px 0 0
      label
        position relative
        margin-right 24px
        color #111
        > *
          vertical-align middle
        span.radio_box
          display inline-block
          width 16px
          height 16px
          margin-right 8px
          background url(//s3.marpple.co/files/u_18053/2018/10/original/f_6008_1539249097835_AtG6XVyrVUB8ILV6xJ.png) no-repeat
          background-size cover
        input[type="radio"]
          position absolute
          top 0
          left 0
          visibility hidden
        &.on
          span.radio_box
            background-image url(//s3.marpple.co/files/u_18053/2018/10/original/f_6007_1539249097835_J9HXd3f3Hd4pXt1X9XJBR.png)
    .agree_email
      margin 32px 0 24px
      label
        display block
        position relative
        padding-left 24px
        padding-bottom 14px
        font-size 14px
        line-height 1.38
        letter-spacing -0.2px
        color #111111
        .check_box
          display block
          position absolute
          top 1px
          left 0
          width 16px
          height 16px
          background url(//s3.marpple.co/files/u_14355/2018/10/original/f_12369_1540968528808_wa9m8Im4uHIc9u0ORxMn.png) no-repeat
          background-size cover
        input[type="checkbox"]
          position absolute
          top 0
          left 0
          visibility hidden
        a
          color #111
          text-decoration underline
        &.on
          .check_box
            background-image url(//s3.marpple.co/files/u_14355/2018/10/original/f_12370_1540968742928_EEZcT5AfHu3P9SBPR2w.png)
    button[type="button"]
      width 100%
      height 52px
      color #fff
      border 0 none
      box-sizing border-box
      font-size 16px
      letter-spacing 1px
      text-align center
      font-weight 600
      border-radius 4px
      background-color #111
    .submit
      margin 24px 0 0
    .fb_submit
      margin 16px 0 0
      button[type="button"]
        color #4167b2
        background-color #f8f9fa
        &:before
          content ''
          background-image url("//s3.marpple.co/files/u_29089/2019/1/original/f_23324_1548301193608_UQk6c1C0lREqzRyt5FJ.svg")
          width 8px
          height 12px
          display inline-block
          vertical-align top
          margin 4px 8px 0 0
    .or
      margin 16px 0
      text-align center
      font-size 12px
      line-height 1.25
      color color_gray3
      position relative
      html[lang="en"] &
        font-family CircularStd
      &:before
        content ''
        width 24px
        height 1px
        background-color #e9ecef
        display inline-block
        vertical-align top
        margin 7px 8px 0 0
      &:after
        content ''
        width 24px
        height 1px
        background-color #e9ecef
        display inline-block
        vertical-align top
        margin 7px 0 0 8px
    .forgot
      margin 32px 0 0
      display flex
      align-items center
      justify-content center
      font-size 12px
      padding 0 1% 40px 1%
      color #dddddd
      gap 10px
      a
        font-size 14px
        font-weight 600
        line-height 1.71
        letter-spacing -0.2px
        text-align center
        color #111
        text-decoration underline
    .forgot_password
      margin 32px 0 0
      padding 0 1% 40px 1%
      font-size 14px
      text-align center
      color #111
      font-weight 600
      a
      span
        cursor pointer
        color #111
        text-decoration underline
    p.txt3
      margin 20px 0 0
      padding 0 1% 10% 1%
      font-size 12px
      text-align center
      color #111
      a
      span
        cursor pointer
        color #111
        text-decoration underline


.mp_email_certification
  .certification
    width 400px
    margin 200px auto 0
    padding 20px
    background-color #fff
    border-radius 10px


html:not([lang="kr"])
  .mp_id_pw_find_frame
    > .don_wrapper
      width 427px !important
      height 336px !important
      .don_page[page_name="@PAGE/Creator/Login/id_pw_find"] .don_tab
      .don_page[page_name="@PAGE/Shopify/Login/id_pw_find"] .don_tab
        .id_pw_find_area .reset_pw_area
          h2
            margin 24px 0 0
            font-size 20px
          p.txt1
            padding 24px 0 24px
            font-size 14px
        .id_pw_find_area .id_pw_area
          display none
        .controls
          .control
            margin 0 0 24px
            input[type="email"]
              height 40px

.mp_id_pw_find_frame
  z-index 101 !important
  > .don_wrapper
    width 427px !important
    height 620px !important
  > .don_wrapper > .header
    height 48px
    .don_hide_frame__
      position absolute
      top 5px
      right 0
      width 40px !important
      height 40px !important
      z-index 11
      background-position 50% 50% !important
      background-size 12px auto
  .don_page
    > .don_wrapper > .header
    .tab_buttons
      display none
    .don_tab
      background-color #fff
  .don_page[page_name="@PAGE/Creator/Login/id_pw_find"] .don_tab
  .don_page[page_name="@PAGE/Shopify/Login/id_pw_find"] .don_tab
    > .don_wrapper > .header
      display none
    > .don_wrapper .reset_pw_area
      width 327px
      margin 0 auto
      h2
        margin 26px 0 0
        padding 0
        font-size 16px
        color #111
      p.txt1
        margin 0
        padding 24px 0 24px
        font-size 14px
        line-height 1.71
        color #111
        &[lang="kr"]
          display none
    > .don_wrapper .id_pw_area
      width 327px
      margin 34px auto 0
      h2
        margin 0
        padding 30px 0 0
        font-size 16px
        color #111
      p.txt1
        margin 0
        padding 24px 0 24px
        font-size 14px
        line-height 1.71
        color #111
    .controls
      margin-top 20px
      .control
        margin 0 0 14px
        input[type="text"]
        input[type="email"]
          width 100%
          padding 16px 24px 14px
          margin 0
          color #111
          font-size 16px
          box-sizing border-box
          border solid 1px #dddddd
          background-color #fff
          border-radius 4px
          &::-webkit-input-placeholder
          &:-ms-input-placeholder
          &::placeholder
            color #a2a2a2
    .submit
      button[type="button"]
        width 100%
        height 52px
        color #fff
        border 0 none
        box-sizing border-box
        font-size 16px
        letter-spacing 1px
        text-align center
        font-weight 600
        border-radius 4px
        background-color #111
  .don_page[page_name="mp.phone_cert_page"]
    > .don_wrapper > .header
      display none
    .don_tab
      > .don_wrapper .phone_cert_area
        width 327px
        margin 0 auto
        h2
          margin 0
          padding 20px 0 0
          font-size 20px
          font-weight normal
          color #111
        p.txt1
          margin 0
          padding 24px 0 24px
          font-size 16px
          line-height 1.71
          color #111
          &[lang="kr"]
            display none
      .controls
        margin-top 10px
        .control
          margin 0 0 14px
          input[type="text"]
            width 100%
            padding 16px 24px 14px
            margin 0
            color #111
            font-size 16px
            box-sizing border-box
            border solid 1px #dddddd
            background-color #fff
            border-radius 4px
            &::-webkit-input-placeholder
            &:-ms-input-placeholder
            &::placeholder
              color #a2a2a2
      .submit
        button[type="button"]
          margin-bottom 20px
          width 100%
          height 52px
          color #fff
          border 0 none
          box-sizing border-box
          font-size 16px
          letter-spacing 1px
          text-align center
          font-weight 600
          border-radius 4px
          background-color #111
          &.btn_resend
            margin-top 40px
            color #111
            height 36px
            font-size 12px
            background-color #ccc
  .don_page[page_name="mp.phone_cert_complete_page"]
    > .don_wrapper > .header
      display none
    .phone_cert_complete_area
      width 327px
      margin 0 auto
      h2
        margin 0
        padding 20px 0 0
        font-size 20px
        font-weight normal
        color #111
      p.txt1
        margin 0
        padding 24px 0 24px
        font-size 15px
        line-height 1.71
        color #111
        &[lang="kr"]
          display none
      .users
        margin-bottom 30px
        ul
          margin 0
          padding 0
          list-style none
          li
            margin-bottom 10px
            input[type="radio"]
              margin-right 10px
              -webkit-appearance auto
      .buttons
        button[type="button"]
          margin-bottom 20px
          width 100%
          height 52px
          color #fff
          border 0 none
          box-sizing border-box
          font-size 16px
          letter-spacing 1px
          text-align center
          font-weight 600
          border-radius 4px
          background-color #111
          &.btn_resend
            margin-top 40px
            color #111
            height 36px
            font-size 12px
            background-color #ccc
  .don_page[page_name="mp.cert_reset_password_page"]
    > .don_wrapper > .header
      display block
    .phone_cert_complete_area
      width 327px
      margin 0 auto
      h2
        margin 0
        padding 80px 0 0
        font-size 20px
        font-weight normal
        color #111
      p.txt1
        margin 0
        padding 24px 0 24px
        font-size 16px
        line-height 1.71
        color #111
        &[lang="kr"]
          display none
      .controls
        margin-top 30px
        .control.email
          label
            font-size 16px
        .control
          margin 0 0 14px
          input[type="password"]
            width 100%
            padding 16px 24px 14px
            margin 0
            color #111
            font-size 16px
            box-sizing border-box
            border solid 1px #dddddd
            background-color #fff
            border-radius 4px
            &::-webkit-input-placeholder
            &:-ms-input-placeholder
            &::placeholder
              color #a2a2a2
      .buttons
        button[type="button"]
          margin-bottom 20px
          width 100%
          height 52px
          color #fff
          border 0 none
          box-sizing border-box
          font-size 16px
          letter-spacing 1px
          text-align center
          font-weight 600
          border-radius 4px
          background-color #111

.reset_password_frame
  z-index 101 !important
  > .don_wrapper
    width 427px !important
    height 336px !important
  > .don_wrapper > .header
    height 48px
    .title
      display none
    .don_hide_frame__
      position absolute
      top 5px
      right 0
      width 40px !important
      height 40px !important
      z-index 11
      background-position 50% 50% !important
      background-size 12px auto
  .don_page
    > .don_wrapper > .header
    .tab_buttons
      display none
    .don_tab
      background-color #fff
  .don_page[page_name="mp.reset_password_page"] .don_tab
    > .don_wrapper > .header
      display none
    > .don_wrapper > .reset_pw_area
      position absolute
      top 0
      left 0
      right 0
      width 327px
      margin 0 auto
      h2
        margin 60px 0 0
        padding 0
        font-size 20px
        font-weight normal
        color #111
      p.txt1
        margin 0
        padding 24px 0 24px
        font-size 14px
        line-height 1.71
        color #111
    .controls
      .control
        margin 0 0 24px
        input
          width 100%
          padding 16px 24px 14px
          margin 0
          color #111
          font-size 16px
          box-sizing border-box
          border solid 1px #dddddd
          background-color #fff
          border-radius 4px
          &::-webkit-input-placeholder
          &:-ms-input-placeholder
          &::placeholder
            color #a2a2a2
    .submit
      button[type="button"]
        width 100%
        height 52px
        color #fff
        border 0 none
        box-sizing border-box
        font-size 16px
        letter-spacing 1px
        text-align center
        font-weight 600
        border-radius 4px
        background-color #111

.change_password
  z-index 101 !important
  > .don_wrapper > .header
    height 48px
    .title
    .don_hide_frame
      display none
  .don_page
    > .don_wrapper > .header
    .tab_buttons
      display none
    .don_tab
      background-color #fff
  .don_page .don_tab
    > .don_wrapper > .header
      display none
    > .don_wrapper > .verify_change_pw
      width 327px
      min-height 500px
      margin 0 auto 140px
      h2
        margin 0
        padding 244px 0 0
        font-size 32px
        color #111
        text-align center
        background url(//s3.marpple.co/files/u_14355/2018/11/original/f_12428_1541140904027_UJ5bp6OsZC4TA1l8sfdz.png) no-repeat
        background-size 80px auto
        background-position 50% 140px
        html[lang="en"] &
          font-family CircularStd
      h3
        margin 0
        padding 30px 0 0
        font-size 14px
        line-height 1.71
        color #111
      p.txt2
        margin 13px 0 0
        padding 0
        font-size 14px
        line-height 1.71
        color #111
    .controls
      margin-top 20px
      .control
        margin 0
        input
          width 100%
          padding 16px 24px 14px
          margin 0
          color #111
          font-size 16px
          box-sizing border-box
          border solid 1px #dddddd
          background-color #fff
          border-radius 4px
          &::-webkit-input-placeholder
          &:-ms-input-placeholder
          &::placeholder
            color #a2a2a2
    .submit
      margin-top 40px
      button[type="button"]
        margin-bottom 5px
        width 100%
        height 52px
        color #fff
        border 0 none
        box-sizing border-box
        font-size 16px
        letter-spacing 1px
        text-align center
        font-weight 600
        border-radius 4px
        background-color #111

html[lang="kr"]
  .mp_email_verify_frame
    > .don_wrapper
      width 475px !important
.mp_email_verify_frame
  z-index 110 !important
  > .don_wrapper
    width 475px !important
    > .header
      position absolute
      top 0
      left 0
      right 0
      .title
        font-size 26px
        font-weight 600
        text-align center
    > .body
      margin-top 100px
      .email_verify_cont
        margin 0 20px
        padding 0 50px 30px
        text-align center
        .email
          margin-bottom 10px
        p.txt1
          margin 0 0 10px
          padding 0
          line-height 1.83
        .p.txt2
          margin 0
          padding 18px 0 0
          border-top 1px solid #ccc
        .btn_resend_email_verify
          margin-top 40px
          margin-bottom 20px
          button
            text-align center
            height 52px
            color #fff
            padding 0 30px
            border 0 none
            box-sizing border-box
            font-size 16px
            letter-spacing 1px
            font-weight 600
            border-radius 4px
            background-color #111