.don_frame[frame_name='/modules/Creator/Login/FindId/Result/S/Mui/frame.js']
  .don_wrapper
    .body
      background-color #fff
  .find-id-result-wrapper
    display flex
    .find-id-result
      display flex
      flex-direction column
      align-items center
      margin 0 auto
      width pxToRem(520px)
      &__sub-title
        width 100%
        padding pxToRem(24px) 0
        margin 0
        font-size pxToRem(17px)
        font-weight 600
        line-height 1
        letter-spacing -0.3px
        text-align left
        color $gray-1
      &__emails
        width 100%
        padding-bottom pxToRem(50px)
        display flex
        flex-direction column
        gap pxToRem(16px)
      &__radio-label
        display flex
        gap pxToRem(8px)
        span
          font-size pxToRem(15px)
          letter-spacing -0.2px
          color $black
        >input[type='radio']
          margin-top -1px
          margin-left 0
          vertical-align middle
          appearance none
          border 1px solid $gray-s1
          border-radius 50%
          width pxToRem(18px)
          height pxToRem(18px)
        >input[type="radio"]:checked
          border pxToRem(6px) solid #3c89f9
      &__buttons
        display flex
        width 100%
        gap pxToRem(10px)
      &__button
        width 100%
        height pxToRem(56px)
        background-color #a2a2a2
        border-radius pxToRem(8px)
        border none
        padding 0
        margin 0
        color white
        font-size pxToRem(16px)
        font-weight bold
        &.reset_pw
          border solid 1px $black
          color $black
          background-color $white
        &.login
          color $white
          background-color $black
