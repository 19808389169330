.{a-p-i}--v5
  width 45%
  margin-bottom 80px
  margin-right 8px
  box-sizing border-box
  max-width 234px
  .{a-p-i}
    &__like
      display block
    &__store-name
      display block
      color #111
      font-size 15px
      margin-bottom 6px
      letter-spacing -0.3px
    &__p-name
      color #4e4e4e
      letter-spacing -0.3px
    &__price
      font-size 18px
      letter-spacing -0.5px
      font-weight 600
      color #111
    &__bottom-info
      margin-top 18px
  .mp-product-img-bg-wrap
    border-radius 8px
