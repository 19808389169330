// badge 컬러 스타일만!
.app-product-item--NESS
  .app-product-item__badge
    &[data-type="limited"]
    &[data-type="collection"]
      color $ness-white
      background-color $ness-black

    &[data-type="sale"]
    &[data-type="period_limited"]
    &[data-type="quantity_limited"]
      color $ness-black
      background-color $ness-blue

    &[data-type="new"]
    &[data-type="ranking"]
      color $ness-black
      background-color $ness-gray-60
    &[data-type="custom"]
      display none
      color $ness-black
      background-color $ness-orange
