.don_frame[frame_name="/modules/MShop/App/CustomerOrder/S/Mui/frame.js"]
  .mobile
    display none
  @import "../share.styl"
  .up-item-product-info
    display flex
    flex-direction column
    row-gap pxToRem(4px)
    &__name
      marginPaddingZero()
      &-value
        marginPaddingZero()
        overflow inherit
        word-break break-all
        word-wrap initial
        -webkit-box-orient initial
        color $black
    &__up_c_id
      marginPaddingZero()
      color $gray-80
    &__second-name
      marginPaddingZero()
      color $gray-50
    &__price
      marginPaddingZero()
      color $black
      &:after
        marginPaddingZero()
        {$kr-20-b}
  .customer-order-footer-info
    &__header
      box-sizing border-box
      border-bottom 1px solid $black
      padding-bottom pxToRem(12px)
      height pxToRem(37px)
      &__revise-button,
      &__receipt-info-button
        color $blue
        background-color $light-blue
        padding pxToRem(4px) pxToRem(8px)
        border none
        border-radius pxToRem(4px)
      &__title
        color $black
    &__body
      margin-top pxToRem(8px)
      &__row
        padding-top pxToRem(12px)
        padding-bottom pxToRem(12px)
        align-items center
        display grid
        grid-template-columns ptr(128) minmax(pxToRem(200px), auto)
        grid-column-gap ptr(48)
      &__label
        color $gray-50
      &__content
        color $black
        &.mp_currency
          color $black
          &:after
            {$kr-14-m}
        &.mp_currency.bold
          &:after
            {$kr-14-b}
        &.mp_currency.total-order-price
          &:after
            {$kr-20-b}
        &--total-qty
          color $black
          &[data-lang="kr"]
            &:after
              content '개'
              {$kr-14-m}

  .up-item
    /* start from upItem pc index */
    &__warning-message
      padding-top pxToRem(18px)
      font-size pxToRem(13px)
      display flex
      align-items flex-start
      img
        width pxToRem(15px)
        padding-bottom pxToRem(2px)
      span
        margin-left pxToRem(4px)
        line-height pxToRem(18px)
        color #f66565
    &__thumbnail-wrapper
      img
        width pxToRem(136px)
        height pxToRem(136px)
    /* end from upItem pc index */
    .up-item-buttons
      box-sizing border-box
      display flex
      justify-content center
      align-items center
      width 100%
      height pxToRem(40px)
      border-radius pxToRem(8px)
      padding 0
      backdrop-filter blur(20px)
      -webkit-backdrop-filter blur(20px)
      &__row
        display flex
        width 100%
        margin-bottom pxToRem(8px)
        column-gap pxToRem(8px)
        &:last-child
          margin 0
      &__digital-product-download
        background none
        border 1px solid $gray-20
        color $black
      &__review
        background $light-blue
        color $blue
        box-sizing border-box
        border none
      &__confirmed
        background none
        border 1px solid $gray-20
        color $black
      &__re-purchase
        background none
        border 1px solid $gray-20
        color $black
      &__contact,
      &__contact-link,
      &__cancel
        backdrop-filter blur(20px)
        box-sizing border-box
        display flex
        justify-content center
        align-items center
        width 100%
        height pxToRem(40px)
        border-radius pxToRem(8px)
        padding 0
        background $white
        border 1px solid $gray-20
        color $black
    .lower-canvas
      width 200% !important
      height 200% !important
    margin-bottom pxToRem(20px)
    &:last-child
      border none
    &__row
      box-sizing border-box
      display grid
      min-height pxToRem(136px)
      grid-gap pxToRem(32px)
      grid-template-columns auto  minmax(auto ,pxToRem(320px)) minmax(auto, pxToRem(140px))
    &__row-left
      display flex
      flex-direction column
    &__row-middle
      display flex
      justify-content center
      align-items center
    &__row-right
      display flex
      justify-content center
      align-items center
    &__thumbnail-product-wrapper
      box-sizing border-box
      display grid
      min-height pxToRem(105px)
      grid-gap pxToRem(16px)
      grid-template-columns pxToRem(136px) minmax(pxToRem(200px), auto)
    &__order-process-step-wrapper
      display flex
      flex-direction column
      justify-content center
      align-items center
      row-gap pxToRem(4px)
      &__order-process-step
        overflow hidden
        white-space nowrap
        text-overflow ellipsis
        color $black
      &__delivery-date
        color $gray-80
        white-space nowrap
        > span:first-child
          color $blue
      &__tracking
        cursor pointer
        color $gray-80
        &__invoice-number
          text-decoration-line underline
          margin-left pxToRem(8px)
      &__pickup
        margin-top pxToRem(8px)
    &__buttons
      width 100%

  .customer-order
    padding-top pxToRem(24px)
    &__order-at-wrapper
      padding pxToRem(24px)
      margin-bottom pxToRem(40px)
      border-radius pxToRem(8px)
      background-color #fafafa
    &__main-body
      margin-bottom pxToRem(60px)
      &__header
        display none
    &__footer-wrapper
      display grid
      grid-gap pxToRem(120px)
      grid-template-columns 1fr 1fr
      margin-bottom pxToRem(60px)
    &__back-to-list
      display flex
      align-items center
      justify-content center
      color $white
      background $blue
      border-radius pxToRem(8px)
      padding 0 ptr(24)
      height ptr(48)
      text-align center
      margin 0 auto pxToRem(80px)
      box-sizing border-box
      min-width ptr(160)
      max-width fit-content
      {$button-blue-theme}
  .store-order
    &__header
      padding-bottom pxToRem(12px)
      display flex
      align-items center
      border-bottom 1px solid $black
    &__name
      display flex
      align-items center
      column-gap pxToRem(4px)
    .store-logo
      width pxToRem(20px)
      height pxToRem(20px)
      {$image-store-logo}
      order 1
    &__name-text
      order 0
      color $black
  .shipping-order
    margin-bottom pxToRem(20px)
    border-bottom 1px solid #f2f2f2
    padding-bottom pxToRem(8px)
    &:last-child
      border-bottom none
      padding-bottom 0
    &__up-items-wrapper
      display flex
      flex-direction column
    &__sid
      color $gray-50
      margin-bottom pxToRem(16px)
      display flex
      align-items center
      justify-content space-between
    &__name
      color $black
    &__header
      box-sizing border-box
      display flex
      justify-content space-between
      width 100%
      margin-top pxToRem(16px)
      margin-bottom pxToRem(12px)
    &__amount-btn
      color $blue
      background-color $light-blue
      padding pxToRem(4px) pxToRem(8px)
      border none
      border-radius pxToRem(4px)
  .shipping-order-price
    display flex
    justify-content space-between
    margin-bottom ptr(8)
    max-width calc(100% - 37.5rem)
    border-top 1px solid $black
    padding pxToRem(16px) 0 0 0
    color $black
    &__left
      color $black
    &__right
      color $black
      &:after
        {$kr-14-m}
    &--additional
      padding 0
      border-top none

