$mshop-default-header = '.mshop-default-header'
$transition_common = 0.3s ease

.mshop-default-header
  position relative
  height pxToRem(80px)
  width 100%
  &__wrapper
    background-color rgba(255,255,255,1)
    color #000000
  &__inner
    pc-layout-inner-style()
    display flex
    align-items center
    height pxToRem(80px)
  &__logo-box
    width pxToRem(120px)
    height pxToRem(40px)
    display flex
    align-items center
    position relative
  &__logo
    margin auto
    position absolute
    top 0
    bottom 0
    left 0
    max-width 100%
    max-height 100%
  &__menus
    margin-left 120px
    display flex
    gap 60px
  &__menu
    color $gray-800
    {$font-heading-h5-r}
  &__menu[data-active="true"]
    {$font-heading-h5-b}
  &__right
    margin-left auto
    display flex
    align-items center
    column-gap pxToRem(24px)
  &__search
    position relative
  &__search-input
    width pxToRem(400)
    padding pxToRem(15) pxToRem(24)
    border-radius pxToRem(21)
    background $gray-100
    border 1px solid $gray-300
    background-color rgba(255, 255, 255, 0.10)
    backdrop-filter blur(10px)
    -webkit-backdrop-filter blur(10px)
    &::placeholder
      color $gray-800
    &:focus::placeholder
      color rgba(0,0,0,0)
  &__search-icon
    width pxToRem(24)
    height pxToRem(24)
    position absolute
    right pxToRem(20)
    top 50%
    transform translateY(-50%)
    cursor pointer
  &__mypage
    width pxToRem(28)
    height pxToRem(28)
    cursor pointer
  &__shopping-bag
    width pxToRem(28)
    height pxToRem(28)
    cursor pointer

  &[data-position="fixed"]
    {$mshop-default-header}__wrapper
      position fixed
      top 0
      right 0
      left 0
      z-index 3
  &[data-layering="true"]
    position absolute
  &[data-theme="transparent"]
    {$mshop-default-header}__wrapper
      background-color rgba(255,255,255,0)
      color #ffffff
    {$mshop-default-header}__menu
      color #ffffff
    {$mshop-default-header}__search-input
      border-color #ffffff
    {$mshop-default-header}__search-input::placeholder
      color #ffffff
    {$mshop-default-header}__search-icon *
      stroke #ffffff !important
    // svg attribute style 이라 important 써야 함
    {$mshop-default-header}__mypage *
      stroke #ffffff !important
    {$mshop-default-header}__shopping-bag *
      stroke #ffffff !important

  // item color 는 transparent 일 때만 적용되어야 함, 그래서 위치가 transparent 랑 white 사이
  &[data-item_color="black"]
    {$mshop-default-header}__logo--white
      opacity 0
    {$mshop-default-header}__wrapper
      color #111111
    {$mshop-default-header}__menu
      color #111111
    {$mshop-default-header}__search-input
      border-color #111111
    {$mshop-default-header}__search-input::placeholder
      color #111111
    {$mshop-default-header}__search-icon *
      stroke #111111 !important
    // svg attribute style 이라 important 써야 함
    {$mshop-default-header}__mypage *
      stroke #111111 !important
    {$mshop-default-header}__shopping-bag *
      stroke #111111 !important

  &[data-theme="white"]
    {$mshop-default-header}__logo--white
      opacity 0
    {$mshop-default-header}__wrapper
      background-color rgba(255,255,255,1)
      color #111111
    {$mshop-default-header}__menu
      color #111111
    {$mshop-default-header}__search-input
      border-color #111111
    {$mshop-default-header}__search-input::placeholder
      color #111111
    {$mshop-default-header}__search-icon *
      stroke #111111 !important
    // svg attribute style 이라 important 써야 함
    {$mshop-default-header}__mypage *
      stroke #111111 !important
    {$mshop-default-header}__shopping-bag *
      stroke #111111 !important

  &--transition-ready
    {$mshop-default-header}__logo
      transition opacity $transition_common
    {$mshop-default-header}__wrapper
      transition background-color $transition_common, color $transition_common
    {$mshop-default-header}__menu
      transition color $transition_common
    {$mshop-default-header}__shopping-bag *
      transition stroke $transition_common
    {$mshop-default-header}__mypage *
      transition stroke $transition_common

  [data-show="false"]
    display none


