.creator-settings
  .pb-product-size-frame
    > .don_wrapper
      max-width 600px
      max-height 326px
      border-radius 8px
      > .body
        overflow visible
    .pb-product-size
      &__title
        display flex
        align-items center
        padding-left 40px
        padding-top 40px
        > h4
          margin  0
          font-weight 800
          font-size 20px
          line-height 26px
          letter-spacing -0.3px
        span.bold
          font-weight 500
          line-height 22px
          letter-spacing -0.2px
          color #111111 !important
      &__wrapper
        padding 24px 40px 40px 40px
      &__options
        font-weight 500
        font-size 18px
        line-height 21.6px
        letter-spacing -0.5px
        margin-left 12px
      &__form
        margin-top 12px
        label
          font-weight 500
          font-size 14px
          line-height 16.8px
          letter-spacing -0.5px
      &__footer
        display flex
        justify-content space-between
        padding-top 28px
        margin-top 28px
        border-top 1px solid #dddddd
        label
          font-weight 500
          font-size 18px
          line-height 21.6px
          letter-spacing -0.5px
      &__inputs
        display grid
        grid-template-columns 1fr 1fr
        gap 12px 20px
      &__form_control
        display flex
        align-items center
        > label
          flex-basis 40px
        > input
          flex-grow 1
          padding 11px 16px
          width  pxToRem(125px)
          height pxToRem(40px)
          border 1px solid #cccccc
          border-radius 4px
      &__buttons
        display flex
        gap 8px
        button
          width pxToRem(143.5px)
          height pxToRem(48px)
          border 1px solid #828282
          font-weight 700
          font-size pxToRem(16px)
          line-height 24px
          letter-spacing -0.3px
          color #828282
          border-radius 4px
          background-color #ffffff
          &.primary
            border none
            color #FFFFFF !important
            background-color #111111
            &:disabled
              background-color #828282
              cursor default
      &__volume
        display flex
        align-items center
        gap 8px
      &__volume-text
        color #3C89F9
        font-weight 700
        font-size 18px
        line-height 21.6px
        letter-spacing -0.5px

