input[type="radio"]
  margin-top -1px
  margin-left 0
  vertical-align middle
  appearance none
  border 1px solid $gray-s1
  border-radius 50%
  width 18px
  height 18px
  cursor pointer
  &:checked
    border 6px solid #3c89f9
