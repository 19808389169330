.plus-style
  width 100%
  &__radio-group
    displayFlex()
  &__radio-box
    displayFlex(flex-start, center)
    & + &
      margin-left 10px
  &__radio
    margin 0 5px 0 0

.plus-style-category-item
  & + &
    margin-top 55px
  &__title-box
    displayFlex(flex-start, center)
  &__title
    margin 0 0 10px
    font(24px, 28px, -0.2px, normal)
  &__image-button
    reset-button()
    position relative
    display block
    overflow hidden
    outline none
    margin-right 15px
    width 80px
    height 80px
    border-radius 100%
    border 1px solid #e2e2e2
    iconPlus(20px)
    &--hide
      &::after, &::before
        display none
  &__list
    margin-top 15px
    width 100%
    display grid
    grid-template-columns: repeat(auto-fill, minmax(200px, auto));
    grid-template-rows: repeat(auto-fill, minmax(200px, auto));
    grid-gap 10px
  &__file
    blind()
  &__image
    width 100%
    vertical-align top
.plus-style-product-item
    reset-button()
    outline none
    display block
    border-radius 12px
    overflow hidden
    box-sizing border-box
    &:last-child
      margin-right 0
    &--add
      imageUploadBox()
      padding-bottom 100%
      height auto
    &__image
      width 100%
      vertical-align top
