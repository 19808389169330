.app-home-today
  [data-show="false"]
    display none

.today-tag-slider
  width 100%
  margin-bottom 18px
  overflow hidden
  box-sizing border-box
  position relative
  z-index 1

  &__wrapper
    width 100%

  &__row
    list-style none
    padding 0
    margin 8px 0
    &:first-child
      margin-top 0
  // ^[{$root-index}]__wrapper--hide ^[{$root-index}..-{$root-index}]

  &__item
    margin 0 6px
    align-items center
    padding: 7px 20px 8px;
    border-radius: 20.5px;
    background-color: #f8f8f8;
    cursor pointer
    font-size 16px
    color #4e4e4e

    &:first-child
      margin-left 1px

    &[data-selected="true"]
      background-color #1C75FF
      color white


  &__item__icon
    margin-right 10px

.home-today-list
  display flex

.home-today-item
  flex 1 1 0
  box-sizing border-box

.home-today-item_padding
  padding 0 pxToRem(36.5px)
  html[is_plus="true"] &
    padding 8px

.home-today-items
  display none

.home-today-list-items
  flex 1 1 0
  display flex
  flex-direction column
  justify-content space-between

