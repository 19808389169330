.up-item-thumbnail
  img
    object-fit contain
    background #f6f6f6
  &__canvas-wrapper
    position relative
    width 100%
    padding-top 100%
    canvas
      background #f6f6f6
      position absolute
      top 0
      left 0