.don_tab[tab_name="/modules/MShop/App/Cart/S/Mui/tab.js"]
  @import "../share.styl"
  .mobile
    display none !important
  .empty_page
    min-height ptr(1325)
  .delivery-type-grouped-item
    &__1
      display flex
      align-items center
      padding ptr(12) ptr(16)
      border-bottom 1px solid $gray-20
    &__2
      &__item
        border-bottom 1px solid $gray-20
        &:last-child
          border-bottom 0
  .pre-shipping-order-item
    display flex
    &__download-period
      color $gray-80
      text-align center
      display none
    &__price-set
      display flex
      flex-direction column
      justify-content center
      align-items center
      &__label
        color $black
        display flex
        flex-direction column
        align-items center
        justify-content center
        row-gap pxToRem(4px)
      &__price
        color $black
        &:after
        &:before
          {$kr-14-b}
      &__free-shipping
        color $black
      &__free-shipping
      &__condition
        display none
      &__condition
        margin-top pxToRem(4px)
        color $gray-80
        text-align center
        padding 0 ptr(16)
        span
          margin-right pxToRem(3px)
    &__1
      flex 1
      border-right 1px solid $gray-s2
      html[lang="en"] &
      html[lang="jp"] &
        width 100%
        border-right none
      &__item
        padding ptr(16)
        padding-top 0
        &:first-child
          padding-top ptr(16)
        &:last-child
          border-bottom 0
        html[lang="en"] &
        html[lang="jp"] &
          padding-right 0
    &__2
      flex 0 0 ptr(136)
      display flex
      align-items center
      justify-content center
      flex-direction column

  .pre-shipping-order-item
    &[data-delivery_type_id="SELLER"]
      .pre-shipping-order-item__price-set__label .mps-tooltip-icon
        display inline-block
        width ptr(16)
        height ptr(16)
    &[data-is_separate_shipping="true"]
      .cart-style-up-item__option__modify-btn
        display none
    &[data-delivery_type_id="DIGITAL"]
      .cart-style-up-item__option__modify-btn
        display none
      .pre-shipping-order-item__price-set__label
        display none
      .pre-shipping-order-item__download-period
        display block
    &[data-has_condition="true"]
      .pre-shipping-order-item__price-set__condition
        display block
    &[data-free_shipping="true"]
      .pre-shipping-order-item__price-set__price
        display none
      .pre-shipping-order-item__price-set__free-shipping
        display block
  .cart-store-item
    &__1
      display flex
      align-items center
      border-bottom 1px solid $gray-700
      padding pxToRem(20px) 0
    &__2
      &__item
        border-bottom 1px solid $gray-20
        &:last-child
          border-bottom 0
    &__price-set
      &__operator
        margin 0 pxToRem(8px)
      &__item
        display inline-flex
        align-items center
        &__text
          color $black
          margin-right pxToRem(4px)
          html[lang="en"] &
            margin-right ptr(16)
        &__price
          color $black
          &:after
          &:before
            {$kr-14-b}
        &__total-price
          color $black
          &:after
          &:before
            {$kr-20-b}
    &--divide
      height 1px
      background-color $gray-20
      margin 0
    .cart-store-item__3
      height pxToRem(60px)
      display flex
      align-items center
      justify-content center
      border-top 1px solid $black

  .mshop-cart
    margin-top $default-header-pc_height
    min-width $pc-layout-body-min-width
    padding-top ptr(32)
    padding-bottom ptr(120)
    &__header
      margin-bottom pxToRem(24)
      color $black
    &__body
      display flex
      column-gap ptr(32)
      .mshop-cart__body__2
        flex 0 0 ptr(448)
      .mshop-cart__body__1
        flex 1
        &__item
          padding 0
          border none
          margin-bottom pxToRem(48px)
          &:last-child
            margin-bottom 0
    &__recommend-products
      min-height ptr(388)
      visibility hidden
      opacity 0
      -webkit-transition visibility 0.5s, opacity 0.5s ease-out
      -moz-transition visibility 0.5s, opacity 0.5s ease-out
      -ms-transition visibility 0.5s, opacity 0.5s ease-out
      -o-transition visibility 0.5s, opacity 0.5s ease-out
      transition visibility 0.5s, opacity 0.5s ease-out
      &.show
        visibility visible
        opacity 1
    &__recommend-products-header
      margin-bottom ptr(16)
    &__recommend-products-title
      color $black
    &__recommend-products-wrap
      & > [data-rune="ProductCardList"]
        display grid
        grid-template-columns repeat(6, minmax(0, 1fr))
        grid-column-gap ptr(16)
  .mshop-cart-empty
    padding ptr(160)
    display flex
    flex-direction column
    justify-content center
    align-items center
    &__icon
      margin-bottom ptr(24)
      img
        width ptr(160)
        height ptr(160)
    &__title
      margin-bottom ptr(24)
      color $gray-80
    &__desc
      margin-bottom ptr(24)
      color $gray-50
      text-align center
  .cart-up-item-list-price
    position sticky
    top $default-header-pc_height + ptr(60)
    display flex
    flex-direction column
    align-items center
    transition: transform 0.3s ease-out;
    transform: translate3d(0, 0, 0)
    &.unpinned {
      transform: s('translate3d(0, -%s, 0)', $default-header-pc_height)
    }
    &.top {
      transform: translate3d(0, 0, 0)
    }
    &__up-item-list-price-info-wrapper
      margin-bottom pxToRem(16px)
      padding pxToRem(16px) pxToRem(24px)
      width 100%
      border 1px solid $gray-20
      border-radius 8px
      background #fff
    &__shipping-info
      display none
      font-size pxToRem(13px)
      color $gray-700
      background $gray-100
      height pxToRem(72px)
      line-height 1.46
      border-radius 8px
      justify-content center
      align-items center
      text-align center
    &__to-checkout-btn
      display flex
      justify-content center
      align-items center
      background $gray-800
      border-radius 8px
      margin-bottom pxToRem(22px)
      border none
      color #fff
      width 100%
      max-width ptr(448)
      padding 0
      height ptr(60)
      {$button-blue-theme}
      &__quantity
        display inline-flex
        justify-content center
        align-items center
        margin-left ptr(8)
        background-color $blue-10
        color $blue-100
        border-radius 50%
        width ptr(16)
        height ptr(16)


  .mshop-cart
    &[data-has_pb="true"]
      .cart-up-item-list-price__shipping-info
        display flex
    &[data-has_sth="true"]
      .mshop-cart__body
        display flex
      .mshop-cart-empty
        display none
    &[data-has_sth="false"]
      .mshop-cart__body
        display none
      .mshop-cart-empty
        display flex
    &[data-is_inhouse="true"]
      .up-item-list-price-info__2__item-shipping
        display none
      .pre-shipping-order-item__price-set__label .mps-tooltip-icon
        display inline-block


.don_frame.has_up_modal_style
  >.don_wrapper
    width pxToRem(448)
    max-height pxToRem(736)
    height auto
    border-radius 16px
    & > .body
      overflow unset
    & > .header
      padding 0 ptr(16)
      border-bottom 1px solid $gray-5
      & > .title
        color $black
        {$kr-16-b}
  .modal-product-info-wrap
    display flex
    align-items center
    column-gap pxToRem(16)
    padding pxToRem(16)
    //margin-bottom pxToRem(24)
    height pxToRem(64)
    >.product_preview
      display block !important
      width pxToRem(64)
      height pxToRem(64)
      border-radius ptr(8)
      overflow hidden
      z-index 10
      img
        width pxToRem(64)
        height pxToRem(64)
        object-fit contain
      .product_face_list.top
        display none !important
      .product_face_container
        img
          background #f6f6f6
      .product_face_list.bottom
        .product_face_container
          width pxToRem(64)
          height pxToRem(64)
          z-index 10
        .product_face
          width pxToRem(64)
          height pxToRem(64)
          background #f8f8f8
          &:not(:first-child)
            display none
          .canvas_60
            width 100%
            height 100%
            > *
              width 100% !important
              height 100% !important
              transform scale(1)
  .modal
    overflow auto
    max-height pxToRem(400)
    background-color $white
    .list_body
      position relative
      padding ptr(16) ptr(16) 0
      .modal-product-info-wrap
        display flex
        align-items center
        column-gap pxToRem(16)
        margin-bottom pxToRem(24)
        height pxToRem(64)
        >.product_preview
          display block !important
          width pxToRem(64)
          height pxToRem(64)
          border-radius ptr(8)
          overflow hidden
          z-index 10
          img
            width pxToRem(64)
            height pxToRem(64)
            object-fit contain
          .product_face_list.top
            display none !important
          .product_face_container
            img
              background #f6f6f6
          .product_face_list.bottom
            .product_face_container
              width pxToRem(64)
              height pxToRem(64)
              z-index 10
            .product_face
              width pxToRem(64)
              height pxToRem(64)
              background #f8f8f8
              &:not(:first-child)
                display none
              .canvas_60
                width 100%
                height 100%
                > *
                  width 100% !important
                  height 100% !important
                  transform scale(1)
      .product_title
        display flex
        flex-direction column
        row-gap ptr(2)
        .pc_name
          color $black
          limitLine(1)
        .bp_name
          color $gray-50
          limitLine(1)
        .price
          color $black
          &:after
          &:before
            {$kr-14-m}
        .creator_price
          html[is_seller="true"] &
            display none
        .seller_price
          html[is_seller="false"] &
            display none
      .item
        position relative
        padding ptr(12) 0
        .size_info
          color $black
          &__price
            color $gray-50
            margin-left pxToRem(8)
            &:after
              display none
        &.is_not_stock
          .size_info span
            color $gray-20
        .info_wrap
          display flex
          align-items center
          justify-content space-between
          overflow hidden
          .info
            display flex
            overflow hidden
            &.size_info
              flex 1
            &.qty_info
              display flex
              flex-direction column
          span.size, span.qty
            margin-right pxToRem(8)
          .input_wrap
            display flex
            align-items center
            justify-content space-between
            padding ptr(8) ptr(10)
            box-sizing border-box
            background-color $gray-5
            border-radius ptr(8)
            input.quantity
              background-color $gray-5
              text-align center
              color $black
              outline none
              border none
              margin 0
              padding 0
              position relative
              &::-webkit-inner-spin-button,
              &::-webkit-outer-spin-button
                -webkit-appearance none
                margin 0
            .plus, .minus
              display inline-block
              width pxToRem(24)
              height pxToRem(24)
              cursor pointer
              background-size pxToRem(12) pxToRem(12)
            .plus
              background url(//s3.marpple.co/files/u_2308668/2024/5/original/d6d0dbda7a0420a4797b135819091b7a507c79e21.svg) no-repeat center
            .minus
              background url(//s3.marpple.co/files/u_2308668/2024/5/original/3fb1ba642668d03ee3587eb8a38e00707ecf93981.svg) no-repeat center
        &.is_not_stock
          .qty_info
            .input_wrap
              display none !important
            &:after
              content "Sold Out"
              color $gray-20
              width pxToRem(134)
              text-align center
              height pxToRem(28)
        .product_price_wrap
          display none
        .product_price
          display flex
          bottom 14px
          &[is_discounted="true"]
            .discounted
              display block
            .original
              text-decoration line-through
              color color_gray3
          &.hide
            .discounted, .original
              display none
          .discounted
            display none
            margin-top 12px
            color color_black
          .original
            margin-right 8px
            margin-top 13px
            color color_black
    .possible-max-quantity
      padding 0 24px 20px
      color #828282

  .modal_footer
    .summary
      margin-top ptr(24)
      margin-bottom ptr(16)
      padding 0 ptr(16)
      display flex
      align-items center
      justify-content space-between
      .title
        color $black
      .total_price
        color $black
        .percent
        .sign
        .origin
          display none
        .discounted
          color $black
          &:after
          &:before
            {$kr-24-b}
    .msg
      margin-top 8px
      color color_gray3
      html[lang="kr"] &
      html[lang="jp"] &
        display none
    .buttons
      text-align center
      display flex
      align-items center
      justify-content space-between
      column-gap pxToRem(8)
      border-top 1px solid $gray-5
      padding ptr(16)
      .buttons-okay
      .buttons-cancel
        flex 1
        width 100%
        padding pxToRem(16) 0
        border-radius pxToRem(8)
        border none
        background-color $gray-5
        color $gray-50
        transition: background-color 200ms ease-out, border 200ms ease-out;
        &.active
          background-color $blue-100
          color $white
          pointer-events auto
          &:hover
            background-color $blue-10
            color $blue
      .buttons-cancel
        border 1px solid $gray-20
        background-color $white
        color $gray-80
        &:hover
          border 1px solid $blue
          color $blue
