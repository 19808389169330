.hello-home-header
  margin-top 24px
  padding 0 80px
  display flex
  align-items center

  &__left_arrow
    width 20px
    margin-right 11px
    cursor pointer

  &__title
    font-size 20px
    color white
    font-weight bold
    letter-spacing -0.3px
    margin-bottom -5px


.hello-home-top-feature
  padding 25px 80px
  position sticky
  top 0
  display flex
  align-items center
  z-index 1
  background-color #000

.hello-home-registration
  border-radius 24px
  padding 11px 31px 13px
  background-color #fff
  display flex
  align-items center
  justify-content center
  font-size 16px
  font-weight bold
  letter-spacing -0.2px
  cursor pointer
  white-space nowrap
  //width 154px



.hello-home-selector-slider
  width 100%

  &__wrapper
    width 100%

  &__row
    list-style none
    padding 0
    margin 0
    &:first-child
      margin-top 0

  &__item
    color white
    border 1px solid black
    margin 5px
    align-items center
    font-weight bold
    letter-spacing -0.2px
    font-size 18px
    margin-right 26px
    cursor pointer

    &:first-child
      margin-left 1px

  &__item__icon
    margin-right 10px

