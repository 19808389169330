h2
  display block
  margin 0
  margin-bottom pxToRem(4)
  font-family Pretendard
  font-size pxToRem(24)
  font-weight 700
  line-height 150%
  letter-spacing -0.3px

h3
  display block
  margin 0
  margin-bottom pxToRem(24)
  font-family Pretendard
  font-size pxToRem(18)
  font-weight 600
  line-height 150%
  letter-spacing -0.3px

h4
  display block
  margin 0
  margin-bottom pxToRem(12)
  font-family Pretendard
  font-size pxToRem(15)
  font-weight 600
  line-height 150%
  letter-spacing -0.3px
