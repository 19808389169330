.don_dialog_msg
  .item-order-change-ordered-to-preparing
    &__description1
      margin-bottom 20px
    &__description2
      border 1px solid $gray-s2
      border-radius 8px
      padding 16px 20px 14px
      display flex
      justify-content space-between
      align-items center
      > *
        &:nth-child(1)
        &:nth-child(3)
          width 100px
        color $gray-3
        &:nth-child(2)
          width 16px
          height 16px
        &:last-child
          color $blue-1
          font-weight bold
