@import "../share.styl"
#creator_settings_body.setting-digital-item-orders
  .header-section
    margin pxToRem(40px) 0
    &__title
      color $gray-1
      margin-bottom pxToRem(10px)
      font-size pxToRem(22px)
    &__description
      color $gray-2
      font-size pxToRem(14px)

  .main-section
    padding pxToRem(25px) pxToRem(25px) pxToRem(15px) pxToRem(25px)
    background-color $gray-6
    .table__wrapper
      position relative
      height 90vh
      min-height 16.3rem
      overflow scroll

  .pagination-item
    a
      padding 7px 8px 6px !important
      &.current
        background-color $blue-1
        color: #fff !important
        border-radius 8px

  .search-section
    margin-bottom pxToRem(20px)
    border 1px solid $gray-5
    align-items center
    padding pxToRem(25px)
    border-radius 8px
    display flex
    gap pxToRem(175px)
    &__buttons
      display flex
      justify-content center
      margin-bottom pxToRem(40px)
      gap pxToRem(10px)
      > button
        width pxToRem(120px)
        height pxToRem(40px)
        display flex
        justify-content center
        align-items center
        font-weight 500
        font-size pxToRem(14px)
        border-radius pxToRem(2px)
    &__reset
      border 1px solid $gray-s1
      color $gray-s3
      background #fff
    &__search
      border 1px solid $blue-1
      color #fff !important
      background $blue-1

  .search
    display flex
    align-items center
    &__name
      font-size pxToRem(14px)
      font-weight 600
      width pxToRem(130px)

  .search-date
    display flex
    align-items center
    &__from
    &__to
      cursor pointer
    > div
      position relative
    &__hidden
      position absolute
      top 0
      left 0
      visibility hidden
    &__divider
      color $gray-s4
      margin 0 pxToRem(9px)
    &__input
      display flex
      align-items center
      border 1px solid $gray-s1
      border-radius 2px
      font-size pxToRem(14px)
      font-weight 500
      text-indent pxToRem(10px)
      padding-right pxToRem(50px)
      height pxToRem(40px)
      min-width pxToRem(130px)
      width 100%
    &__icon--back-ground
      position absolute
      top 50%
      right pxToRem(4px)
      transform translate(0, -50%)
      width pxToRem(32px)
      height pxToRem(32px)
      display flex
      justify-content center
      align-items center
      background-color $gray-5
      border-radius 2px
      &__calendar-icon
        width pxToRem(18px)
        height pxToRem(18px)
        background-image url("//s3.marpple.co/files/u_2308668/2023/6/original/8748b11a34e7ac1dc95c2325498c11e235be79e61.png")
        background-size pxToRem(18px) pxToRem(18px)
        background-repeat no-repeat

  .search-select
    display flex
    align-items center
    height pxToRem(40px)
    gap pxToRem(10px)
    select
      -webkit-appearance none
      -moz-appearance none
      border 1px solid $gray-s1
      border-radius 2px
      background $image-down-arrow no-repeat 95% center
      background-size pxToRem(18px) pxToRem(18px)
      font-size pxToRem(14px)
      text-indent pxToRem(10px)
      font-weight 500
      width pxToRem(140px)
      height 100%
    input
      width pxToRem(260px)
      height 100%
      font-size pxToRem(14px)
      text-indent pxToRem(10px)
      font-weight 500

.main-section__table
  margin-bottom pxToRem(15px)
  .table
    background #fff
    border 1px solid $gray-s2
    &__head
      display flex
      position sticky
      top 0
      background #fff
      z-index 2
    &__body
      z-index 1
      display flex
    &__row
      display flex
      min-height pxToRem(58px)
    &__th
    &__td
      font-size pxToRem(14px)
      padding 0 4px
      display flex
      justify-content center
      align-items center
    &__right
      width 100%
    &__head
      .table__row
        border-bottom 1px solid #000
        height pxToRem(58px)
    .table__th
      font-weight 600
    &__body
      .table__row
        border-bottom 1px solid $gray-s1
    [data-key="상품명"]
    [data-key="상품명 별칭"]
    [data-key="주문자 이름"]
    [data-key="주문자 이메일"]
      text-overflow:ellipsis;
      overflow:hidden;
      word-break: break-all; // 모든 문자를 자른다. (단어 구분 없는 줄바꿈)
      white-space: nowrap;
      *
        text-overflow:ellipsis;
        overflow:hidden;
        word-break: break-all; // 모든 문자를 자른다. (단어 구분 없는 줄바꿈)
        white-space: nowrap;
    [data-key="주문번호"]
    [data-key="상품번호"]
      text-align center
      width 150px
    [data-key="주문일시"]
    [data-key="상품금액"]
    [data-key="마플샵 수수료"]
      text-align center
      width 200px
    [data-key="상품명"]
    [data-key="상품명 별칭"]
    [data-key="주문자 이메일"]
      text-align center
      min-width 200px
      flex 2
    [data-key="주문자 이름"]
    [data-key="연락처1"]
    [data-key="연락처2"]
      text-align center
      min-width 100px
      flex 1
