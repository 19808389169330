.stores-delivery-company-frame
  >.don_wrapper
    width 1100px !important
    height 817px !important
    >.header
      .don_hide_frame
        top 42px !important
        right 42px !important
        background-size 24px !important
    .stores-delivery-company-tab
      padding 50px
      .studio-info-form__label-txt
        width 180px !important
      &__header
        font-size 20px
        font-weight 800
        line-height 1.3
        letter-spacing -0.3px
        text-align left
        color $gray-1
        margin 0
      &__section
        margin-top pxToRem(24px)
      &__submit
        display flex
        align-items center
        justify-content center
      &__countries-selector
        &--hide
          display none
    .en-jp-shipping-price
      color #1C75FF
      font-size 14px
      font-weight 500
      line-height 18px
      letter-spacing -0.5px
      margin-left 12px
      &--block
        display block
        margin 10px 0 0
    .studio-info-form__select
      width 200px
    .stores-delivery-companies-countries
      display flex
      gap 8px
      flex-wrap wrap
      &[data-is_len="true"]
        margin 20px 0 10px
      select
      &__item
        border-radius 4px
        background #EBF3FE
        border 0
        padding 6px 8px
        display flex
        align-items center
        gap 4px
