.keyword-container
  position relative
  width pxToRem(564px)
  margin 0 auto 56px auto
  &__form
    width 100%
    background-color #fff
    box-sizing border-box
    display flex
    align-items center
    position relative
  &__input_el
    width 100%
    height pxToRem(64px)
    border-top none
    border-left none
    border-right none
    border-bottom 2px solid #11111
    background-color transparent
    font-size pxToRem(20px)
    font-weight bold
    letter-spacing -0.2px
    outline none
    caret-color #1C75FF
    &::placeholder
      color #c1c1c1
      font-weight 600
  &__btn
    reset-button()
    display none
    outline none
    position absolute
    right 0
    width pxToRem(36px)
    height pxToRem(36px)
    background-color #fff
    cursor pointer
  &__btn--show
    display unset
  &__btn_search
    background url(//s3.marpple.co/files/u_2232571/2023/5/original/7dbec22fd3f2f658d1af1426fd8f7d0fa1aa937f1.png) no-repeat center
    background-size pxToRem(36px)
  &__btn_reset
    position absolute
    background url(//s3.marpple.co/files/u_2232571/2023/5/original/e7f83937db9275cba9e68808d0402019f2a2d0991.png) no-repeat center
    background-size pxToRem(36px)
    background-color white
    &--show
      display block

  .auto-complete
    &__list
      opacity 1
      margin 0
      border 1px solid #dddddd
      padding pxToRem(10px)
      position absolute
      width 100%
      background-color #fff
      box-sizing border-box
      z-index 1
      &.hide
        opacity 0
        visibility hidden
        height 0
        padding 0
    &__item
      padding pxToRem(14px) pxToRem(10px)
      color #111111
      list-style none
      font-size pxToRem(15px)
      font-weight 500
      letter-spacing -0.2px
      cursor pointer
      &--matched
        color #1C75FF
      &[selected="true"]
        background-color #f8f8f8
        border-radius 4px


.don_frame[frame_name="/modules/MShop/App/Keyword/S/Mui/frame.js"]
  .creator-layout__inner
    overflow hidden
    min-height 600px

  .don_loader_wrap
    position absolute !important
    top auto
    bottom -120px

  .search-history
    margin 20px 0
    &.hide
      display none
    &__list
      list-style none
      display flex
      gap 10px
    &__no-result
      color #828282
      font-size pxToRem(15px)

  .app-search
    margin-bottom 80px
    &__results
      margin 80px 0 8px
    &__result
      &--products
        margin-bottom 38px
      &--todays
        margin-bottom 88px
    &__title
      display flex
      justify-content space-between
      width 97%
      font-size pxToRem(24px)
      letter-spacing -0.2px
      color #000000
      font-weight bold
      margin-bottom pxToRem(24px)
    &__count
      font-weight normal
      color #a2a2a2
      margin-left pxToRem(8px)
    &__view-more
      font-size pxToRem(16px)
      font-weight 500
      letter-spacing -0.3px
      color #828282
      cursor pointer
    &__view-more::after
      content ''
      display inline-block
      background url(https://s3.marpple.co/files/u_2232571/2023/5/original/e81934b374b55990f3f06134143b5d26d32374ce1.png) no-repeat center
      background-size pxToRem(12px)
      width pxToRem(12px)
      height pxToRem(12px)

    &__result-list
      display flex
      flex-wrap wrap
      &--todays
        display block
    &__popular
      margin 56px 0 16px
    &__popular-list
      display flex
      flex-wrap wrap
    &__popular-item
      padding 11px 11px 11px 12px
      border-radius 2px
      background-color #f8f8f8
      margin-right 8px
      margin-bottom 8px
      button
        font-size pxToRem(18px)
        line-height 1
        letter-spacing -0.2px
        color #4e4e4e
        reset-button()
    &__zero
      margin-bottom 136px

    &__result-list--sellers
      display flex
      .seller-item2
        width 50%
        margin-bottom 56px
        box-sizing border-box
        padding 0 28px 8px
      .about__thumb
        margin-right pxToRem(12px)
      .about__name
        cursor pointer
        color #111111
        font-size pxToRem(15px)
        line-height 1.2
        letter-spacing -0.2px
        margin-top pxToRem(6px)
        margin-bottom pxToRem(14px)
        overflow hidden
        text-overflow ellipsis
        white-space nowrap
      .about__meta
        background-color #f8f8f8
        border-radius 18px
        padding 4px 10px
        width fit-content


  .app-product-zero__title
    .keyword
      color #1C75FF

  .app-product-item
    &--v5
      width 100%
      margin-right 0
      margin-bottom 0
      overflow hidden
    &__store-name
      font-size pxToRem(15px)
      color #111
      font-weight 600
      margin-bottom pxToRem(5px)
    &__p-name
      font-size pxToRem(15px)
      color #828282
      font-weight 500
      margin-bottom pxToRem(12px)
    &__price
      font-size pxToRem(16px)
      font-weight 600

  .app-store-like
    display flex
    align-items center
    &__btn
      reset-button()
      position relative
      min-width pxToRem(24px)
      .count
        padding 2px 2px 0 2px
        color #828282
        font-size pxToRem(14px)
        letter-spacing -0.2px
        font-weight 500
      &:before
        content ''
        display block
        width pxToRem(22px)
        height pxToRem(22px)
        border-radius 100%
        margin auto
        background-image url(//s3.marpple.co/files/u_29089/2021/4/original/e3906e0ac1fe7396b7f4f37dba60467c71afb9cb1.png)
        background-size pxToRem(16px)
        background-position left
        background-repeat no-repeat
    &__heart
      display none
      &--on
        display block
        background-image url(//s3.marpple.co/files/u_29089/2021/4/original/04f209f889e653e24cd3954e32f73bbdfb3f6d991.png)
        background-size pxToRem(17px 17px)
        background-position left
        background-repeat no-repeat
        position absolute
        top 0
        left 0
        right 0
        bottom 0

  .app-product-sub__sort
    position relative
    &-btn
      border none
      background-color #fff
      color #070918
      display flex
      align-items center
      font-size pxToRem(16px)
      font-weight 500
      letter-spacing -0.2px
    &-btn:after
      content ''
      margin-left 6px
      display block
      background url(https://s3.marpple.co/files/u_2232571/2023/5/original/b05f123addfbe454cdb5deb2185b204f94f3526a1.png) no-repeat center center
      background-size pxToRem(15px) pxToRem(16px)
      width pxToRem(15px)
      height pxToRem(16px)
    &-selector
      position absolute
      top 100%
      right 0
      z-index 1
      visibility hidden
      min-width pxToRem(158px)
    &-selector--show
      visibility visible
      left auto
    &-list
      margin-top 4px
      color #111111
      background-color #ffffff
      border-radius 4px
      border solid 1px #dddddd
    &-item
      text-align center
      border 1px solid #f8f8f8
      button
        reset-button()
        padding 10px 0
        font-size 15px
        line-height 1.4
        letter-spacing -0.2px
        color #424242
        width 100%
      &--selected
        button
          color #111
          font-weight 600
      &:last-child
        border none

  .app-search__tabs
    position relative
    > a
      margin-right pxToRem(40px)
    .header-menu__name
      font-size pxToRem(18px)
      font-weight 500
      letter-spacing -0.2px
      color #828282
    .header-menu__count
      font-size pxToRem(18px)
      font-weight 500
      letter-spacing -0.5px
      color #a2a2a2
    &--selected
      .header-menu__name
        color #111111
      .header-menu__count
        color #1C75FF
    &-divider-wrap
      transform rotate(0)
    &-divider
      position fixed
      bottom -20px
      left -3000px
      right -3000px
      height 1px
      background-color #f8f8f8

  .app-search__result-list--products
    .infi_wrapper
      width 100%
  .seller-item2__sns_icon
    width pxToRem(28px)
    height pxToRem(28px)
    padding 2px
    bottom pxToRem(-2px)
    right pxToRem(-2px)
    box-sizing content-box

  .app-search__result-list--todays
    display grid
    grid-template-columns repeat(3, minmax(0, 1fr))
    grid-column-gap pxToRem(20px)
    grid-row-gap pxToRem(56px)
    .today-feed__thumbnail
      max-height 230px
      object-fit contain
    .today-feed__content
      padding pxToRem(24px) 0 0 0
    .today-feed__content_products
      display none

// 셀러 탭
.don_tab[tab_name="/modules/MShop/App/Keyword/Seller/S/Mui/tab.js"]
  .app-search__title
    margin-right 0
    width 100%
    > span
      font-size pxToRem(24px)
      font-weight bold
  .app-search__result-list--sellers
    margin 0
    width 100%
  .seller-product-item-empty
    margin-top pxToRem(60px)
    font-size pxToRem(14px)
    font-weight 400
    color #a2a2a2
    justify-content start
  .infi_wrapper
    width 100%
    display flex
    flex-direction column
    gap pxToRem(56px)
  .infi_items > div
    display grid
    grid-template-columns repeat(3, minmax(0, 1fr))
    grid-column-gap pxToRem(41px)
    grid-row-gap pxToRem(56px)
  .about__info
    overflow hidden
    height 100%
    display flex
    flex-direction column
    justify-content center
  .about__description
    display block
    margin-top pxToRem(13px)
    color #828282
    font-size pxToRem(14px)
    letter-spacing -0.2px
    font-weight 500
    line-height 1.2
    text-overflow ellipsis
    white-space nowrap
  .about__meta
    margin-top pxToRem(13px)
  .about__thumb
    width unset
    margin 0
  .about__thumb-img
    width pxToRem(120px)
    border-radius 50%
  .about__profile
    height pxToRem(120px)
    margin-bottom pxToRem(16px)
    min-height initial
  .about__name
    margin 0 !important
    font-size pxToRem(15px)
  .mp-product-img-bg-wrap
    border-radius unset
  .seller-item2__products
    grid-template-columns repeat(4, 1fr)
    grid-column-gap pxToRem(8px)
  .seller-item2__sns_icon
    width pxToRem(32px)
    height pxToRem(32px)


.don_tab[tab_name="/modules/MShop/App/Keyword/Product/S/Mui/tab.js"]
  .app-search__title
    margin-right 0
    width 100%
    > span
      font-size pxToRem(24px)
      font-weight bold
  .infi_wrapper
    display flex
    flex-direction column
    gap pxToRem(48px)
  .infi_items > div
    display grid
    grid-template-columns repeat(5, 1fr)
    grid-column-gap pxToRem(20px)
    grid-row-gap pxToRem(48px)
  .app-search__result-list--products
    margin 0
  .app-product-item
    width 100%
    margin 0
    overflow hidden
  .mp-product-img-bg-wrap
    border-radius 0

.don_tab[tab_name="/modules/MShop/App/Keyword/Contents/S/Mui/tab.js"]
  .app-search__title
    margin-right 0
    width 100%
    > span
      font-size pxToRem(24px)
      font-weight bold
  .mp-product-img-bg-wrap
    border-radius 0


.don_tab[tab_name="/modules/MShop/App/Keyword/S/Mui/tab.js"]
  .app-search__title
    margin-right 0
    width 100%
    > span
      font-size pxToRem(24px)
      font-weight bold
  .app-search__result-list--sellers
    display grid
    grid-template-columns repeat(5, 1fr)
    grid-column-gap pxToRem(20px)
    margin 0 0 pxToRem(80) 0
    .about__name
      margin-top 0
      font-size pxToRem(15px)
    .about
      display flex
      flex-direction column
      justify-content center
      overflow hidden
    .about__profile
      min-height initial
      gap pxToRem(12px)
      height pxToRem(88px)
    .about__thumb
      height pxToRem(88px)
      margin 0
      width unset
    .about__thumb-img
      border-radius 50%
      width pxToRem(88px)
    .about__info
      overflow hidden
      display flex
      flex-direction column
      height 100%
      justify-content center
  .app-search__result--products
    width 100%
    margin-bottom pxToRem(80px)
  .app-search__result-list--products
    display grid
    grid-template-columns repeat(5, 1fr)
    grid-column-gap pxToRem(20px)
    margin 0
  .mp-product-img-bg-wrap
    border-radius 0

