.select-goods-type-href
  width 100%
  border-bottom 1px solid $gray-5
  margin 10px 0 20px
  &__items
    display flex
    align-items center
  &__item
    padding 11px 0
    margin-right 28px
    font-size 15px
    &--selected
      font-weight 600
      padding-bottom 10px
      margin-bottom -1px
      border-bottom 2px solid $blue-1

