
body.creator_home
  .ad_res
    display none


div.ad_res
  max-width 760px
  margin ptr(100) auto 0
  input
    appearance auto;
  .form
    margin-bottom 98px
    *
      box-sizing border-box
    .fieldset + .fieldset
      margin-top 80px
    legend
      display flex
      justify-content space-between
      align-items center
      width 100%
      margin-bottom 24px
      p
        margin 0
        font-size 26px
        font-weight 600
        line-height 1.31
        letter-spacing -0.3px
        color #111111
      small
        font-size 14px
        font-weight normal
        line-height 1.29
        letter-spacing -0.2px
        text-align right
        color #111111
        i
          color #ec1131
    .divider
      height 2px
      margin 16px 0
      background-color #f8f8f8
    &__item
      position relative
      + .form__item
        margin-top 24px
    &__label
      display block
      margin-bottom 10px
      font-size 16px
      font-weight 600
      line-height 1.38
      letter-spacing -0.2px
      color #111111
      &.required:after
        content ' *'
        color #ec1131
    &__control
      width 100%
      padding 15px 24px 13px
      border-radius 4px
      border solid 1px #dddddd
      font-size 16px
      line-height 1.38
      letter-spacing -0.2px
      color #111111
      &::placeholder
        color #a2a2a2
      &[type=textarea]
        min-height 160px
        padding-bottom 43px
        resize none
    &__count
      position absolute
      bottom 16px
      right 22px
      font-size 16px
      line-height 1.38
      letter-spacing -0.2px
      text-align right
      color #a2a2a2
    &__desc
      margin 8px 0 0
      font-size 13px
      line-height 1.54
      letter-spacing -0.2px
      color #a2a2a2
    &__group
      width 100%
      display flex
      justify-content space-between
      align-items center
      select.form__control
        max-width 240px
        padding-right 50px
        margin-right 8px
        background #fff
        background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15'%3E%3Cg fill='none' fill-rule='evenodd' stroke-linecap='round'%3E%3Cg stroke='%23A2A2A2'%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cpath d='M9.657 2.657L9.657 10.657 1.657 10.657' transform='translate(-1325 -246) translate(932 88) translate(0 112) translate(0 30) rotate(90 196 212) rotate(-45 5.657 6.657)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A")
        background-repeat no-repeat
        background-position calc(100% - 20px) 50%
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
    &__file
      position relative
      display block
      max-width 50%
      margin-bottom 8px
      padding 15px 15px 13px
      border-radius 26px
      border solid 1px #dddddd
      overflow hidden
      font-size 16px
      line-height 1.38
      letter-spacing -0.2px
      text-align center
      color #4e4e4e
      cursor pointer
      input[type=file]
        width 0
        height 0
        opacity 0
    &__checks
      > div:not(.divider)
        display flex
        justify-content space-between
        align-items center
        + div:not(.divider)
          margin-top 14px
      label
        display flex
        align-items center
        font-size 16px
        line-height 1.38
        letter-spacing -0.2px
        color #111111
      a
        text-decoration underline
        cursor pointer
      input[type=checkbox]
        margin -3px 12px 0 0
      #all_check + span
        font-weight 600
    &__submit
      margin 24px 0 0
      &-btn
        width 100%
        margin 0
        padding 16px 0 14px
        font-size 18px
        font-weight 600
        line-height 1.33
        letter-spacing -0.2px
        text-align center
        color #fff
        background-color #111111
        border-radius 27px
        border none
        &.disabled
          color #111111
          background-color #f8f8f8
    .btn_privacy_pop
      cursor pointer
      text-decoration underline
  .noti
    margin 20px 0 0
    padding 24px
    border-radius 6px
    background-color #f8f8f8
    &__title
      margin 0 0 16px 0
      font-size 14px
      font-weight 600
      line-height 1.29
      letter-spacing -0.2px
      color #4e4e4e
      i
        margin-right 3px
    ul
      padding 0 0 0 24px
      margin 0
    li
      list-style-type '-    '
      font-size 14px
      line-height 1.29
      letter-spacing -0.2px
      color #4e4e4e
    li + li
      margin-top 12px

  .file_list
    margin 15px 0 30px
    padding 0 0 0 24px
    li
      list-style-type '-    '
      font-size 16px
      font-weight 600
      line-height 1.29
      letter-spacing -0.2px
      color #4e4e4e
    li + li
      margin-top 12px
    .btn_delete_file
      margin-left 10px
      padding 5px 10px
      color #fff
      font-size 12px
      font-weight 600
      border 0 none
      cursor pointer
      border-radius 5px
      background-color #000

.privacy_pop
  text-align left
  h2
    margin 0 0 10px
    padding 0
    font-size 18px !important
    font-weight 600
  p.txt1
    margin 0 0 10px !important
    padding 0
    font-size 14px !important
    color #000 !important
    font-weight 600
  ul
    margin 0
    padding 0
    list-style none
  li
    font-size 14px
  p.txt2
    font-size 14px !important
