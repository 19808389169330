.mshop-error
  display flex
  flex-direction column
  text-align center
  height 100%
  width 100%
  position absolute
  justify-content center
  .mshop-error
    &__content
      justify-content center
      display flex
      flex-direction column
      align-items center
    &__img
      width 259px
      margin-bottom 24px
      img
        width 100%
    &__title
      font-size 24px
      font-weight 600
      line-height 1.33
      letter-spacing -0.2px
      text-align center
      color #4e4e4e
      margin-bottom 8px
    &__description
      font-size 18px
      font-style normal
      line-height 1.33
      letter-spacing -0.2px
      text-align center
      color #828282
      margin-bottom 80px
    &__buttons
      display flex
      justify-content center
      align-items center
    &__button
      reset-button()
      font-size 18px
      font-weight 600
      line-height 1.33
      letter-spacing -0.2px
      text-align center
      color #ffffff
      background-color #111
      width 212px
      height 54px
      display flex
      align-items center
      justify-content center
      border-radius 4px
      margin 0 4px
      &--main
        background-color #1C75FF
