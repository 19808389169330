.don_tab[tab_name="/modules/MpShopPlus/Home/S/Mui/tab.js"]
.don_tab[tab_name="/modules/MShop/App/Home/S/Mui/tab.js"]
  .creator-layout__body
    padding 0
    margin 0
    max-width 100vw

.app-home
  display flex
  flex-direction column
  gap pxToRem(100)
  margin-bottom pxToRem(108)
  min-width 1024px
  width 100%
  &__section
    width 100%
    &[data-max-width="true"]
      max-width pxToRem(1720)
      padding 0 pxToRem(80)
      box-sizing border-box
      align-self center
    &[data-section-hide="true"]
      display none !important
  &__title
    display flex
    flex-direction column
    gap pxToRem(4)
    margin-bottom pxToRem(24)
    &[data-align="center"]
      text-align center
    &[data-theme="white"]
      .app-home__sub-title
      .app-home__mid-title
        color $white
  &__sub-title
    {$font-body-b1-r}
    color $gray-600
  &__mid-title
    margin 0
    {$font-heading-h2-b}
  &__more-anchor
    display inline-flex
    width fit-content
    align-items center
    gap pxToRem(8)
  &__more-anchor-arrow
    width pxToRem(24)
    height pxToRem(24)
    transform rotate(180deg)
  &__more-box
    display flex
    margin 0 auto
    align-items center
    justify-content center
    height 40px
    line-height 1.38
    letter-spacing -0.2px
    color #a2a2a2
    width -webkit-fill-available
    max-width 460px
    border-radius 22px
    border solid 1px #dddddd
    background-color #ffffff

.app-home-advance
  &__target
    display block
  &__item
    background url("//s3.marpple.co/files/u_29089/2021/5/original/face371aa0b602f589f58daf72a91f82aceea6d81.jpg") no-repeat
    position relative
    display flex
    justify-content center
    align-items center
    background-color #1C75FF
  &__left
    flex-shrink 0
    display flex
    align-items center
    line-height 1.33
    letter-spacing -0.2px
    color #ffffff
    font-size 24px
    margin-right 32px
  &__right
    position relative
    font-size 64px
  &__hand
    transform rotate(-21deg)
    line-height 1.2
    height 64px
  &__dot
    position absolute
    border-radius 100%
    &--black
      width 10px
      height 10px
      top 0
      right -10px
      background-color #000000
    &--red
      width 6px
      height 6px
      bottom 8px
      left -12px
      background-color #ed1638
  &__title
    font-weight 600
    margin-right 16px

.app-home-apply
  display flex
  justify-content space-between
  > div
    flex-basis 49.6%
    a
      display flex
      align-items center
      justify-content center
      color #fff
      text-align center
      img
        width 100%
