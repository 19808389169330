.maker-upload-type-template
  &__download-btn
    background #F5F9FE
    border 1px solid $blue-1
    color $blue-1
  &__upload-btn
    background $blue-1
    border 1px solid $blue-1
  &__upload-description
    background #f6f6f6
.maker-upload-type-board
  color $blue-1
  background #f8f9fa




.maker-product-info__bp-option-group
  .bp_option_groups
    .select_box
      .option
        &.selected
          color $blue-1
          border 1px solid $blue-1

//.bp_option_group[data-dev_name="UPLOAD_TYPE"]
//.maker-upload-type-template
//.maker-upload-type-board
//.maker-upload-type-selector
//.maker-upload-type-mobile
//  display none
#maker_frame
  .dosu_warning
    z-index 2 !important
    width 330px
    text-align center
    border-radius 12px
    background-color $blue-1
    padding 12px
    color #fff
    font-size 16px
    font-weight 500
    position absolute
    top 120px
    left calc(50% - 424px)
  html[marpplizer_mode="short"] &
    .dosu_warning
      left calc(50% - 336px)
  html.all_faces_mode.maker_page &
    .dosu_warning
      display none
  #marpplizer
    .custom-text-input-box
      position relative
      width 100%
      margin 16px 0
      .title
        font-size 14px
        margin-bottom 8px
        font-weight bold
      input
        height 40px
        box-sizing border-box
        padding 0 12px
        border solid 1px #d5dbe0
        background white
        width 100%
        outline none

    .custom-select-box
      position relative
      width 100%
      margin 16px 0

      .title
        font-size 14px
        margin-bottom 8px
        font-weight bold

      .select-selected
        height 40px
        padding 0 12px
        border solid 1px #d5dbe0
        cursor pointer
        background white
        position relative
        display flex
        align-items center
        -webkit-user-select none
        -moz-user-select none
        -ms-user-select none
        user-select none

        &:after
          content ''
          position absolute
          right 12px
          top 50%
          width 10px
          height 10px
          background url('//s3.marpple.co/file/u_15/2018/7/original/f_5274_1531137521497_VF6f4lRDkA4DTihI1tX.svg') no-repeat center
          background-size contain
          transform translateY(-50%) rotate(90deg)

      .select-items
        position absolute
        top 100%
        left 0
        right 0
        z-index 99
        background white
        border solid 1px #d5dbe0
        border-top none
        display none

        .select-option
          padding 0 12px
          height 40px
          cursor pointer
          display flex
          align-items center
          -webkit-user-select none
          -moz-user-select none
          -ms-user-select none
          user-select none

          &.selected
            color #1F89FA
            background-color rgba(31, 137, 250, 0.1)

          &:hover
            background-color #D5DBE0

          &.select-option-reset
            border-bottom solid 1px #d5dbe0
            color #666
            &:hover
              background-color #D5DBE0

      &.active
        .select-items
          display block
        .select-selected:after
          transform translateY(-50%) rotate(-90deg)

    .section .footer
      margin-top 8px
      span
        color GY
        font-weight 400
        font-size 12px
    .section.dosu_option_cards
      &__error
        .option_text__body
        .color_count
        .dosu_option_cards__footer-message
          color #FF6B00
      .body
        display flex
        gap 8px
        flex-direction column
        width 100%
        .dosu_option_card
          position relative
          display flex
          height 50px
          padding 0 16px
          align-items center
          justify-content space-between
          background-color #F6F6F6
          border-radius 8px
          .description
            display flex
            align-items center
            &.option_summary
              width calc(100% - 73px)
            &.option_color
              position absolute
              right 16px
              .color-chips
                display flex
                position relative
                gap 3px
                .color-chip
                  border-radius 100%
                  border 1.5px solid #F6F6F6
                  margin-left -12px
                  width 24px
                  height 24px
                .color_count
                  display flex
                  justify-content center
                  align-items center
                //.color_count
                //  position absolute
                //  top -3px
                //  right -3px
                //  display flex
                //  justify-content center
                //  align-items center
                //  height 14px
                //  min-width 14px
                //  border-radius 999px
                //  background-color $blue-1
                //  .count
                //    font-size 10px
                //    font-weight 500
                //    color white

    .dosu-colors
      margin-top 8px
      .color-pickers
        display flex
        flex-wrap wrap
        gap 13.5px
        .color-picker
          display flex
          justify-content center
          cursor pointer
          border-radius 100%
          span
            box-sizing border-box
            width 24px
            height 24px
            border-radius 100%
            margin 1px
            &.too_bright
              border 1px solid #CED4DA
          &.original_tag
            span
              background url(//s3.marpple.co/files/u_1187078/2023/10/original/4b0a81febbcf4ebb28d05033ce040cb71b9e32e71.png) center / 27px no-repeat
              border none
              border-radius 0
            &.selected
              box-shadow unset
          &.selected
            box-shadow: 0 0 0 1px $blue-1
            border none
          &.used
            &::before
              content ""
              background-color #555
              width 4px
              height 4px
              border-radius 100%
              margin-top -8px
              position absolute
      html[marpplizer_mode="short"] &
        .color-pickers
          gap 9.5px
        .color-pickers
          .color-picker
            span
              width 22px
              height 22px
.maker-color-picker
  display flex
  flex-direction column
  justify-content center
  &__title
    text-align center
  &__list
    display flex
    flex-wrap wrap
  &__item
    display flex
    justify-content center
    cursor pointer
    border-radius 100%
    border 1px solid transparent
    margin 0 12px 6px 0
    html[marpplizer_mode="short"] &
      margin 0 6px 4px 0
    span
      width 24px
      height 24px
      html[marpplizer_mode="short"] &
        width 22px
        height 22px
      box-sizing border-box
      border-radius 100%
      margin 1px
      border 1px solid transparent
      &.too_bright
        border 1px solid #CED4DA
    &.original_tag
      span
        background url(//s3.marpple.co/files/u_1187078/2023/10/original/4b0a81febbcf4ebb28d05033ce040cb71b9e32e71.png) center / 27px no-repeat
        border none
        border-radius 0
      &.selected
        box-shadow unset
    &.selected
      border 1px solid $blue-1

body.setting-products
  @import "./maker/pc/index.styl"
  @import "../../../modules/NessCustomMaker/F/Style/Pc/maker_image.styl";


.creator-settings.maker_page
  @import "new_maker_guide.styl"
  .mp_maker[data-is_user_studio="true"]
    .m_image_pattern
      display none
