.product_detail_total_price
  &__label
    $product-option-label()
  &__controls
    $flex(space-between, center)
  &__counter
    width ptr(130)
  &__price_text
    {$kr-14-b}
  &__price
    $flex(flex-end, center)
    gap ptr(16)
    {$en-24-unica-b}
    color $black
  &__max_count
    $flex(flex-start, flex-start, column)
    gap ptr(4)
    margin-top ptr(16)
    p
      $flex(flex-start, center)
      gap ptr(4)
      color $blue
    svg
      width ptr(20)

