// modules/Ness/App/ReviewCreator/F/Style/Pc/index.styl

$image-ness-upload-file-icon--plus = url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M10 0.999146V9.99915M10 9.99915V18.9991M10 9.99915H19M10 9.99915H1' stroke='white'/%3E%3C/svg%3E");
$image-upload-file = url("data:image/svg+xml,%3Csvg width='62' height='62' viewBox='0 0 62 62' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='60' height='60' rx='8' stroke='%23D9D9D9' stroke-linecap='round' stroke-linejoin='round' stroke-dasharray='3 4'/%3E%3Cg clip-path='url(%23clip0_1089_166324)'%3E%3Cpath d='M37.0797 30.4159H31.5797V24.9226C31.5797 24.6026 31.3197 24.3359 30.993 24.3359C30.6663 24.3359 30.4063 24.5959 30.4063 24.9226V30.4226H24.9197C24.5997 30.4226 24.333 30.6826 24.333 31.0093C24.333 31.3359 24.593 31.5959 24.9197 31.5959H30.4197V37.0959C30.4197 37.4159 30.6797 37.6826 31.0063 37.6826C31.333 37.6826 31.593 37.4226 31.593 37.0959V31.5959H37.093C37.413 31.5959 37.6797 31.3359 37.6797 31.0093C37.6797 30.6826 37.4197 30.4226 37.093 30.4226L37.0797 30.4159Z' fill='%23D9D9D9'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1089_166324'%3E%3Crect width='16' height='16' fill='white' transform='translate(23 23)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A")

$image-star = url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.8402 3.97781C15.3202 3.01781 16.6936 3.01781 17.1736 3.97781L20.2936 10.2845C20.4802 10.6711 20.8536 10.9378 21.2669 10.9911L28.2269 12.0045C29.2936 12.1645 29.7202 13.4711 28.9469 14.2178L23.9069 19.1245C23.6002 19.4178 23.4669 19.8578 23.5336 20.2711L24.7202 27.2045C24.9069 28.2711 23.7869 29.0711 22.8402 28.5778L16.6136 25.2978C16.2402 25.0978 15.7869 25.0978 15.4002 25.2978L9.17355 28.5778C8.22689 29.0845 7.10689 28.2711 7.29355 27.2045L8.48022 20.2711C8.54689 19.8445 8.41355 19.4178 8.10689 19.1245L3.06689 14.2178C2.29355 13.4711 2.72022 12.1511 3.78689 12.0045L10.7469 10.9911C11.1736 10.9245 11.5336 10.6578 11.7202 10.2845L14.8402 3.97781Z' fill='%23E7E7E7'/%3E%3C/svg%3E%0A")
$image-star--full = url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.8402 3.97781C15.3202 3.01781 16.6936 3.01781 17.1736 3.97781L20.2936 10.2845C20.4802 10.6711 20.8536 10.9378 21.2669 10.9911L28.2269 12.0045C29.2936 12.1645 29.7202 13.4711 28.9469 14.2178L23.9069 19.1245C23.6002 19.4178 23.4669 19.8578 23.5336 20.2711L24.7202 27.2045C24.9069 28.2711 23.7869 29.0711 22.8402 28.5778L16.6136 25.2978C16.2402 25.0978 15.7869 25.0978 15.4002 25.2978L9.17355 28.5778C8.22689 29.0845 7.10689 28.2711 7.29355 27.2045L8.48022 20.2711C8.54689 19.8445 8.41355 19.4178 8.10689 19.1245L3.06689 14.2178C2.29355 13.4711 2.72022 12.1511 3.78689 12.0045L10.7469 10.9911C11.1736 10.9245 11.5336 10.6578 11.7202 10.2845L14.8402 3.97781Z' fill='%231C75FF'/%3E%3C/svg%3E%0A")

/* define modal header, body style */
.don_frame[frame_name="mp.review.write"]
  >.don_wrapper
    background $white
    width pxToRem(608px)
    border-radius pxToRem(16px)
    >.header
      height pxToRem(56px)
      padding pxToRem(16px)
      z-index 10
      border-bottom 1px solid $gray-5
      >.title
        {$kr-16-b}
        color $black
    >.body
      padding pxToRem(2px)
  .thankyou
    display none
    &__body
      min-height pxToRem(436px)
      display flex
      flex-direction column
      justify-content center
      align-items center
    &__image
      width ptr(180)
      height ptr(180)
      margin-bottom ptr(24)
      img
        max-width 100%
        max-height 100%
        object-fit contain
        width auto
    &__title
      color $black
      margin-bottom ptr(8)
    &__desc
      color $gray-80
    .buttons
      position fixed
      bottom 0
      left 0
      right 0
      background-color $white
      padding pxToRem(16px) pxToRem(32px) pxToRem(32px) pxToRem(32px)
      border-top 1px solid $gray-5
      z-index 10
      &:after
        display block
        content ''
        padding-bottom env(safe-area-inset-bottom)
        padding-bottom constant(safe-area-inset-bottom)
    .continue
      width 100%
      margin 0 auto
      height pxToRem(56px)
      padding 0
      border none
      background $blue-100
      color $white
      border-radius ptr(8)


.don_page[page_name="mp.review.write"]
  >.don_wrapper
    >.header
      margin 0
    >.body
      padding 0 pxToRem(16px)
      padding-top pxToRem(16px)
      padding-bottom calc(env(safe-area-inset-bottom) + 136px)
      padding-bottom calc(constant(safe-area-inset-bottom) + 136px)

#write_review_form
  *
    box-sizing border-box
  h3
    margin 0
    padding 0

  .upc_wrap
    height pxToRem(96px)
    display flex
    align-items center
    column-gap pxToRem(16px)
    margin-bottom pxToRem(24px)
    .upc_img
      width pxToRem(96px)
      height pxToRem(96px)
      background-color #f6f6f6
      border-radius pxToRem(8px)
      .img-wrap
        width 100%
        height 100%
        display flex
        justify-content center
        align-items center
        background-color #f6f6f6
        transform scale(1)
        img
          object-fit contain
          max-width 100%
          max-height 100%
          width 100%
    &__product-text-info
      display flex
      flex-direction column
      row-gap pxToRem(4px)
    &__ordered-at
      {$kr-12-m}
      color $gray-80
    &__review-id
      {$kr-12-m}
      color $gray-80
    &__product-name
      {$kr-16-m}
      color $black
    &__product-second-name
      {$kr-14-m}
      color $gray-50

  .score_wrap
    margin-bottom pxToRem(24px)
    display flex
    flex-direction column
    row-gap pxToRem(10px)
    >.header
      h3
        {$kr-16-b}
        color $black
    label
      select
        display none
        position absolute
        top 0
        left 0
        padding 0
        margin 0
        border 0
      .review_score
        display inline-block
        vertical-align middle
        height pxToRem(32px)
        cursor pointer
        .star
          display inline-block
          vertical-align top
          width pxToRem(32px)
          height pxToRem(32px)
          margin-right pxToRem(2px)
          background-image $image-star
          background-size pxToRem(32px) pxToRem(32px)
          &[is="true"]
            background-image $image-star--full

  .comment_wrap
    display flex
    flex-direction column
    row-gap pxToRem(8px)
    >.header
      h3
        {$kr-16-b}
        color $black
    >.body
      position relative
      display flex
      flex-direction column
      padding pxToRem(12px) 0
      label
        margin-bottom auto
        display block
        height calc(100% - 60px)
        textarea
          height pxToRem(100)
          resize none
          outline none
          width 100%
          box-shadow none
          background transparent
          padding 0
          border 0
          {$kr-16-m}
          color $black
          &::placeholder
            {$kr-16-m}
            color $gray-50
      .file_upload_wrap
        position relative
        overflow hidden
        height fit-content
        #before_files
          display inline-block
          vertical-align top
          position relative
          width 100%
          overflow visible
          ul
            position relative
            font-size 0
            padding 0
            margin 0
            .before_file
              width pxToRem(60px)
              height pxToRem(60px)
              overflow hidden
              position relative
              display inline-block
              margin-right pxToRem(8px)
              .remove
                position absolute
                width pxToRem(14px)
                height pxToRem(14px)
                top pxToRem(3px)
                right pxToRem(3px)
                button
                  display flex
                  justify-content center
                  align-items center
                  background-color rgba(0,0,0,0.3)
                  color #fff
                  font-size pxToRem(9px)
                  width 100%
                  height 100%
                  padding 0
                  margin 0
                  border none
                  box-shadow none
                  html[lang="en"] &
                    font-family CircularStd
              .img
                width 100%
                height 0
                padding-bottom 100%
                position relative
                font-size 0
                border-radius pxToRem(8px)
                overflow hidden
                img
                  object-fit cover
                  display block
                  top 0
                  left 0
                  right 0
                  bottom 0
                  margin auto
                  position absolute
                  &[loaded="false"]
                    display none
        #review_file_upload
          display inline-block
          vertical-align top
          width 60px
          height 60px
          background-image $image-upload-file
          background-size 60px 60px
          position relative
          label
            width 100%
            height 100%
            display block
            cursor pointer
            span
              display none
            input[type="file"]
              position absolute
              left 0
              right 0
              bottom 0
              top 0
              visibility hidden
    &__text-length
      display flex
      justify-content flex-end
      {$kr-12-m}
      color $gray-50
      padding pxToRem(4) 0 pxToRem(8)
      margin-bottom pxToRem(16)
      border-bottom pxToRem(1px) solid $gray-10
      &.error
        color $red-80
        border-bottom pxToRem(1px) solid $red-80
    &__desc
      display flex
      flex-direction column
      row-gap pxToRem(4px)
    &__desc-row
      {$kr-12-m}
      color $gray-50
      display flex
      column-gap pxToRem(8px)
      &:before
        display block
        content '·'


  .sns_url_wrap
    margin-top pxToRem(24px)
    display flex
    flex-direction column
    row-gap pxToRem(8px)
    >.header
      h3
        {$kr-16-b}
        color $black
    >.body
      input
        width 100%
        border none
        outline none
        border-bottom pxToRem(1px) solid $gray-10
        border-radius 0
        -webkit-border-radius 0
        padding pxToRem(12px) 0
        {$kr-16-m}
        &:placeholder
          {$kr-16-m}
          color $gray-50
          -webkit-text-fill-color $gray-50

  .write_footer
    margin-top pxToRem(24px)
    display flex
    flex-direction column
    row-gap pxToRem(40px)
    {$kr-16-m}
    .buttons
      position fixed
      bottom 0
      left 0
      right 0
      background-color $white
      padding pxToRem(16px) pxToRem(16px) pxToRem(16px) pxToRem(16px)
      border-top 1px solid $gray-5
      z-index 10
      &:after
        display block
        content ''
        padding-bottom env(safe-area-inset-bottom)
        padding-bottom constant(safe-area-inset-bottom)
    #write
      width 100%
      height pxToRem(56px)
      background-color $blue
      border none
      color $white
      {$kr-16-b}
      border-radius pxToRem(8px)
      {$button-blue-theme}
      &.disabled
        cursor not-allowed
        background-color $gray-5
        color $gray-50
    .clause_view
      margin-left 8px
      cursor pointer
      text-decoration underline
      color $gray-50

.don_page[page_name="mp.review.write.clause"]
  >.don_wrapper
    >.header
      display none
    .review_clause
      padding ptr(32)
      padding-bottom calc(env(safe-area-inset-bottom) + 136px)
      padding-bottom calc(constant(safe-area-inset-bottom) + 136px)
      font-size 12px
      >.footer
        position fixed
        bottom 0
        left 0
        right 0
        background-color $white
        padding pxToRem(16px) pxToRem(32px) pxToRem(32px) pxToRem(32px)
        border-top 1px solid $gray-5
        z-index 10
        button
          width 100%
          height pxToRem(56px)
          background-color $blue
          border none
          color $white
          {$kr-16-b}
          border-radius pxToRem(8px)
          {$button-blue-theme}
        &:after
          display block
          content ''
          padding-bottom env(safe-area-inset-bottom)
          padding-bottom constant(safe-area-inset-bottom)
