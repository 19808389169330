// modules/NewMaker/ConditionTooltip/F/Style/Pc/index.styl
.condition_tooltip
  display none
  position absolute
  overflow hidden
  bottom 40px
  left 0
  border-radius 20px
  background var(--WHT, #FFF)
  padding 16px 16px 0 16px
  width 296px
  height 312px
  transition height 0.5s ease
  &.is_closed
    height 59px
    .condition_tooltip_text
      img
        transform rotate(180deg)
    @media screen and (max-width: 1440px)
      height 42px
    @media screen and (max-height: 900px)
      height 42px
    .condition_tooltip_img_text
      display none
  @media screen and (max-width: 1440px)
    width 216px
    height 230px
    padding 11px 11px 0 11px
  @media screen and (max-height: 900px)
    width 216px
    height 230px
    padding 11px 11px 0 11px
  & .condition_tooltip_img_text
    color var(--GY, #858585)
    font-family Pretendard
    font-size 14px
    font-style normal
    font-weight 500
    line-height 140%
    position absolute
    left 16px
    bottom 200px
    z-index 1


  & .condition_tooltip_img
    //position absolute
    //width 100%
    //right 16px
    //aspect-ratio 74 / 63
    //max-width 296px
    //border-radius 12px
    //overflow hidden

    & img
      width 100%
  //position absolute
  //top 0
  //left 0

  //& img
  //  position absolute
  //  left 23px
  //  top 70px
  //  z-index 1


  &_text
    color var(--OG, #FF6B00)
    font-family Pretendard
    font-size 16px
    font-style normal
    font-weight 500
    line-height 150%
    border-bottom 1px solid var(--BG-MODEL, #E8E8E8)
    padding-bottom 12px
    cursor pointer
    padding-right 11%
    @media screen and (max-width: 1440px)
      font-size 11px
    @media screen and (max-height: 900px)
      font-size 11px
    & img
      position absolute
      right 16px
      top 16px
      width 24px
      height 24px
      @media screen and (max-width: 1440px)
        width 17px
        height 17px
        right 11px
        top 11px
      @media screen and (max-height: 900px)
        width 17px
        height 17px
        right 11px
        top 11px



    & span
      color var(--OG, #FF6B00)
      font-family Pretendard
      font-size 16px
      @media screen and (max-width: 1440px)
        font-size 11px
      @media screen and (max-height: 900px)
        font-size 11px
      font-style normal
      font-weight 700
      line-height 150%
