$transition_common = 0.3s ease

.keyvisual
  position relative
  display flex
  &__swiper
    flex-shrink 0
    width 100%
    overflow hidden
  &__wrapper
    reset-list()
    width 100%
    will-change transform
  &__slide
    width 100%
    display flex
    align-items center
    justify-content center
    overflow hidden
  &__item
    width 100%
    position relative
    flex-shrink 0
    display flex
    align-items center
    justify-content center
  &__anchor
    display block
    width 100%
  &__image-box
    display flex
    align-items center
    justify-content center
    width 100%
  &__image
    vertical-align top
    width 100%
    max-width 1920px
    max-height 720px
    aspect-ratio 1920/720
    object-fit cover
  &__text-box
    width 100%
    padding 0 pxToRem(80)
    box-sizing border-box
    max-width pxToRem(1720px)
    position absolute
    left 50%
    top 50%
    transform translate(-50%, -50%)
    z-index 10
  &__title
    {$font-heading-h0-b}
    word-break keep-all
    limitLine(2)
    color inherit
  &__description
    margin-bottom pxToRem(16px)
    {$font-body-b1-m}
    limitLine(1)
    color inherit
  &__pagination
    width 100%
    height 2px
    display flex
    align-items center
    justify-content center
    position absolute
    bottom 0
    left 0
    right 0
    padding 0 pxToRem(80)
    box-sizing border-box
    &__info
      {$font-body-en-b2-r}
      padding-bottom pxToRem(16)
      display inline-flex
      align-items center
      height pxToRem(20)
      gap pxToRem(4)
      color $white
      transition color $transition_common
      & > span
        opacity 0.5
      & > b
        font-weight 475
        opacity 1
    &__wrapper
      position absolute
      bottom pxToRem(40)
      left 50%
      transform translateX(-50%)
      width 100%
      max-width pxToRem(1720px)
      z-index 10
      padding 0 pxToRem(80)
      box-sizing border-box
      display flex
      justify-content space-between
      &[data-item_color="black"]
        .keyvisual__pagination__line__value
          background-color rgba(0, 0, 0, 0.10)
        .keyvisual__pagination__bullet::after
          background-color $black
        .keyvisual__pagination__info
          color $black
    &__line
      width 100%
      height 2px
      box-sizing border-box
      padding 0 pxToRem(80)
      position absolute
      bottom 0
      left 0
      right 0
      &__value
        width 100%
        height 2px
        background-color rgba(255, 255, 255, 0.10)
        transition background-color $transition_common
    &__bullet
      flex 1 1 0
      transition opacity 0.2s ease-in-out
      cursor pointer
      height 2px
      padding pxToRem(40) 0
      opacity 0
      &::after
        display block
        content ''
        width 100%
        background-color $white
        transition background-color $transition_common
        height 2px
      &--active
        opacity 1
  &__controller
    padding-bottom pxToRem(12)
    align-items center
    justify-content space-between
    z-index 10
    box-sizing border-box
    display flex
    width fit-content
    height fit-content
  &__button
    reset-button()
    height pxToRem(28)
    width pxToRem(28)
  &__blind
    blind()
