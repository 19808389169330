.don_frame[frame_name='/modules/Creator/Dormant/Cert/S/Mui/frame.js']
  .dormant-cert-wrapper
    padding pxToRem(96px) 0
    display flex
    .dormant-cert
      display flex
      flex-direction column
      align-items center
      margin 0 auto
      width pxToRem(520px)
      &__logo
        img
          height pxToRem(24px)
      &__info
        width 100%
        display flex
        flex-direction column
        padding-top pxToRem(60px)
        padding-bottom pxToRem(50px)
      &__title
        padding 0 0 pxToRem(20px) 0
        margin 0
        line-height 1.08
        letter-spacing -0.5px
        font-size pxToRem(24px)
        font-weight bold
      &__content
        margin pxToRem(4px) 0
        padding 0
        line-height 1.6
        letter-spacing -0.2px
        font-size pxToRem(15px)
        color #111
        .bold
          font-weight bold
      &__privacy
        box-sizing border-box
        width 100%
        display flex
        flex-direction column
        gap pxToRem(10px)
        margin pxToRem(20px) 0
        padding pxToRem(20px) pxToRem(24px)
        background-color #f8f8f8
        border-radius pxToRem(8px)
        &__data
          display flex
          gap pxToRem(10px)
        &__key
          width pxToRem(128px)
          color #828282
        &__value
          font-size pxToRem(14px)
          &.email
            font-weight 600
            color #0e2ee2
      &__buttons
        display flex
        width 100%
        gap pxToRem(10px)
      &__button
        width 100%
        height pxToRem(52px)
        background-color #111
        border-radius pxToRem(8px)
        border none
        padding 0
        margin 0
        color white
        font-size pxToRem(16px)
        font-weight bold
        &[disabled]
          cursor default
          background-color #a2a2a2