.main-section
  &__table
    table
      min-width 57rem !important
    button[data-ecommerce_func]
      border none
      border-radius 4px
      width pxToRem(70px)
      height pxToRem(30px)
      display flex
      justify-content center
      align-items center
    button[data-ecommerce_func="승급요청"]
      background $blue-1
      color #fff
    button[data-ecommerce_func="승급보류"]
      background $red-a
      color #fff
    button[data-ecommerce_func="심사중"]
      background $blue-5
      color #fff
      cursor not-allowed
    td[data-key="이커머스 승급"]
      display flex
      align-items center
      justify-content center
    td[data-key="재고수량"]
      input
        text-align center
        width pxToRem(100px)
    tr
      td[data-key = "상품명"]
        max-width pxToRem(150px)
        div
          overflow hidden
          text-overflow ellipsis
          word-break break-all
          white-space nowrap
      td[data-key = "이미지"]
        img
          cursor pointer
          width pxToRem(44px)
          height pxToRem(44px)
      td[data-key = "주문일시"]
      td[data-key = "택배사"]
        width pxToRem(150px)
.search-select
  select
    &[name="cate_item_id"]
      .option
        display none
        &.is_visible
          display block
