.mshop-core-icon [data-view_state="false"]
  display none

.mshop-core-icon
  &__badge
    position absolute
    display flex
    justify-content center
    align-items center
    box-sizing border-box
    border-radius 50%
    right pxToRem(-1.5px)
    margin 0
    height pxToRem(15px)
    width pxToRem(15px)
    color white
    background-color $ms-blue
    user-select none
    { $font-icon-badge }
    &[data-badge_count="0"]
      display none
    &__count
      margin-top pxToRem(2px)
  &__heart
    display inline-block
    width 100%
    height 100%

.mshop-core-icon-alarm
  width 100%
  height 100%
  position relative

.mshop-core-icon-shopping-bag
  width 100%
  height 100%
  position relative

.mshop-core-icon-sns
  display flex
  justify-content center
  align-items center
  border-radius 50%
  background-color black
  width 100%
  height 100%

.mshop-core-icon-control
  cursor pointer

.mshop-core-icon-volume
  cursor pointer

.mshop-core-icon-comment
  display inline-block
  width 100%
  height 100%

svg[data-view_state="false"],
.mshop2023-atom__icon-badge[data-badge_count="0"]
  display none

.mshop-core-icon-button-back:hover
  & > svg > circle
    fill $dim-25

.mshop-core-icon-button-forward:hover
  & > svg > circle
    fill $dim-25

.mshop-core-icon-button-close:hover
  & > svg > circle
    fill $dim-25

.mshop-core-icon-comment,
.mshop-core-icon-heart,
.mshop-core-icon-share
  width 100%
  height 100%

.mshop-core-icon-heart,
.mshop-core-icon-share
  display flex
  justify-content center
  align-items center
  width 100%
  height 100%
  border none
  outline none
  background transparent
  margin 0
  padding 0