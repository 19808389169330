.header-section
  margin-bottom pxToRem(30px)
  &__title
    color $gray-1
    margin-bottom pxToRem(10px)
    font-size pxToRem(22px)
  &__description
    color $gray-2
    font-size pxToRem(14px)
.pagination-item
  a
    padding 7px 8px 6px !important
    &.current
      background-color $blue-1
      color: #fff !important
      border-radius 8px
.main-section
  padding pxToRem(25px) pxToRem(25px) pxToRem(35px)
  background $gray-6
  border-radius pxToRem(8px)
  display flex
  flex-direction column
  &__table
    overflow-x scroll
    margin-bottom pxToRem(10px)
    img
      width pxToRem(44px)
      height pxToRem(44px)
      object-fit contain
    background #fff
    border 1px solid $gray-s2
    min-height pxToRem(400px)
    tbody
      tr:hover
        background-color $blue-8
    .checkbox
      border-right 1px solid $gray-s1
    input[type="checkbox"]
      width pxToRem(18px)
      height pxToRem(18px)
      margin pxToRem(20px)
    input[type="text"]
      text-align center
      border-radius 2px
      border 1px solid $gray-s1
      width 80%
      height 60%
    select
      -webkit-appearance none
      -moz-appearance none
      border 1px solid $gray-s1
      border-radius 2px
      background $image-down-arrow no-repeat 95% center
      background-size pxToRem(18px) pxToRem(18px)
      font-size pxToRem(14px)
      width 80%
      height 60%
      text-indent pxToRem(10px)
    .link
      cursor pointer
      text-decoration underline
    table
      border-collapse collapse
      min-width pxToRem(1500px)
      width 100%
    tr
      td
      th
        height pxToRem(57px)
        text-align center
      td
        border-bottom 1px solid $gray-s1
    thead
      th
        font-size pxToRem(14px)
        font-weight 600
        border-bottom 1px solid #000
    tbody
      tr
        font-size pxToRem(14px)
  &__buttons
    button
      background #fff
      font-weight 500
      font-size pxToRem(13px)
      border 1px solid $gray-s2
      border-radius 2px
      padding pxToRem(8px) pxToRem(18px)
      margin-right pxToRem(6px)
      &:last-child
        margin-right 0

.search-section
  margin-bottom pxToRem(40px)
  &__selects
    padding pxToRem(25px) pxToRem(25px) pxToRem(15px)
    margin-bottom pxToRem(40px)
    border 1px solid $gray-5
  &__group
    display flex
    flex-wrap wrap
  &__buttons
    display flex
    justify-content center
    > button
      width pxToRem(120px)
      height pxToRem(40px)
      display flex
      justify-content center
      align-items center
      font-weight 500
      font-size pxToRem(14px)
      border-radius pxToRem(2px)
      margin-right pxToRem(10px)
      &:last-child
        margin-right 0
  &__reset
    border 1px solid $gray-s1
    color $gray-s3
    background #fff
  &__search
    border 1px solid $blue-1
    color #fff !important
    background $blue-1

.search-select
  display flex
  > *
    margin-right pxToRem(12px)
    &:last-child
      margin-right 0
  select
    -webkit-appearance none
    -moz-appearance none
    border 1px solid $gray-s1
    border-radius 2px
    background $image-down-arrow no-repeat 95% center
    background-size pxToRem(18px) pxToRem(18px)
    font-size pxToRem(14px)
    font-weight 500
    width 100%
    height 100%
  input
  select
    text-indent pxToRem(10px)
    font-size pxToRem(14px)

.search
  display flex
  align-items center
  height pxToRem(40px)
  width 33%
  @media screen and (max-width: 1300px)
    width 50%
  @media screen and (max-width: 800px)
    width 100%
  margin-bottom pxToRem(12px)
  &__name
    flex 1
    font-size pxToRem(14px)
    font-weight 600
  &__target
    flex 2
    height 100%
  &__has-text-input
    flex 3
    select
      flex 1
    input
      flex 2
  &__vacant
    flex 1

.search-date
  display flex
  align-items center
  > div
    position relative
  &__hidden
    position absolute
    width 100%
    height 100%
    top 0
    left 0
    visibility hidden
  &__from
    width 100%
    height 100%
    margin-right 4px
  &__divider
    color $gray-s4
  &__to
    width 100%
    height 100%
    margin-left pxToRem(4px)
  &__input
    display flex
    align-items center
    border 1px solid $gray-s1
    border-radius 2px
    font-size pxToRem(14px)
    font-weight 500
    padding-left pxToRem(10px)
    width 100%
    height 100%
