.don_page[page_name="/modules/MShop/App/CustomerOrders/S/Mui/page.js"]
.don_page[page_name="/modules/MShop/App/Mypage/Community/S/Mui/page.js"]
.don_page[page_name="/modules/MShop/App/Mypage/Like/S/Mui/page.js"]
.don_page[page_name="/modules/MShop/App/Mypage/Recent/S/Mui/page.js"]
.don_page[page_name="/modules/MShop/App/Store/QuestionList/S/Mui/page.js"]
.don_page[page_name="/modules/MShop/App/Solution/Home/S/Mui/page.js"]
.don_page[page_name="/modules/MShop/App/Mypage/MembershipList/S/Mui/page.js"]
  min-width $pc-layout-body-min-width
  >.don_wrapper >.header
    box-sizing border-box
    margin-top pxToRem(36px)
    .tab_buttons
      width 100%
      display flex
      justify-content center
      align-items center
      box-sizing border-box
      position relative
      z-index 2
      max-width ptr(1376)
      margin auto
      margin-top ptr(24)
    .tab_button
      reset-button()
      flexCenter()
      display flex
      align-items center
      column-gap ptr(4)
      padding ptr(12) 0
      flex 1
      color $gray-50
      box-sizing border-box
      {$kr-16-b}
      border-bottom 1px solid $gray-20
      .count
        {$en-16-unica-b}
        margin-bottom ptr(2)
      &[selected="true"]
        border-bottom 1px solid $black
        color $black
        {$kr-16-b}
.mypage-main
  width 100%
  padding $default-header-pc_height + ptr(72) ptr(32) 0
  max-width $pc-layout-body-max-width
  margin 0 auto
  &__body
    padding 0 0 pxToRem(100)
    min-height pxToRem(300)
    width 100%
  &__left
  &__right
    flex 1 1 0
  &__title
    margin ptr(8) 0 ptr(16)
    padding 0
    color $gray-80
  &__menu_area
    display flex
    column-gap ptr(16)
  &__banners
    margin-top pxToRem(40)
  &__ad-res-banner
    position relative;
    width 100%
    background-color $blue
    border-radius ptr(24)
    overflow hidden
    display flex
    justify-content center
    padding ptr(40) 0
    color $white
  &__ad-res-banner-button
    margin-left ptr(24)
    padding ptr(9) ptr(24)
    border-radius ptr(8)
    background $blue-10
    backdrop-filter: blur(20px)
    -webkit-backdrop-filter: blur(20px)
    color $blue
    display flex
    align-items center
  &__ad-res-banner-image1
    transform translateY(-50%) rotate(-11.445deg)
    position absolute
    left 10%
    top 50%
    width ptr(250)
  &__ad-res-banner-image2
    position absolute
    transform rotate(-18.074deg)
    width ptr(198)
    filter blur(3.55px)
    top -16%
    right 11%
  &__ad-res-banner-image3
    position absolute
    width ptr(123)
    transform rotate(4.6deg)
    filter blur(2px)
    top -36%
    right 9%
  &__ad-res-banner-text
    z-index 1


.mypage-layout-header
  box-sizing border-box
  display flex
  gap pxToRem(48)
  margin 0 auto
  margin-top ptr(104)
  padding 0 32px
  max-width 1440px
  &__title
    {$kr-28-b}
    color $black
    padding 0
    margin 0
  &__menus
    display flex
    align-items center
    gap pxToRem(24)
    a
      {$kr-16-m}
      color $gray-50
      &[data-active="true"]
        {$kr-16-m}
        border-bottom 1px solid $black
        color $black


.mypage-user
  box-sizing border-box
  position relative
  width 100%
  display flex
  align-items center
  justify-content space-between
  border-radius pxToRem(16)
  margin-bottom ptr(48);
  &__welcome
    display flex
    column-gap ptr(24)
  &__title
    margin-bottom ptr(12)
  &__subscription
    margin-bottom ptr(24)
  &__pic
    width ptr(150)
    height ptr(150)
    border-radius ptr(999)
    background-color $white
    border 1px solid $gray-5
    overflow hidden
    img
      width 100%
      height 100%
      object-fit cover
  &__content
    display: flex;
    row-gap: ptr(16)
    flex-direction: column;
    justify-content: center;
    width ptr(546)
  &__content-icon
    width ptr(16)
    display inline-flex
    align-items center
  &__btns
    display flex
    align-items center
    column-gap ptr(8)
    row-gap ptr(8)
    flex-wrap wrap
  &__btn
    min-width ptr(120)
    padding pxToRem(9) pxToRem(24)
  &__infos
    display flex
    column-gap ptr(16)

.mypage-before-login
  box-sizing border-box
  position relative
  width 100%
  display flex
  align-items center
  justify-content space-between
  border-radius pxToRem(16)
  margin-bottom ptr(48);
  &__welcome
    display flex
    flex-direction column
  &__title
    margin-bottom ptr(12)
  &__subscription
    margin-bottom ptr(24)
  &__auth-buttons
    display flex
    column-gap ptr(8)
  &__auth-button
    width ptr(120)
.mypage-user-info
  display flex
  flex-direction column
  row-gap ptr(12)
  width ptr(140)
  &__title
    color $gray-50
  &__number
    color $black

.mypage-menu
  reset-list()
  &__item
    display block
    cursor pointer
    box-sizing border-box
    padding ptr(16) 0
    border-bottom 1px solid $gray-5
    &:last-child
      border-bottom 0 none
  &__version
    position absolute
    top 50%
    right 0
    transform translateY(-50%)
    font-weight 600
  a
    display block



.mypage-settings
  margin pxToRem(10) pxToRem(24) 0

.mypage-site-guide
  p
    font-size pxToRem(14) !important
    font-weight normal !important
    line-height 1.43 !important
    letter-spacing pxToRem(-0.2) !important
    color #111111 !important
    text-align justify !important

.app-live-chat
  font-size pxToRem(16) !important
  line-height 1.43 !important
  letter-spacing pxToRem(-0.2) !important
  color #111111 !important
  text-align justify !important

.mypage__outlink
  display inline-block
  width ptr(16)
  height ptr(16)
