html[is_mobile="false"] .today-body
  margin-top $default-header-pc_height
  &[data-mps_type="plus"]
    margin s('calc(%s + %s) 0 0 0', $default-header-pc_height, $default-sub-header-pc_height)

  &__title-upper
    text-transform uppercase

  &__container
    max-width: 1440px;
    justify-content center
    display flex
    column-gap ptr(32)
    padding-top ptr(60)

  &__content
    width ptr(896);
    @import '../../../../../../../www/css/creator/feed/editor.styl'
    .editor
      // 첫 번째 이미지 margin-top 안주기 위함
      & > *:first-child
        margin-top: 0;

  &__left_sidebar
    top $default-sub-header-pc_height + $default-header-pc_height
    position sticky
    width ptr(448);
    align-self flex-start
    margin-bottom 20px
    transition: transform 0.3s ease-out;
    transform: translate3d(0, 0, 0)
    &.unpinned {
      transform: s('translate3d(0, -%s, 0)', $default-header-pc_height)
    }
    &.top {
      transform: translate3d(0, 0, 0)
    }

  &__meta-container
    width 100%
    box-sizing border-box

  &__title-container {
    display flex
    column-gap ptr(8)
  }

  &__title-text-container {
    display flex
    flex-direction column
    row-gap ptr(16)
  }

  &__share-container {
    margin-left auto;
    .share-btn {
      margin: 6px;
      cursor: pointer
      width: ptr(24)
      height ptr(24)
      display inline-block
    }
    svg {
      width: ptr(24);
      aspect-ratio 1
    }
  }

  &__description {
    margin-bottom ptr(24)
  }

  &__publication-date {
    margin-bottom ptr(24)
    color $gray-50
  }

  &__related-link-btn {
    padding ptr(16) ptr(24);
    display: inline-flex;
    min-width: ptr(160);
    justify-content center
    align-items: center;
    gap: 8px;
    border-radius: ptr(12);
    background: $blue-10;
    color: $blue-100;
    svg {
      width: ptr(20);
      aspect-ratio: 1;
    }
  }

  &__title
    margin 0 0 ptr(32) 0
    word-break break-all

  &__navigate_container
    margin-top ptr(48)
    hr
      border 0
      height 1px
      background #f8f8f8

  &__navigate-category
    margin-right ptr(8)

  &__navigate
    text-align left
    padding ptr(16) 0

    &__link
      display inline-flex
      align-items center

    &__title
      cursor pointer
    &__icon
      display inline-flex
      align-items center
      svg {
        width ptr(20);
        margin-right 4px
      }
      margin-right ptr(32)
      cursor pointer

  &__tags
    padding ptr(24) 0
    display flex
    column-gap ptr(8)
  &__tag
    padding ptr(4) ptr(8)
    background-color $gray-5
    color $gray-80

  &__products-title
    margin ptr(100) 0 ptr(24)
  &__products
    display grid
    grid-template-columns repeat(3, 1fr)
    row-gap ptr(32);
    column-gap ptr(16);

html[is_mobile="false"]
  .today-relates
    margin ptr(120) 0
    text-align left
    &__title
      margin-bottom ptr(24)
      display flex
    &__see-more
      margin-left auto
      color $gray-80
      align-self flex-end
    &__items
      display grid
      & > .swiper-wrapper
        display grid
        column-gap ptr(24)
        grid-template-columns repeat(4, minmax(0, 1fr))
    // plus 쪽은 3개라서 별도로 한번 더 감쌈
    &[data-mps_type="plus"] .today-relates__items > .swiper-wrapper
      grid-template-columns repeat(3, minmax(0, 1fr))




