select.spo_option
select.spo_custom_option
  cursor pointer
  -webkit-appearance none /* 크롬 화살표 없애기 */
  -moz-appearance none /* 파이어폭스 화살표 없애기 */
  appearance none /* 화살표 없애기 */
  box-sizing border-box
  width 100%
  margin-bottom 10px
  padding 15px 20px
  border-radius 4px
  font-weight 500
  font-size 15px
  border solid 1px $gray-s2
  background url(//s3.marpple.co/files/u_193535/2022/8/original/eb2efaf7a443b0ddb722ae65025a8a38583297b41.png) no-repeat 95% center
  background-size 15px 15px
  color #111
  outline none
  &[data-is_default_selected="true"]
    color $gray-s4
input.spo_text_option
  resize none
  justify-content start
  align-items start
  box-sizing border-box
  width 100%
  height 90px
  margin-bottom 10px
  padding 15px 20px
  border-radius 4px
  font-weight 500
  font-size 15px
  border solid 1px $gray-s2
  color #111
  &::placeholder
    color $gray-s4
  //border solid 1px #a2a2a2
  //color #111
