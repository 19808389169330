// modules/MShop/App/PopUpStore/OrderCounts/F/Style/Pc/index.styl
.don_tab[tab_name="/modules/MShop/App/PopUpStore/OrderCounts/S/Mui/tab.js"]
  .don_wrapper
    width 100%
    max-width 1360px
    margin auto
    .content
      padding 40px 0
      table
        width 100%
        border 1px solid #EDEDED
        border-collapse collapse
        border-radius 3px
        height 100%
        thead
          background-color #EDEDED
          .content__product-name
            padding 10px
            font-weight 600
            word-break keep-all
      &__date
        width 15%
      &__tbody
        text-align center
      tr
        border-bottom 1px solid var(--Gray-200, #F2F2F2)
        height 42px
      u
        cursor pointer

