// @Variable
default-footer-text-color = #828282
$root-index = 1

{$creator}-footer
  background-color #f8f8f8

  &--is-tab
    padding-bottom 66px
  &__inner
    margin 0 auto
    padding pxToRem(40px) pxToRem(80px)
    max-width pc-max-width
    box-sizing border-box
    font 13px 18px -.2px
    color default-footer-text-color
    @media screen and (max-width pc-break-point)
      padding pxToRem(30px) pxToRem(80px)
    &--relative
      position relative
  &__row
    & ~ ^[{$root-index}..-{$root-index}]:not(.creator-footer__row--pc)
      margin-top 20px
    &--flex
      display flex
      align-items center
    &--pc
      margin-top 20px
    &--sns
      displayFlex(space-between, center)
    &--inipay
      display flex
      align-items center
      position absolute
      top pxToRem(40px)
      right pxToRem(80px)
      min-width pxToRem(310px)
      @media screen and (max-width pc-break-point)
        width pxToRem(340px)
  &__anchor
    &:not(:first-child)
      margin-left 12px
  &__text
    font 13px 18px -.2px
    color default-footer-text-color
    &--active
      color #111
      font-weight 600
    &--sns
      display block
  &__label
    display flex
    align-items center
    font 14px 18px -.2px
    color #111
    &:after
      margin-left 8px
    ^[{$root-index}]__button:checked + ^[{$root-index}..-{$root-index}]:after
      transform rotate(-135deg)
      margin-top 2px
      margin-bottom 0
  &__button
    blind()
  &__box
    display flex
    flex-wrap wrap
    margin-right pxToRem(450px)
    ~/__text ~ ^[{root-index}..-1]
      margin-top 10px
    ^[{$root-index}]__button:checked ~ ^[{$root-index}..-{$root-index}]
      display block
      margin-top 6px
    ^[{$root-index}]__text ~ ^[{$root-index}..-{$root-index}]
      margin-top 10px
    &--sns
      displayFlex()
  &__dl
    display flex
    align-items center
    margin 0
    line-height 24px
    letter-spacing -.2px
    &:not(:last-child)
      margin-right 17px
  &__dt
    line-height inherit
    color default-footer-text-color
  &__dd
    margin 0 0 0 6px
    line-height inherit
    color default-footer-text-color
  &__sns
    border-radius 5px
    width 20px
    height 20px
    &--fb
      background-image url(//s3.marpple.co/files/u_1396787/2021/5/original/9032b2a77858e202acf121e0f6bd047419c90a003.svg)
    &--tw
      background-image url(//s3.marpple.co/files/u_1396787/2021/5/original/d71d2d9a18862b4dfc701dc39a84a52748454e6e5.svg)
    &--in
      background-image url(//s3.marpple.co/files/u_1396787/2021/5/original/e989804e6619528533872eedee0f6862c22b71d24.svg)
    &--yt
      background-image url(//s3.marpple.co/files/u_1396787/2021/5/original/783ddbd0bf8a6f3f99a2d58259f04515d0c1d86f6.svg)
    &--bg
      background-image url(//s3.marpple.co/files/u_1396787/2021/5/original/416f17b0001fc2bc4b6aa8c2a4378d03138fb1d72.svg)
    & ~ ^[{$root-index}..-{$root-index}]
        margin-left 20px
  &__blind
    blind()
  &__title
    font 14px 20px -.2px
    color #111
  &__emphasis
    color #111
  &__font-bold
    font-weight 600
  &__w-br
    display block

.icon-arrow
  &:after
    display flex
    border solid #828282
    content ''
  &--up
    &:after {
      margin-top 2px
      padding 4px
      border-width 0 2px 2px 0
      transform rotate(-135deg)
    }
  &--down
    &:after {
      margin-bottom 7px
      padding 3px
      border-width 0 2px 2px 0
      transform rotate(45deg)
    }

html[is_app=true]
  {$creator}-footer
    &--is-tab
      padding-bottom 0


.footer-inipay
  &__img
    margin-left pxToRem(5px)
    img
      width pxToRem(40px)
      vertical-align top
    &--pointer
      cursor pointer
  &__txt
    margin-left pxToRem(8px)
    font-size pxToRem(12px)
    font-weight 500
    line-height 1.5
    letter-spacing -0.2px
    color #a2a2a2
  &__br
    display block
