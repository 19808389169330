// modules/MShop/ExtraAuth/Crew/F/Style/Pc/index.styl
.don_frame[frame_name='/modules/MShop/ExtraAuth/Crew/S/Mui/frame.js']
  .crew-authorization
    &__wrapper
      display flex
      min-height 100vh
      flex-direction column
      justify-content center
      align-items center
      gap pxToRem(32)
    width pxToRem(400)
    display flex
    flex-direction column
    align-items center
    gap pxToRem(40)

    &__title
      {$font-heading-h2-b}
    &__logo-text
    &__logo
      font-size pxToRem(20)
      height pxToRem(44)
      object-fit contain

    &__data-wrapper
      width 100%
      display flex
      flex-direction column
      gap pxToRem(24)
    &__data
      flex 1 0 0
      display flex
      flex-direction column
      gap pxToRem(8)
    &__data-title
      {$font-body-b2-sb}
    &__data-value
      flex 1 0 0
      padding pxToRem(16) pxToRem(13.5)
      background #f8f8f8
      border 1px solid #dddddd
      border-radius pxToRem(8)
      {$font-body-b2-r}

    &__button-wrapper
    &__term-wrapper
      width 100%
      display flex
      flex-direction column
      gap pxToRem(12)
    &__term
      display flex
      align-items center
      gap pxToRem(8)
      > a
        text-decoration-line underline
        color $gray-700
        {$font-body-b2-sb}
