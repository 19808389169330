// modules/MShop/Youtube/SelectChannel/F/Style/Pc/index.styl

.select-channel-list-frame.don_frame[is_modal="true"]
  > .don_wrapper
    width 400px
    height 351px

  .don_page
    height 100%
    > .don_wrapper
      height 100%
      > .header
        display flex
        align-items center
        justify-content flex-start
        height 60px
        padding 0 20px
        .title
          font-size 22px
      > .body
        height calc(100% - 60px)
    .don_tab
      height 100%
      > .don_wrapper
        height 100%



.youtube-channel-list
  padding 0 20px 60px
  ul
    margin 0
    padding 0
    list-style none
    max-height 210px
    overflow auto
    box-sizing border-box
  li
    margin-bottom 12px
    font-size 16px
    min-height 56px
    line-height 150%
    box-sizing border-box
    border-radius 4px
    border 1px solid #DDD
    background #FFF
    &[data-selected="true"]
    &:hover
    &:active
      border-color #3C89F9
  &__info
    display flex
    align-items center
    justify-content flex-start
    gap 10px
    padding 12px 16px
    cursor pointer
  &__radio
    display flex
    align-items center
    input[type="radio"]
      margin 0
      width pxToRem(18)
      height pxToRem(18)
      appearance none
      border pxToRem(1) solid #ddd
      border-radius 50%
      background-color #FFF
      flex 0 0 auto
      &:checked
        border pxToRem(6) solid #3C89F9
      &:disabled
        background-color #f2f2f2
  &__img
    display flex
    align-items center
    img
      width 32px
      vertical-align top
      border-radius 999px
  &__title
    padding-top 2px
    limitLine(1)
  &__btns
    position absolute
    left 20px
    right 20px
    bottom 20px
  &__btn-select
    width 100%
    height 48px
    display flex
    align-items center
    justify-content center
    color #fff !important
    font-size 16px
    font-weight 700
    line-height 24px
    letter-spacing -0.3px
    border-radius 4px
    border 0 none
    background #111
