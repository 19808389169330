.about_edit_modal > .don_wrapper
  max-width ptr(608) !important
  > .header
    padding: ptr(16) !important;
    border-bottom 1px solid $gray-5
    .title
      font-size ptr(16) !important
      line-height ptr(24)

.about-form
  padding 0 ptr(16) ptr(16)
  *
    box-sizing border-box
  input.hidden
    position fixed
    width 0
    height 0
    opacity 0
    top -100000px
    left -10000px
  > div + div
    margin-top ptr(24)
  &__thumb
    margin 0
  &__thumb-img
    position relative
    width ptr(150)
    height ptr(150)
    margin 0 auto
    img
      width 100%
      height 100%
      object-fit cover
      object-position center
      border-radius 9999px
  &__thumb-p
    margin: ptr(28) 0 0;
    padding: 0;
    color: $gray-50;
    text-align: center;
    font-family: Pretendard;
    font-size: ptr(14);
    font-style: normal;
    font-weight: 500;
    line-height: ptr(22);
    letter-spacing: -0.2px;
  &__edit-thumb
    position absolute
    bottom ptr(-10)
    left 50%
    transform translateX(-50%)
    width ptr(45)
  &__edit-thumb-btn
    width ptr(45)
    height ptr(25)
    padding ptr(5) ptr(6) ptr(6) ptr(5)
    color: $white;
    display: flex;
    cursor pointer;
    align-items: center;
    justify-content center;
    font-family: Pretendard;
    font-size: ptr(12);
    font-style: normal;
    font-weight: 500;
    line-height: ptr(17);
    letter-spacing: -0.2px;
    border-radius: ptr(8);
    background: $blue-100;
    border 0 none
  &__label
    display block
    margin-bottom ptr(10)
    font-size ptr(16)
    font-weight 600
    line-height 1.38
  &__control
    width 100%
    font-size ptr(16)
    line-height 1.38
    padding ptr(16) 0 ptr(14)
    color: $black;
    border 0 none
    border-bottom solid 1px $gray-10
    &:placeholder
      color $gray-50
    &[type=textarea]
      height ptr(140)
      resize none
  &__desc
    position: relative;
  &__desc-length
    position: absolute;
    bottom: pxToRem(10);
    right: pxToRem(25);
    color: $gray-50;
    text-align: right;
    font-family: Pretendard;
    font-size: pxToRem(12);
    font-style: normal;
    font-weight: 500;
    line-height: pxToRem(17)
    letter-spacing: -0.2px;
  &__rep
    &.image
      .about-form__image-preview
        display block
      [data-type=image]
        color #fff
        border solid 1px #111111
        background-color #111111
    &.video
      .about-form__video-url
      .about-form__video-preview
        display block
      [data-type=video]
        color #fff
        border solid 1px #111111
        background-color #111111
  &__row-buttons
    display flex
    justify-content stretch
    align-items center
  &__opt-btn
    width 100%
    padding 14px 15px 12px
    font-size 16px
    line-height 1.38
    text-align center
    color #4e4e4e
    border-radius 26px
    border solid 1px #dddddd
    outline none
    background #fff
    &:not(:first-child)
      margin-left 7px
  &__video-url
    display none
    margin-top 16px
  &__image-preview
    img
      width 100%
  &__video-preview
    iframe
      width 100%
      height 210px
  &__image-preview
  &__video-preview
    position relative
    display none
    margin-top 16px
    .remove-img-btn
      position absolute
      top 0
      right 0
      width 24px
      height 24px
      padding: 5px 5px 5px 6px
      opacity 0.8
      background-color #111111
      border none
      svg
        width: 13px;
        height: 13px;
  &__tip
    margin-top 8px
    font-size 13px
    line-height 1.54
    color #4e4e4e
  &__submit
    margin-top 40px
    button
      width 100%
      padding ptr(16)
      height ptr(56)
      box-sizing border-box
      font-size ptr(16)
      font-weight 700
      line-height 1.33
      text-align center
      color $white
      border none
      outline none
      border-radius: ptr(8);
      background: $blue-100;
