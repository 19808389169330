
.about-gallery
  &__header
    display flex
    justify-content space-between
  &__title
    margin 0 0 pxToRem(16px)
    padding 0
    font-size 20px
    font-weight 600
    line-height 1.3
    letter-spacing -0.2px
    color #111111
  &__btn_add
    font-size 16px
    line-height 1.25
    letter-spacing -0.2px
    color #4e4e4e
    padding 11px 32px 9px
    border-radius 20px
    border solid 1px #dddddd
    background none


.gallery-item
  margin-bottom pxToRem(70px)
  width 100%
  cursor pointer
  &__created_at
    margin-bottom pxToRem(10px)
    font-size 16px
    font-weight 600
    line-height 1.25
    letter-spacing -0.3px
    color #4e4e4e
  &__imgs
    text-align center
  &__img
    margin-bottom pxToRem(8px)
    img
      vertical-align top
      max-width 100%

.empty-gallery
  margin-top 100px
  text-align center
  &__icon
    margin-bottom 14px
  &__text
    line-height 1.33
    letter-spacing -0.2px
    font-size 18px
    letter-spacing -0.2px
    color #a2a2a2
    b
      display block
      margin-bottom 6px
      font-size 24px
      font-weight 600
      color #4e4e4e