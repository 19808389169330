.withdrawal
  display flex
  flex-direction column
  gap ptr(32)
  max-width ptr(512)
  align-self center
  margin ptr(60) 0
  &__title
    display flex
    flex-direction column
    color #A2A2A2
    font-size: 14px
    font-weight 500
    line-height 22px
    letter-spacing -0.2px
  &__logo
    height 32px
    color #1C75FF
  &__txt1
    margin 0
    padding 0
    font-size 18px
    font-weight 600
    line-height 1.44
    letter-spacing -0.2px
    color #111
  &__txt2
    margin-top ptr(16)
    padding 0
    font-size 16px
    font-weight 600
    line-height 1.38
    letter-spacing -0.2px
    color #111
  &__txt3
    margin 0
    padding 0
    font-size 15px
    line-height 1.4
    letter-spacing -0.2px
    color #4e4e4e

  &__ol
    margin 0
    padding-left ptr(16)
    list-style-position outside
    li
      margin-top 16px
      font-size 15px
      line-height 1.4
      letter-spacing -0.2px
      color #4e4e4e
      strong
        color #000
      a
        text-decoration underline
        color #1C75FF

  &__info
    margin 16px 0
    padding 24px
    border-radius 8px
    background-color #f8f8f8
  &__info_ul
    margin 0
    padding 0
    list-style none
    li
      display flex
      margin-top 8px
      justify-content space-between
      font-size 18px
      line-height 1.33
      letter-spacing -0.2px
      color #6B6B6B
      &:first-child
        margin-top 0
  &__info_head
    font-size 14px
    font-style normal
    font-weight 500
    line-height 22px
    letter-spacing -0.2px
    color: #6B6B6B
  &__info_body
    font-size 14px
    font-style normal
    font-weight 700
    line-height 22px
    letter-spacing -0.2px
    color: #141414
    &.is_seller
      color #1C75FF

  &__btns
    display flex
    gap 16px
  &__btn_cancel
    border-radius 8px
    border: 1px solid #D9D9D9
    background #fff
    font-size 16px
    font-style normal
    font-weight 700
    line-height 24px
    letter-spacing -0.2px
    color #6B6B6B
    min-width ptr(160)

  &__btn_not_withdrawal
  &__btn_withdrawal
    display flex
    min-width 160px
    padding 16px
    justify-content center
    align-items center
    gap 8px
    flex 1 0 0
    border-radius 8px
    background #1C75FF
    color #fff
    font-size 16px
    font-style normal
    font-weight 700
    line-height 24px
    letter-spacing -0.2px

.withdrawal-alert
  text-align left
  font-size 15px
  line-height 1.4
  letter-spacing -0.2px
  color #111
  &__title
    font-size 20px
    font-weight 600
    line-height 1.3
    letter-spacing -0.3px
  &__txt1
    font-size 15px !important
    font-weight normal
    font-stretch normal
    font-style normal
    line-height 1.4
    letter-spacing -0.2px
    color #111
  &__ul
    margin 16px 0 0
    padding 0
    list-style none
