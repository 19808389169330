@import "../share.styl"
body.setting-pb-item-orders
  @import "../Basic.styl"
  @import "../Table.styl"
  .settings-content
    padding-top pxToRem(40px)
  .main-section
    &__table-v2
      margin-top pxToRem(13px)
    &__buttons2
      justify-content space-between
    &__buttons
      margin-bottom pxToRem(12px)
      > div
        display flex
      select
        -webkit-appearance none
        -moz-appearance none
        border 1px solid $gray-s1
        border-radius 2px
        margin-right pxToRem(8px)
        background $image-down-arrow no-repeat 95% center
        background-size pxToRem(18px) pxToRem(18px)
        background-color #fff
        font-size pxToRem(14px)
        width pxToRem(150px)
        text-indent pxToRem(10px)
      input[type="text"]
        width 200px
        border 1px solid $gray-s1
        margin-right pxToRem(8px)
      button
        display flex
        align-items center
    &__buttons__related-excel
      img
        width 13px
        margin-right 7px
  .related-excel
    &__download-excel
      color #23a566 !important
      &--disabled
        color #bbb !important
        &:hover
          position relative
          .related-excel__tooltip
            position absolute
            width 182px
            margin 0 auto
            left -100px
            right -100px
            bottom -50px
            display block
            font-size 13px
            line-height 1.38
            letter-spacing -0.5px
            color #fff
            padding 10px
            border-radius 6px
            box-shadow 0 2px 8px 0 rgba(0, 0, 0, 0.2)
            background-color rgba(17, 17, 17, 0.9)
            z-index 3
            svg
              position absolute
              top -5px

    &__register-invoice
      color #3c89f9 !important
    &__tooltip
      display none
.item-order-download-modal
  .content
    width 475px !important
    .body
      margin-bottom 0 !important
.item-order-upload-modal
  .content
    width 700px !important
    .body
      margin-bottom 0 !important
.item-order-download
  text-align left
  &__title
    display flex
    justify-content space-between
    align-items center
    font-size 20px
    font-weight 800
    letter-spacing -0.3px
    color #111
    margin-bottom 24px
  &__close
    background transparent
    border 0
    img
      width 24px
  &__description
    font-size 14px
    line-height 1.57
    letter-spacing -0.5px
    color #4e4e4e
    margin-bottom 16px
  &__warning
    padding 20px
    border-radius 8px
    background-color #f8fbff
    margin-bottom 24px
    ul
      padding 0
      margin 0
      list-style none
    li
      font-size 13px
      line-height 1.54
      letter-spacing -0.8px
      color #4e4e4e
      display flex
      flex-shrink 0

  &__rect
    width 1.5px
    height 1.5px
    margin 9px 8.5px 9.5px 0
    box-shadow inset 0 0 3px 0 rgba(0, 0, 0, 0.25)
    background-color #4e4e4e

  &__form
    display flex
    justify-content center
  &__submit
    padding 12px 35px 11px
    border-radius 2px
    background-color #3c89f9
    letter-spacing -0.5px
    color #fff
    font-size 14px
    display flex
    align-items center
    justify-content center
    border 0
    img
      width 14px
      height 14px
      margin-right 7px


@keyframes spin {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg)  }
}

.item-order-upload
  font-size 14px
  &__template
    margin-top 12px
    display flex
    align-items center
    margin-bottom 30px
    a
      letter-spacing -0.5px
      color #3c89f9
      text-decoration none !important
      img
        width 14px
        height 14px
        margin-right 7px
  &__upload-excel
    width 100%
  &__input-file
    position absolute
    top 0
    left 0
    width 0
    height 0
    border 0
    visibility hidden
  &__file-name
    flex 1
    margin-right 10px
    border-radius 2px
    border solid 1px #ededed
    height 22px
    padding 9px 13px
    line-height 1.57
    letter-spacing -0.5px
    color #a2a2a2
    &--uploading
      color #111
  &__button
    padding 12px 29px 11px
    border-radius 2px
    background-color #3c89f9
    letter-spacing -0.5px
    text-align center
    color #fff
    position relative
    overflow hidden
  &__upload-ing
    display none
    &--start
      position absolute
      left 0
      top 0
      background-color #3c89f9
      width 100%
      height 100%
      align-items center
      justify-content center
      display flex
  &__upload-circle
    width 10px
    height 10px
    border-radius 100%
    border 2px solid #fff
    position relative
    animation spin 1.5s linear infinite forwards
  &__upload-circle-cut
    position absolute
    bottom -2px
    right -2px
    width 6px
    height 6px
    background-color #3c89f9

  &__s-title
    font-weight 600
    letter-spacing -0.5px
    color #000
    margin-bottom 12px
  &__label
    display flex
    align-items center
    cursor pointer
  &__file
    margin-bottom 30px
  &__result-table
    font-size 13px
    border-radius 2px
    border solid 1px #ededed
    overflow hidden
  &__result-header
    padding 12px 0
    background-color #fafafa
    display flex
    align-items center
    justify-content space-around
  &__result-header-title
    font-weight 500
    font-stretch normal
    font-style normal
    line-height normal
    letter-spacing -0.5px
    text-align center
    color #4e4e4e
  &__result-body
    display flex
    align-items center
    justify-content space-around
    letter-spacing -0.5px
    text-align center
    color #a2a2a2
    padding 16px 0
  &__result-body-content
    &--all
      color #4e4e4e
    &--success
      color #02bc77
    &--fail
      color #f66565
  &__fail-wrapper
    text-align center
  &__fail-download
    margin-top 12px
    padding 10px 30px
    border-radius 2px
    background-color #f8f8f8
    display inline-flex
    justify-content center
    letter-spacing -0.5px
    text-align center
    color #3c89f9 !important
    text-decoration none !important
    font-size 13px
    img
      width 14px
      height 14px
      margin-right 7px


