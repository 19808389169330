.main-section
  &__table
    td[data-key = "옵션"]
    td[data-key = "주문자 이름"]
    td[data-key = "수령인 이름"]
    td[data-key = "주문자 이메일"]
      width pxToRem(100px)
      text-overflow:ellipsis;
      overflow:hidden;
      word-break: break-all; // 모든 문자를 자른다. (단어 구분 없는 줄바꿈)
      white-space: nowrap;
    td[data-key = "상품명"]
      width pxToRem(200px);
      text-overflow:ellipsis;
      overflow:hidden;
      word-break: break-all; // 모든 문자를 자른다. (단어 구분 없는 줄바꿈)
      white-space: nowrap;
    td[data-key = "환불수량"]
    td[data-key = "상품가격"]
    td[data-key = "환불금액"]
      width pxToRem(100px)
    td[data-key = "환불 사유"]
    td[data-key = "진행상태"]
    td[data-key = "요청일시"]
      width pxToRem(130px)
    td[data-key = "완료일시"]
      width pxToRem(130px)
