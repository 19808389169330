green_button =
  background-color #00d664
  font-weight 600
  border-color #00d664
  color #fff
black_button =
  font-weight 600
  border-radius 2px
  background-color #3c89f9
  border-color #3c89f9
  color #fff

html.terms_page
  .text_body
    padding 0 !important
.don_frame.terms_style:not(.terms_not_modal_style)
  z-index 101 !important
.don_frame.terms_style
  color #4e4e4e
  p.no_padding
    margin 0
    padding 0
  .text_align_right
    text-align right
  .text_align_center
    text-align center
  .paragraph
    margin-bottom 40px
    > *:not(.header)
      padding-inline-start 10px
  ul
  ol
    padding-inline-start 0
    ul
    ol
      padding-inline-start 10px
  li
    list-style none
    line-height 20px
  h4
    margin 8px 0
    font-weight 400
  table
    border 1px solid #444444
    border-collapse collapse
  table.ml10
    margin-left 10px
  th
    background #eee
  th, td
    padding 10px
  .tb1
    text-align center
    margin-left 10px
    th, td
      border 1px solid #444444
  .tb2
    margin-left 10px
    th
      border 1px solid #444444
    td
      border-right 1px solid #444444

  .terms_body
    padding 0 pxToRem(32) pxToRem(32)
    .terms_preview
      table-layout fixed
      width 100%
      border 1px solid #ddd
      p.no_padding
        margin 0
        padding 0
      th
      td
        border 1px solid #ddd
      td
        padding 5px 20px
        vertical-align top
    >.body
      padding-bottom env(safe-area-inset-bottom)
      padding-bottom constant(safe-area-inset-bottom)
      html[not-safe-area="true"] &
        padding-bottom 0
      html.safe_area_inset_bottom &
        padding-bottom 34px
    >.option
      text-align center
      button
        html[is_mobile="false"] &
          height pxToRem(56)
          width 100%
          border-radius pxToRem(8)
          border 0 none
          background $gray-800
          {$font-body-b1-sb}
          color $white
  @media screen and (max-width: 1024px)
    .no_padding
      li
        padding-left 0 !important
        text-indent 0 !important
  .agree_header
    p
      font-size 13px
      line-height 22px
      @media screen and (max-width: 1024px)
        margin-top 0
        padding-top 0
  .table_of_contents
    border 1px solid #eee
    background-color #f4f4f4
    font-weight 600
    @media screen and (min-width: 1025px)
      padding 25px 25px 14px
      display flex
    @media screen and (max-width: 1024px)
      padding 4%
    ul
      flex-grow 1
      margin 0
      padding 0
      list-style none
      li
        margin-bottom 12px
        padding-left 8px
        background url("https://s3.marpple.co/s/5/marpple2/img/user/rules_icon1.png") no-repeat 0 50%
        a
          text-decoration none
  .text_body
    html[lang="kr"] &
      font-size 12px
      box-sizing border-box
    a
      color #333
      font-weight 500
      text-decoration underline
    .prev_link
      margin-top 15px


.don_frame.terms_not_modal_style
  .terms_body
    >.option
      display none
    @media screen and (min-width: 1025px)
      width 1200px
      margin 0 auto 80px
    @media screen and (max-width: 1024px)
      margin 6% 4% 80px
    > .title
      margin-bottom 26px
      margin-top 50px
    .text_body
      html[lang="kr"] &
        @media screen and (min-width: 1025px)
          padding 25px
    .table_of_contents
      font-size 13px
.don_frame.terms_modal_style
  .table_of_contents
    display none
  .don_page
    >.don_wrapper
      >.header
        html[is_mobile="false"] &
          display none
  .terms_body
    html[is_mobile="true"] &
      padding pxToRem(24) pxToRem(24) pxToRem(70)
    .table_of_contents
      font-size 11px
    >.title
      display none
    >.body
      html[is_mobile="false"] &
        height pxToRem(340)
        padding pxToRem(16)
        overflow-y scroll
        border 1px solid $gray-300
        margin-bottom pxToRem(30)
      .nbox-terms-page
      .nbox-privacy-page
        width 100%
        margin 0
        padding 0
        h1
          display none
        h2
          {$font-body-b4-b}
          color $gray-700
          margin pxToRem(24) 0 0
        h1+h2
          margin 0
        p
        li
          {$font-body-b5-r}
          color $gray-700
          margin 0

body.setting-terms
  .don_frame
    #creator_settings_body
      .settings-content
        > .header
          margin-bottom 10px
      &.setting-terms
        .inner_hidden
          display none
        .terms_body
          padding-bottom 20px !important
          .text_body
            padding 0 20px 0 0
      .table_of_contents
        display none
      .terms_body
        .title
          margin 40px 0 0
        > .header
          margin-bottom 30px
          position relative
          .options
            position absolute
            top 0
            right 0
        .text_body
          height 300px
          padding 20px
          background #fff
          overflow auto
          @media screen and (max-width: 1024px)
            padding 0
          .privacy2_list
            margin 12px 0
            table
            td
            th
              border 1px solid #f4f4f4
            .title
              margin-top 0
              margin-bottom 8px
            ul
              padding-left 24px
              li
                list-style disc
                line-height 20px
      .agree_checkbox
        margin 16px 0 0
      .option
        display none
      .options
        margin 30px 0 40px
  &.setting-terms-studio
    #creator_settings_body
      .table_of_contents
        display block
        margin-bottom 24px
        background #fff
        ul
          li
            padding-left 0
            background transparent
      .terms_body
        .text_body
          height auto
          margin-bottom 30px


.privacy-popup
  &__body
    padding 0 pxToRem(32) pxToRem(32)
  &__table
    width 100%
    th, td
      border 1px solid $gray-300
      border-collapse collapse
    th, td
      text-align center
      word-break keep-all
      word-wrap break-word
    th
      background-color $gray-200
      {$font-body-b4-b}
      color $gray-700
    td
      {$font-body-b5-r}
      color $gray-700
    &--marketing
      th, td
        width 25%
  &__sub-title
    {$font-body-b5-r}
    margin 0 0 pxToRem(8)
    color $gray-600
  &__p
    margin pxToRem(8) 0 pxToRem(30)
    {$font-body-b5-r}
    color $gray-600
    a
      color $gray-600
  button.done
    html[is_mobile="false"] &
      height pxToRem(56)
      width 100%
      border-radius pxToRem(8)
      border 0 none
      background $gray-800
      {$font-body-b1-sb}
      color $white

body.mshop .don_frame.terms_modal_style[is_modal="true"] >.don_wrapper >.header
  padding pxToRem(32) pxToRem(32) pxToRem(24)
  height pxToRem(30)
  box-sizing content-box
  {$font-heading-h4-b}
html[is_mobile="true"]
  body.mshop .don_frame.terms_modal_style
    >.don_wrapper >.header
      padding pxToRem(17) 0
      height pxToRem(26) !important
      box-sizing content-box
      width auto
      right 0
      {$font-heading-h4-b}
      .title
        width 100%
        text-align center
      .don_hide_frame
        position absolute
        top pxToRem(17)
        right pxToRem(24)
    .privacy-popup
      &__body
        padding pxToRem(24) pxToRem(24) pxToRem(16)
    .option
      position fixed
      box-sizing content-box
      background-color $white
      padding pxToRem(8) pxToRem(8) 0
      left 0
      right 0
      bottom 0
      display block
      border-top 1px solid $gray-200
      padding-bottom env(safe-area-inset-bottom)
      padding-bottom constant(safe-area-inset-bottom)
      html[not-safe-area="true"] &
        padding-bottom 0
      html.safe_area_inset_bottom &
        padding-bottom pxToRem(34)
    button.done
      {$font-body-b1-sb}
      margin-bottom pxToRem(8)
      border 0 none
      border-radius pxToRem(8)
      padding pxToRem(14)
      background $gray-800
      color $white
      width 100%
body.studio-terms
  .terms_body
    .text_body
      padding 10px !important

.don_frame.terms_style.afreeca
  .privacy-popup
    button.done
      html[is_mobile="false"] &
      html[is_mobile="true"] &
        background-color #0545B1
  .terms_body
    >.option
      button.done
        html[is_mobile="false"] &
        html[is_mobile="true"] &
          background-color #0545B1
