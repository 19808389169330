.don_frame[frame_name="/modules/MShop/App/CustomerOrders/S/Mui/frame.js"]
  .mobile
    display none
  @import "../share.styl"
  .up-items
    display flex
    flex-direction column
    row-gap pxToRem(32)
  .up-item-thumbnail
    img
      width pxToRem(136px) !important
      height pxToRem(136px) !important
      border-radius pxToRem(16px)
    canvas
      width pxToRem(272px) !important
      height pxToRem(272px) !important
      border-radius pxToRem(16px)
  .empty-orders-page
    padding pxToRem(120px) 0
    margin-bottom ptr(120)
    display flex
    flex-direction column
    align-items center
    &__img
      width pxToRem(160px)
      height pxToRem(160px)
    &__p1
      padding-top pxToRem(24px)
      padding-bottom pxToRem(8px)
      color $gray-80
    &__p2
      color $gray-50
      padding-bottom ptr(32)
    &__to-main
      min-width pxToRem(196px)
  .customer-orders
    margin-bottom pxToRem(100px)
  .customer-order
    //border 1px solid $gray-5
    border-radius pxToRem(8px)
    margin-bottom pxToRem(96px)
    &__created-at-wrapper
      box-sizing border-box
      border-bottom 1px solid $gray-5
      width 100%
      padding-bottom pxToRem(12px)
      margin-bottom pxToRem(16px)
  .customer-order-created-at
    display flex
    justify-content space-between
    width 100%
    &__title
      color $gray-1
      font-size pxToRem(16px)
      margin-right pxToRem(8px)
    &__at
      color $gray-80
    &__to-detail
      border none
      background none
      color $gray-80
  .up-item
    box-sizing border-box
    marginPaddingZero()
    &__row
      display grid
      align-items center
      justify-content space-between
      height pxToRem(136px)
      column-gap pxToRem(16px)
      grid-template-columns 912fr 292fr 140fr
    &-thumbnail
      width pxToRem(136px)
      height pxToRem(136px)
    &__product
      display flex
      align-items center
      column-gap pxToRem(16px)
    &__product-info-wrapper
      box-sizing border-box
      margin-right auto
      overflow hidden
    &__order-process-step-wrapper
      display flex
      flex-direction column
      align-items start
      justify-content center
      gap pxToRem(4)
      &__order-process-step
        color $black
      &__delivery-date
        white-space nowrap
        display inline-block
        color $gray-80
      &__pickup
        margin-top pxToRem(8px)
      &__tracking
        cursor pointer
        color $gray-80
        &__invoice-number
          margin-left pxToRem(8px)
          text-decoration underline
    &:last-child
      border none
    &__buttons-wrapper
      align-items flex-end
      display flex
      flex-direction column
      gap pxToRem(10px)

  .up-item-price
    color $gray-1
    font-size pxToRem(16px)
    font-weight 500
  .up-item-button
    background none
    height pxToRem(40px)
    display flex
    justify-content center
    align-items center
    padding 0
    box-sizing border-box
  .up-item-buttons
    box-sizing border-box
    display flex
    justify-content center
    align-items center
    width 100%
    height pxToRem(40px)
    border-radius pxToRem(8px)
    padding 0
    backdrop-filter blur(20px)
    -webkit-backdrop-filter blur(20px)
    &__wrapper
      display flex
      flex-direction column
    &__row
      display flex
      margin-bottom pxToRem(8px)
      column-gap pxToRem(8px)
      &:last-child
        margin 0
    &__review
      background $light-blue
      color $blue
      box-sizing border-box
      border none
      border-radius pxToRem(8px)
      height pxToRem(40px)
    &__digital-product-download,
    &__confirmed,
    &__re-purchase
      background none
      border 1px solid $gray-20
      color $black
      border-radius pxToRem(8px)
      height pxToRem(40px)
    &__contact,
    &__contact-link,
    &__cancel
      backdrop-filter blur(20px)
      box-sizing border-box
      display flex
      justify-content center
      align-items center
      width 100%
      height pxToRem(40px)
      border-radius pxToRem(8px)
      padding 0
      background $white
      border 1px solid $gray-20
      color $black

  .up-item-product-info
    display flex
    flex-direction column
    gap pxToRem(4px)

    &__shipping-place
      color $gray-50
      overflow hidden
      white-space nowrap
      text-overflow ellipsis
      marginPaddingZero()
    &__name
      color $black
      limitLine(2)
      marginPaddingZero()
    &__second-name
      color $gray-50
      marginPaddingZero()
    &__price
      color $black
      overflow hidden
      white-space nowrap
      text-overflow ellipsis
      marginPaddingZero()
      &:after
        {$kr-16-b}


  .up-item-product-info--inhouse
    gap pxToRem(4px)

  .store-order
    margin-bottom pxToRem(16px)
    &__header
      display flex
      align-items center
      border-bottom 1px solid $black
      padding-bottom pxToRem(12px)
    &__name
      display flex
      align-items center
      column-gap pxToRem(4px)
    .store-logo
      width pxToRem(20px)
      height pxToRem(20px)
      {$image-store-logo}
      order 1
    &__name-text
      order 0

  .up-item-wrapper
    display flex
    flex-direction column
    gap pxToRem(16)


  .order-process-steps
    background-color $gray-5
    border-radius pxToRem(8px)
    padding pxToRem(16px) pxToRem(24px)
    margin-top pxToRem(24px)
    margin-bottom pxToRem(32px)
    display flex
    align-items center
    flex-wrap wrap
    gap pxToRem(24px)

  .order-process-step
    padding 0
    margin 0
    border none
    background-color transparent
    display flex
    align-items center
    justify-content center
    gap pxToRem(8)
    text-align center
    flex-shrink 0
    color $gray-50
    &__count
      &[data-id=""]
        display none
    &--selected
      color $black
      border-bottom 1px solid $black


