$ap = app-product;
$cg = category-group

.custom-slide-selector-wrapper
  .slide-selector
    width auto
    padding 0
    &__row
      padding 0
      margin 0
      flex-wrap wrap
    &__item
      cursor pointer
      display flex
      align-items center
      margin-bottom 8px
      height 38px
      border-radius 19px
      background #f8f8f8
      margin-right 8px
      font-size 15px
      padding 0 17px
      line-height 1.25
      letter-spacing -0.2px
      color #4e4e4e
      &:last-child
        margin-right 0
    &__item__icon
      margin-right 9px

.{$ap}
  display flex
  flex-direction column
  margin-bottom 16px
  flex-shrink 0
  flex-grow 1
  &__cate_lists
    margin 2px 0 40px

.{$cg}
  &__title
    margin 0 0 16px
  &__h1
    margin 0
    font-size 26px
    line-height 1.3
    letter-spacing -0.3px
    color #111111
    word-break keep-all
  &__box
    position relative
    margin-bottom 64px
    &--small
      .{$cg}-item__list
        margin-right -(pxToRem(20px))
        margin-left -(pxToRem(20px))
        display flex
        flex-wrap wrap
      .{$cg}-item
        &__item
          margin-right 0
          width (100 / 3)%
          padding 0 pxToRem(20px)
          box-sizing border-box
          margin-bottom 8px
        &__target
          display flex
        &__top-info
          width pxToRem(164px)
          margin-bottom 0
          margin-right pxToRem(20px)
          border-radius pxToRem(32px)
        &__bottom-info
          text-align left
          display flex
          align-items center
          width pxToRem(190px)
    &--large
      .{$cg}-item
        &__top-info
          border-radius pxToRem(70px)
        &__item
          width calc((100% - 24px)/4)
.{$cg}-item-wrapper
  position relative
.{$cg}-item
  z-index auto
  &__list
    z-index auto
  &__item
    width calc((100% - 40px)/6)
    margin-right 8px
    position relative
    &:last-child
      margin-right 0
      .{$cg}-item__target
        margin-right 0
  &__target
    width auto
    display block
  &__top-info
    position relative
    display flex
    align-items center
    justify-content center
    border-radius pxToRem(60px)
    background-color #f8f8f8
    margin-bottom 12px
    overflow hidden
  &__img
    width 100%
    height 0
    padding-bottom 100%
    img
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      margin auto
      width 100%
      height 100%
  &__bottom-info
    text-align center
  &__cate_name
    font-size 18px
    line-height 1.33
    letter-spacing -0.2px
    color #111111
    margin-bottom 2px
    @media screen and (max-width pc-break-point)
      font-size 17px
    @media screen and (max-width 1000px)
      font-size pxToRem(24px)
  &__filter_name
    font-size 15px
    line-height 1.2
    letter-spacing -0.2px
    color #828282
    position relative
    @media screen and (max-width pc-break-point)
      font-size 14px
    @media screen and (max-width 1000px)
      font-size pxToRem(21px)
  &__button
    width 20px
    height 48px
    background-position center
    background-size 20px 48px
    background-repeat no-repeat
    background-image url(//s3.marpple.co/files/u_29089/2021/4/original/98b790954ffa881d5ab6861b0a7ef2120efc23462.png)
    &--next
      right -37px
    &--prev
      left -37px
      transform rotate(180deg)
    &.swiper-button-disabled
      opacity 0.07

.don_tab[tab_name="/modules/MShop/App/Product/S/Mui/tab.js"]
  .creator-layout__inner
    margin-top pxToRem(58px)
  .product-preview
    display flex
    gap pxToRem(8px)
    margin-top
    .category
      &__title
        width pxToRem(234px)
        display flex
        align-items center
        gap pxToRem(10px)
        font-size pxToRem(24px)
        font-weight bold
      &__view-more
        display inline-block
        font-size pxToRem(16px)
        font-weight 500
        color #828282
        padding pxToRem(24px) 0
      &__arrow
        display inline-block
        background url(//s3.marpple.co/files/u_193535/2022/10/original/24d61fbd62137823a9268754541993f5d1ca72961.svg) no-repeat 50% 50%
        background-size auto
        width pxToRem(12px)
        height pxToRem(12px)
    &__product-wrap
      display flex
      gap pxToRem(8px)
      width 100%
      margin auto
  &__name
    text-align center
    font-size pxToRem(20px)
    color #111
