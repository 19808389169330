.pd-modal
  z-index 5
  > .don_wrapper
    width calc(100% - 40px) !important
    max-width 475px !important
    border-radius 8px !important
    > .body
      common()
      padding 12px 50px 40px

  &-guide
    d-flex(space-between, flex-start)
    height 530px
    &__content
      p, ul
        margin 0
        word-break keep-all
      p + p, ul + p
        margin-top 20px
      p + ul
        margin-top 12px
      ul
        unordered-list()
    &__contact
      width 100%
      padding-top 12px
      border-top 1px solid #f8f8f8
    &__contact-item
      margin 4px 0 0
      font-size 13px
      span
        min-width 70px
        margin-right 15px
        font-size 13px
        font-weight 600
        line-height 1.54
        letter-spacing -0.2px
        color #828282

  &.size
    > .don_wrapper
      max-width 890px !important
      max-height calc(100vh - 100px) !important
      overflow-y auto
    .pd-bp__section-header
      margin-top 0
