.mshop-inhouse-badge
  display flex
  justify-content center
  align-items center
  gap pxToRem(4px)
  height pxToRem(24px)
  background-color $black
  border-radius pxToRem(4px)
  padding 0 pxToRem(10px)
  userSelectNone()
  userDragNone()
  &__img
    object-fit contain
    width pxToRem(12px)
    height pxToRem(12px)
  &__content
    {$font-body-b5-m}
    color $white
