{$creator}-live
  &__row
    aspect-ratio 1560 / 580
    max-height pxToRem(580px)
    display flex
    gap pxToRem(24px)
    & ~ ^[1..-1]
      margin-top 50px
  &__column
    flex 2.04
    &:last-child
      flex 1
      border-radius pxToRem(24px)
      background-color #f6f6f6
    &--space-between
      display flex
      justify-content center
      align-items center
      flex-direction column
      width 100%
  &__inner-column
    width 100%
    display flex
    flex-direction column
    align-items center
    justify-content center
    gap pxToRem(16px)
  &__link
    display block
    margin-top pxToRem(60px)
    padding 0 pxToRem(32px)
  &__title
    margin 0
    {$font-heading-h2-b}
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-align: center;
  &__description
    margin 0
    padding-top 6px
    word-break break-word
    font 16px 26px -.2px
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    color #4e4e4e
  &__image
    width 100%
    vertical-align top
  &__iframe-box
    position relative
    width 100%
    padding-bottom 56.25%
    border-radius pxToRem(24px)
    overflow hidden
    -webkit-mask-image: -webkit-radial-gradient(white, black)
  &__iframe
    position absolute
    top 50%
    left 50%
    border-radius pxToRem(24px)
    transform translateY(-50%) translateX(-50%)
    width calc(100% + 6px)
    height calc(100% + 6px)
  &__products
    width 100%
    display flex
    align-items center
    justify-content center
  &__anchor
    flexCenter()
    box-sizing border-box
    margin-top 16px
    padding 10px
    width 100%
    min-height 52px
    border 1px solid #4e4e4e
    border-radius 26px
    font pxToRem(18px) pxToRem(24px) pxToRem(-0.2px)
    color #4e4e4e
  & .app-product-item--live
    width 55%
    & .app-product-item__bottom-info
      text-align center
      height unset
    & .app-product-item__p-name
      margin-right 0
    & .app-product-item__img,
      .mp-product-img-bg-wrap
        border-radius 0
    & .app-product-item__review-info
      justify-content center
      margin-top pxToRem(12px)
    & .app-product-item__like,
      .app-product-item__badge
        display none

{$creator}-product
  display flex
  &__title
    margin 0
    font 14px 20px -.2px 600
    color #111
  &__description
    margin 0
    word-break break-word
    font 15px 21px -.2px
    color #4e4e4e
  &__price
    display block
    padding-top 12px
    font 15px 21px -.2px
    color #4e4e4e
  &__image
    width 100%
    vertical-align top
  &__column
    width 25.6%
    box-sizing border-box
    &:last-child
      padding-left 14px
      width 75.4%

{$app}-live
  margin-bottom 100px
  &__section
    & + .app-live__section
      margin-top 72px
    &.resent_sold.expanded
    &.top_sellers.expanded
      .app-live__more
        display none
  &__videos
    margin-top 72px
  &__title
    display flex
    justify-content space-between
    align-items center
    &--left
      p
        margin 0 0 4px 0
        font-size 18px
        font-weight 600
        line-height 1.33
        letter-spacing -0.2px
        color #a2a2a2
      h2
        margin 0 0 24px
        font-size pxToRem(26px)
        font-weight 600
        line-height 1.31
        letter-spacing -0.3px
    &--right
      font-weight normal
      line-height 1.43
      letter-spacing -0.2px
      text-align right
      color #4e4e4e
      strong
        font-weight 600
        color #111
  &__period
    display inline-block
    margin-bottom 4px
    padding-right 40px
    border-bottom solid 2px #111
    cursor pointer
    background-image: url("//s3.marpple.co/files/u_29089/2021/5/original/99e339848ab8a6a98e4abd8b859a600a4963c5c81.png");
    background-repeat no-repeat
    background-size 20px 20px
    background-position 91% center
  &__more
    margin-top 32px
    display flex
    align-items center
    justify-content center
  &__more-btn
    height 40px
    line-height 1.38
    letter-spacing -0.2px
    color #a2a2a2
    width -webkit-fill-available
    max-width 460px
    border-radius 22px
    border solid 1px #dddddd
    background-color #ffffff
  &__tp
    display flex
    flex-direction row
  &__tp-head
    flex-basis 672px
  &__tp-rest
    flex-basis 528px
    display flex
    flex-direction row
    flex-wrap wrap
    > div
      width calc(50% - 8px)
      margin-left 8px
      margin-bottom 0
    > div:nth-child(n+3)
      align-self flex-end
  &__ts
    display flex
    flex-direction row
    justify-content space-between
    flex-wrap wrap
    > div
      box-sizing border-box
    > div:nth-child(1)
      width 25%
      min-width 25%
      display flex
      flex-direction row
      .ts
        display flex
        align-items flex-start
      .ts__thumb
        display block
        margin-right 14px
      .ts__name
        white-space normal
        margin 12px 0
    > div:nth-child(2)
      width 75%
      min-width 75%
      .ts
        width calc(100% / 3 - 33px)
        padding-left 33px
    > div:nth-child(3)
      width 100%
      min-width 100%
      .ts
        margin-top pxToRem(24px)
      .ts:not(:nth-child(4n+1))
        width calc(100% / 4 - 33px)
        padding-left 33px
      .ts:nth-child(4n+1)
        width calc(100% / 4)
        padding-left 0
    > div:nth-child(2)
    > div:nth-child(3)
      display flex
      flex-direction row
      flex-wrap wrap
      align-content space-between
      justify-content flex-start
      .ts__info
      .ts__info > div
        display flex
        flex-direction row
        justify-content space-between
        align-items center
      .ts__info > div:first-child
        width 80%

  &__trs
    display flex
    flex-direction row
    justify-content space-between
    align-items flex-start
    margin-left -8px
    > div
      flex 1
      margin-left 8px
  &__rsp
    display flex
    flex-direction row
    justify-content space-between
    align-items flex-start
    flex-wrap wrap
    margin-left -9px
    > div
      width calc(100% / 6 - 9px)
      margin-left 9px
      margin-bottom 0
    > div:nth-child(n+7)
      margin-top 24px


.ts
  &__rank
    margin 0 12px 0 0
    padding-left 5px
    padding-right 5px
    min-width 28px
    text-align center
  &__thumb
    display none
    position relative
    width pxToRem(120px)
    min-width pxToRem(120px)
    margin-right 18px
  &__thumb-img
    position relative
    width 100%
    padding-top 100%
    background-size cover
    background-repeat no-repeat
    background-position center
    border-radius pxToRem(48px)
  &__sns
    position absolute
    bottom -3px
    right -3px
    width pxToRem(40px)
    height pxToRem(40px)
    padding pxToRem(3px)
    overflow hidden
    border-radius 50%
    background-color #fff
    img
      width 100%
      border-radius 50%
      vertical-align top
  &__name
    font-size 18px
    line-height 1.33
    display block
    width 100%
    white-space nowrap
    text-overflow ellipsis
    overflow hidden
  &__grown
    font-size 15px
    font-weight 600
    line-height 1.4
    letter-spacing -0.2px
    &.new
    &.up
      color #ed2643
    &.up:before
      content "▲"
    &.down
      color #1C75FF
      &:before
        content "▼"

.trs
  &__thumb
    position relative
    max-width 100%
    margin-bottom pxToRem(14px)
  &__thumb-img
    position relative
    width 100%
    padding-top 100%
    background-size cover
    background-repeat no-repeat
    background-position center
    border-radius pxToRem(78px)
  &__sns
    position absolute
    bottom -3px
    right -3px
    width pxToRem(40px)
    height pxToRem(40px)
    border pxToRem(3px) solid #fff
    border-radius 50%
    overflow hidden
    img
      width 100%
  &__rank
    margin-bottom pxToRem(12px) !important
  &__name
    margin-bottom pxToRem(12px)
    font-size pxToRem(18px)
    line-height 1.33
    color #4e4e4e
    white-space nowrap
    text-overflow ellipsis
    overflow hidden
  &__info
    display flex
    justify-content flex-start
    align-items center
    font-size 15px
    font-weight 600
    line-height 1.4
  &__star
    margin-right 3px
    img
      display inline-block
      margin-bottom -1px
      height 14px
      width 14px
  &__avg
    margin-right 10px
  &__review-txt
    font-weight normal


.app-live-dp
  padding 0 50px 50px
  .flatpickr-calendar
    margin-bottom 40px
    box-shadow none
    .flatpickr-day.week.selected
      box-shadow -5px 0 0 #1C75FF, 5px 0 0 #1C75FF
    .flatpickr-day.selected,
    .flatpickr-day.selected.inRange
      color #fff
      font-weight 600
      background #1C75FF
      border-color #1C75FF
    .flatpickr-day.inRange.flatpickr-disabled:not(.selected),
    .flatpickr-day.inRange.flatpickr-disabled:not(.selected):hover
      box-shadow none
      border none
      background transparent
    .flatpickr-current-month .flatpickr-monthDropdown-months
      -webkit-appearance unset
      appearance unset
      margin-right 10px
      line-height 1.33
    .flatpickr-day.today
      border none
    &.arrowTop:before
    &.arrowTop:after
      display none
  &__btn-ok
    border-radius 4px
    background-color #111
    font-size 18px
    font-weight 600
    line-height 1.33
    color #fff
    padding 15px 0
    margin 0
    width 100%
    border 0

.don_frame.date-picker
  .don_wrapper
    display inline-flex !important
    max-width 100%


