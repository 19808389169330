/*
 * -----------------메인-----------------------
 * 이미지, 뱃지, (상품이름 || 제품이름), 가격, 스토어이름, 좋아요 버튼
 *  - 홈에서 상품 더보기, 상품탭 상품
*/
.{a-p-i}--v2
  width 20%
  margin-bottom pxToRem(60px)
  box-sizing border-box
  position relative
  .{a-p-i}
    &__like
      display block
    &__store-name
      display block
    &__bottom-info
      margin 16px 0 0
    &__img
      border-radius 16px
      overflow hidden
    &__img--soldout::after
      content 'SOLD OUT'
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      display flex
      align-items center
      justify-content center
      {$font-heading-h3-b}
      color $white
      background-color $dim-25
      border-radius 16px
      font-family Campton, -apple-system, BlinkMacSystemFont, system-ui, Roboto

  &.grid-stack-container__item
    width 100%
  &.swiper-slide
    .{a-p-i}
      &__like
        display none
