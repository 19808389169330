ic-close = url("//s3.marpple.co/files/u_18054/2018/9/original/f_5853_1537163999801_xZp4kmo6Zse1am5Ts1h9W.svg")
down_close = url(//s3.marpple.co/files/u_14355/2018/11/original/f_12437_1541409165567_lrHD9Mhcw3lhZ9a9p8kl.png);
$icon-hide = url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_77_118579)'%3E%3Cpath d='M11.0485 10.0021L17.2816 3.76042C17.5728 3.46875 17.5728 3.00208 17.2816 2.71875C16.9903 2.42708 16.5243 2.42708 16.233 2.71875L10 8.95208L3.76699 2.71875C3.47573 2.42708 3.00971 2.42708 2.71845 2.71875C2.42718 3.01042 2.42718 3.47708 2.71845 3.76042L8.95146 10.0021L2.71845 16.2437C2.42718 16.5354 2.42718 17.0021 2.71845 17.2854C2.85992 17.4271 3.05132 17.5021 3.24272 17.5021C3.43412 17.5021 3.6172 17.4271 3.76699 17.2854L10 11.0521L16.233 17.2854C16.3745 17.4271 16.5659 17.5021 16.7573 17.5021C16.9487 17.5021 17.1318 17.4271 17.2816 17.2854C17.5728 16.9937 17.5728 16.5271 17.2816 16.2437L11.0485 10.0021Z' fill='%23141414'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_77_118579'%3E%3Crect width='20' height='20' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A")
.don_frame[is_modal]
  position fixed
  top 0
  left 0
  bottom 0
  right 0
  &:before
    content ''
    position fixed
    top -100px
    left -100px
    bottom -100px
    right -100px
    background-color rgba(0,0,0,0.4)
.don_frame[is_modal="true"]
  > .don_wrapper
    position absolute
    top 0
    left 0
    bottom 0
    right 0
    margin auto
    width 1024px
    height 768px
    box-sizing border-box
    background-color #fff
    box-shadow 0 2px 16px 0 rgba(0, 0, 0, 0.1)
    > .body
      width 100%
      height 100%
      overflow auto
      box-sizing border-box
      > .don_page
        > .don_wrapper
          position static !important
  .don_back_page
    position absolute
    top 10px
    left 10px
    display block
    padding 0
    width 40px
    height 40px
    cursor pointer
    background url(//s3.marpple.co/files/u_14355/2018/11/original/f_12438_1541409323249_q9vQ8BNlU4U9lrAq6ER5V.png) no-repeat
    background-size 19px auto
    background-position 50%
    &:active
      background-color #ced4da
.don_frame[is_modal="true"]
.don_page
  &[hide_frame_button_type="X"]
  &[hide_frame_button_type="x"]
  &[hide_frame_button_type="V"]
  &[hide_frame_button_type="v"]
    >.don_wrapper
      >.header
        .don_hide_frame
          display block
          background-color transparent
          position absolute
          background-position 50% 50%
          background-repeat no-repeat
          background-size 18px
          background-image $icon-hide
          width 36px
          height 36px
          top 35-(18/2)px
          right 24-(18/2)px
          border none
          outline none
          &:active
            background-color #ced4da
  &[hide_frame_button_type="v"]
    >.don_wrapper
      >.header
        .don_hide_frame
          background-image url(//s3.marpple.co/files/u_14355/2018/11/original/f_12437_1541409165567_lrHD9Mhcw3lhZ9a9p8kl.png)
          top 6px
body.mshop
  .don_frame[is_modal="true"]
    z-index 5
    >.don_wrapper
      width auto
      height auto
      max-width 896px
      max-height 720px
      border-radius 8px
      overflow hidden
      position relative
      margin 0
      left 50%
      top 50%
      transform translate(-50%, -50%)
      @media screen and (max-width 944px)
        max-width 95%
      @media screen and (max-height 715px)
        max-height 95%
      >.header
        display flex
        justify-content space-between
        align-items center
        padding 48px 50px 24px
        box-sizing border-box
        .title
          font-size 20px
          font-weight bold
          line-height 1.3
          letter-spacing -0.3px
          color #111111
        .don_hide_frame
          background-image $icon-hide
          position relative
          top auto
          right auto
          background-size 20px
          margin-right -8px

body.mshop.login
  .don_frame:not([frame_index="0"])
    >.don_wrapper
      >.header
        display flex
        position fixed
        top 0
        width 100%
        background-color #fff

body.creator-settings
  .don_frame.frame-studio-modal-style[is_modal="true"]
    >.don_wrapper >.body
      order 2
    z-index 5
    >.don_wrapper
      display flex
      flex-direction column
      width auto
      height auto
      max-width 896px
      max-height 680px
      border-radius 8px
      overflow hidden
      position relative
      margin 0
      left 50%
      top 50%
      transform translate(-50%, -50%)
      @media screen and (max-width 944px)
        max-width 95%
      @media screen and (max-height 715px)
        max-height 95%
      >.header
        display flex
        justify-content space-between
        align-items center
        padding 48px 50px 24px
        box-sizing border-box
        .title
          font-size 20px
          font-weight bold
          line-height 1.3
          letter-spacing -0.3px
          color #111111
        .don_hide_frame
          background-image url(//s3.marpple.co/files/u_29089/2021/5/original/f05b717d9a56b9d20020119abb82aad91b130c6f1.png)
          position relative
          top auto
          right auto
          background-size 20px
          margin-right -8px
      .inner_hidden
        display none
