html[is_mobile="false"]
  .editor-color-pallete
    display flex
    align-items center
    &__item
      display inline-block
      margin 0 5px
    &__preview
      position relative
      border-radius 50%
      border 1px solid transparent
      width 20px
      height 20px
      cursor pointer
      box-sizing content-box
      &--white
        border 1px solid #dddddd
      &--transparent
        opacity 0.5

    &__input:checked + &__preview
      border 1px solid gray
      opacity 1

    &__input
      display none

  .editor-button-edit-container
    width 100%
    height 100%
    padding 20px
    padding-top 76px
    display flex
    flex-direction column
    &__property
      text-align center
      margin-top 20px
    &__property_title
      text-align left
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px; /* 157.143% */
      letter-spacing: -0.2px;
    &__link, &__content
      padding 10px 12px
      width 100%

  .editor-button-container
    display flex
    margin ptr(16) 0
    gap ptr(24)
    position relative
    a.editor-button {
      text-decoration none
    }

    &.align-center
      justify-content center

    &.align-left
      justify-content flex-start

  .editor-button
    {$kr-16-b}

    &__content
      padding ptr(16) ptr(24)
      min-width ptr(160)
      max-width ptr(343)
      column-gap ptr(8);

      justify-content center
      color white
      border none
      width 100%
      display flex
      align-items center
      border-radius ptr(12)

      &:after {
        flex-shrink 0
        content: '';
        aspect-ratio 1
        width ptr(20)
        height ptr(20)
        background-image url('//s3.marpple.co/files/u_1165777/2024/4/original/468fc26eca3cb703b0c05a031ebdcebb38f173431.png')
        background-size contain
        background-repeat no-repeat
      }
      &--black
        background-color $black
      &--purple
        background-color $purple
      &--blue
        background-color $blue
      &--green
        background-color $green
      &--yellow
        background-color $yellow
      &--orange
        background-color $orange
      &--red
        background-color $red
      &--pink
        background-color $pink
      &--burgundy
        background-color $burgundy
      &--brown
        background-color $brown
      &--white
        background-color $white
        color black
        border 1px solid #dddddd

    &:last-child
      margin-right 0

  .editor-button-edit-footer
    width 100%
    display flex
    justify-content space-between
    margin-top auto
    &__button
      flex-grow 1
      margin-right 5px
      &:last-child
        margin-right 0

  // frame style
  .editor-button-modal-frame[is_modal="true"]
    > .don_wrapper
      border-radius 10px
      width 600px

      .don_hide_frame
        top 9px !important

    frame_modal_full_height()
    frame_modal_default_header()



