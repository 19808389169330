.mshop-creators
  margin-bottom 100px
  &__list
    margin 0
    padding 0
    display grid
    grid-template-columns repeat(3, minmax(0, 1fr))
    grid-gap pxToRem(48px) pxToRem(8px)
    list-style none
    .keyvisual__slide
      margin-right 0
