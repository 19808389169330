.shipping-info-modal
  width 100%
  &__content
    box-sizing border-box
    width 100%
    .timeline
      max-height pxToRem(144px)
      overflow-y hidden
      background white
      padding 0 pxToRem(43px) 0 pxToRem(43px)
      &.open
        max-height unset
        padding-bottom pxToRem(16px)
      &__expander
        display flex
        justify-content center
        align-items center
        gap pxToRem(4px)
        border none
        border-top pxToRem(1px) solid $gray-10
        border-bottom pxToRem(8px) solid $gray-5
        width 100%
        height pxToRem(48px)
        background-color inherit
        letter-spacing -0.2px
        color $gray-2
        font-size pxToRem(14px)
        box-sizing content-box
        padding 0
        &__arrow
          width pxToRem(16px)
          height pxToRem(16px)
          background url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.57002 11.6646C8.2534 12.1399 7.55507 12.1399 7.23844 11.6646L2.95914 5.24093C2.60496 4.70927 2.9861 3.9974 3.62493 3.9974L12.1835 3.9974C12.8224 3.9974 13.2035 4.70927 12.8493 5.24093L8.57002 11.6646Z' fill='%23A2A2A2'/%3E%3C/svg%3E%0A") no-repeat right center
          background-size pxToRem(16px) pxToRem(16px)
          transform rotate(0)
          &.open
            transform rotate(180deg)

      &__card
        position relative
        margin 0 0 pxToRem(16px) pxToRem(20px)
        background white
        color gray
        border-radius pxToRem(8px)
        &:not(:last-child)::before
          content ' '
          background-color $black
          display inline-block
          position absolute
          left pxToRem(-19px)
          width pxToRem(1px)
          top pxToRem(17px)
          height calc(100% + 2px)
        &__info
          display flex
          flex-direction column
          gap pxToRem(8px)
          &__title
            position relative
            color $gray-50
            display flex
            align-items center
            column-gap pxToRem(4px)
            &__date
              color $gray-50
            &::before
              content ""
              box-sizing border-box
              position absolute
              width pxToRem(8px)
              height pxToRem(8px)
              background white
              border-radius 50%
              top pxToRem(6px)
              left pxToRem(-22.5px)
              border pxToRem(1px) solid $black
          &__content
            color $gray-50

    & .timeline__card:first-child
      & .timeline__card__info__title
        color $black
        &::before
          content " "
          position absolute
          width pxToRem(8px)
          height pxToRem(8px)
          background $black
          border-radius 50%
          top pxToRem(6px)
          left pxToRem(-22.5px)

    & .other
      display flex
      flex-direction column
      padding pxToRem(24px) pxToRem(32px)
      &__title
        margin-bottom pxToRem(16px)
      &__company
        margin-bottom pxToRem(8px)
        &__title
          display inline-block
          min-width pxToRem(80px)
          color $black
        &__name
          color $black
      &__invoice
        margin-bottom pxToRem(16px)
        &__title
          display inline-block
          min-width pxToRem(80px)
          font-size pxToRem(14px)
          color $black
        &__number
          color $black
        &__copy
          box-sizing border-box
          border none
          background-color inherit
          padding 0
          margin-left pxToRem(8px)
          white-space nowrap
          color $gray-50

      &__buttons
        display flex
        justify-content space-between
        align-items center
        gap pxToRem(16px)
        &__company-number
        &__worker-number
          box-sizing border-box
          display flex
          justify-content center
          align-items center
          gap pxToRem(4px)
          width 100%
          border-radius pxToRem(8px)
          border 1px solid $gray-20
          background-color $white
          color $gray-80
          height ptr(48)
          .span
            line-height 1.2
          &__icon
            width pxToRem(18px)
            height pxToRem(18px)
            background url(//s3.marpple.co/files/u_2308668/2023/3/original/91f72beb35e786615d3f16ddf31677aa246ed01d1.webp) no-repeat right center
            background-size pxToRem(18px) pxToRem(18px)
        &__company-number.full
          width 100%

  &__summary
    width 100%
    background-color #ebf3fe
    box-sizing border-box
    flex-direction column
    display flex
    align-items center
    justify-content center
    &__image
      width pxToRem(288.249px)
      height pxToRem(150px)
      margin-bottom pxToRem(16px)
    &__title
      margin-bottom pxToRem(4px)
      .blue
        color $blue
    &__desc
      color $black

    &__icon-wrapper
      display flex
      gap pxToRem(5.8px)
    &__icon
      gap pxToRem(12px)
      flex-direction column
      display flex
      align-items center
      justify-content center
      &__image_wrapper
        width pxToRem(80px)
        height pxToRem(80px)
        border-radius pxToRem(24px)
        background-color #fff
        display flex
        align-items center
        justify-content center
      &__image
        width pxToRem(48px)
        height pxToRem(48px)
      &__title
        font-size pxToRem(14px)
        font-weight 500
        color $gray-4
        &.active
          color $gray-1
          font-weight 600
    &__dot-wrapper
      display flex
      width pxToRem(20px)
      align-items center
      justify-content space-between
      transform translate(0, -12%)
      &:last-child
        display none
      .dot
        width pxToRem(4px)
        height pxToRem(4px)
        border-radius 50%
        background-color #fff


  &__up-item
    width 100%
    display flex
    padding pxToRem(32px) pxToRem(32px) pxToRem(24px) pxToRem(32px)
    box-sizing border-box
    gap pxToRem(12px)
    &__thumbnail-wrapper
      img
      canvas
        width pxToRem(80px)
        height pxToRem(80px)
        border-radius pxToRem(8px)
        display block
        background-color #f6f6f6
  &__up-item-product-info
    display flex
    flex-direction column
    justify-content center
    gap pxToRem(4px)
    &__up_c_id
      color $gray-80
    &__name
      color $black
    &__second-name
      color $gray-50

.not-bold
  font-weight 500 !important
  color $gray-3 !important