
.request-of-solution-menu
  width 100%
  margin-top $default-header-pc_height
  .help_tab
    margin-bottom 36px
    ul
      display flex
      padding 0
      margin 0
      list-style none
      li
        width 100%
        text-align center
        border-bottom #a2a2a2 1px solid
        padding 10px 0
        a
          font-size 20px
          line-height 1.38
          letter-spacing -0.2px
          color #a2a2a2
        &[selected="true"]
          border-bottom-color #000000
          a
            font-weight 600
            color #000000
.request-of-solution
  margin-top $default-header-pc_height
  word-break keep-all
  min-height 700px
  .buttons
    margin-top 30px
    display flex
    margin-bottom 96px
    a
      cursor pointer
      display block
      padding 16px 0 14px
      text-align center
      margin-right 4px
      font-size 18px
      font-weight 600
      line-height 1.33
      letter-spacing -0.2px
      color #111111
      border-radius 27px
      border solid 1px #111111
      background-color #ffffff
      width 100%
      &:last-child
        margin-right 0
        margin-left 4px
        background #111
        color #fff !important
        border-color #111
  .request-of-solution-body
    color #111111
    >section.header
      margin-bottom 20px
    h2
      margin 0 0 16px
      font-size 22px
      font-weight 600
      line-height 1.27
      letter-spacing -0.2px
    li
      margin-bottom 16px
      a
        span
          text-decoration underline
    p
      letter-spacing -.5px
      margin 0 0 10px
      font-size 15px
      line-height 1.73
      letter-spacing -0.2px
      color #4e4e4e
      &.a2
        font-size 13px
        line-height 1.54
        color #a2a2a2
    .article
      margin 0 0 48px
      .org-list
        margin 0 0 8px
        padding 0
        li
          list-style none
          margin-right 8px
          font-size 0
          display inline-block
          vertical-align top
          margin-bottom 0
          border-radius 10px
          border solid 1.3px #dddddd
          background-color #ffffff
          img
            margin 26px 21px
          .copyright-img
            margin 26px 31px

    .request-of-solution-home
      .buttons
        margin-top 56px
      .process-img
        width 100%
        margin 20px 0
        font-size 0
        img
          width 100%

    .request-of-solution-add
      .select
        list-style none
        padding 0
        margin 0
        li
          margin-bottom 10px
          display flex
          cursor pointer
          font-size 15px
          line-height 1.4
          letter-spacing -0.2px
          color #4e4e4e
          .type-selector
            width 16px
            height 16px
            border-radius 100%
            padding 3px
            margin-right 10px
            border solid 1px #4e4e4e
            box-sizing border-box
            display flex
            justify-content center
            align-items center
          > div:last-child
            width 90%
          &.on
            .type-selector:after
              content ''
              display block
              border-radius 100%
              width 10px
              height 10px
              background-color #111111
              flex-shrink 0


    .request-of-solution-list
      .solution-list
        margin-bottom 40px
        .no-solution-list
          min-height 300px
          display flex
          justify-content center
          align-items center
      .solution-item
        .solution-item-wrapper
          padding 30px 32px 22px
          border-radius 8px
          background-color #f8f8f8
          margin-bottom 16px
          .top
            padding-bottom 24px
            border-bottom solid 1px #dddddd
            .head
              display flex
              justify-content space-between
              align-items center
              margin-bottom 14px
              .status
                padding 2px 5px 0
                border-radius 3px
                line-height 1.29
                letter-spacing -0.2px
                color #ffffff
                &[data-status="ing2"]
                &[data-status="close"]
                  background-color #a2a2a2
                &[data-status="ing"]
                &[data-status="confirm"]
                  background-color #1C75FF
              .dday
                font-size 13px
                font-weight 600
                line-height 1.54
                letter-spacing -0.2px
                color #1C75FF
                &[data-is-close="true"]
                  color #111
            .type
              font-size 24px
              font-weight 600
              line-height 1.33
              letter-spacing -0.2px
              color #111111
              margin-bottom 2px
            .created_at
              line-height 1.43
              letter-spacing -0.3px
              color #828282
          .bottom
            padding-top 22px
            .bottom-item
              display flex
              font-size 15px
              line-height 1.4
              letter-spacing -0.2px
              color #4e4e4e
              margin-bottom 8px
              .title
                color #a2a2a2
                margin-right 12px
    .request-of-solution-item
      margin-bottom 96px
      .info
        padding 30px 32px 22px
        border-radius 8px
        background-color #f8f8f8
        margin-bottom 48px
      .top
        padding-bottom 24px
        border-bottom solid 1px #dddddd
        .store-name
          font-size 24px
          font-weight 600
          line-height 1.33
          letter-spacing -0.2px
          color #111111
      .bottom
        padding-top 22px
        .flex
          display flex
          margin-bottom 8px
          font-size 15px
          line-height 1.4
          letter-spacing -0.2px
          .title
            color #a2a2a2
            width 180px
          .content
            flex 1
            color #4e4e4e
            a
              color #4e4e4e
      .solution-thread-info
        .solution-thread-item
          padding 32px
          border-radius 8px
          background-color #f8f8f8
          margin-bottom 16px
          &[is_seller="result"]
            background-color transparent
            margin-bottom 0
          &[is_seller="marppleshop"]
          &[is_seller="true"]
            background-color #fff
            border solid 1px #dddddd
            box-sizing border-box
          .content
            display flex
            .title
              font-size 18px
              font-weight 600
              line-height 1.33
              letter-spacing -0.2px
              color #111111
            .created_at
              font-size 13px
              line-height 1.54
              letter-spacing -0.2px
              color #828282
            .left
              position relative
              width 177px
            .content-wrapper
              width 100%
              box-sizing border-box
              margin-bottom 24px
              .comment
                margin 10px 0 28px
                font-size 15px
                line-height 1.73
                letter-spacing -0.2px
                color #4e4e4e
              .files
                margin-top 6px

    .system-reply
      font-size 13px
      line-height 1.54
      letter-spacing -0.2px
      color #4e4e4e
      margin-bottom 56px
      .status-message:before
        content 'ⓘ '
      .buttons
        margin 48px 0 0
      .reason
        width 100%
        textarea
          width 100%
          margin 0
          box-sizing border-box
          padding 16px 24px 14px
          border-radius 4px
          border solid 1px #dddddd
          font-size 16px
          letter-spacing -0.2px
          margin-top 6px
          resize none
          height 220px
          line-height 1.71
          &::placeholder
            color #a2a2a2
      .highlight
        font-weight 600
  .file-list
    .file-item
      margin 6px 0
      display flex
      align-items center
      font-size 15px
      line-height 1.73
      letter-spacing -0.2px
      a
        color #4e4e4e
        &:hover
          text-decoration underline
      button.remove-file
        margin-left 10px
        padding 3px 10px
        color #fff
        border 0 none
        border-radius 5px
        background-color #000
  .form
    .form-flex
      display flex
    .form-item2
      flex 1
      margin-bottom 32px
      align-items center
      .title
        font-weight 600
        font-size 16px
        line-height 1.38
        letter-spacing -0.2px
        color #111111
        margin-bottom 4px
      .content
        font-size 16px
        line-height 1.38
        letter-spacing -0.2px
        color #4e4e4e
        textarea
        input
          width 100%
          margin 0
          box-sizing border-box
          padding 16px 24px 14px
          border-radius 4px
          border solid 1px #dddddd
          font-size 16px
          line-height 1.38
          letter-spacing -0.2px
          margin-top 6px
          &::placeholder
            color #a2a2a2
        input
          height 52px
        textarea
          resize none
          height 220px
          line-height 1.71
        .check_result
          display flex
          margin-top 8px
          font-size 13px
          line-height 1.54
          letter-spacing -0.2px
          color #4e4e4e
          div
            margin-right 24px
            a
              text-decoration underline
              color #4e4e4e
      .buttons
        margin-top 14px
        #add_solution
          margin-right 0
          width 100%
      .file-title
        font-size 13px
      &.no-touch
        p
          font-size 12px
      &[is_hide="true"]
        display none
      .warning
        margin-bottom 8px
        font-size 13px
        line-height 1.54
        letter-spacing -0.2px
        color #4e4e4e
      &.warn2
        ol
        ul
          margin 0
        li
          font-size 13px
          margin-bottom 12px
        p
          font-size 14px
          margin 0 0 12px 0
      &.file
        .content
          input
            display none
          label
            cursor pointer
            width 380px
            height 52px
            margin 10px 197px 8px 0
            padding 16px 89px 14px
            border-radius 26px
            border solid 1px #dddddd
            box-sizing border-box
            text-align center
            display block
    .buttons
      margin 40px 0 0
    #add_solution
    #add_solution_thread
      position relative
      border-radius 4px
      button
        background transparent
        border 0
        padding 0
        position absolute
        top 0
        left 0
        width 100%
        height 100%
  .agree-box
    margin 14px 0 16px
    border-radius 6px
    padding 32px 32px 14px
    background-color #f8f8f8
    h5
      font-size 15px
      line-height 1.4
      letter-spacing -0.2px
      color #4e4e4e
      margin 0 0 6px
    .note
      line-height 1.43
      letter-spacing -0.2px
      color #4e4e4e
      ul
        padding 0 0 0 16px
        margin 0 0 18px
      li
        margin-bottom 6px
    &[type]
      .info
        display none
        h5
          display none
      &[type="copyright"]
        .info.copyright
          display block
      &[type="fake-product"]
        .info.fake-product
          display block
      &[type="internal-regulation"]
        .info.internal-regulation
          display block
    &[user-type="person"]
      .group-li
        display none
.request-of-solution-add-form
  padding 0 50px 50px

