/*
 * ------------------스토어---------------------
 * 이미지, 뱃지, (상품이름 || 제품이름), 가격, (상품이름 ? bp이름 : null), 좋아요 버튼
 *  - 스토어(상품)
 * not-like 이미지, 뱃지, (상품이름 || 제품이름), 가격, (상품이름 ? bp이름 : null)
 *  - 스토어(커뮤니티) (마지막)
 *  < 가로형
 *    - 리뷰(스토어안에서 전부)
*/
/*
 * ------------------스토어---------------------
 * 이미지, 뱃지, (상품이름 || 제품이름), 가격, (상품이름 ? bp이름 : null), 좋아요 버튼
 *  - 스토어(상품)
 * not-like 이미지, 뱃지, (상품이름 || 제품이름), 가격, (상품이름 ? bp이름 : null)
 *  - 스토어(커뮤니티) (마지막)
 *  < 가로형
 *    - 리뷰(스토어안에서 전부)
*/

.{a-p-i}--horizontal
  display flex
  padding 0
  margin 0 0 10px 0
  width 100%
  position relative
  box-sizing border-box
  .{a-p-i}
    &__badge
      display none
    &__top-info
      width 96px
      flex-shrink 0
    &__bottom-info
      margin 0
      padding-left 14px
      display flex
      flex-direction column
      justify-content center
      min-width 0
      box-sizing border-box
      width 100%
    &__like
      display none
