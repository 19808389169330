.new_maker_guide_layer.don_frame[is_modal="true"]
  >.don_wrapper
    width 608px !important
    >.header
      width 100%
      box-sizing border-box
      padding ptr(16px) ptr(16px) ptr(16px) ptr(24px)
      margin 0
      height ptr(56px)
      border-bottom 1px solid $gray-5
      >.title
        {$kr-16-b}

.dtg-download-guide
  padding ptr(32px)
  padding-bottom calc(env(safe-area-inset-bottom) + 136px)
  padding-bottom calc(constant(safe-area-inset-bottom) + 136px)
  &__content-container
    display flex
    flex-direction column
    row-gap ptr(8px)
  &__content-wrap
    display flex
    flex-direction column
    row-gap ptr(8px)
    padding-left ptr(9px)
  &__content
    display flex
    column-gap ptr(10px)
    &:before
      content '·'
      display block
  &__buttons
    position fixed
    bottom 0
    left 0
    right 0
    background-color $white
    padding ptr(16px) ptr(32px) ptr(32px) ptr(32px)
    border-top 1px solid $gray-5
    z-index 10
    display flex
    &:after
      display block
      content ''
      padding-bottom env(safe-area-inset-bottom)
      padding-bottom constant(safe-area-inset-bottom)
    & > button
      width 100%
      height ptr(56px)
      border-radius ptr(8px)
  &__btn_down_agree
    color $white
    border 0 none
    background-color $blue
    {$button-blue-theme}