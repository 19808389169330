

.app-nft
  position relative
  width 100%
  height 950px
  overflow hidden
  background-color #000
  &__header
    position absolute
    top 0
    left 0
    z-index 1
    height 80px
    background-color #000
    h1
      margin 0
      padding 24px 24px 0
  video
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -50%)
  &__txts
    position absolute
    top 50%
    left 50%
    width 700px
    transform translate(-50%, -50%)
    margin -20px 0 0 -300px
    padding 0
  &__txt1
    margin 0
    padding 0
    font-size 32px
    font-weight 600
    color #fff
    line-height 1.4
  &__txt2
    margin 15px 0 0
    padding 0
    font-size 20px
    color #fff
    line-height 1.4

