.tooltip-icon2
  display inline-block
  width pxToRem(20)
  height pxToRem(20)
  vertical-align middle
  margin-bottom 2px
  &:hover
    path
      fill black

.tooltip-container
  border-radius $studio_border_radius
  ul
    list-style disc
    margin-bottom 0
    margin-left 4px
    word-break break-all
