.setting-token-gate-check
  width 14px
  height 14px
  margin-left 1px
  margin-top 0
  margin-bottom 0

.setting-token-gate-open
  display flex
  align-items center

.setting-token-gate-empty
.setting-token-gate-list
  display none
.setting-token-gate-condition
  display none
  &--show
    margin-bottom 12px
    display flex
    align-items center
  &__or
  &__and
    display flex
    align-items center
    margin-right 20px
.setting-token-gate-empty
  &__icon
    width 15px
    height 15px
    margin 3px 6px 4px 0
    border solid 1px #111
    border-radius 50%
    color #111
    text-align center
    font-size 11px
    font-weight bold
.setting-token-gate-wrapper
  width 100%
  background-color #fff
  border-radius 3px
  padding 20px
  border solid 1px #ededed
  @media screen and (max-width 1024px)
    overflow-x auto
  &--empty
    .setting-token-gate-empty
      display flex
      align-items center
  &--list
    .setting-token-gate-list
      display block
      @media screen and (max-width 1024px)
        display table
        width 800px

.setting-token-gate-edit
  display flex
  margin 10px 0
  justify-content space-between
  .setting-token-gate-edit__address
  .setting-token-gate-edit__amount
  &__chain
    margin 0 10px 0 0
    height 40px
    display block
    padding 9px 10px
    font-weight 500
    font-size 14px
    line-height 1.57
    background-color #fff
    background-clip padding-box
    border 1px solid #ededed
    border-radius 3px
    transition border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
    outline none
    position relative
    &[disabled]
      color #a2a2a2
      @media screen and (max-width 1024px)
        padding-right 20px

  &__chain
    color #a2a2a2
    width 160px
    -webkit-appearance none
    -moz-appearance none
    border solid 1px #ededed !important
    border-radius 3px
    background url(//s3.marpple.co/files/u_193535/2022/11/original/e462add35987941d476946a8ee136154b8923dd11.svg) no-repeat calc(100% - 10px) center
    background-size 18px 18px
    &--black
      color #111111
    @media screen and (max-width 1024px)
      flex 1
  &__address
    flex 1
    @media screen and (max-width 1024px)
      flex unset
      width 162px

  &__amount
    width 100px
    display none
  &__add-btn
    flex-shrink 0
    display flex
    justify-content center
    align-items center
    background-color #3c89f9
    width 40px
    height 40px
    border none
    border-radius 3px
    box-shadow 0 2px 10px 0 rgba(0, 0, 0, 0.05)
    &:after
      content ''
      display block
      width 26px
      height 26px
      background-image url(//s3.marpple.co/files/u_29089/2022/9/original/deafe507b598d1d4d020b262fd7eebab1068ef2a1.svg)
      background-repeat no-repeat
      background-size 100%
    &[disabled]
      cursor default
      background-color #ededed
      &:after
        background-image url(//s3.marpple.co/files/u_29089/2022/9/original/32edbf2f30d74572ab9c87a091f399364fd943ba1.svg)

.setting-token-gate-item
  display flex
  justify-content space-between
  align-items center
  overflow hidden
  text-overflow ellipsis
  white-space nowrap
  margin-bottom 10px
  &:last-child
    margin-bottom 0
  &__chain
    width 12%
    display flex
    align-items center
  &__chain-icon
    display flex
    margin-right 4px
  &__address
    width 15%
    overflow hidden
    text-overflow ellipsis
    white-space nowrap
    a
      color #000
  &__name
    width 35%
    overflow hidden
    text-overflow ellipsis
    white-space nowrap
  &__erc-interface
    width 10%
    display flex
    align-items center
  &__erc-interface-box
    display inline-block
    align-items center
    border 1px solid rgb(112, 209, 53)
    color rgb(112, 209, 53)
    padding 3px 6px
    border-radius 3px
    font-size 12px
  &__symbol
    width 20%
    overflow hidden
    text-overflow ellipsis
    white-space nowrap
  &__last
    width 8%
    display flex
    align-items center
    justify-content flex-end
  &__remove
    background-color transparent
    background-position 50% 50%
    background-repeat no-repeat
    background-size 10px
    background-image url("//s3.marpple.co/files/u_14345/2021/5/original/e6fc7c6c93a1091aa268db9f6fa0730c8da9793d1.svg")
    width 20px
    height 20px
    border none
    outline none
    padding 10px
input::placeholder
  color #a2a2a2