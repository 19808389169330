// modules/BpOption/UploadType/F/Style/Pc/index.styl

.maker-product-info__upload-type
.maker-upload-type-mobile
  display none
body.maker_page
  #maker_frame
    &[data-maker_upload_type="DIRECT"]
      .maker-product-info__upload-type
        display block !important
      .maker_menu
      .decoration_menu_for_pc
      .enter_marpple_shop
        display none !important
      #marpplizer
        .general_option_buttons #to_vector_editor
          display none !important
        .bp_option_groups[data-is_designed="false"]
          .bp_option_group[data-dev_name="키링_자유_공통_사이즈"]
            display none !important
      &[data-upload_type_direct_is_designed="false"]
        .maker-upload-type-board
          display flex !important
      &[data-upload_type_direct_is_designed="true"][data-is_vector="false"]
        .canvass
          opacity 0 !important
      &[data-upload_type_direct_is_designed="true"]
      &[data-maker_type="DEFAULT"]
        .board__upload-type-direct-result
          display flex !important

#maker_frame
  .maker-upload-type-board
  .board__upload-type-direct-result
    justify-content center
    align-items center
    text-align center
    font-size 26px
    line-height 33.8px
    position absolute
    top 0
    right 0
    bottom 0
    left 0
  .maker-upload-type-template
    &__file-name
      limitLine(1)
      text-align left
    &__upload-description
      li
        display flex
        img
          width 18px
          height 18px
    &__title
      font-size 14px
      font-weight 500
      margin-bottom 16px
    > div
      margin-bottom 40px
      &:last-child
        margin-bottom 0
    button
      padding 13px
      font-size 14px
      font-weight 500
      outline none
      display flex
      justify-content center
      align-items center
      img
      svg
        margin-right 8px
    &__download-btn
      width 100%
    &__upload-btn
      color #fff
      width 100%
      margin-right 8px
    &__upload-description
      font-size 14px
      padding 16px
      line-height 140%
  .maker-upload-type-selector
    display flex
    flex-wrap nowrap
    cursor pointer
    svg
      width 24px
      height 24px
      html[marpplizer_mode="basic"] &
        margin-right 8px
    &__text
      display flex
      justify-content center
      align-items center
      flex-wrap wrap
      div
        line-height 140%
    .option
      html[marpplizer_mode="short"] &
        flex-direction column
        svg
          margin-bottom 4px
      width 100%
      display flex
      justify-content center
      align-items center
      padding 13px 0
      height auto
      text-align center
      flex-grow 1
      &:last-child
        margin-right 0
