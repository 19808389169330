#search-drawer
  .drawer__inner
    width pxToRem(520px)
    box-sizing border-box
    padding pxToRem(80px) pxToRem(48px)
  .drawer__header
    padding 0
    height fit-content
    > h2
      margin 0
      font-size pxToRem(28px)
      font-weight bold
      letter-spacing -0.2px
  .drawer__body
    padding 0
    margin-top 0
    .keyword-container
      width 100%
      margin pxToRem(20px) 0 pxToRem(80px) 0
    .search-history
      margin 0
      &__header
        display flex
        justify-content space-between
        align-items center
        margin-bottom pxToRem(20px)
      &__title
        font-size pxToRem(18px)
        font-weight bold
        letter-spacing -0.2px
        color #111
      &__delete-all-btn
        background-color #fff
        border none
        font-size pxToRem(13px)
        letter-spacing -0.2px
        color #828282
        padding 0
      &__items
        display flex
        flex-wrap wrap
        gap pxToRem(8px)
      &__item
        background-color #f5f9fe
        border-radius 20px
        color #111
        font-size pxToRem(15px)
        letter-spacing -0.2px
        padding pxToRem(9px) pxToRem(20px)
        display flex
        align-items center
      &__link
        max-width pxToRem(330px)
        text-overflow ellipsis
        white-space nowrap
        overflow hidden
      &__delete-btn
        border none
        width pxToRem(11.3px)
        height pxToRem(11.3px)
        background url(//s3.marpple.co/files/u_2232571/2023/5/original/8057bae073876906d963c37cca74670d447f39531.png) no-repeat center
        background-size pxToRem(18px)
        margin-left pxToRem(8px)
      &__no-result
        color #828282
        font-size pxToRem(15px)

    .search-history
      &__item
        cursor pointer

    .search-popular
      margin-top pxToRem(48px)
      &__title
        font-size pxToRem(18px)
        font-weight bold
        letter-spacing -0.2px
        color #111
        margin-bottom pxToRem(20px)
      &__items
        display flex
        flex-wrap wrap
        gap pxToRem(8px)
      &__item
        background-color #fff
        box-shadow 0 0 0 1px #ededed inset
        border-radius 20px
        color #111
        font-size pxToRem(15px)
        letter-spacing -0.2px
        padding pxToRem(9px) pxToRem(20px)
