/*
 * -----------------메인-----------------------
 * 이미지, 뱃지, (상품이름 || 제품이름) - 뱃지
 *  - 홈(한정상품)
*/

.{a-p-i}--v3
  width 100%
  margin-right 10px
  position relative
  .{a-p-i}
    &:last-child
      margin-right 0
    &__price
      display none
    &__store-name
      display none
    &__badge
      top auto
      left 30px
      bottom 72px
      border-radius 2px
    &__bottom-info
      position absolute
      left 30px
      right 30px
      bottom 20px
    &__p-name
      height 30px
      font-size 23px
      font-weight 600
      line-height 1.3
      letter-spacing -0.3px
      color #111111
      overflow hidden
      text-shadow 0 0 5px #f6f6f6
    &__img
      .mp-product-img
        max-width 80%
        max-height 80%
