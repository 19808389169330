.app-today
  min-height 80vh
  &__infinite__scroll
    margin-bottom 20px
    &__divider
      font-size 16px
      font-weight 600
      line-height 1.25
      letter-spacing -0.3px
      color #4e4e4e
      margin-top 32px
      margin-bottom 8px

.today-static-grid
  display flex
  &__column
    display flex
    flex-direction column
    flex 1 1 0
    // ie - flex shorthand 지원 안함
    flex-grow 1
    min-width 0
    min-height 0
    margin-right 40px
    &:last-child
      margin-right 0
  &__item
    margin-bottom 8px






