.{a-p-i}
  &__tags
    display flex
    margin-bottom 10px
  &__rank
    display inline-block
    margin-bottom pxToRem(10px)
    padding pxToRem(4px) pxToRem(14px) pxToRem(1px)
    border solid 1px #4e4e4e
    border-radius pxToRem(11px)
    span
      font-size pxToRem(15px)
      font-weight bold
      line-height 1
  &__top-info
    position relative
  &__bottom-info
    position relative
    height 119px
    margin-top pxToRem(14px)
  &__badge
    position absolute
    top pxToRem(16px)
    left pxToRem(16px)
    display flex
    padding 3px 8px
    border-radius 4px
    {$font-body-b3-r}
    color $white
    &--coming
      background-color $red-700
    &--sell_close
      background-color $sky-800
    &--sell_at
      background-color $sky-800
    &--real
      background-color $orange-800
    &--limit
      background-color $sky-800
    &--nft
      background-image $purple-800
    &--product-detail
      z-index 1
  &__p-name
    line-height 1.27
    letter-spacing -0.2px
    color #4e4e4e
    margin-right 14px
    margin-bottom 12px
    font-size 15px
    word-break keep-all
    text-overflow ellipsis
    white-space nowrap
    overflow hidden
  /* none / block 경계 */
  &__store-name
    display none
    height 18px
    font-weight 600
    line-height 1.38
    letter-spacing -0.2px
    color #111111
    margin-right 14px
    margin-bottom 2px
    text-overflow ellipsis
    white-space nowrap
    overflow hidden
  &__category-name
    display none
  &__like
    reset-button()
    display none
    border 0
    width 24px
    height 24px
    position absolute
    top 16px
    right 16px
  &__price
    {$font-body-b1-m}
    color $gray-800
  &__review-info
    margin-top 4px
    {$font-body-b3-m}
    color $gray-500
    column-gap 8px
    display flex
    &--hidden
      display none
  &__review-number
    {$font-body-en-b2-r}
    color $gray-500
  &__bottom-badge
    margin-top pxToRem(12px)
    padding pxToRem(4px) pxToRem(16px)
    height pxToRem(29px)
    border-radius 999px
    display inline-flex
    align-items center
    box-sizing border-box
    {$font-body-b3-r}
    .remain_time
      display inline-flex
      width pxToRem(50)
      margin-right pxToRem(3px)
    &--hidden
      display none
    &--black
      background-color $gray-200
      color $gray-700
    &--blue
      background-color $sky-200
      color $sky-800

