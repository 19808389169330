.email_text
  .btn
    background #fff
.cart-style-up-item__option
  display none
.checkout-order-info__1
  .pc
    display none !important
.mobile
  display block

.pre-shipping-order-item
  &__download-period
    color $gray-50
    text-align center
    display none
  &__price-set
    padding 0
    &__price
      min-width 55px
      text-align right
      limitLine(1)
      &:after
        {$kr-14-m}
    &__shipping-label
      min-width 50px
      limitLine(1)
      display flex
      column-gap 2px
      align-items center
      color $black
    &__basic
      margin-bottom pxToRem(8px)
    &__additional
      display none
    &__basic
    &__additional
      width 100%
      justify-content space-between
    &__basic
      display flex
    &__condition
      span
        color $gray-50
        &:nth-child(1)
          margin-right pxToRem(4px)
          margin-left pxToRem(5px)
          limitLine(1)
        &:nth-child(2)
          margin-right pxToRem(4px)
          limitLine(1)
    &__free-shipping
    &__condition
      display none
    &__label
      color $gray-600
      font-size pxToRem(13px)
      display flex
      align-items center
    &__content
      color $gray-800
      font-size pxToRem(13px)
  &__1
    flex-grow 1
    &__item
      padding pxToRem(24px) 0 pxToRem(12px)
      border-bottom 1px solid $gray-200
      &:last-child
        border-bottom 0

.pre-shipping-order-item
  &[data-delivery_type_id="SELLER"]
    .pre-shipping-order-item__price-set__label .mps-tooltip-icon
      display inline-block
  &[data-delivery_type_id="DIGITAL"]
    .pre-shipping-order-item__price-set__basic
      display none
    .pre-shipping-order-item__download-period
      display block
  &[data-has_condition="true"]
    .pre-shipping-order-item__price-set__condition
      display flex
  &[data-has_additional_shipping_price="true"]
    .pre-shipping-order-item__price-set__additional
      display flex
  &[data-free_shipping="true"]
    .pre-shipping-order-item__price-set__basic
      .pre-shipping-order-item__price-set__price
        display none
    .pre-shipping-order-item__price-set__free-shipping
      display flex


