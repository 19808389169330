button
  a
    width 100%
    height 100%
    display flex
    flex-direction column
    justify-content center
    color inherit
.customer-order
  &[data-can_revise_shipping="false"]
    .customer-order-footer-info__header__revise-button
      display none
  &__order-at-wrapper
    display flex
    align-items center
    padding 0 pxToRem(24px)
  &__main-body
    &.hide
      .customer-order__main-body
        &__header
          &__close
            transform rotate(0)
        &__store-orders-wrapper
          display none

  /* FROM UPITEM SHARE */
  .up-item__thumbnail-wrapper
    border-radius pxToRem(16px)
    overflow hidden
    display inherit
    div
    a
      display inherit
    img
      object-fit contain
      background #f6f6f6
  .up-item-thumbnail
    &__canvas-wrapper
      position relative
      width 100%
      padding-top 100%
      canvas
        background #f6f6f6
        position absolute
        width 200%
        height 200%
        top 0
        left 0
        transform scale(0.5);
        transform-origin 0 0
        border-radius pxToRem(4px)
  .up-item-option-infos
    width 100%
    height 100%
    span
      display inline-block
    &__divide
      margin 0 pxToRem(4px)
    &__flex
      display flex
      align-items center
    &__option
      overflow hidden
      white-space nowrap
      text-overflow ellipsis
  .up-item-text-option-infos
    width 100%
    height 100%
    &__option
      font-size pxToRem(13px)
      color $gray-600
    &__value
      font-size pxToRem(13px)
      color $gray-700
  .up-item-product-info
    width 100%
    height 100%
    color $gray-800
    display flex
    flex-direction column
    justify-content center
    &__up_c_id
      color $gray-500
    &__second-name
      color $gray-500
    &__store-name
      color $gray-800
      font-weight bold
      font-size pxToRem(15px)
      padding-top pxToRem(2px)
      margin-bottom pxToRem(6px)
    &__name
      &-value
        word-break keep-all
        color $gray-800
    .store-logo
      padding-left pxToRem(23px)
      background url(//s3.marpple.co/files/u_193535/2022/9/original/8ebf49d1f7a16946fce523b9cd2e619fe1ac3f7e5.svg) no-repeat left pxToRem(-1px)
      background-size pxToRem(18px) pxToRem(18px)
  .seller-delivery-type-logo
    display flex
    align-items center
    &:after
      content ""
      {$image-seller-delivery-logo}
      margin-left pxToRem(4px)
    &:before
      display none
  .marpple-delivery-type-logo
  .fast_marpple-delivery-type-logo
    display flex
    align-items center
    &:after
      content ""
      {$image-marpple-delivery-logo}
      margin-left pxToRem(4px)
    &:before
      display none
  .digital-delivery-type-logo
    display flex
    align-items center
    &:after
      content ""
      {$image-digital-delivery-logo}
      margin-left pxToRem(4px)
    &:before
      display none

.customer-order-to-orders
  width 100%
  height 100%
  &__button
    width 100%
    height 100%
    display flex
    background-color $gray-1
    justify-content center
    align-items center
    font-weight bold
    color #ffffff
    border none
.customer-order-footer-info
  &.customer-order-refund-info
    .mp_currency
      color #ec1131
  .bold
    font-weight bold
  &__header
    display flex
    justify-content space-between
    align-items center
    color $black
    &__title
      color $black
    &__revise-button,
    &__receipt-info-button
      color $blue
      border none
  &__body
    &__label
      color $gray-50
    &__content
      color $black
.customer-order-created-at
  display flex
  align-items flex-start
  column-gap pxToRem(8px)
  &__box-icon
    display none
  &__title
    color $gray-80
  &__at
    color $black
.up-item-buttons
  width 100%
  height 100%
  display flex
  flex-direction column
  justify-content center
  &__row
    display flex
    margin-bottom pxToRem(8px)
    &:last-child
      margin 0
  &__button
    padding 0
    flex 1
    display flex
    justify-content center
    align-items center
    width 100%
    border-radius ptr(8)
    border solid 1px #ddd
    background-color #fff
    {$font-body-b4-r}
    color $gray-700
    margin-right pxToRem(10px)
    height pxToRem(42px)
    &:last-child
      margin 0
  &__already-review
    span
      line-height 1.1
  &__review
    border-color #dddddd
    color $gray-2
  &__digital-product-download
  &__search-shipping
    color #4E4E4E
    border-color #dddddd
  &__download-img
    width pxToRem(9px)
    height pxToRem(10px)
    margin-right pxToRem(6px)

.shipping-order
  &__price-info
    color $gray-2
    border-top 1px dashed #f2f2f2
    box-sizing border-box
    display flex
    justify-content space-between
    width 100%
    padding pxToRem(15px) 0
    &__right
      font-size pxToRem(16px)
      font-weight bold
/*
 필정 새로운 class
 */
.up-item-options
  margin-top pxToRem(12px)
  display flex
  flex-direction column
  row-gap ptr(8)
  background-color $gray-5
  border-radius ptr(8)
  padding ptr(16)
  &__status-not-normal
    background $red-a
    font-weight bold
    color #fff
    margin-right pxToRem(8px)
    border-radius pxToRem(2px)
    font-size 12px
    padding pxToRem(4px) pxToRem(6px) pxToRem(3px)
  &__list
    display flex
    flex-direction column
    row-gap pxToRem(12px)
  &__item
    display flex
    justify-content space-between
    border-radius pxToRem(12px)

  &__left
    color $gray-80
  &__right
    flex-shrink 0
    color $gray-80
    &:after
      {$kr-12-m}



.up-item-text-options
  &__item
    display flex
    flex-direction column
  &__name
    color $gray-80
  &__value
    color $black

.up-item__thumbnail-wrapper
  img
    border-radius pxToRem(4px)
