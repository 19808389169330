.mshop-plus-product-list
  padding-top pxToRem(48px)
  max-width 1560px
  margin 0 auto
  &__features
    display flex
    align-items flex-start
    margin-bottom pxToRem(36px)
  &__filters
    display flex
    column-gap 8px
    row-gap 8px
    flex-wrap wrap
  &__sort-box
    margin-left auto
    flex-shrink 0
  &__filter-all
    reset-button()
  &__dropdown .mshop-comp-dropdown__button, &__filter-all
    padding pxToRem(8px) pxToRem(20px)
    border 1px solid $gray-200
    border-radius pxToRem(26px)
    display flex
    align-items center
    {$font-body-b1-r}
  &__filter-all
    &[data-active="true"]
      border-color $nbox-color
      color $nbox-color
  &__products
    overflow hidden



