.main-section
  &__table
    td[data-key = "진행상태"]
    td[data-key = "등록일시"]
    td[data-key = "주문번호"]
    td[data-key = "제목"]
    td[data-key = "상품명"]
    td[data-key = "이름"]
      max-width pxToRem(200px)
      min-width pxToRem(100px)
    td[data-key = "상품명"]
    td[data-key = "제목"]
      min-width pxToRem(200px)
      max-width pxToRem(300px)
    td
      text-overflow:ellipsis;
      overflow:hidden;
      word-break: break-all; // 모든 문자를 자른다. (단어 구분 없는 줄바꿈)
      white-space: nowrap;