html.creator .tippy-box[data-theme='mps-dark-tooltip-style1'],
.tippy-box[data-theme='mps-dark-tooltip-style1']
  box-shadow 0 2px 8px 0 rgba(0, 0, 0, 0.2)
  background-color rgba(17, 17, 17, 0.9)
  color #fff
  padding 10px
  border-radius 6px
  position relative
  .tippy-content
    line-height 1.54
    font-size 13px
