.app-mypage-recent
  margin-top 0
  &__lists-seller
    margin-top ptr(48)
    display grid
    grid-template-columns repeat(4, minmax(0, 1fr))
    grid-column-gap ptr(24)
    grid-row-gap ptr(28)
    &.no-result
      display unset

.no-recent-result
  display none
  &[data-len="0"]
    width 100%
    flexCenter(column)
    padding ptr(160) 0
    margin-bottom ptr(120)
  &__heart
    margin-bottom ptr(24)
  &__heart-img
    width ptr(160)
  &__title
    text-align center
    color $gray-80
    margin-bottom ptr(8)
  &__desc
    text-align center
    color $gray-50
    margin-bottom ptr(24)
  &__button
    width ptr(196)