.pb-me-modal-frame
  >.don_wrapper
    display flex
    flex-direction column
    width 900px
    height 740px
    transform rotate(0)
    >.header
      order 1
      display flex
      justify-content center
      align-items center
      border-bottom 1px solid #ededed
      .title
        font-size 16px
        font-weight 600
        letter-spacing -0.5px
        text-align center
    >.body
      order 2
.pb-me-modal
  padding 40px 40px 70px
  label
    cursor pointer
  &__header
    margin 0 0 40px
  &__title
    font-size 16px
    font-weight 600
    line-height 1.31
    letter-spacing -0.5px
    margin-bottom 8px
    display flex
  &__description
    font-size 14px
    letter-spacing -0.5px
    color #828282
  &__checked-all
    position relative
    display inline-flex
    align-items center
    margin 0 0 16px
  &__input-checkbox
    position absolute
    top 0
    left 0
    width 0
    height 0
  &__checkbox
    display block
    width 18px
    height 18px
    background-image url(//s3.marpple.co/files/u_29089/2023/3/original/4baa494958751358e0dfcf7de5d9a74f4b53a0fc1.webp)
    background-size 18px
    background-repeat no-repeat
    margin-right 6px
    &--checked
      background-image url(//s3.marpple.co/files/u_29089/2023/3/original/0d8db8245577f770ffa3f1b0e0dbd34fe4c8e2721.webp)
  &__body
    margin 0 -6px
    .infi_wrapper
      padding 0 6px
  &__footer
    background-color #fff
    border-top solid #ededed 1px
    padding 15px
    position fixed
    width 100%
    bottom 0
    left 0
    right 0
    display flex
    justify-content flex-end
  &__apply
    padding 12px 40px 11px
    border-radius 2px
    background-color #828282
    border 0
    text-align center
    color #fff !important
    &--active
      background-color #3c89f9
.pb-me-modal-item
  margin-bottom 32px
  position relative
  &__image
    margin-bottom 16px
    border-radius 6px
    overflow hidden
  &__name
    letter-spacing -0.5px
    text-align center
    color #111
    overflow hidden
    white-space nowrap
    text-overflow ellipsis
  &__checked-one
    position absolute
    top 10px
    right 1px
