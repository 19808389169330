
body.creator-settings
  .studio-info
    margin-top pxToRem(10px)
    &--cert
      margin-top 0
    &__header
      margin-bottom 10px
      display flex
      align-items center
      justify-content space-between
    &__header-btn
      button
        min-width 60px
        padding 9px 5px 8px
        border-radius 2px
        background-color #3c89f9
        border 0 none
        font-size 14px
        font-weight 600
        letter-spacing -0.5px
        text-align center
        color #fff !important
    &__h2
      margin 0
      padding 0
      font-size 15px
      font-weight 600
      font-stretch normal
      font-style normal
      letter-spacing -0.2px
      color #111
      &:before
        content '•'
      &--no-dotted:before
        content ''
    &__buttons
      margin-top 48px
      text-align center
    &__btn-save
      min-width 120px
      margin 0 10px 0 0
      padding 11px 10px
      color #fff !important
      border-radius 2px
      border solid 1px #3c89f9
      background-color #3c89f9
    &__btn-cancel
      min-width 120px
      padding 11px 10px
      color #4c4c4c
      font-size 14px
      font-weight 500
      letter-spacing -0.5px
      text-align center
      border-radius 2px
      border solid 1px #ddd
      background-color #fff
    &__help-box
      margin 20px 0 48px
      padding 20px
      border-radius 3px
      background-color #f8f8f8
    &__help-txt1
      margin 0
      padding 0
      font-size 14px
      font-weight 600
      line-height 1.29
      letter-spacing -0.2px
      color #111
    &__help-ul
      margin 10px 0 0
      padding 0
      list-style none
      color #111
    &__help-li
      font-size 13px
      line-height 1.54
      letter-spacing -0.2px
      color #757575
    &__help-confirm-box
      margin-top 12px
      font-size 14px
      font-weight 500
      line-height 1.36
      letter-spacing -0.2px
      color #000
      label
        display flex
        align-items center
      input
        margin 0 5px 2px 0



  .studio-info-form
    $btn_border_radius = pxToRem(4)
    &__row
      display flex
      align-items stretch
      justify-content space-between
      margin pxToRem(8px) 0
      padding 0
      border-radius pxToRem(4px)
      border solid 1px #f2f2f2
    &__btn-blue
      display inline-block
      padding pxToRem(11px) pxToRem(10px) pxToRem(10px)
      min-width pxToRem(100px)
      color #fff !important
      font-weight 500
      letter-spacing -0.5px
      text-align center
      border-radius $btn_border_radius
      background-color #3c89f9
      border 1px solid #3c89f9
      box-sizing border-box
      &:disabled
        background-color #F2F2F2
        color #A2A2A2 !important
        border 1px solid #F2F2F2
        cursor not-allowed
    &__btn-white
      display inline-block
      padding pxToRem(11px) pxToRem(10px) pxToRem(10px)
      min-width pxToRem(100px)
      color #000 !important
      font-weight 500
      letter-spacing -0.5px
      text-align center
      border-radius pxToRem(2px)
      background-color #fff
      border 1px solid #ddd
      box-sizing border-box
    &__btn-gray
      display inline-block
      padding pxToRem(11px) pxToRem(10px) pxToRem(10px)
      min-width pxToRem(100px)
      color #fff !important
      font-weight 500
      letter-spacing -0.5px
      text-align center
      border-radius pxToRem(2px)
      background-color $gray-3
      border 1px solid $gray-3
      box-sizing border-box
    &__control
      width 50%
      display flex
      align-items stretch
      &--width100
        width 100%
      &:nth-child(2)
        .studio-info-form__label
          border-left 1px solid #ededed
    &__label
      display flex
      align-items stretch
      justify-content space-between
      width 100%
      &--width100
        width 100%
      &--mr0
        margin-right 0 !important
      &--inline
        display inline-flex
        align-items center
        input
          margin-bottom pxToRem(4px)
          margin-right pxToRem(5px)
    &__label-txt
      width 240px
      padding 23px 25px
      box-sizing border-box
      background-color #fafafa
      border-right solid 1px #ededed
      line-height 1
      .tooltip-icon2--question
        position absolute
        margin-top -2px
    &__body-wrap
      padding 10px
      flex 1
      align-items center
      &--pl0
        padding-left 0
      &--p0
        padding 0
    &__body
      position relative
      &--mb-8
        margin-bottom 8px
      &--radio
        width 100%
        padding-left pxToRem(5px)
        border 0 none
      &--align-center
        display flex
        align-items center
        height 100%
      &--align-center-max-40
        height 40px
      &--error
        border-color #f66565
      label
        margin-right 20px
        display inline-block
      &--flex
        display flex
        align-items center

    &__body-textarea
      min-height: 200px;
      resize: none;
    &__body-input
    &__body-textarea
      width 100%
      padding 11px
      color #000
      box-sizing border-box
      border-radius 3px
      border solid 1px #ededed !important
      background-color transparent !important
      outline none
      &[disabled]
        border 1px solid #ededed !important
        background-color #f8f8f8 !important
      &--size1
        width 50%
        margin-right 5px
      &--align-right
        padding-right 2px
        text-align right
      &--error
        border-color #f66565 !important
      &--default-css
        &[disabled]
          color #000 !important
          border solid 1px #ededed !important
          background-color transparent !important
    &__select
      -webkit-appearance none
      -moz-appearance none
      border 1px solid $gray-s1
      border-radius 2px
      background $image-down-arrow no-repeat 95% center
      background-size 18px 18px
      width 100%
      height pxToRem(40px)
      text-indent 10px
      &--error
        border-color #f66565 !important
    &__body-textarea-length
      margin: 0;
      padding: 4px 6px 0 0;
      text-align: right;
      color: #828282;
      font-size pxToRem(13px)
      font-weight 500
      letter-spacing -0.3px
    &__txt1
      margin pxToRem(5px) pxToRem(2px) 0
      padding 0
      font-size pxToRem(13px)
      font-weight 500
      letter-spacing -0.3px
      line-height 1.67
      color #828282
      &--important
        padding-left 18px
        text-indent pxToRem(-18px)
    &__body-input-radio
      margin 0 4px 2px 0
      vertical-align middle
    &__icon-important
      margin-top 2px
      margin-left pxToRem(2px)
      display inline-block
      vertical-align top
      width pxToRem(15px)
      height pxToRem(15px)
      background url(//s3.marpple.co/files/u_218933/2022/11/original/b68ec617c1ff343b353f7fc0ab9c92d8fa40066f1.png) no-repeat
      background-size pxToRem(15px) pxToRem(15px)
      &--ml0
        margin-left 0
        margin-right pxToRem(2px)
    &__error-msg
      display none
      margin pxToRem(7px) 0 0
      padding 0
      font-size pxToRem(13px)
      font-weight 500
      line-height 1.38
      letter-spacing -0.5px
      color #f66565
      &--show
        display block
    &__search-btn
      margin-left 10px
    &__postcode-layer
      position absolute
      top 36px
      left 0
      right 0
      background #fff
    &__qna-type
      padding 8px 0
  .studio-alert
    &__title
      font-size pxToRem(20px) !important
    &__body
      margin-bottom pxToRem(10px)
    &__p
      font-size pxToRem(15px) !important
      text-align center !important


